import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useBalance, useNetwork } from 'wagmi';
import { constants } from '../helpers/constants';
import { normalizeEther, normalizeNumber, toFixed } from '../helpers/digits';
import useGetUSDPriceOfToken from '../hooks/useGetUSDPriceOfToken';
import { closeMblMenu } from '../redux/counterSlice';
import { MetamaskCompIcon } from './metamask/Metamask';
import UserId from './userId';
import { Tooltip } from '@mui/material';
import Icon, { ChainIcon } from './Icon';
import { UserImage } from './userImage';
import getChainName, {
  getCoinGeckoId,
  getCoinGeckoWrappedTokenId,
  getTokenName,
} from '../helpers/getChainName';
import { Bit5Logo } from './bit5Logo';
import { ConnectButtonWrapper, DisconnectButtonWrapper } from './Web3WalletProvider';
import { CheckInput } from './checkInput';
import { getSupportedChain } from '../helpers/getSupportedChain';
import { useGetUserInfo } from '../hooks/useGetUserInfo';
import PropTypes from 'prop-types';
import { useCartFunctions } from './cart/useCartFunctions';
import { useChainModal } from '@rainbow-me/rainbowkit';

export const useWalletInfo = ({ walletAddress, chainIdParam } = {}) => {
  const [chainId, setChainId] = useState(chainIdParam || constants.helpers.chainId);
  let { address } = useAccount();
  if (walletAddress) address = walletAddress;
  const { chain, chains } = useNetwork();
  const currentChain = getSupportedChain(chainId) || chain;
  const {
    data: balanceData,
    isError,
    isLoading,
  } = useBalance({
    address,
    chainId: currentChain?.id,
    watch: true,
  });
  const { data: wrappedTokenBalance } = useBalance({
    address,
    chainId: currentChain?.id,
    token: currentChain?.wrappedToken,
    watch: true,
  });
  const formattedBalance = normalizeEther(
    (isLoading || isError ? null : balanceData?.formatted) || null,
  );
  const [wbnbBalance, setWbnbBalance] = useState(null);
  const usdPrice = Object.values(useGetUSDPriceOfToken(getCoinGeckoId(chainId), 1)?.data || {})?.[0]
    ?.usd;

  useEffect(() => {
    setWbnbBalance(normalizeEther(wrappedTokenBalance?.value || 0));
  }, [address, wrappedTokenBalance, chain, chains]);

  useEffect(() => {
    if (chain && chains.some((c) => c.id === chain.id)) {
      const chainId = chains.find((c) => c.id === chain.id)?.hexId;
      setChainId(chainId);
    }

    return () => {
      setChainId(constants.helpers.chainId);
    };
  }, [chain, chains]);

  return {
    chainId,
    formattedBalance,
    wbnbBalance,
    usdPrice,
    totalBalance: formattedBalance + wbnbBalance,
  };
};

const useHooks = () => {
  const { address, isConnected } = useAccount();
  const router = useRouter();
  const dispatch = useDispatch();
  const { user } = useGetUserInfo(address);
  const { toggleCart, itemList } = useCartFunctions();
  const {
    data: balanceData,
    isError,
    isLoading,
  } = useBalance({
    address,
  });
  const { chainId, formattedBalance, usdPrice, wbnbBalance } = useWalletInfo();
  const { openChainModal } = useChainModal();

  return {
    address,
    isConnected,
    router,
    user,
    balanceData,
    isError,
    isLoading,
    chainId,
    formattedBalance,
    usdPrice,
    wbnbBalance,
    itemList,
    dispatch,
    openChainModal,
    toggleCart,
  };
};

const MblNavbar = ({ theme, onThemeChange }) => {
  const { mblMenu } = useSelector((state) => state.counter);
  const {
    address,
    isConnected,
    router,
    user,
    balanceData,
    isError,
    isLoading,
    chainId,
    formattedBalance,
    usdPrice,
    wbnbBalance,
    itemList,
    dispatch,
    openChainModal,
    toggleCart,
  } = useHooks();
  const [profileShow, setProfileShow] = useState(false);
  const [hoverCartState, setHoverCartState] = useState(false);

  const redirectToProfile = () => {
    if (router.asPath === `/profile/${user.walletAddress}`) {
      router.replace(`/user/${user.walletAddress}`);
    }
  };

  const supportedChains = constants.helpers.supportedChains;

  const handleItemDropdown = (e) => {
    const target = e.target.closest('li');

    if (!target.classList.contains('show')) {
      target.classList.add('show');
    } else {
      target.classList.remove('show');
    }
  };

  const nativeUSD = usdPrice * normalizeEther((isLoading || isError ? 0 : balanceData?.value) || 0);

  const nativeWUSD = usdPrice * wbnbBalance;

  const wrappedToken = getCoinGeckoWrappedTokenId(chainId);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1024) {
        dispatch(closeMblMenu());
      }
    });
  }, [dispatch]);

  const servicesData = [
    {
      id: 100,
      text: 'Universal Wrapper (Beta)',
      href: '/universal-wrapper',
    },
    {
      id: 99,
      href: 'https://docs.bit5.com/services/conversation-of-static-nft-to-dynamic-nft-dnft-.',
      text: 'Converting to dNFT',
      target: '_blank',
    },
    {
      id: 98,
      href: 'https://docs.bit5.com/services/nft-minting',
      text: 'NFT Minting',
      target: '_blank',
    },
    {
      id: 97,
      href: 'https://docs.bit5.com/services/token-minting',
      text: 'Token Minting',
      target: '_blank',
    },
    {
      id: 45,
      text: 'For Collection Owners',
      href: '/for-owners',
    },
  ];
  const exploreData = [
    {
      id: 11,
      text: 'Explore NFTs',
      href: '/collection/explore_nfts',
    },
    {
      id: 12,
      text: 'Collections',
      href: '/collection/explore_collection',
      New: false,
    },
    ...(isConnected
      ? [
          {
            id: 10,
            text: 'Bulk Tool',
            href: `/user/${address}?active=8#tabs`,
          },
        ]
      : []),
    {
      id: 31,
      text: 'Activity',
      href: '/activity',
      New: false,
    },
    {
      id: 32,
      text: 'Rankings',
      href: '/rankings',
    },
  ];
  const launchpadData = [
    {
      id: 40,
      text: 'Minting',
      href: '/launchpad',
    },
    {
      id: 41,
      text: 'Apply for Minting',
      href: 'https://docs.google.com/forms/d/e/1FAIpQLSfb0LK0nVypb1i1knzI8E_I_oqNEnb-mHwnxW-pfukvZZXfwg/viewform',
      target: '_blank',
    },
  ];
  const financeData = [
    {
      id: 50,
      text: 'Lending',
      href: constants?.helpers?.lending?.url ?? 'https://lending.bit5.com/',
      target: '_blank',
    },
  ];

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      dispatch(closeMblMenu());
    }
  };

  return (
    // <StaticContent>
    <div
      className={
        mblMenu
          ? 'js-mobile-menu nav-menu--is-open invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 dark:bg-jacarta-800 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent'
          : 'js-mobile-menu invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 dark:bg-jacarta-800 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent'
      }
    >
      {/* <!-- Mobile Logo / Menu Close --> */}
      <div className='t-0 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 dark:bg-jacarta-800 lg:hidden'>
        {/* <!-- Mobile Logo --> */}

        <Link href='/' passHref>
          <a onClick={() => dispatch(closeMblMenu())} onKeyDown={handleKeyDown}>
            <Bit5Logo />
          </a>
        </Link>

        {/* <!-- Mobile Menu Close --> */}
        <button
          className='js-mobile-close rounded-xl group ml-2 flex h-10 w-10 items-center justify-center border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
          onClick={() => dispatch(closeMblMenu())}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='24'
            height='24'
            className='h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
          >
            <path fill='none' d='M0 0h24v24H0z' />
            <path d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z' />
          </svg>
        </button>
      </div>

      {/* <!-- Primary Nav --> */}
      <nav className='navbar w-full'>
        <ul className='flex flex-col lg:flex-row'>
          <li className='js-nav-dropdown group relative mt-24 lg:mt-0'>
            <button
              className={
                'dropdown-toggle flex w-full items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5'
              }
              onClick={(e) => handleItemDropdown(e)}
            >
              <span>Marketplace</span>
              <i className='lg:hidden'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                  className='h-4 w-4 dark:fill-white'
                >
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                </svg>
              </i>
            </button>
            <ul
              className='dropdown-menu rounded-xl relative left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2'
              aria-labelledby='navDropdown-4'
            >
              {exploreData.map(({ id, text, href }) => {
                return (
                  <li key={id}>
                    <Link href={href}>
                      <a
                        className='rounded-xl flex items-center px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600'
                        onClick={() => {
                          dispatch(closeMblMenu());
                        }}
                        onKeyDown={handleKeyDown}
                      >
                        <span className={'font-display text-sm text-jacarta-700 dark:text-white'}>
                          {text}
                        </span>
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>

          <li className='js-nav-dropdown group relative'>
            <button
              className={
                'dropdown-toggle flex w-full items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5'
              }
              onClick={(e) => handleItemDropdown(e)}
            >
              <span>Finance</span>
              <i className='lg:hidden'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                  className='h-4 w-4 dark:fill-white'
                >
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                </svg>
              </i>
            </button>
            <ul
              className='dropdown-menu rounded-xl relative left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2'
              aria-labelledby='navDropdown-4'
            >
              {financeData.map(({ id, text, href, target }) => {
                return (
                  <li key={id}>
                    <Link href={href}>
                      <a
                        className='rounded-xl flex items-center px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600'
                        onClick={() => {
                          dispatch(closeMblMenu());
                        }}
                        onKeyDown={handleKeyDown}
                        {...(target && { target })}
                      >
                        <span className={'font-display text-sm text-jacarta-700 dark:text-white'}>
                          {text}
                        </span>
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>

          <Link href='/gaming'>
            <li
              className='js-nav-dropdown group relative'
              onClick={() => {
                dispatch(closeMblMenu());
              }}
              onKeyDown={handleKeyDown}
            >
              <button
                className={
                  'dropdown-toggle flex w-full items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5'
                }
                onClick={(e) => handleItemDropdown(e)}
              >
                <span>Gaming</span>
              </button>
            </li>
          </Link>

          <li className='js-nav-dropdown group relative'>
            <button
              className={
                'dropdown-toggle flex w-full items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5'
              }
              onClick={(e) => handleItemDropdown(e)}
            >
              {/* <span className='animate-gradient'>Launchpad</span> */}
              <span>Launchpad</span>
              <i className='lg:hidden'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                  className='h-4 w-4 dark:fill-white'
                >
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                </svg>
              </i>
            </button>
            <ul
              className='dropdown-menu rounded-xl relative left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2'
              aria-labelledby='navDropdown-4'
            >
              {launchpadData.map(({ id, text, href, target }) => {
                return (
                  <li key={id}>
                    <Link href={href}>
                      <a
                        {...(target && { target })}
                        className='rounded-xl flex items-center px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600'
                        onClick={() => {
                          dispatch(closeMblMenu());
                        }}
                        onKeyDown={handleKeyDown}
                      >
                        <span className={'font-display text-sm text-jacarta-700 dark:text-white'}>
                          {text}
                        </span>
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>

          <li className='js-nav-dropdown group relative'>
            <button
              className={
                'dropdown-toggle flex w-full items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5'
              }
              onClick={(e) => handleItemDropdown(e)}
            >
              <span>Services</span>

              <i className='lg:hidden'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                  className='h-4 w-4 dark:fill-white'
                >
                  <path fill='none' d='M0 0h24v24H0z' />
                  <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                </svg>
              </i>
            </button>
            <ul
              className='dropdown-menu rounded-xl relative left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2'
              aria-labelledby='navDropdown-4'
            >
              {servicesData.map(({ id, text, href, target }) => {
                return (
                  <li key={id}>
                    <Link href={href}>
                      <a
                        className='rounded-xl flex items-center px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600'
                        target={target}
                        onClick={() => {
                          dispatch(closeMblMenu());
                        }}
                        onKeyDown={handleKeyDown}
                      >
                        <span className={'font-display text-sm text-jacarta-700 dark:text-white'}>
                          {text}
                        </span>
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>

          {isConnected ? (
            <Fragment>
              <li className='js-nav-dropdown group relative lg:hidden'>
                <button
                  className={
                    'dropdown-toggle flex w-full items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5'
                  }
                  onClick={(e) => handleItemDropdown(e)}
                >
                  <span>Profile</span>

                  <i>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      width='24'
                      height='24'
                      className='h-4 w-4 dark:fill-white'
                    >
                      <path fill='none' d='M0 0h24v24H0z' />
                      <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                    </svg>
                  </i>
                </button>
                <ul
                  className='dropdown-menu rounded-xl relative left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2'
                  aria-labelledby='navDropdown-4'
                >
                  <div
                    className={
                      profileShow
                        ? 'dropdown-menu group-dropdown-hover:opacity-100 group-dropdown-hover:visible rounded-xl show relative !left-auto z-10  w-full whitespace-nowrap bg-white transition-all will-change-transform dark:border-jacarta-600 dark:bg-jacarta-800 lg:visible lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:opacity-100 lg:shadow-2xl'
                        : 'dropdown-menu group-dropdown-hover:opacity-100 group-dropdown-hover:visible rounded-xl relative !left-auto z-10 hidden w-full whitespace-nowrap bg-white transition-all will-change-transform dark:border-jacarta-600 dark:bg-jacarta-800 lg:invisible lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl'
                    }
                    onMouseEnter={() => setProfileShow(true)}
                    onMouseLeave={() => setProfileShow(false)}
                  >
                    {isConnected ? (
                      <div>
                        <UserId
                          classes='js-copy-clipboard font-display text-jacarta-700 my-4 flex select-none items-center whitespace-nowrap px-5 leading-none dark:text-white'
                          userId={isConnected ? address : 'Connect Your Wallet'}
                          shortId={true}
                          user={user}
                          nameClass={'text-lg'}
                        />
                        <div className='z-10 flex items-center lg:hidden'>
                          <span
                            className={
                              'flex w-full items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5'
                            }
                          >
                            Night Mode
                          </span>
                          <CheckInput checked={theme == 'dark'} onChange={onThemeChange} />
                        </div>
                        <div className='my-4 rounded-lg px-5'>
                          <div className='flex items-center'>
                            <br />
                            <ChainIcon
                              width={18}
                              name={chainId}
                              tooltip={getTokenName(chainId)}
                              sideGap
                            />
                            <span className='text-lg text-green'>{formattedBalance}</span>
                            &nbsp;
                            <Tooltip title={Number(nativeUSD)}>
                              <span className='text-dark mr-2 text-sm  '>
                                ~ ${Number(nativeUSD).toFixed(2)}
                              </span>
                            </Tooltip>
                          </div>
                          <div className='mt-3 flex items-center'>
                            <Icon
                              name={`icon-${wrappedToken}`}
                              tooltip={wrappedToken.toUpperCase()}
                            />
                            <span className='text-lg text-green  '>
                              {normalizeNumber(wbnbBalance)}
                            </span>
                            &nbsp;
                            <Tooltip title={Number(nativeWUSD)}>
                              <span className='text-dark mr-2 text-sm  '>
                                ~ ${Number(nativeWUSD).toFixed(2)}
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <Link
                            href={isConnected ? `/user/${address}` : `/login`}
                            className='inline-flex'
                          >
                            <a
                              onClick={() => {
                                dispatch(closeMblMenu());
                              }}
                              onKeyDown={handleKeyDown}
                              className='rounded-xl flex items-center space-x-2 px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600'
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 24 24'
                                width='24'
                                height='24'
                                className='h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white'
                              >
                                <path fill='none' d='M0 0h24v24H0z' />
                                <path d='M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z' />
                              </svg>
                              <span className='mt-1 font-display text-sm text-jacarta-700 dark:text-white'>
                                My Profile
                              </span>
                            </a>
                          </Link>
                          <Link
                            href={isConnected ? `/profile/${address}` : `/login`}
                            className='inline-flex'
                          >
                            <a
                              onClick={() => {
                                dispatch(closeMblMenu());
                              }}
                              onKeyDown={handleKeyDown}
                              className='rounded-xl flex items-center space-x-2 px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600'
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 24 24'
                                width='24'
                                height='24'
                                className='h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white'
                              >
                                <path fill='none' d='M0 0h24v24H0z' />
                                <path d='M9.954 2.21a9.99 9.99 0 0 1 4.091-.002A3.993 3.993 0 0 0 16 5.07a3.993 3.993 0 0 0 3.457.261A9.99 9.99 0 0 1 21.5 8.876 3.993 3.993 0 0 0 20 12c0 1.264.586 2.391 1.502 3.124a10.043 10.043 0 0 1-2.046 3.543 3.993 3.993 0 0 0-3.456.261 3.993 3.993 0 0 0-1.954 2.86 9.99 9.99 0 0 1-4.091.004A3.993 3.993 0 0 0 8 18.927a3.993 3.993 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.121 3.993 3.993 0 0 0 4 11.999a3.993 3.993 0 0 0-1.502-3.124 10.043 10.043 0 0 1 2.046-3.543A3.993 3.993 0 0 0 8 5.071a3.993 3.993 0 0 0 1.954-2.86zM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' />
                              </svg>
                              <span className='mt-1 font-display text-sm text-jacarta-700 dark:text-white'>
                                Edit Profile
                              </span>
                            </a>
                          </Link>
                          <DisconnectButtonWrapper>
                            <button className='inline-flex' data-testid='sign-out'>
                              <a
                                onClick={() => {
                                  dispatch(closeMblMenu());
                                }}
                                onKeyDown={handleKeyDown}
                                className='rounded-xl flex items-center space-x-2 px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600'
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  viewBox='0 0 24 24'
                                  width='24'
                                  height='24'
                                  className='h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white'
                                >
                                  <path fill='none' d='M0 0h24v24H0z' />
                                  <path d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11V8l-5 4 5 4v-3h8v-2H7z' />
                                </svg>
                                <span className='mt-1 font-display text-sm text-jacarta-700 dark:text-white'>
                                  Sign out
                                </span>
                              </a>
                            </button>
                          </DisconnectButtonWrapper>
                        </div>
                      </div>
                    ) : (
                      <ConnectButtonWrapper>
                        <div className='mt-2'>
                          <button className='rounded-2xl hover:bg-accent-hover flex w-full items-center justify-center border border-transparent bg-accent px-5 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2'>
                            Connect Wallet
                          </button>
                        </div>
                      </ConnectButtonWrapper>
                    )}
                  </div>
                </ul>
              </li>
            </Fragment>
          ) : (
            <Fragment>
              {/* <li className='z-10 flex items-center lg:hidden'>
                <span
                  className={
                    'flex w-full items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5'
                  }
                >
                  Night Mode
                </span>
                <CheckInput checked={theme == 'dark'} onChange={onThemeChange} />
              </li> */}
              <ConnectButtonWrapper>
                <div className='mt-2'>
                  <button className='rounded-2xl hover:bg-accent-hover flex w-full items-center justify-center border border-transparent bg-accent px-5 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2 lg:hidden'>
                    Connect Wallet
                  </button>
                </div>
              </ConnectButtonWrapper>
            </Fragment>
          )}
        </ul>
      </nav>

      {/* <!-- Actions --> */}
      <div className='ml-8 hidden space-x-2 lg:flex xl:ml-12'>
        {/* <!-- Chain Seelectbox --> */}
        {isConnected && (
          <>
            <div
              data-testid='cart'
              className='js-dark-mode-trigger group relative flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
              onMouseEnter={() => {
                setHoverCartState(true);
              }}
              onMouseLeave={() => {
                setHoverCartState(false);
              }}
              onClick={() => {
                toggleCart(true);
              }}
            >
              <div
                className={`absolute right-0 top-0 flex h-[20px] w-[20px] items-center justify-center rounded-full ${
                  hoverCartState ? 'bg-white text-accent' : 'bg-accent text-white'
                } text-center text-[10px]`}
              >
                {itemList?.length}
              </div>

              {hoverCartState ? (
                <svg
                  width='40'
                  height='40'
                  viewBox='0 0 40 40'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className=''
                >
                  <circle cx='20' cy='20' r='20' fill='#8358FF' />
                  <path
                    d='M23.4501 32.0002C24.0058 32.0002 24.4563 31.5497 24.4563 30.994C24.4563 30.4383 24.0058 29.9878 23.4501 29.9878C22.8943 29.9878 22.4438 30.4383 22.4438 30.994C22.4438 31.5497 22.8943 32.0002 23.4501 32.0002Z'
                    fill='white'
                    stroke='white'
                    strokeWidth='0.5'
                  />
                  <path
                    d='M12.8649 32.0002C13.4206 32.0002 13.8711 31.5497 13.8711 30.994C13.8711 30.4383 13.4206 29.9878 12.8649 29.9878C12.3091 29.9878 11.8586 30.4383 11.8586 30.994C11.8586 31.5497 12.3091 32.0002 12.8649 32.0002Z'
                    fill='white'
                    stroke='white'
                    strokeWidth='0.5'
                  />
                  <path
                    d='M26.8675 18.7724L25.6928 24.7458C25.5928 25.3206 25.193 25.7205 24.6681 25.7205L14.496 26.1954L13.2213 28.2198H24.2432C24.8181 28.2198 25.2929 28.7196 25.2929 29.2945C25.2929 29.4944 25.118 29.7194 24.918 29.7194C24.7181 29.7194 24.5431 29.5194 24.5431 29.3195C24.5431 29.1695 24.4182 28.9696 24.2432 28.9696H13.3213C12.8964 28.9696 12.5965 28.8946 12.4465 28.6197C12.2466 28.2448 12.4965 27.8949 12.5465 27.8199L13.8461 25.7705L10.847 15.7983C10.797 15.6983 10.3971 15.4234 9.29741 15.4234C9.29741 15.4234 8.89678 15.4389 9.02549 14.986C9.02549 14.986 9.05673 14.6853 9.67231 14.7236C11.2469 14.7986 11.5468 15.4484 11.5968 15.6483L11.9717 16.898L25.8428 17.7477C26.3926 17.7477 26.8675 18.1976 26.8675 18.7724ZM26.1177 18.6725C26.0927 18.5475 25.9677 18.4225 25.8178 18.4225L12.1966 17.6228L14.5459 25.4706L24.6431 24.9707C24.893 24.9707 24.943 24.6958 24.968 24.6208L26.1177 18.6725Z'
                    fill='white'
                    stroke='white'
                    strokeWidth='0.5'
                  />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-full hover:hidden'
                  viewBox='0 0 40 40'
                  fill='none'
                >
                  {/* <circle cx='20' cy='20' r='19.5' fill={'dark:#32344b ligth:white'} stroke={'light:#E7E8EC dark:none'} /> */}
                  <path
                    d='M23.4501 32.0001C24.0058 32.0001 24.4563 31.5496 24.4563 30.9939C24.4563 30.4382 24.0058 29.9877 23.4501 29.9877C22.8943 29.9877 22.4438 30.4382 22.4438 30.9939C22.4438 31.5496 22.8943 32.0001 23.4501 32.0001Z'
                    fill={'#8358FF'}
                    stroke={'#8358FF'}
                    strokeWidth='0.5'
                  />
                  <path
                    d='M12.8649 32.0001C13.4206 32.0001 13.8711 31.5496 13.8711 30.9939C13.8711 30.4382 13.4206 29.9877 12.8649 29.9877C12.3091 29.9877 11.8586 30.4382 11.8586 30.9939C11.8586 31.5496 12.3091 32.0001 12.8649 32.0001Z'
                    fill={'#8358FF'}
                    stroke={'#8358FF'}
                    strokeWidth='0.5'
                  />
                  <path
                    d='M26.8675 18.7722L25.6928 24.7455C25.5928 25.3204 25.193 25.7202 24.6681 25.7202L14.496 26.1951L13.2213 28.2195H24.2432C24.8181 28.2195 25.2929 28.7194 25.2929 29.2942C25.2929 29.4942 25.118 29.7191 24.918 29.7191C24.7181 29.7191 24.5431 29.5192 24.5431 29.3192C24.5431 29.1693 24.4182 28.9693 24.2432 28.9693H13.3213C12.8964 28.9693 12.5965 28.8944 12.4465 28.6194C12.2466 28.2445 12.4965 27.8946 12.5465 27.8197L13.8461 25.7702L10.847 15.798C10.797 15.6981 10.3971 15.4231 9.29741 15.4231C9.29741 15.4231 8.89678 15.4386 9.02549 14.9858C9.02549 14.9858 9.05673 14.6851 9.67231 14.7233C11.2469 14.7983 11.5468 15.4481 11.5968 15.6481L11.9717 16.8977L25.8428 17.7475C26.3926 17.7475 26.8675 18.1974 26.8675 18.7722ZM26.1177 18.6722C26.0927 18.5473 25.9677 18.4223 25.8178 18.4223L12.1966 17.6225L14.5459 25.4703L24.6431 24.9705C24.893 24.9705 24.943 24.6955 24.968 24.6206L26.1177 18.6722Z'
                    fill={'#8358FF'}
                    stroke={'#8358FF'}
                    strokeWidth='0.5'
                  />
                  <path
                    d='M24.3125 16.64C23.9627 16.64 23.6483 16.5734 23.3692 16.4402C23.092 16.3052 22.8706 16.1202 22.7051 15.8853C22.5395 15.6504 22.4511 15.3822 22.4399 15.0807H23.2771C23.2976 15.3249 23.4064 15.5256 23.6037 15.6828C23.8009 15.84 24.0371 15.9186 24.3125 15.9186C24.532 15.9186 24.7264 15.8687 24.8957 15.7688C25.0669 15.6671 25.2008 15.5274 25.2976 15.3498C25.3962 15.1723 25.4455 14.9697 25.4455 14.7422C25.4455 14.511 25.3953 14.3047 25.2948 14.1235C25.1943 13.9422 25.0557 13.7998 24.879 13.6962C24.7041 13.5926 24.5032 13.5399 24.2762 13.538C24.1032 13.538 23.9292 13.5676 23.7543 13.6268C23.5795 13.686 23.4381 13.7637 23.3302 13.8599L22.5404 13.7434L22.8613 10.88H26.0036V11.6153H23.5785L23.3971 13.2051H23.4306C23.5423 13.0978 23.6902 13.0081 23.8743 12.936C24.0604 12.8638 24.2595 12.8278 24.4716 12.8278C24.8195 12.8278 25.1292 12.9101 25.4008 13.0747C25.6743 13.2393 25.8892 13.4641 26.0455 13.7489C26.2036 14.0319 26.2818 14.3575 26.2799 14.7256C26.2818 15.0936 26.198 15.422 26.0287 15.7105C25.8613 15.9991 25.6288 16.2266 25.3311 16.3931C25.0353 16.5577 24.6957 16.64 24.3125 16.64Z'
                    fill='white'
                  />
                </svg>
              )}
            </div>
            <div className='js-nav-dropdown group-dropdown relative'>
              <button
                onClick={openChainModal}
                data-testid='openChainModal'
                className='js-dark-mode-trigger group flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
              >
                <ChainIcon
                  width={20}
                  name={chainId}
                  tooltip={getChainName(chainId)}
                  placement={'top'}
                  type={'chain'}
                />
              </button>
              {/* <div className='dropdown-menu group-dropdown-hover:opacity-100 group-dropdown-hover:visible rounded-xl !-right-4 !top-[85%] !left-auto z-10 hidden space-y-2 whitespace-nowrap border border-jacarta-100 bg-white transition-all will-change-transform dark:border-jacarta-600 dark:bg-jacarta-800 lg:invisible lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl'>
                {supportedChains.map(({ id, name, hexId }) => (
                  <div
                    key={`chain-${id}`}
                    className='rounded-xl flex cursor-pointer items-center px-5 py-3 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600'
                    onClick={() => {
                      switchNetwork(id);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        switchNetwork(id);
                      }
                    }}
                  >
                    <div className='flex items-center space-x-2'>
                      <ChainIcon width={17} name={hexId} tooltip={null} type={'chain'} />
                      <span className='font-display text-sm text-jacarta-700 dark:text-white'>
                        {name}
                      </span>
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          </>
        )}
        {/* <!-- Wallet --> */}
        {isConnected ? (
          <div className='js-nav-dropdown group-dropdown relative'>
            <button
              className='dropdown-toggle group flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
              onMouseEnter={() => setProfileShow(true)}
              onMouseLeave={() => setProfileShow(false)}
            >
              <div className='h-full w-full'>
                {<UserImage user={user || { walletAddress: address }} />}
              </div>
            </button>

            <div
              className={`
                ${
                  profileShow
                    ? 'show lg:visible lg:opacity-100'
                    : 'hidden lg:invisible lg:opacity-0'
                } 
                dropdown-menu group-dropdown-hover:opacity-100 group-dropdown-hover:visible rounded-xl show !-right-4 !top-[85%] !left-auto z-10 whitespace-nowrap border border-jacarta-100 bg-white transition-all will-change-transform dark:border-jacarta-600 dark:bg-jacarta-800 lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl`}
              onMouseEnter={() => setProfileShow(true)}
              onMouseLeave={() => setProfileShow(false)}
            >
              <UserId
                classes='ml-auto js-copy-clipboard font-display text-jacarta-700 flex select-none items-center whitespace-nowrap px-5 leading-none dark:text-white'
                userId={isConnected ? address : 'Connect Your Wallet'}
                shortId={true}
                user={user}
                nameClass={'text-lg'}
              />
              <div className='z-10 mt-2 flex items-center'>
                <span
                  className={
                    'flex w-full items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-3'
                  }
                >
                  Night Mode
                </span>
                <CheckInput checked={theme == 'dark'} onChange={onThemeChange} />
              </div>
              <div className='rounded-lg pb-4 pt-2'>
                <div className='flex items-center'>
                  <br />
                  <ChainIcon
                    width={18}
                    className={'ml-auto'}
                    name={chainId}
                    tooltip={getTokenName(chainId)}
                    sideGap
                  />
                  <Tooltip title={formattedBalance}>
                    <span className='text-lg text-green  '>{toFixed(formattedBalance)}</span>
                  </Tooltip>
                  &nbsp;
                  <Tooltip title={Number(nativeUSD)}>
                    <span className='text-dark mr-2 text-sm  '>
                      ~ ${Number(nativeUSD).toFixed(2)}
                    </span>
                  </Tooltip>
                </div>
                <div className='mt-3 flex items-center'>
                  <span className='ml-auto'>
                    <Icon name={`icon-${wrappedToken}`} tooltip={wrappedToken.toUpperCase()} />
                  </span>
                  <Tooltip title={normalizeNumber(wbnbBalance)}>
                    <span className='text-lg text-green  '>{toFixed(wbnbBalance)}</span>
                  </Tooltip>
                  <Tooltip title={Number(nativeWUSD)}>
                    <span className='text-dark mr-2 text-sm'>
                      ~ ${Number(nativeWUSD).toFixed(2)}
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className='flex flex-col items-end'>
                <Link href={isConnected ? `/user/${address}` : `/login`} className='inline-flex'>
                  <a
                    className='rounded-xl flex items-center space-x-2 px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600'
                    data-testid='my-profile'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      width='24'
                      height='24'
                      className='h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white'
                    >
                      <path fill='none' d='M0 0h24v24H0z' />
                      <path d='M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z' />
                    </svg>
                    <span className='mt-1 font-display text-sm text-jacarta-700 dark:text-white'>
                      My Profile
                    </span>
                  </a>
                </Link>
                <Link href={isConnected ? `/profile/${address}` : `/login`} className='inline-flex'>
                  <a
                    className='rounded-xl flex items-center space-x-2 px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600'
                    data-testid='edit-profile'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      width='24'
                      height='24'
                      className='h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white'
                    >
                      <path fill='none' d='M0 0h24v24H0z' />
                      <path d='M9.954 2.21a9.99 9.99 0 0 1 4.091-.002A3.993 3.993 0 0 0 16 5.07a3.993 3.993 0 0 0 3.457.261A9.99 9.99 0 0 1 21.5 8.876 3.993 3.993 0 0 0 20 12c0 1.264.586 2.391 1.502 3.124a10.043 10.043 0 0 1-2.046 3.543 3.993 3.993 0 0 0-3.456.261 3.993 3.993 0 0 0-1.954 2.86 9.99 9.99 0 0 1-4.091.004A3.993 3.993 0 0 0 8 18.927a3.993 3.993 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.121 3.993 3.993 0 0 0 4 11.999a3.993 3.993 0 0 0-1.502-3.124 10.043 10.043 0 0 1 2.046-3.543A3.993 3.993 0 0 0 8 5.071a3.993 3.993 0 0 0 1.954-2.86zM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' />
                    </svg>
                    <span className='mt-1 font-display text-sm text-jacarta-700 dark:text-white'>
                      Edit Profile
                    </span>
                  </a>
                </Link>
                <DisconnectButtonWrapper>
                  <button className='inline-flex' onClick={redirectToProfile}>
                    <a
                      className='rounded-xl flex items-center space-x-2 px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600'
                      data-testid='sign-out'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width='24'
                        height='24'
                        className='h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white'
                      >
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11V8l-5 4 5 4v-3h8v-2H7z' />
                      </svg>
                      <span className='mt-1 font-display text-sm text-jacarta-700 dark:text-white'>
                        Sign out
                      </span>
                    </a>
                  </button>
                </DisconnectButtonWrapper>
              </div>
            </div>
          </div>
        ) : (
          <MetamaskCompIcon />
        )}
        {/* <!-- Dark Mode --> */}
        {/* <button
          className='js-dark-mode-trigger group flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
          onClick={() => {
            onThemeChange();
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='24'
            height='24'
            className='dark-mode-light h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden'
          >
            <path fill='none' d='M0 0h24v24H0z' />
            <path d='M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z' />
          </svg>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='24'
            height='24'
            className='dark-mode-dark hidden h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white'
          >
            <path fill='none' d='M0 0h24v24H0z' />
            <path d='M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z' />
          </svg>
        </button> */}
      </div>
    </div>
    // </StaticContent>
  );
};

MblNavbar.propTypes = {
  theme: PropTypes.string,
  onThemeChange: PropTypes.func,
};

export default MblNavbar;
