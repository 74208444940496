import React from 'react';
import 'tippy.js/dist/tippy.css';
import { useCartFunctions } from '../cart/useCartFunctions';
import { useFloorSweep } from '../floor-sweep/useFloorSweep';
import NFTItemCard from './nftItemCard';

const SelectedItem = ({ index, item, selectedItems }) => {
  const { itemList } = useCartFunctions();

  const { collectionAddress, itemId } = item;

  return (
    <>
      {selectedItems.length == 0 &&
      itemList.findIndex(
        (f) => f.collectionAddress == collectionAddress && f.itemId == item.itemId,
      ) > -1 ? (
        <div
          className='rounded-2.5xl w-full bg-accent p-[2px]'
          key={`CategoryItemIC/${collectionAddress ?? index}/${itemId ?? index}`}
        >
          <NFTItemCard
            // key={`CategoryItemIC/${item?.collectionAddress}/${item?.itemId}`}
            item={item}
            itemOwner={item.ownerObject}
            bidCount={item?._count?.orders ?? 0}
          />
        </div>
      ) : (
        <NFTItemCard
          // key={`CategoryItemIC/${item?.collectionAddress}/${item?.itemId}`}
          key={`CategoryItemIC/${collectionAddress ?? index}/${itemId ?? index}`}
          item={item}
          itemOwner={item.ownerObject}
          bidCount={item?._count?.orders ?? 0}
        />
      )}
    </>
  );
};

const LoadingItems = ({ withContainerClass }) => (
  <div
    className={
      withContainerClass
        ? 'grid grid-cols-2 gap-[0.875rem] md:grid-cols-3 lg:grid-cols-4'
        : 'grid w-full grid-cols-2 gap-[0.875rem] p-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-8'
    }
  >
    {Array(10)
      .fill()
      .map((item, index) => {
        return <NFTItemCard key={`CategoryItemICsk` + index} item={{}} skeleton />;
      })}
  </div>
);

const Sweeping = ({ index, item, selectedItems }) => {
  const { collectionAddress, itemId } = item;

  return (
    <div key={`CategoryItemIC/${collectionAddress ?? index}/${itemId ?? index}`}>
      <NFTItemCard key={`CategoryItemIC/${item?.collectionAddress}/${item?.itemId}`} item={item} />
    </div>
  );
};

const CategoryItem = ({ foo, loading, withContainerClass, selectedItems }) => {
  if (loading) return <LoadingItems />;

  return (
    <div
      className={
        withContainerClass
          ? 'grid grid-cols-2 gap-[0.875rem] md:grid-cols-3 lg:grid-cols-4'
          : 'grid w-full grid-cols-2 gap-[0.875rem] p-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-8'
      }
    >
      {foo?.length > 0 &&
        foo.map((item, index) => {
          return <Sweeping item={item} index={index} key={index} selectedItems={selectedItems} />;
        })}
    </div>
  );
};

export default CategoryItem;
