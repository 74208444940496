import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { constants } from '../helpers/constants';

export const ImageFixedAO = ({
  burned = false,
  image,
  resolution,
  alt,
  bottomRounded,
  skeletonMode,
  moonpetsAccessory,
  removeSkeletonModeBottomRounded,
  removeSkeletonModePadding,
  className,
  children,
}) => {
  const [src, setSrc] = useState(image);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    let newSrc = image;
    if (resolution && newSrc && newSrc.includes) {
      newSrc = newSrc.replace('w-540', `w-${resolution}`);
    }
    setSrc(newSrc);
  }, [image]);

  return (
    <div className={`group/item relative w-full pt-[100%]`}>
      {moonpetsAccessory && (
        <div
          style={{
            backgroundImage: `url(/standart.png)`,
            backgroundSize: 'cover',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            transition: 'opacity 0.3s ease-in-out',
          }}
          className='opacity-30 group-hover/item:opacity-80'
        />
      )}
      {
        !loaded && (
          <div
            className={`${
              skeletonMode && !removeSkeletonModePadding && 'p-2'
            } absolute top-0 left-0 h-full w-full`}
          >
            <div
              className={`${
                !removeSkeletonModeBottomRounded &&
                (bottomRounded || skeletonMode) &&
                'rounded-b-2lg'
              } ${className} skeleton h-full w-full rounded-t-2lg`}
            />
          </div>
        ) /*<div
      alt={alt}
      className={`${bottomRounded && 'rounded-b-2lg'} bg-jacarta-200 animate-pulse rounded-t-2lg bg-cover w-full absolute h-full top-0 left-0`}
      loading="lazy"
    />*/
      }
      {!skeletonMode && (
        <>
          <img
            onError={() => {
              setSrc(constants.helpers.noImage);
            }}
            onLoad={() => {
              setLoaded(true);
            }}
            src={src || constants.helpers.noImage}
            alt={alt}
            className={`${bottomRounded ? 'rounded-b-2lg' : ''} ${
              className ?? ''
            } absolute top-0 left-0 h-full w-full rounded-t-2lg bg-cover`}
            loading='lazy'
          />
          {children}
        </>
      )}

      {burned && (
        <div
          className={`${
            !removeSkeletonModeBottomRounded && (bottomRounded || skeletonMode) && 'rounded-b-2lg'
          } absolute top-0 left-0 h-full w-full rounded-t-2lg bg-white opacity-50`}
        />
      )}
    </div>
  );
};
