import { ethers } from 'ethers';

// for huge numbers
export const normalizeEther = (number, digits = 4) => {
  if (!number) return 0;
  try {
    if (Number.isInteger(number)) return Number(number);
    const ether = ethers.utils.formatEther(number);
    const value = Number(ether);
    const parsed = parseFloat(value);
    const fixed = parsed.toFixed(digits);

    // console.log("????", ether, value, parsed, fixed);
    return fixed;
  } catch (e) {
    return parseFloat(Number(number).toFixed(digits));
  }
};

// for small numbers
export const normalizeNumber = (number, digits = 4) => {
  if (!number) return 0;
  return parseFloat(Number(number).toFixed(digits));
};

// for number format
export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const toFixed = (number, digits = 4) => {
  if (!number) return 0;
  return Number(Number(number).toFixed(digits));
};

export const priceFormatter = (price, digits = 4) => {
  return new Intl.NumberFormat(undefined, {
    maximumFractionDigits: digits,
  }).format(price);
};

export const priceFormatterFixed = (price, digits = 4) => {
  return priceFormatter(toFixed(price, digits));
};
