import useSWR from 'swr';
import { useEffect, useState } from 'react';

export default function useAdaptor(key, fetcher, config) {
  const [mergedConfig, setMergedConfig] = useState({
    // switches
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    revalidateIfStale: true,
    shouldRetryOnError: true,

    // timeouts
    focusThrottleInterval: 1000 * 60 * 60,
    dedupingInterval: 2000,
  });

  useEffect(() => {
    setMergedConfig((prev) => {
      return {
        ...prev,
        ...config,
      };
    });
  }, []);

  const { data, error, mutate } = useSWR(key, fetcher, mergedConfig);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
