import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';
import { isAddress } from 'ethers/lib/utils.js';

export function getLendOrderFetcher(collectionAddress, itemId, refreshDate) {
  const enabled = !!collectionAddress && !!itemId && isAddress(collectionAddress);

  const config = {
    url: `${constants.api.url_new}/items/getLendingByItemId`,
    method: constants.httpMethods.get,
    params: { collectionAddress, itemId, refreshDate },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return { config, fetcher, enabled };
}
export default function useGetLendOrder(collectionAddress, itemId) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, enabled, fetcher } = getLendOrderFetcher(collectionAddress, itemId, refreshDate);
  return useAdaptor(enabled ? axios.getUri(config) : null, fetcher);
}
