// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'uwRegistar_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'defaultCollectionFee_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'defaultWrapFee_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'defaultUnwrapFee_',
        type: 'uint256',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [],
    name: 'Reentrancy',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'collectionAddress',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'dn404Address',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'dn404MirrorAddress',
        type: 'address',
      },
    ],
    name: 'CollectionWrapped',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'collectionAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'tokenIds',
        type: 'uint256[]',
      },
    ],
    name: 'UnWrapNFT',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'collectionAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'tokenIds',
        type: 'uint256[]',
      },
    ],
    name: 'WrapNFT',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: 'collectionAddresses_',
        type: 'address[]',
      },
      {
        internalType: 'uint256[][]',
        name: 'tokenIds_',
        type: 'uint256[][]',
      },
    ],
    name: 'batchUnwrapMultiCollectionNFT',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'collectionAddress_',
        type: 'address',
      },
      {
        internalType: 'uint256[]',
        name: 'tokenIds_',
        type: 'uint256[]',
      },
    ],
    name: 'batchUnwrapNFT',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: 'collectionAddresses_',
        type: 'address[]',
      },
      {
        internalType: 'uint256[][]',
        name: 'tokenIds_',
        type: 'uint256[][]',
      },
    ],
    name: 'batchWrapMultiCollectionNFT',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'collectionAddress_',
        type: 'address',
      },
      {
        internalType: 'uint256[]',
        name: 'tokenIds_',
        type: 'uint256[]',
      },
    ],
    name: 'batchWrapNFT',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'factoryAddress',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCollectionFee',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getUnwrapFee',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getWrapFee',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'collectionAddress_',
        type: 'address',
      },
    ],
    name: 'getWrappedCollection',
    outputs: [
      {
        internalType: 'address',
        name: 'dn404Address',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'dn404MirrorAddress',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'dn404Fee',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'collectionAddress_',
        type: 'address',
      },
    ],
    name: 'isWrappedCollection',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'newFee_',
        type: 'uint256',
      },
    ],
    name: 'setCollectionFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'newFee_',
        type: 'uint256',
      },
    ],
    name: 'setUnwrapFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'newFee_',
        type: 'uint256',
      },
    ],
    name: 'setWrapFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'collectionAddress_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId_',
        type: 'uint256',
      },
    ],
    name: 'unwrapNFT',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'vaultAddress',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount_',
        type: 'uint256',
      },
    ],
    name: 'withdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'collectionAddress_',
        type: 'address',
      },
    ],
    name: 'wrapCollection',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'collectionAddress_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId_',
        type: 'uint256',
      },
    ],
    name: 'wrapNFT',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
];
