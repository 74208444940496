import { useCallback, useContext, useState } from 'react';
import { BaseError, UnknownRpcError } from 'viem';
import { usePublicNodeWaitForTransaction } from './usePublicNodeWaitForTransaction';
import { ToastDescriptionWithTx } from '../components/DescriptionWithTx';
import { errorToast, successToast } from '../components/toast';
import { DataRefreshContext } from '../components/refreshContext';
import { useDispatch } from 'react-redux';
import { closeModal } from '../redux/counterSlice';

/// only show corrected parsed viem error
export function parseError(err) {
  if (err instanceof BaseError) {
    return err;
  }
  if (typeof err === 'string') {
    return new UnknownRpcError(new Error(err));
  }
  if (err instanceof Error) {
    return new UnknownRpcError(err);
  }
  return null;
}

export const useCatchTxError = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { refreshHooks } = useContext(DataRefreshContext);
  const { waitForTransaction } = usePublicNodeWaitForTransaction();
  const [txResponseLoading, setTxResponseLoading] = useState(false);

  const handleNormalError = useCallback((error) => {
    const err = parseError(error);
    console.log('err', err);
  }, []);

  const handleTxError = useCallback((error, hash) => {
    const err = parseError(error);
    console.log('err', err);
  }, []);

  const fetchWithCatchTxError = useCallback(
    async ({ callTx, toastMessage, chainId, keepModalOpen = false }) => {
      let tx = null;
      try {
        setLoading(true);
        tx = await callTx();
        const hash = typeof tx === 'string' ? tx : tx.hash;
        const receipt = await waitForTransaction({
          confirmations: chainId == '81457' ? 8 : 2,
          hash,
        });
        successToast(
          <ToastDescriptionWithTx txHash={hash} external txChainId={chainId}>
            {toastMessage}
          </ToastDescriptionWithTx>,
        );
        refreshHooks();
        if (!keepModalOpen) dispatch(closeModal());
        return receipt;
      } catch (err) {
        errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
      } finally {
        setLoading(false);
      }

      return null;
    },
    [waitForTransaction, handleNormalError, handleTxError],
  );

  const fetchTxResponse = useCallback(
    async (callTx) => {
      let tx = null;

      try {
        setTxResponseLoading(true);
        tx = await callTx();
        const hash = typeof tx === 'string' ? tx : tx.hash;
        return { hash };
      } catch (error) {
        console.log('error', error);
      } finally {
        setTxResponseLoading(false);
      }

      return null;
    },
    [handleNormalError, handleTxError],
  );

  return {
    fetchWithCatchTxError,
    fetchTxResponse,
    loading,
    txResponseLoading,
  };
};
