import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useWalletClient } from 'wagmi';
import { getContract } from 'wagmi/actions';
import abi from '../../helpers/abi';
import { getTokenBalance, cancelAllOrders } from '../../helpers/blockchainOperations';
import { normalizeEther } from '../../helpers/digits';
import wbnbABI from '../../helpers/wbnbABI';
import { closeModal } from '../../redux/counterSlice';
import { DataRefreshContext } from '../refreshContext';
import { ModalContainer } from './modalContainer';
import BulkToolBulkSell from '../bulkTool/BulkToolBulkSell';
import useGetListingBulk from '../../hooks/bulktool/useGetListingsBulk';
import { useCatchTxError } from '../../hooks/useCatchTxError';
import { useGetChain } from '../../hooks/useGetChain';
import { errorToast } from '../toast';
import { SwitchNetworkButton } from '../Button';
import useGetTokenPrice from '../../hooks/useGetTokenPrice';

const MODAL_KEY = 'bulkCancelModal';
const BulkCancelModal = () => {
  const { refreshHooks } = useContext(DataRefreshContext);
  const { address } = useAccount();
  const { modal } = useSelector((state) => state.counter);
  const data = modal?.data;
  const { fetchWithCatchTxError } = useCatchTxError();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { selectedItems, chain } = data;

  const { chain: currentChain, isChainActive } = useGetChain(chain);

  const [buttonText, setButtonText] = useState('Cancel Bulk Order');

  const [wbnbBalance, setWbnbBalance] = useState(0);

  const { data: walletClient } = useWalletClient();
  const { usdPrice: tokenPrice } = useGetTokenPrice(modal?.data?.chain);

  const collectionsWithItems = selectedItems.reduce((acc, item) => {
    // Check if a collection with the same address already exists in the accumulator
    const existingCollection = acc.find((col) => col.collectionAddress === item.collectionAddress);

    // If it exists, push the itemId to the existing collection's itemIds
    if (existingCollection) {
      existingCollection.itemIds.push(item.itemId);
    } else {
      // If it doesn't exist, create a new collection object
      acc.push({
        collectionAddress: item.collectionAddress,
        itemIds: [item.itemId],
      });
    }

    return acc;
  }, []);

  const listings = useGetListingBulk({
    walletAddress: address,
    collectionAddress: collectionsWithItems,
    findMany: true,
  }).data;

  const bulkListings = listings?.filter(
    (listing) => listing.bulkSignature !== undefined && listing.bulkSignature !== null,
  );
  const singleListings = listings?.filter(
    (listing) => listing.bulkSignature === undefined || listing.bulkSignature === null,
  );

  const wbnbContract = getContract({
    address: currentChain?.wrappedToken,
    abi: wbnbABI,
    walletClient,
    chainId: Number(data?.chain),
  });

  useEffect(() => {
    const updateWbnbBalance = async () => {
      const b = await getTokenBalance(wbnbContract, address);
      setWbnbBalance(normalizeEther(b));
    };

    if (address) updateWbnbBalance();
  }, [address, wbnbBalance, wbnbContract]);

  const marketplaceContract = getContract({
    address: currentChain?.marketplace,
    abi,
    walletClient,
    chainId: Number(currentChain.id),
  });

  const handleBulkCancel = async () => {
    if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
    try {
      setLoading(true);
      setButtonText('Canceling the bulk order...');

      if (bulkListings.length > 0 || singleListings.length > 0) {
        setButtonText('Canceling the old sell...');

        const contractFn = await cancelAllOrders(singleListings, bulkListings, currentChain);
        const receipt = await fetchWithCatchTxError({
          callTx: contractFn,
          chainId: currentChain.id,
          toastMessage: 'Listing cancelled!',
          keepModalOpen: true,
        });
        if (!receipt) return;
      }

      refreshHooks();

      dispatch(closeModal());
    } catch (err) {
      console.log(err);
      toast.error(err.reason ? err.reason : 'err:' + err);
    } finally {
      setLoading(false);
      setButtonText('Cancel Bulk Order');
    }
  };

  return (
    <ModalContainer modalKey={MODAL_KEY} title='Cancel Bulk Order'>
      {/* <Toaster position='top-right' /> */}
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        {selectedItems?.map((item) => (
          <div className='mt-4 flex flex-col' key={item.id}>
            <BulkToolBulkSell item={item} tokenPrice={tokenPrice} />
          </div>
        ))}
      </div>
      {/* <!-- end body --> */}
      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {!isChainActive ? (
            <SwitchNetworkButton chainId={currentChain.id} />
          ) : (
            <CustomButton isDisabled={loading} onClick={handleBulkCancel}>
              {buttonText}
            </CustomButton>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

const CustomButton = ({ children, isDisabled, ...props }) => {
  return (
    <button
      type='button'
      className={`${
        isDisabled ? 'opacity-40 ' : ''
      }rounded-xl bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark`}
      disabled={isDisabled}
      {...props}
      data-testid='button'
    >
      {children}
    </button>
  );
};

export default BulkCancelModal;
