import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { useSetMarketPlaceContractToWindow } from '../helpers/setMarketPlaceAbi';
import Footer from './footer';
import AcceptModal from './modal/acceptModal';
import BidsModal from './modal/bidsModal';
import BuyModal from './modal/buyModal';
import CancelModal from './modal/cancelModal';
import GiveGlobalBidModal from './modal/giveGlobalBidModal';
import LendOfferModal from './modal/giveLendOfferModal';
import SellModal from './modal/sellModal';
import TransferModal from './modal/transferModal';
import Wallet_modal from './modal/wallet_modal';
import Navbar from './navbar';
import AcceptBidModal from './modal/acceptBidModal';
import LendingModal from './modal/lendingModal';
import UpdateSellModal from './modal/updateSellModal';
import CancelSellModal from './modal/cancelSellModal';
import AcceptTermsModal from './modal/acceptTermsModal';
import ReportModal from './modal/reportModal';
import BulkSellModal from './modal/bulkSellModal';
import BulkCancelModal from './modal/bulkCancelModal';
import BulkUpdateSellModal from './modal/bulkUpdateSellModal';
import BulkBidsModal from './modal/bulkBidModal';
import UpdateBidModal from './modal/updateBidModal';
import UwWrapModal from './modal/uwWrapModal';
import UwUnwrapModal from './modal/uwUnwrapModal';
import UwCollWrapModal from './modal/uwCollWrapModal';

const modals = {
  sellModal: SellModal,
  bidsModal: BidsModal,
  lendOfferModal: LendOfferModal,
  buyModal: BuyModal,
  cancelModal: CancelModal,
  acceptModal: AcceptModal,
  giveGlobalBidModal: GiveGlobalBidModal,
  transferModal: TransferModal,
  acceptBidModal: AcceptBidModal,
  lendingModal: LendingModal,
  updateSellModal: UpdateSellModal,
  cancelSellModal: CancelSellModal,
  acceptTermsModal: AcceptTermsModal,
  reportModal: ReportModal,
  bulkSellModal: BulkSellModal,
  bulkCancelModal: BulkCancelModal,
  bulkUpdateSellModal: BulkUpdateSellModal,
  bulkbidsModal: BulkBidsModal,
  updateBidModal: UpdateBidModal,
  uwWrapModal: UwWrapModal,
  uwUnwrapModal: UwUnwrapModal,
  uwCollWrapModal: UwCollWrapModal,
};

export default function Layout({ children }) {
  const { modal, walletModal } = useSelector((state) => state.counter);
  useSetMarketPlaceContractToWindow();
  const Modal = modals[modal?.key] || (() => null);

  return (
    <>
      <Navbar />
      {walletModal && <Wallet_modal />}
      <Modal />
      <main>{children}</main>
      <Footer />
    </>
  );
}
