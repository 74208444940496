import { ChainIcon } from '../Icon';
import { useCartFunctions } from './useCartFunctions';
import { getTokenName } from '../../helpers/getChainName';
import { useState } from 'react';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { getItemImagePath } from '../../helpers/path';
import { priceFormatterFixed } from '../../helpers/digits';

const ListItem = ({ item, index, showDelete, setShowDelete }) => {
  const { removeItemFromCart, cartChainId } = useCartFunctions();

  let hexChainId = `0x${cartChainId.toString(16)}`;
  return (
    <div
      className={'flex flex-col transition-all duration-300 ease-in-out '.concat(
        // showDelete === index ? 'h-max' : 'h-[60px]',
        'h-[60px]',
      )}
    >
      <div
        className='group relative flex cursor-pointer items-center gap-2.5 px-2.5'
        onClick={() => setShowDelete(showDelete === index ? -1 : index)}
      >
        <div className='h-[32px] w-[32px] rounded-[6px]'>
          <img
            src={getItemImagePath(item, 'thumbnail')}
            alt=''
            className='min-h-[32px] min-w-[32px]'
          />
        </div>
        <div className='flex w-full items-center justify-center'>
          <div>
            <div className='text-[16px] text-[#000] dark:text-white'>
              {item.name} {!item.name && item.itemId ? `#${item.itemId}` : ''}
            </div>

            <div className='bounce block overflow-hidden text-[12px] text-[#8A8C95]'>
              <p
                className={[
                  'max-w-[170px]',
                  ('' + item.collection.name).length > 30 ? 'bounce-40' : '',
                  ('' + item.collection.name).length > 40 ? 'bounce-60' : '',
                  ('' + item.collection.name).length > 50 ? 'bounce-80' : '',
                  ('' + item.collection.name).length > 60 ? 'bounce-100' : '',
                ].join(' ')}
              >
                {item.collection.name}
              </p>
            </div>
          </div>

          {showDelete === index ? (
            <div className='ml-auto transition-all duration-300 hover:text-accent-light dark:text-jacarta-200 dark:hover:text-white'>
              <div
                onClick={() => {
                  setShowDelete(-1);
                  removeItemFromCart(item);
                }}
              >
                <RiDeleteBin2Line size={20} />
              </div>
            </div>
          ) : (
            <div className='ml-auto transition-all duration-300'>
              <div className='text-right text-[16px] text-[#000] dark:text-white'>
                <span className='flex items-center justify-end whitespace-nowrap'>
                  <ChainIcon name={hexChainId} tooltip={getTokenName(hexChainId)} sideGap={true} />
                  <span className='font-medium tracking-tight text-green'>{item.price}</span>
                </span>
              </div>
              <div className='text-right text-[12px] text-[#8A8C95]'>
                ${item.usdPrice ? priceFormatterFixed(item.usdPrice, 2) : 0}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const CartList = () => {
  const { itemList } = useCartFunctions();
  const [showDelete, setShowDelete] = useState(-1);
  return (
    <div className='w-full overflow-y-auto'>
      {itemList?.map((item, index) => {
        return (
          <ListItem
            key={`${item.collection + index}`}
            item={item}
            index={index}
            showDelete={showDelete}
            setShowDelete={(i) => {
              console.log('remove', i);
              setShowDelete(i);
            }}
          />
        );
      })}
    </div>
  );
};
