import { useEffect, useState } from 'react';
import { useCartFunctions } from '../../cart/useCartFunctions';
import { Tooltip } from '@mui/material';
import { AddToCartButton, RemoveFromCartButton } from '../cart/cartButtons';

export const CartBadge = ({ item }) => {
  const [isAddedCart, setIsAddedCart] = useState(false);
  const { itemList } = useCartFunctions();

  useEffect(() => {
    if (!itemList?.length) return setIsAddedCart(false);
    setIsAddedCart(
      itemList.findIndex(
        (f) => f.collectionAddress == item.collectionAddress && f.itemId == item.itemId,
      ) > -1,
    );
  }, [itemList, item?.collectionAddress, item?.itemId]);

  return (
    <Tooltip
      placement='top-end'
      // classes={{ tooltip: '!bg-[#FDFAFD] !transition-colors !duration-500' }}
      title={
        <div>
          <div className='flex w-full'>
            <span className='font-bold'>{isAddedCart ? 'Remove to cart' : 'Add to cart'}</span>
          </div>
        </div>
      }
      arrow
    >
      <div>
        {isAddedCart ? <RemoveFromCartButton item={item} /> : <AddToCartButton item={item} />}
      </div>
    </Tooltip>
  );
};
