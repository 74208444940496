'use client';

import React, { useState } from 'react';
import { BsDiscord } from 'react-icons/bs';

export default function WidgetBotDiscord({ server, channel }) {
  const [show, setShow] = useState(false);

  // server: '1057184902874939494', // Bit5
  // channel: '1057290290261655552', // #bit5,

  return (
    <div className={['hidden sm:flex', 'select-none flex-col items-end justify-end'].join(' ')}>
      <div
        className={[
          show ? 'opacity-1' : 'hidden translate-y-1 opacity-0',
          'flex transition-all duration-300',
        ].join(' ')}
        onMouseLeave={() => setShow(false)}
      >
        <iframe
          src={`https://discord.com/widget?id=${server}&theme=dark`}
          width='350'
          height='500'
          sandbox='allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts'
          className='rounded-[8px]'
        />
      </div>
      <div
        className={[
          'mt-1 h-[64px] w-[64px]',
          'flex items-center justify-center',
          'cursor-pointer rounded-full bg-accent',
        ].join(' ')}
        onClick={() => setShow((s) => !s)}
      >
        <BsDiscord size={32} color='white' />
      </div>
    </div>
  );
}
