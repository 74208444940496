/* eslint-disable @next/next/no-img-element */
export const Bit5Logo = ({ betaOff = true }) => {
  return (
    <div>
      <div className='relative block h-fit w-fit'>
        <img
          src='/icons/Bit5_logo_doublecolor_positive_RGB.svg'
          alt=''
          className='h-auto w-[60px] min-w-[60px] dark:hidden sm:w-[70px] sm:min-w-[70px] md:w-[100px] md:min-w-[100px]'
        />
        <img
          src='/icons/Bit5_logo_doublecolor_negative_RGB.svg'
          className='hidden h-auto w-[60px] min-w-[60px] dark:block sm:w-[70px] sm:min-w-[70px] md:w-[100px] md:min-w-[100px]'
          alt='Bit5 | Create. Collect. Connect.'
        />
        {!betaOff && (
          <div className='absolute bottom-[6px] -right-8 -rotate-90 rounded border px-1 text-xs opacity-60 dark:text-white sm:bottom-[8px] md:bottom-4'>
            Beta
          </div>
        )}
      </div>
    </div>
  );
};
