import '@rainbow-me/rainbowkit/styles.css';
import {
  ConnectButton,
  connectorsForWallets,
  RainbowKitProvider,
  darkTheme,
  lightTheme,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, useDisconnect, useSwitchNetwork, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import React, { useEffect, useState } from 'react';
import { constants } from '../helpers/constants';
import {
  walletConnectWallet,
  metaMaskWallet,
  rabbyWallet,
  rainbowWallet,
  coinbaseWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { safepalWallet } from './web3WalletConnectors/safePal';
import { useTheme } from 'next-themes';

const supportedChains = constants.helpers.supportedChains;

const { chains, publicClient, webSocketPublicClient } = configureChains(supportedChains, [
  publicProvider(),
]);

const projectId = '680a79ea8d1873053b92b970da717428';

export const Web3WalletProvider = ({ children }) => {
  const [isSafePal, setSafePal] = useState(false);
  const [count, setCount] = useState(0);

  const [wagmiClient, setWagmiClient] = useState(null);
  useEffect(() => {
    if (count < 5 && !isSafePal) {
      setTimeout(() => {
        if (
          (typeof window !== 'undefined' && window.ethereum && window.ethereum.isRabby) ||
          count == 4
        ) {
          const connectors = connectorsForWallets([
            {
              groupName: 'Recommended',

              wallets: [
                metaMaskWallet({ chains, projectId }),
                // trustWallet({ chains, projectId }),
                coinbaseWallet({ chains, projectId }),
                rainbowWallet({ chains, projectId }),
                walletConnectWallet({ chains, projectId }),
                safepalWallet({ chains, projectId }),
                rabbyWallet({ chains, projectId }),
                // ledgerWallet({ chains, projectId }),
              ],
            },
          ]);

          setWagmiClient(
            createConfig({
              connectors,
              autoConnect: true,
              publicClient,
              webSocketPublicClient,
            }),
          );

          setSafePal(true);
        }
        setCount(count + 1);
      }, 50);
    }
  }, [count]);

  if (!isSafePal || !wagmiClient) {
    return null;
  }

  return (
    <WagmiConfig config={wagmiClient}>
      {/* <RainbowKitProvider chains={chains}>{children}</RainbowKitProvider> */}
      <RainbowProvider>{children}</RainbowProvider>
    </WagmiConfig>
  );
};
const themeOptions = {
  accentColor: '#7b3fe4',
  accentColorForeground: 'white',
  borderRadius: 'large',
  fontStack: 'system',
  overlayBlur: 'small',
};

export const RainbowProvider = ({ children }) => {
  const { theme } = useTheme();

  return (
    <RainbowKitProvider
      chains={chains}
      theme={
        theme === 'dark'
          ? darkTheme({
              ...themeOptions,
              accentColor: '#8358FF',
              // ...darkTheme.accentColors.purple,
            })
          : lightTheme({
              ...themeOptions,
              accentColor: '#7444FF',
              // ...lightTheme.accentColors.purple,
            })
      }
    >
      {children}
    </RainbowKitProvider>
  );
};

export const ConnectButtonWrapper = ({ children }) => {
  return (
    <ConnectButton.Custom>
      {({ openConnectModal }) => <div onClick={openConnectModal}>{children}</div>}
    </ConnectButton.Custom>
  );
};

export const DisconnectButtonWrapper = ({ children }) => {
  const dc = useDisconnect();
  const { disconnect } = dc;
  return <div onClick={disconnect}>{children}</div>;
};

export const useSwitchNetworkWrapper = () => {
  const { chains, switchNetwork } = useSwitchNetwork();

  if (!switchNetwork) {
    return {
      chains,
      switchNetwork: () => {
        alert('Please Manually Switch Your Network to And Connect Your Wallet Again!');
      },
    };
  }

  return { chains, switchNetwork };
};

export const defaultChainId = supportedChains?.[0]?.hexId;
