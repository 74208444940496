import { Slider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState, useEffect, useContext } from 'react';
import 'react-range-slider-input/dist/style.css';
import { useFloorSweep } from './useFloorSweep';
import { useChainId, useAccount, useNetwork } from 'wagmi';
import { useCartFunctions } from '../cart/useCartFunctions';
import { setModal } from '../../redux/counterSlice';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { DataRefreshContext } from '../refreshContext';

export const CartRemove = () => {
  const { sweepingItems } = useFloorSweep();
  const { buyEnabled, removeItemFromCart, removeMultiFromCart } = useCartFunctions();
  const [toogleCartButton, setToogleCartButton] = useState(false);

  const removeCart = async () => {
    await removeMultiFromCart(sweepingItems);
  };
  return (
    <div
      onClick={removeCart}
      onMouseEnter={() => {
        setToogleCartButton(!toogleCartButton);
      }}
      onMouseLeave={() => {
        setToogleCartButton(!toogleCartButton);
      }}
      className={`rounded-xl ml-1 flex h-[48px] cursor-pointer items-center justify-center bg-[#E7E8EC] bg-opacity-75 px-6 py-3 text-base text-white hover:bg-accent dark:bg-white/[.15] dark:text-jacarta-900`}
      data-testid='sweepRemoveCart'
    >
      <button type='button' disabled={!buyEnabled} className='flex items-center'>
        {toogleCartButton ? (
          <svg
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M23.2652 4.91103C23.2652 2.22687 21.0666 0 18.3828 0C15.6989 0 13.4717 2.22687 13.4717 4.91103C13.4717 5.79611 13.713 6.64571 14.1127 7.35938L3.50689 6.70952L3.07766 5.27874C3.02043 5.04981 2.67704 4.3058 0.87425 4.21996C0.169445 4.17617 0.133676 4.52042 0.133676 4.52042C-0.0136951 5.03922 0.445015 5.0212 0.445015 5.0212C1.70411 5.0212 2.16196 5.33597 2.21919 5.45043L5.65307 16.8681L4.16505 19.2146C4.10782 19.3004 3.82167 19.701 4.05059 20.1303C4.22229 20.4451 4.56567 20.5309 5.05214 20.5309H17.5572C17.7575 20.5309 17.9006 20.7598 17.9006 20.9315C17.9006 21.1604 18.1009 21.3894 18.3298 21.3894C18.5587 21.3894 18.7591 21.1318 18.7591 20.9029C18.7591 20.2447 18.2154 19.6724 17.5572 19.6724H4.93768L6.39708 17.3546L18.0437 16.8109C18.6446 16.8109 19.1024 16.353 19.2169 15.6948L20.4677 9.33473C23.353 7.90022 23.2652 4.91103 23.2652 4.91103ZM18.3871 15.5518C18.3584 15.6376 18.3012 15.9524 18.015 15.9524L6.45431 16.5247L3.76443 7.53938L14.7214 8.18266C15.5521 9.19995 16.9265 9.82205 18.3541 9.82205C18.3541 9.82205 18.6901 9.8438 19.5245 9.66724L18.3871 15.5518ZM21.6947 5.31079C21.6947 5.48219 21.5806 5.59637 21.4092 5.59637H15.6986C15.5272 5.59637 15.413 5.48219 15.413 5.31079V4.73962C15.413 4.56821 15.5272 4.45403 15.6986 4.45403H21.4092C21.5806 4.45403 21.6947 4.56821 21.6947 4.73962V5.31079Z'
              fill='white'
            />
            <path
              d='M16.6491 23.9999C17.2854 23.9999 17.8012 23.4841 17.8012 22.8479C17.8012 22.2116 17.2854 21.6958 16.6491 21.6958C16.0129 21.6958 15.4971 22.2116 15.4971 22.8479C15.4971 23.4841 16.0129 23.9999 16.6491 23.9999Z'
              fill='white'
              stroke='white'
              strokeWidth='0.5'
            />
            <path
              d='M4.53 23.9999C5.16627 23.9999 5.68207 23.4841 5.68207 22.8479C5.68207 22.2116 5.16627 21.6958 4.53 21.6958C3.89373 21.6958 3.37793 22.2116 3.37793 22.8479C3.37793 23.4841 3.89373 23.9999 4.53 23.9999Z'
              fill='white'
              stroke='white'
              strokeWidth='0.5'
            />
          </svg>
        ) : (
          <svg
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M23.2652 4.91103C23.2652 2.22687 21.0666 0 18.3828 0C15.6989 0 13.4717 2.22687 13.4717 4.91103C13.4717 5.79611 13.713 6.64571 14.1127 7.35938L3.50689 6.70952L3.07766 5.27874C3.02043 5.04981 2.67704 4.3058 0.87425 4.21996C0.169445 4.17617 0.133676 4.52042 0.133676 4.52042C-0.0136951 5.03922 0.445015 5.0212 0.445015 5.0212C1.70411 5.0212 2.16196 5.33597 2.21919 5.45043L5.65307 16.8681L4.16505 19.2146C4.10782 19.3004 3.82167 19.701 4.05059 20.1303C4.22229 20.4451 4.56567 20.5309 5.05214 20.5309H17.5572C17.7575 20.5309 17.9006 20.7598 17.9006 20.9315C17.9006 21.1604 18.1009 21.3894 18.3298 21.3894C18.5587 21.3894 18.7591 21.1318 18.7591 20.9029C18.7591 20.2447 18.2154 19.6724 17.5572 19.6724H4.93768L6.39708 17.3546L18.0437 16.8109C18.6446 16.8109 19.1024 16.353 19.2169 15.6948L20.4677 9.33473C23.353 7.90022 23.2652 4.91103 23.2652 4.91103ZM18.3871 15.5518C18.3584 15.6376 18.3012 15.9524 18.015 15.9524L6.45431 16.5247L3.76443 7.53938L14.7214 8.18266C15.5521 9.19995 16.9265 9.82205 18.3541 9.82205C18.3541 9.82205 18.6901 9.8438 19.5245 9.66724L18.3871 15.5518ZM21.6947 5.31079C21.6947 5.48219 21.5806 5.59637 21.4092 5.59637H15.6986C15.5272 5.59637 15.413 5.48219 15.413 5.31079V4.73962C15.413 4.56821 15.5272 4.45403 15.6986 4.45403H21.4092C21.5806 4.45403 21.6947 4.56821 21.6947 4.73962V5.31079Z'
              fill='#8358FF'
            />
            <path
              d='M16.6491 23.9999C17.2854 23.9999 17.8012 23.4841 17.8012 22.8479C17.8012 22.2116 17.2854 21.6958 16.6491 21.6958C16.0129 21.6958 15.4971 22.2116 15.4971 22.8479C15.4971 23.4841 16.0129 23.9999 16.6491 23.9999Z'
              fill='#8358FF'
              stroke='#8358FF'
              strokeWidth='0.5'
            />
            <path
              d='M4.53 23.9999C5.16627 23.9999 5.68207 23.4841 5.68207 22.8479C5.68207 22.2116 5.16627 21.6958 4.53 21.6958C3.89373 21.6958 3.37793 22.2116 3.37793 22.8479C3.37793 23.4841 3.89373 23.9999 4.53 23.9999Z'
              fill='#8358FF'
              stroke='#8358FF'
              strokeWidth='0.5'
            />
          </svg>
        )}
      </button>
    </div>
  );
};

export const CartAdd = () => {
  const { sweepingItems } = useFloorSweep();
  const { buyEnabled, addItemToCart } = useCartFunctions();
  const [toogleCartButton, setToogleCartButton] = useState(false);
  const addCart = async () => {
    await addItemToCart(sweepingItems);
  };
  return (
    <div
      onClick={addCart}
      onMouseEnter={() => {
        setToogleCartButton(!toogleCartButton);
      }}
      onMouseLeave={() => {
        setToogleCartButton(!toogleCartButton);
      }}
      data-testid='sweepAddCart'
      className={`rounded-xl ml-1 flex h-[48px] cursor-pointer items-center justify-center bg-[#E7E8EC] bg-opacity-75 px-6 py-3 text-base text-white hover:bg-accent dark:bg-white/[.15] dark:text-jacarta-900`}
    >
      <button type='button' disabled={!buyEnabled} className='flex items-center'>
        {toogleCartButton ? (
          <svg
            width='25'
            height='26'
            viewBox='0 0 25 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={'visible relative bottom-[1px]'}
          >
            <path
              d='M24.1604 5.91094C24.1604 3.22683 21.9619 1 19.278 1C16.5942 1 14.3671 3.22683 14.3671 5.91094C14.3671 6.79601 14.6083 7.64559 15.0081 8.35926L4.40243 7.7094L3.9732 6.27864C3.91597 6.04972 3.57259 5.30573 1.76983 5.21988C1.06504 5.1761 1.02927 5.52034 1.02927 5.52034C0.881617 6.03885 1.3406 6.02111 1.3406 6.02111C2.59967 6.02111 3.05752 6.33588 3.11475 6.45034L6.54857 17.8678L5.06058 20.2142C5.00335 20.3001 4.7172 20.7007 4.94612 21.1299C5.11781 21.4447 5.46119 21.5305 5.94765 21.5305H18.4525C18.6528 21.5305 18.7959 21.7595 18.7959 21.9312C18.7959 22.1601 18.9962 22.389 19.2251 22.389C19.454 22.389 19.6543 22.1315 19.6543 21.9025C19.6543 21.2444 19.1106 20.6721 18.4525 20.6721H5.83319L7.29257 18.3543L18.9389 17.8106C19.5399 17.8106 19.9977 17.3527 20.1122 16.6946L21.3629 10.3346C24.2482 8.90008 24.1604 5.91094 24.1604 5.91094ZM19.2823 16.5515C19.2537 16.6373 19.1965 16.9521 18.9103 16.9521L7.3498 17.5244L4.65997 8.53925L15.6167 9.18251C16.4474 10.1998 17.8218 10.8219 19.2494 10.8219C19.2494 10.8219 19.5854 10.8436 20.4198 10.6671L19.2823 16.5515ZM22.4472 6.28236C22.4472 6.45377 22.2188 6.53933 22.076 6.53933H20.1345C19.9631 6.53933 19.8775 6.71073 19.8775 6.85352V8.90924C19.8775 9.05203 19.7061 9.08064 19.5633 9.08064H18.9638C18.7927 9.08064 18.7355 9.08064 18.7355 8.90924V6.85352C18.7355 6.71073 18.5644 6.53933 18.393 6.53933H16.4514C16.28 6.53933 16.1659 6.42515 16.1659 6.28236V5.73982C16.1659 5.5687 16.28 5.3973 16.4514 5.3973H18.393C18.5358 5.3973 18.7355 5.31174 18.7355 5.16895V3.11323C18.7355 2.97044 18.8211 2.82765 18.9638 2.82765H19.5633C19.7061 2.82765 19.8775 2.97044 19.8775 3.11323V5.16895C19.8775 5.31174 19.9917 5.3973 20.1345 5.3973H22.076C22.2188 5.3973 22.4472 5.59703 22.4472 5.73982V6.28236Z'
              fill='white'
              stroke='white'
              strokeWidth='0.5'
            />
            <path
              d='M17.5444 24.9994C18.1806 24.9994 18.6964 24.4836 18.6964 23.8474C18.6964 23.2111 18.1806 22.6953 17.5444 22.6953C16.9081 22.6953 16.3923 23.2111 16.3923 23.8474C16.3923 24.4836 16.9081 24.9994 17.5444 24.9994Z'
              fill='white'
              stroke='white'
            />
            <path
              d='M5.42573 24.9994C6.06199 24.9994 6.57778 24.4836 6.57778 23.8474C6.57778 23.2111 6.06199 22.6953 5.42573 22.6953C4.78947 22.6953 4.27368 23.2111 4.27368 23.8474C4.27368 24.4836 4.78947 24.9994 5.42573 24.9994Z'
              fill='white'
              stroke='white'
            />
          </svg>
        ) : (
          <svg
            width='25'
            height='26'
            viewBox='0 0 25 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={'visible relative bottom-[1px]'}
          >
            <path
              d='M24.1604 5.91094C24.1604 3.22683 21.9619 1 19.278 1C16.5942 1 14.3671 3.22683 14.3671 5.91094C14.3671 6.79601 14.6083 7.64559 15.0081 8.35926L4.40243 7.7094L3.9732 6.27864C3.91597 6.04972 3.57259 5.30573 1.76983 5.21988C1.06504 5.1761 1.02927 5.52034 1.02927 5.52034C0.881617 6.03885 1.3406 6.02111 1.3406 6.02111C2.59967 6.02111 3.05752 6.33588 3.11475 6.45034L6.54857 17.8678L5.06058 20.2142C5.00335 20.3001 4.7172 20.7007 4.94612 21.1299C5.11781 21.4447 5.46119 21.5305 5.94765 21.5305H18.4525C18.6528 21.5305 18.7959 21.7595 18.7959 21.9312C18.7959 22.1601 18.9962 22.389 19.2251 22.389C19.454 22.389 19.6543 22.1315 19.6543 21.9025C19.6543 21.2444 19.1106 20.6721 18.4525 20.6721H5.83319L7.29257 18.3543L18.9389 17.8106C19.5399 17.8106 19.9977 17.3527 20.1122 16.6946L21.3629 10.3346C24.2482 8.90008 24.1604 5.91094 24.1604 5.91094ZM19.2823 16.5515C19.2537 16.6373 19.1965 16.9521 18.9103 16.9521L7.3498 17.5244L4.65997 8.53925L15.6167 9.18251C16.4474 10.1998 17.8218 10.8219 19.2494 10.8219C19.2494 10.8219 19.5854 10.8436 20.4198 10.6671L19.2823 16.5515ZM22.4472 6.28236C22.4472 6.45377 22.2188 6.53933 22.076 6.53933H20.1345C19.9631 6.53933 19.8775 6.71073 19.8775 6.85352V8.90924C19.8775 9.05203 19.7061 9.08064 19.5633 9.08064H18.9638C18.7927 9.08064 18.7355 9.08064 18.7355 8.90924V6.85352C18.7355 6.71073 18.5644 6.53933 18.393 6.53933H16.4514C16.28 6.53933 16.1659 6.42515 16.1659 6.28236V5.73982C16.1659 5.5687 16.28 5.3973 16.4514 5.3973H18.393C18.5358 5.3973 18.7355 5.31174 18.7355 5.16895V3.11323C18.7355 2.97044 18.8211 2.82765 18.9638 2.82765H19.5633C19.7061 2.82765 19.8775 2.97044 19.8775 3.11323V5.16895C19.8775 5.31174 19.9917 5.3973 20.1345 5.3973H22.076C22.2188 5.3973 22.4472 5.59703 22.4472 5.73982V6.28236Z'
              fill='#8358FF'
              stroke='#8358FF'
              strokeWidth='0.5'
            />
            <path
              d='M17.5444 24.9994C18.1806 24.9994 18.6964 24.4836 18.6964 23.8474C18.6964 23.2111 18.1806 22.6953 17.5444 22.6953C16.9081 22.6953 16.3923 23.2111 16.3923 23.8474C16.3923 24.4836 16.9081 24.9994 17.5444 24.9994Z'
              fill='#8358FF'
              stroke='#8358FF'
            />
            <path
              d='M5.42573 24.9994C6.06199 24.9994 6.57778 24.4836 6.57778 23.8474C6.57778 23.2111 6.06199 22.6953 5.42573 22.6953C4.78947 22.6953 4.27368 23.2111 4.27368 23.8474C4.27368 24.4836 4.78947 24.9994 5.42573 24.9994Z'
              fill='#8358FF'
              stroke='#8358FF'
            />
          </svg>
        )}
      </button>
    </div>
  );
};

export const FloorSweep = ({ collection, onSaleItemLength }) => {
  const { refreshDate } = useContext(DataRefreshContext);
  const [value, setValue] = useState(0);
  const [buttonText, setButtonText] = useState('');
  const [totalPriceInfo, setTotalPriceInfo] = useState('');
  const { buyStatusText, buyEnabled, buyBatch, itemList, nativeBalance } = useCartFunctions();
  const [haveSameItems, setHaveSameItems] = useState(false);
  const [isEnoughBalance, setIsEnoughBalance] = useState(true);

  const { sweepCount, setSweepCount, sweepingItems, setSweepingItems, sweepStatus } =
    useFloorSweep();
  const isIncludeBid = sweepingItems?.some((item) => item?.orders?.length == 0);
  const { chain } = useNetwork();
  const dispatch = useDispatch();
  const { address, isConnected } = useAccount();
  const [cartAdded, setCartAdded] = useState(false);
  const { openConnectModal } = useConnectModal();

  const pid = collection;
  let chainId = useChainId();

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    setSweepCount(newValue, 'sweep');
  };

  const clear = () => {
    setValue(0);
    // setSweepCount(0);
    setSweepingItems([], 'sweep');
  };

  useEffect(() => {
    if (sweepingItems && isConnected) {
      let totalPrice = 0;

      sweepingItems.map((item) => {
        totalPrice += item.price;
      });

      setValue(sweepingItems.length);

      if (isIncludeBid) {
        setButtonText(
          `Place ${sweepingItems.length} ${sweepingItems.length === 1 ? 'Bid' : 'Bids'}`,
        );
        setIsEnoughBalance(true);
      } else {
        if (parseFloat(nativeBalance?.formatted || '0') < totalPrice) {
          setIsEnoughBalance(false);
          setButtonText('Insufficient Funds');
        } else {
          setIsEnoughBalance(true);
          setButtonText(`Buy ${sweepingItems.length}`);
        }
        setTotalPriceInfo(`${totalPrice.toFixed(3)} ${chain.nativeCurrency.symbol}`);
      }
    }
  }, [chain, sweepStatus, sweepingItems]);

  useEffect(() => {
    if (sweepCount == 0) setSweepingItems([]);
  }, [sweepCount]);

  useEffect(() => {
    const addedItems = sweepingItems.filter(
      (sweepingItem) => !itemList.some((item) => item.id === sweepingItem.id),
    );

    const deletedItems = itemList.filter(
      (item) => !sweepingItems.some((sweepingItem) => sweepingItem.id === item.id),
    );

    if (addedItems.length > 0) setCartAdded(false);
    if (deletedItems.length > 0) setCartAdded(true);
  }, [sweepingItems]);

  // useEffect(() => {
  //   if (onSaleItemLength > 0) {
  //     let widget = document.getElementsByTagName('widgetbot-crate');
  //     if (widget.length > 0) {
  //       widget[0].style.visibility = 'hidden';
  //     }
  //   }
  // }, [onSaleItemLength]);

  useEffect(() => {
    const haveSameItems = sweepingItems.every((sweepingItem) =>
      itemList.some((item) => item.id === sweepingItem.id),
    );
    setHaveSameItems(haveSameItems);
  }, [sweepingItems.length, itemList]);

  useEffect(() => {
    clear();
  }, [refreshDate]);

  return (
    <div className='bg-neutral-50 sticky bottom-0 z-10 inline-flex w-full items-center gap-2 bg-white px-2 dark:bg-jacarta-800'>
      <div className='flex w-full flex-col items-center justify-between gap-2 py-4 md:flex-row'>
        <div className='rounded-xl border-gray-200 flex items-center justify-start gap-4 border border-[#000000] pr-4 dark:border-[#494c6a]'>
          <div className='rounded-l-xl flex h-12 items-center justify-center gap-4 bg-[#E7E8EC] px-6 py-4 dark:bg-[#23263e]'>
            <div>
              <svg
                width='21'
                height='24'
                viewBox='0 0 21 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M19.5458 0.269672C18.9335 -0.209518 18.0816 -0.0231662 17.6823 0.615754L10.0419 13.0214C9.96203 13.1546 9.77568 13.2078 9.64257 13.1279L6.87392 11.3443C6.74081 11.2644 6.55446 11.291 6.47459 11.4241L5.88892 12.3293C5.80905 12.4624 5.83567 12.6487 5.96878 12.7286L13.7157 17.6802C13.8488 17.7601 14.0351 17.7335 14.115 17.6004L14.7007 16.6952C14.7806 16.5621 14.7539 16.3758 14.6208 16.2959L11.8522 14.5123C11.7191 14.4324 11.6658 14.246 11.7723 14.1129L19.8387 2.0267C20.1581 1.46765 20.0783 0.695619 19.5458 0.269672Z'
                  fill='#8358FF'
                />
                <path
                  d='M12.8105 17.9199L6.20835 13.687C6.10187 13.6338 5.99538 13.6338 5.88889 13.687C5.2766 14.0597 3.067 15.4707 0.990509 17.84C0.963887 17.8666 0.963887 17.9199 1.01713 17.9465L2.34821 18.7984C2.50794 18.9049 2.6943 18.8783 2.8274 18.7451L3.75916 17.7335C3.8124 17.6803 3.89227 17.7335 3.86565 17.8134L3.30659 19.3308C3.30659 19.3574 3.30659 19.3841 3.33321 19.4107L3.97213 19.81C3.99876 19.8366 4.052 19.81 4.07862 19.7834C4.15849 19.4905 4.47795 18.5322 5.43633 17.6803C5.46295 17.6537 5.48957 17.6803 5.48957 17.7069C5.11687 18.2127 4.63768 19.4639 4.61105 20.1827C4.61105 20.2093 4.63768 20.2093 4.63768 20.236L5.24997 20.6353C5.2766 20.6619 5.30322 20.6619 5.32984 20.6353L6.31484 19.7301C6.36808 19.6769 6.44795 19.7301 6.42133 19.81L6.022 21.1145C6.022 21.1411 6.022 21.1677 6.04862 21.1943L6.55444 21.5138C6.58106 21.5404 6.60768 21.5404 6.6343 21.5138C6.87389 21.2742 8.12511 19.9964 9.45619 19.7568C9.50944 19.7568 9.56268 19.8366 9.50944 19.8633C9.05687 20.2626 7.83227 21.4073 7.43295 21.993C7.40633 22.0196 7.43295 22.0728 7.45957 22.0995L8.73741 22.8981C8.76403 22.9247 8.81728 22.8981 8.8439 22.8715L9.16336 22.3657C9.2166 22.2858 9.26984 22.206 9.32309 22.1261C9.32309 22.1261 9.37633 22.0728 9.37633 22.0196C9.37633 21.993 9.40295 21.9397 9.42957 21.9397C9.45619 21.9397 9.42957 22.0196 9.42957 22.0462C9.42957 22.0995 9.40295 22.1261 9.40295 22.1793C9.37633 22.3124 9.34971 22.4456 9.32309 22.6053L9.2166 23.2176C9.2166 23.2442 9.2166 23.2708 9.24322 23.2974L10.3347 23.9896C10.3613 24.0162 10.4146 23.9896 10.4412 23.9364C10.5743 23.3773 11.373 20.3957 12.8371 18.3724C12.9969 18.2127 12.9703 18.0264 12.8105 17.9199Z'
                  fill='#8358FF'
                />
                <path
                  d='M7.51282 6.89852C7.4862 6.81866 7.37971 6.81866 7.37971 6.89852C7.2466 7.27122 6.95376 7.96339 6.28822 8.30947C6.23498 8.33609 6.23498 8.41596 6.28822 8.44258C6.98039 8.81528 7.27322 9.48082 7.37971 9.85353C7.40633 9.93339 7.51282 9.93339 7.51282 9.85353C7.64593 9.48082 7.93877 8.78866 8.60431 8.44258C8.65755 8.41596 8.65755 8.33609 8.60431 8.30947C7.93877 7.93677 7.61931 7.27122 7.51282 6.89852Z'
                  fill='#8358FF'
                />
                <path
                  d='M14.9403 18.5321C14.9137 18.4789 14.8604 18.4789 14.8338 18.5321C14.754 18.7983 14.5144 19.3041 14.0352 19.5704C13.9819 19.597 13.9819 19.6502 14.0352 19.6769C14.541 19.9431 14.754 20.4223 14.8338 20.7151C14.8604 20.7683 14.9137 20.7683 14.9403 20.7151C15.0202 20.4489 15.2598 19.9431 15.739 19.6769C15.7922 19.6502 15.7922 19.597 15.739 19.5704C15.2598 19.3041 15.0202 18.7983 14.9403 18.5321Z'
                  fill='#8358FF'
                />
                <path
                  d='M4.87723 8.46913C4.85061 8.41589 4.79737 8.41589 4.77074 8.46913C4.69088 8.73535 4.47791 9.18792 4.02534 9.45413C3.99872 9.48075 3.99872 9.534 4.02534 9.534C4.50453 9.77359 4.69088 10.2528 4.77074 10.519C4.79737 10.5722 4.85061 10.5722 4.87723 10.519C4.95709 10.2528 5.17007 9.80021 5.62264 9.534C5.64926 9.50738 5.64926 9.45413 5.62264 9.45413C5.17007 9.21454 4.95709 8.73535 4.87723 8.46913Z'
                  fill='#8358FF'
                />
              </svg>
            </div>
            <div className='text-violet-500 w-3 text-center text-[16px] font-normal text-accent'>
              {value}
            </div>
          </div>
          <div className='ml-[16px] flex w-[132px] align-middle'>
            <Slider
              defaultValue={0}
              aria-label='Default'
              valueLabelDisplay='auto'
              value={value}
              onChange={handleChange}
              max={onSaleItemLength}
              sx={{ color: '#8358FF' }}
              data-testid='sweepSlider'
            />
            <div className='absolute left-0 top-[-2px] h-4 w-[132px]'>
              <div className='bg-neutral-300 absolute left-0 top-[6px] h-1 w-[132px] rounded-[7px]' />
              <div className='bg-violet-500 absolute left-[1px] top-[6px] h-1 w-px rounded-[7px]' />
              <div className='bg-indigo-950 absolute left-0 top-0 h-4 w-4 rounded-full' />
            </div>
          </div>
        </div>
        <div className='flex flex-row gap-1'>
          {value == 0 ? (
            <div
              className={`rounded-xl hover:color-[#f1f1f1] relative ml-auto flex h-auto cursor-pointer items-center justify-center bg-accent px-6 py-3 text-base font-medium text-white hover:bg-[#673AB7]`}
            >
              <button
                onClick={() => {
                  if (!isConnected) {
                    return openConnectModal();
                  }
                  dispatch(
                    setModal({
                      key: 'giveGlobalBidModal',
                      data: pid,
                    }),
                  );
                }}
                type='button'
                data-testid='sweepMakeGlobalBid'
              >
                Make a global bid
              </button>
            </div>
          ) : (
            <div className='ml-auto flex'>
              {isConnected ? (
                <>
                  <button
                    type='button'
                    onClick={() => {
                      if (isIncludeBid) {
                        dispatch(
                          setModal({
                            key: 'bulkbidsModal',
                            data: sweepingItems,
                            chainId: chainId,
                          }),
                        );
                      } else buyBatch(chainId, sweepingItems);
                    }}
                    disabled={!buyEnabled || !isEnoughBalance}
                    className={`rounded-xl flex h-[48px] cursor-pointer items-center justify-center bg-accent px-6 py-3 text-base text-white hover:text-jacarta-200`}
                    data-testid='sweepBuy'
                  >
                    <div className='flex items-center'>
                      {buyEnabled ? (
                        <>
                          {buttonText}&nbsp;&nbsp;
                          {!isIncludeBid && isEnoughBalance && (
                            <>
                              <svg
                                width='7'
                                height='6'
                                viewBox='0 0 7 6'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle cx='3.62988' cy='3' r='3' fill='#C9CACE' />
                              </svg>
                              &nbsp;&nbsp;
                              <span className='text-jacarta-200'>{totalPriceInfo}</span>
                            </>
                          )}
                        </>
                      ) : (
                        buyStatusText
                      )}
                    </div>
                  </button>
                  {haveSameItems ? <CartRemove /> : <CartAdd />}
                </>
              ) : (
                <div
                  onClick={openConnectModal}
                  className={`rounded-xl ml-1 flex h-[48px] cursor-pointer items-center justify-center bg-accent px-6 py-3 text-base text-white hover:bg-jacarta-200`}
                >
                  <button type='button' disabled={!buyEnabled} className='flex items-center'>
                    Connect Wallet
                  </button>
                </div>
              )}

              <div
                onClick={clear}
                data-testid='sweepClearCart'
                className={`rounded-xl ml-1 flex h-[48px] cursor-pointer items-center justify-center bg-[#E7E8EC] bg-opacity-75 px-6 py-3 text-base text-[#000000] hover:bg-accent hover:text-white dark:bg-white/[.15] dark:text-white`}
              >
                <button type='button' disabled={!buyEnabled} className='flex items-center'>
                  Clear
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
