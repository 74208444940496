import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Tooltip } from '@mui/material';
import { useNetwork } from 'wagmi';
import { getSupportedChain } from '../helpers/getSupportedChain';
import { TbExternalLink } from 'react-icons/tb';

const UserId = ({ classes, userId, shortId, user, showAddress, nameClass }) => {
  const [copied, setCopied] = useState(false);
  const name = user?.ensName ?? user?.name;
  const { chain: activeChain } = useNetwork();
  const chain = getSupportedChain(activeChain?.hexId);
  const title = user?.ensName
    ? user?.ensName
    : !showAddress
    ? user?.name
      ? user?.name
      : userId
    : userId;

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);

  return (
    <div className='flex flex-col items-center gap-1.5' data-testid='userWalletAddress'>
      {(name || showAddress) && (
        <a
          href={user?.scanLink ? user?.scanLink : `${chain?.scan_url}/address/${userId}`}
          target='_blank'
          className='w-fit'
          rel='noreferrer noopener'
        >
          <Tooltip placement='top' title={title}>
            <div className='flex items-center justify-center gap-1 text-accent'>
              <span className={`font-semibold ${nameClass ?? ''}`}>
                {title?.length > 15
                  ? `${title?.substring(0, 5)}...${title?.substring(title?.length - 5)}`
                  : title}
              </span>
              <TbExternalLink />
            </div>
          </Tooltip>
        </a>
      )}
      <Tooltip
        placement='bottom'
        // hideOnClick={false}
        title={copied ? <span>Copied</span> : <span>Copy</span>}
        // title={userId}
      >
        <button className={classes}>
          <CopyToClipboard text={userId} onCopy={() => setCopied(true)}>
            {/* <span>{!shortId ? userId : `${userId?.substring(0, 17)}...`}</span> */}
            <span className={name ? 'text-[15px] opacity-60' : 'text-base'}>
              {!shortId
                ? userId
                : `${userId?.substring(0, 10)} ... ${userId?.substring(userId?.length - 5)}`}
            </span>
          </CopyToClipboard>
        </button>
      </Tooltip>
    </div>
  );
};

export default UserId;
