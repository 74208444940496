import { ethers } from 'ethers';
import { getAddress, parseEther } from 'ethers/lib/utils';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useBalance, useWalletClient, useSignTypedData, usePublicClient } from 'wagmi';
import { getContract } from 'wagmi/actions';
import { approveERC20 } from '../../helpers/blockchainOperations';
import { constants } from '../../helpers/constants';
import { normalizeNumber } from '../../helpers/digits';
import erc20ABI from '../../helpers/erc20ABI';
import wbnbABI from '../../helpers/wbnbABI';
import axios from '../../lib/axios';
import { dynamicDomain, types } from '../../lib/sign';
import { closeModal } from '../../redux/counterSlice';
import Icon, { ChainIcon } from '../Icon';
import { ModalContainer } from './modalContainer';
import { getCoinGeckoWrappedTokenId, getTokenName } from '../../helpers/getChainName';
import useGetTokenPrice from '../../hooks/useGetTokenPrice';
import { DataRefreshContext } from '../refreshContext';
import { ModalItemDetails } from './modalComponents/modalItemDetails';
import { errorToast, successToast } from '../../components/toast';
import { useGetChain } from '../../hooks/useGetChain';
import { Button, SwitchNetworkButton } from '../Button';
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');
import { hashOrder } from '../../helpers/hashOrder';
import { useCartFunctions } from '../cart/useCartFunctions';
import { useFloorSweep } from '../floor-sweep/useFloorSweep';

const MODAL_KEY = 'bulkbidsModal';
const BulkBidsModal = () => {
  const { address } = useAccount();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { refreshHooks } = useContext(DataRefreshContext);
  const { modal } = useSelector((state) => state.counter);
  const { clearCart, toggleCart, isCartOpen } = useCartFunctions();
  const { chainId } = modal ?? {};
  const itemList = modal?.data;

  const dispatch = useDispatch();
  const [BNBAmount, setBNBAmount] = useState('');
  const [totalBNBAmount, setTotalBNBAmount] = useState('');
  const [usdAmount, setUsdAmount] = useState(0);
  const [expiration, setExpiration] = useState(1);
  const defaultText = 'Place Bids';
  const [buttonText, setButtonText] = useState(defaultText);
  const { chain, isChainActive } = useGetChain(chainId);
  const wrappedToken = getCoinGeckoWrappedTokenId(chain.hexId);
  const { usdPrice: tokenPrice } = useGetTokenPrice(chain.hexId);
  const { setSweepCount, setSweepingItems } = useFloorSweep();

  const isSameChain = itemList.every((item) => {
    return item.chain === itemList[0].chain;
  });

  let { data: nativeBalance } = useBalance({ address });
  let { data: wrappedTokenBalance } = useBalance({
    address,
    token: chain?.wrappedToken,
  });

  const wbnbContract = getContract({
    address: chain?.wrappedToken,
    abi: wbnbABI,
    walletClient,
    chainId: chain.id,
  });
  const { signTypedDataAsync } = useSignTypedData();

  const handleConvert = async () => {
    try {
      const diff = balanceDifference();

      if (totalBNBAmount > parseFloat(wrappedTokenBalance?.formatted) && 0 > diff) {
        const tx = await wbnbContract.write.deposit({
          value: parseEther(Math.abs(diff).toString()).toString(),
        });
        await publicClient.waitForTransactionReceipt({
          confirmations: 2,
          hash: tx,
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const tokenContract = getContract({
    address: chain?.wrappedToken,
    abi: erc20ABI,
    walletClient,
    chainId: chain.id,
  });

  const handlePlaceBid = async () => {
    if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
    if (BNBAmount == 0) return errorToast('Enter a valid number');

    try {
      setButtonText('Converting tokens...');
      await handleConvert();
      setButtonText('Approving tokens...');
      await approveERC20(
        tokenContract,
        address,
        parseEther(String(totalBNBAmount)).toString(),
        chain?.marketplace,
      );

      setButtonText('Giving the bid...');
      const orders = itemList.map((item) => {
        return {
          issuer: getAddress(address),
          nftAddress: item.collectionAddress,
          tokenId: item.itemId.toString(),
          paymentToken: chain?.wrappedToken,
          price: parseEther(BNBAmount).toString(),
          end: Math.floor(Date.now() / 1000) + expiration * 24 * 60 * 60,
          kind: 0,
          tokenKind: item?.contractType === 'ERC1155' ? 1 : 0,
          globalBidAmount: 0,
          privileges: {
            privilegedCollection: ethers.constants.AddressZero,
            privilegedTokenId: String(0),
          },
        };
      });

      const leaves = orders.map((order) => {
        return hashOrder(order);
      });

      const merkleTree = new MerkleTree(leaves, keccak256, { sort: true });
      const merkleRoot = merkleTree.getHexRoot();

      const signature = await signTypedDataAsync({
        domain: dynamicDomain(chain.hexId),
        message: { root: merkleRoot },
        types: types,
        primaryType: 'Root',
      });

      const ordersWithProofs = orders.map((order) => {
        const proof = merkleTree.getHexProof(hashOrder(order));
        proof.unshift(merkleRoot);
        return {
          ...order,
          proof: proof,
          bulkSignature: signature,
          orderHash: hashOrder(order),
        };
      });

      // returns boolean
      const res = (
        await axios.post(`${constants.api.url_new}/orders/giveBulkBid`, {
          orders: ordersWithProofs,
          chain: chain.hexId,
        })
      ).data;

      if (!res) throw 'Failed to give bid';
      successToast(`Bids given!`);
      clearCart(false);
      setSweepingItems([], 'sweep');
      setSweepCount(0);

      refreshHooks();
      dispatch(closeModal());
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setButtonText(defaultText);
    }
  };

  const handleEThAmount = (e) => {
    e.preventDefault();
    const newAmount = e?.target?.value.replace(',', '.');
    const zeroCounts = newAmount.split('.');

    if (zeroCounts?.length >= 1 && zeroCounts[1]?.length > 4) return;
    setBNBAmount(newAmount);
    setTotalBNBAmount((newAmount * itemList.length).toFixed(4));

    const usd = Number(tokenPrice * e.target.value * itemList.length).toFixed(2);
    setUsdAmount(usd);
  };

  const balanceDifference = () => {
    try {
      if (!BNBAmount || !wrappedTokenBalance) return 0;

      return (parseFloat(wrappedTokenBalance?.formatted) - totalBNBAmount).toFixed(18);
    } catch (err) {
      return 1;
    }
  };

  const totalBalance =
    parseFloat(nativeBalance?.formatted ?? 0) + parseFloat(wrappedTokenBalance?.formatted ?? 0);
  const isButtonDisabled = buttonText !== defaultText;

  useEffect(() => {
    if (isCartOpen) toggleCart();
  }, []);

  return (
    <ModalContainer modalKey={MODAL_KEY} title={'Place Bids'}>
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        <div className='max-h-72 overflow-y-auto'>
          {itemList.map((item) => {
            return (
              <ModalItemDetails
                item={item}
                collection={item.collection}
                isGlobal={false}
                key={item.id}
              />
            );
          })}
        </div>
        <div className='mb-2 mt-5 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            Price
          </span>
        </div>

        <div className='relative mb-2 flex items-center overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600'>
          <div className='flex items-center justify-center self-stretch border-jacarta-100 bg-jacarta-50 px-5 dark:border-jacarta-600 dark:bg-jacarta-700'>
            <ChainIcon name={chain.hexId} tooltip={getTokenName(chain.id)} />
          </div>

          <input
            type='number'
            className='h-12 w-full flex-[3] border-0 focus:shadow-none focus:ring-0 dark:bg-jacarta-700 dark:text-white dark:focus:ring-accent'
            placeholder='Price'
            value={BNBAmount}
            onChange={(e) => handleEThAmount(e)}
            onWheel={(e) => e.target.blur()}
          />

          <div className='flex flex-1 justify-end self-stretch border-l border-jacarta-100 bg-jacarta-50 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white'>
            <span className='self-center px-2 text-sm'>~${usdAmount}</span>
          </div>
        </div>

        <div className='mt-5 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            Expiration
          </span>
        </div>
        <div className='mt-3 mb-6'>
          <select
            className='h-12 w-full rounded-lg border border-jacarta-100 bg-jacarta-50 px-4 text-sm text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white'
            value={expiration}
            onChange={(e) => setExpiration(e.target.value)}
          >
            <option value={1}>1 day</option>
            <option value={3}>3 days</option>
            <option value={7}>7 days</option>
            <option value={30}>30 days</option>
            <option value={180}>6 months</option>
          </select>
        </div>

        <div className='flex items-center justify-end'>
          Balance:&nbsp; <Icon name={`icon-${wrappedToken}`} tooltip={wrappedToken.toUpperCase()} />
          <span className='text-sm dark:text-jacarta-400'>
            {normalizeNumber(wrappedTokenBalance?.formatted)}
          </span>
        </div>

        {/* <!-- Terms --> */}

        <div className='alert-info mt-2 w-full rounded-lg p-2'>
          Note: Bit5 does not escrow your money. Which means, you can bid without paying for gas
          fees.
        </div>
      </div>
      {/* <!-- end body --> */}

      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {isSameChain ? (
            itemList[0].chain !== chain.hexId ? (
              <SwitchNetworkButton chainId={itemList[0].chain} />
            ) : totalBalance >= totalBNBAmount ? (
              <Button
                key={'give_bid_button'}
                onClick={handlePlaceBid}
                disabled={isButtonDisabled}
                text={buttonText}
              />
            ) : (
              'Insufficient Funds'
            )
          ) : (
            'Use Only Same Chain Collections'
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default BulkBidsModal;
