import { constants } from '../helpers/constants';
import { getSupportedChain } from '../helpers/getSupportedChain';

export const domain = {
  name: 'Bit5 Marketplace',
  version: '1',
  verifyingContract: constants.helpers.marketplace,
  chainId: constants.helpers.chainId,
};

export const dynamicDomain = (chain) => ({
  name: 'Bit5 Marketplace',
  version: '1',
  verifyingContract: getSupportedChain(chain)?.marketplace,
  chainId: parseInt(chain),
});

export const types = {
  PrivilegedInformation: [
    { name: 'privilegedCollection', type: 'address' },
    { name: 'privilegedTokenId', type: 'uint256' },
  ],
  Order: [
    { name: 'issuer', type: 'address' },
    { name: 'nftAddress', type: 'address' },
    { name: 'paymentToken', type: 'address' },
    { name: 'price', type: 'uint256' },
    { name: 'tokenId', type: 'uint256' },
    { name: 'end', type: 'uint256' },
    { name: 'kind', type: 'uint256' },
    { name: 'tokenKind', type: 'uint256' },
    { name: 'globalBidAmount', type: 'uint256' },
    { name: 'privileges', type: 'PrivilegedInformation' },
  ],
  Like: [{ name: 'Like', type: 'string' }],
  EditProfile: [{ name: 'EditProfile', type: 'string' }],
  Game: [{ name: 'Game', type: 'string' }],
  Watch: [{ name: 'Watch', type: 'string' }],
  Report: [{ name: 'Report', type: 'string' }],
  Layout: [{ name: 'Layout', type: 'string' }],
  AcceptTerms: [{ name: 'M', type: 'string' }],
  Root: [{ name: 'root', type: 'bytes32' }],
};

export const rootType = {
  Root: [
    { name: "root", type: "bytes32" }
  ]
};

export const multipleTypes = {
  PrivilegedInformation: [
    { name: "privilegedCollection", type: "address" },
    { name: "privilegedTokenId", type: "uint256" },
  ],
  Order: [
    { name: "issuer", type: "address" },
    { name: "nftAddress", type: "address" },
    { name: "paymentToken", type: "address" },
    { name: "price", type: "uint256" },
    { name: "tokenId", type: "uint256" },
    { name: "end", type: "uint256" },
    { name: "kind", type: "uint256" },
    { name: "tokenKind", type: "uint256" },
    { name: "globalBidAmount", type: "uint256" },
    { name: "privileges", type: "PrivilegedInformation" },
  ],
  OrderGroup: [
    {name: "orders", type: "Order[]"}
  ]
};

export const editProfileMessage =
  'Thank you for deciding to update your profile information! Having accurate and up-to-date details helps us provide you with more personalized experiences. Please review your changes carefully. If you have any questions, our support team will be happy to assist you.';
export const likeMessage = 'You like this NFT!';
export const unLikeMessage = 'You unliked this NFT!';
export const gameMessage = 'Lets Play!';
export const watchMessage = 'You are watching!';
export const unWatchMessage = 'You are unWatching :( ';
export const reportMessage = 'Reporting';
export const layoutMessage = 'Saving dashboard design';
export const acceptTermsMessage = `Welcome to Bit5!  Click to sign in and accept the Bit5 Terms of Service (https://docs.bit5.com/legal/terms-of-service) and Privacy Policy (https://docs.bit5.com/legal/privacy-policy).This request will not trigger a blockchain transaction or cost any gas fees.`;

export const LENDING_CONTRACT_ADDRESS = '0x7CDEe851050EbD22Dd7dDb6dfc97c73C5c36238f';

export const CREATE_BORROW_EIP712_DOMAIN = {
  name: 'Bit5 Lending',
  version: '1',
  chainId: 56,
  verifyingContract: LENDING_CONTRACT_ADDRESS,
};

export const CREATE_DYNAMIC_BORROW_EIP712_DOMAIN = (chainId) => ({
  name: 'Bit5 Lending',
  version: '1',
  chainId: parseInt(chainId),
  verifyingContract: getSupportedChain(chainId)?.lendingContract,
});

export const CREATE_BORROW_EIP712_TYPES = {
  EIP712Domain: [
    { name: 'name', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'chainId', type: 'uint256' },
    { name: 'verifyingContract', type: 'address' },
  ],
  Times: [
    { name: 'signingTime', type: 'uint256' },
    { name: 'expiration', type: 'uint256' },
    { name: 'duration', type: 'uint256' },
  ],
  Order: [
    { name: 'issuer', type: 'address' },
    { name: 'nftAddresses', type: 'address[]' },
    { name: 'colleteralTokenIDs', type: 'uint256[]' },
    { name: 'amounts', type: 'uint256[]' },
    { name: 'paymentToken', type: 'address' },
    { name: 'borrowAmount', type: 'uint256' },
    { name: 'interestRate', type: 'uint256' },
    { name: 'times', type: 'Times' },
    { name: 'orderKind', type: 'uint256' },
    { name: 'tokenKinds', type: 'uint256[]' },
    { name: 'isGlobal', type: 'bool' },
  ],
};

export const CREATE_BORROW_EIP712_TYPES_ORDER = {
  Times: [
    { name: 'signingTime', type: 'uint256' },
    { name: 'expiration', type: 'uint256' },
    { name: 'duration', type: 'uint256' },
  ],
  Order: [
    { name: 'issuer', type: 'address' },
    { name: 'nftAddresses', type: 'address[]' },
    { name: 'colleteralTokenIDs', type: 'uint256[]' },
    { name: 'amounts', type: 'uint256[]' },
    { name: 'paymentToken', type: 'address' },
    { name: 'borrowAmount', type: 'uint256' },
    { name: 'interestRate', type: 'uint256' },
    { name: 'times', type: 'Times' },
    { name: 'orderKind', type: 'uint256' },
    { name: 'tokenKinds', type: 'uint256[]' },
    { name: 'isGlobal', type: 'bool' },
  ],
};
