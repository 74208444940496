import axios from '../lib/axios';
import { constants } from '../helpers/constants';

export async function createReport(data) {
  const config = {
    url: `${constants.api.url_new}/report`,
    headers: {
      //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: constants.httpMethods.post,
    data,
  };

  const res = (await axios(config)).data.data;

  return res;
}
