import React, { useEffect, useState } from 'react';
import { createFavoriteItem, deleteFavoriteItem } from '../services/favoriteItem';
import { getAddress, hashMessage } from 'ethers/lib/utils';
import { Tooltip } from '@mui/material';
import { useAccount, useSignTypedData, useNetwork } from 'wagmi';
import { dynamicDomain, types, likeMessage, unLikeMessage } from '../lib/sign';
import { errorToast } from '../components/toast';
import { useConnectModal } from '@rainbow-me/rainbowkit';

const Likes = ({
  from,
  item,
  alreadyLiked,
  likeCount,
  classes = 'dark:bg-jacarta-700 absolute top-3 right-3 flex items-center space-x-1 rounded-md bg-white p-2',
  md = false,
}) => {
  const { isConnected } = useAccount();
  const [liked, setLiked] = useState(false);
  const [likeNumber, setLikeNumber] = useState(likeCount);

  const { chain } = useNetwork();
  const { openConnectModal } = useConnectModal();

  const { signTypedDataAsync } = useSignTypedData({
    domain: dynamicDomain(chain?.hexId),
    types: types,
    primaryType: 'Like',
  });

  const handleLike = async () => {
    if (!isConnected) {
      return openConnectModal();
    }
    try {
      if (liked) {
        /*     const hashedMessage = hashMessage(
          JSON.stringify({
            walletAddress: from,
            collectionAddress: item?.collectionAddress,
            itemId: item?.itemId,
          }),
        ); */
        const signature = await signTypedDataAsync({
          message: { Like: unLikeMessage },
        });
        deleteFavoriteItem(
          {
            walletAddress: from,
            collectionAddress: item?.collectionAddress,
            itemId: item?.itemId,
            signature,
          },
          chain?.hexId,
        ).then((res) => {
          if (res) {
            setLikeNumber((prev) => Number(prev) - 1);
            setLiked(false);
          }
        });
      } else {
        /*  const hashedMessage = hashMessage(
          JSON.stringify({
            from,
            collectionAddress: item?.collectionAddress,
            itemId: item?.itemId,
          }),
        ); */
        const signature = await signTypedDataAsync({
          message: { Like: likeMessage },
        });
        createFavoriteItem(
          {
            from,
            eventType: 'like',
            collectionAddress: item?.collectionAddress,
            itemId: item?.itemId,
            signature,
          },
          chain?.hexId,
        ).then((r) => {
          setLikeNumber((prev) => Number(prev) + 1);
          setLiked(true);
        });
      }
    } catch (err) {
      let error = err;
      if (typeof error === 'object') error = JSON.parse(JSON.stringify(err));
      errorToast(err?.cause?.reason ?? err?.reason ?? 'err: ' + err);
    }
  };

  useEffect(() => {
    setLiked(alreadyLiked);
    setLikeNumber(likeCount);
  }, [alreadyLiked, likeCount]);

  return (
    <Tooltip title='Favorite'>
      <div className={classes + ' group/icon'} onClick={handleLike} data-testid='likes'>
        <button className='js-likes relative cursor-pointer'>
          {liked ? (
            <svg
              className={`icon icon-heart-fill ${
                md ? 'h-5 w-5' : 'h-4 w-4'
              } fill-jacarta-500 group-hover/icon:fill-red dark:fill-jacarta-200 dark:group-hover/icon:fill-red`}
            >
              <use xlinkHref='/icons.svg#icon-heart-fill' />
            </svg>
          ) : (
            <svg
              className={`icon icon-heart-fill ${
                md ? 'h-5 w-5' : 'h-4 w-4'
              } fill-jacarta-500 group-hover/icon:fill-red dark:fill-jacarta-200 dark:group-hover/icon:fill-red`}
            >
              <use xlinkHref='/icons.svg#icon-hert-fill' />
            </svg>
          )}
        </button>
        <span className={`${md ? 'text-md' : 'text-sm'} dark:text-jacarta-200`}>{likeNumber}</span>
      </div>
    </Tooltip>
  );
};

export default Likes;
