import { bsc } from 'wagmi/chains';
import { getSupportedChain } from './getSupportedChain';

export function getBlockExploreLink(data, type, chainId) {
  const chain = getSupportedChain(chainId);
  switch (type) {
    case 'transaction': {
      return `${chain?.blockExplorers?.default?.url}/tx/${data}`;
    }
    case 'token': {
      return `${chain?.blockExplorers?.default?.url}/token/${data}`;
    }
    case 'block': {
      return `${chain?.blockExplorers?.default?.url}/block/${data}`;
    }
    case 'countdown': {
      return `${chain?.blockExplorers?.default?.url}/block/countdown/${data}`;
    }
    default: {
      return `${chain?.blockExplorers?.default?.url}/address/${data}`;
    }
  }
}

export function getBlockExploreName(chainId) {
  const chain = getSupportedChain(chainId);
  return chain?.blockExplorers?.default?.name || bsc.blockExplorers.default.name;
}

export function getScanLinkForNft(collectionAddress, tokenId, chainId) {
  const chain = getSupportedChain(chainId);
  return `${chain?.blockExplorers?.default?.url}/token/${collectionAddress}?a=${tokenId}`;
}

// add 10%
export function calculateGasMargin(value, margin = 1000n) {
  return (value * (10000n + margin)) / 10000n;
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
