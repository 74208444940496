import { getAddress, parseEther } from 'ethers/lib/utils';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useBalance, useWalletClient, useSignTypedData, usePublicClient } from 'wagmi';
import { getContract } from '@wagmi/core';
import { approveERC20 } from '../../../helpers/blockchainOperations';
import { constants } from '../../../helpers/constants';
import { normalizeEther, normalizeNumber } from '../../../helpers/digits';
import axios from '../../../lib/axios';
import { dynamicDomain, types } from '../../../lib/sign';
import { closeModal } from '../../../redux/counterSlice';
import { ethers } from 'ethers';
import erc20ABI from '../../../helpers/erc20ABI';
import wbnbABI from '../../../helpers/wbnbABI';
import useGetCollection from '../../../hooks/useGetCollection';
import useGetIsPrivileged from '../../../hooks/useGetIsPrivileged';
import Icon from '../../Icon';
import { ChainIcon } from '../../Icon';
import { getCoinGeckoWrappedTokenId, getTokenName } from '../../../helpers/getChainName';
import useGetTokenPrice from '../../../hooks/useGetTokenPrice';
import { DataRefreshContext } from '../../refreshContext';
import DropDownItemProperties from '../../dropdown/DropDownItemProperties';
import { ModalContainer } from '../modalContainer';
import { errorToast, successToast } from '../../toast';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { useGetChain } from '../../../hooks/useGetChain';
import { Button, SwitchNetworkButton } from '../../Button';

export const RaritySelector = ({ onChange, collectionInfo, isUpdate, rarity }) => {
  const [rarityFrom, setRarityFrom] = useState(rarity ? rarity?.min : null);
  const [rarityTo, setRarityTo] = useState(rarity ? rarity?.max : null);

  const dif = (rarityTo || 1) - (rarityFrom || 1) + 1;
  useEffect(() => {
    if (collectionInfo && rarity) {
      if (!isUpdate) {
        setRarityFrom(1);
        setRarityTo(collectionInfo.itemCount);
      } else {
        setRarityFrom(rarity?.min);
        setRarityTo(rarity?.max);
      }
    }
  }, [collectionInfo, rarity]);

  useEffect(() => {
    onChange({
      min: Number(rarityFrom),
      max: Number(rarityTo),
    });
  }, [rarityFrom, rarityTo]);

  const fixDir = () => {
    if (!rarityFrom) rarityFrom = 1;
    if (!rarityTo) rarityTo = 1;
    if (rarityFrom > rarityTo) {
      setRarityTo(rarityFrom);
      setRarityFrom(rarityTo);
    } else {
      setRarityTo(rarityTo);
      setRarityFrom(rarityFrom);
    }
  };

  return (
    <>
      <div className='mb-0 flex items-center justify-between'>
        <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
          Filter
        </span>
      </div>
      <div className='mb-2 grid max-h-[200px] grid-cols-2 overflow-auto rounded-md py-3'>
        {/* mid */}
        <div className='flex pr-2'>
          <p className='mt-4 w-[55px] font-semibold'>Min</p>
          <input
            type='number'
            placeholder='Min Rarity'
            value={rarityFrom}
            min={1}
            onBlur={fixDir}
            data-testid='minRarity'
            onChange={(e) => {
              if (
                (e.target.value <= 0 && e.target.value !== '') ||
                e.target.value > collectionInfo?.itemCount
              )
                return;
              setRarityFrom(e.target.value);
            }}
            onWheel={(e) => e.target.blur()}
            className='my-2 w-full 
          rounded-md border border-jacarta-100 p-2 dark:border-jacarta-600 dark:text-jacarta-900'
          />
        </div>
        <div className='flex pl-2'>
          <p className='mt-4 w-[55px] font-semibold'>Max</p>
          <input
            type='number'
            placeholder='Max Rarity'
            value={rarityTo}
            min={1}
            max={collectionInfo?.itemCount || 100}
            onBlur={fixDir}
            data-testid='maxRarity'
            onChange={(e) => {
              if (
                (e.target.value <= 0 && e.target.value !== '') ||
                e.target.value > collectionInfo?.itemCount
              )
                return;
              setRarityTo(e.target.value);
            }}
            onWheel={(e) => e.target.blur()}
            className='my-2 w-full 
          rounded-md border border-jacarta-100 p-2 dark:border-jacarta-600 dark:text-jacarta-900'
          />
        </div>
      </div>
      <div className='mb-2 flex'>
        <div className='mt-2 w-full'>
          <RangeSlider
            value={[rarityFrom || 1, rarityTo || 1]}
            min={1}
            max={collectionInfo?.itemCount || 100}
            onInput={(e) => {
              setRarityFrom(e[0]);
              setRarityTo(e[1]);
            }}
          />
        </div>
      </div>
      {/*dif <= 0 && (rarityFrom || rarityFrom === 0) && rarityTo ? (
        <div className='mb-2 flex items-center justify-between'>
          <span className={`w-full py-3 text-center font-display text-sm font-semibold !text-red`}>
            Max Rarity must be greater than Min Rarity
          </span>
        </div>
      ) : null*/}
      {dif > 0 ? (
        <div className='mb-2 flex items-center justify-between'>
          <span
            className={`w-full py-3 text-center font-display text-sm font-semibold text-jacarta-700 ${
              dif === 0 ? '!text-red' : ''
            } dark:text-white`}
          >
            {dif === 0 ? (
              'This Filter Has No Corresponding NFTs'
            ) : (
              <>
                There are <span className='font-bolder'>({dif})</span> NFTs on this rarity range
              </>
            )}
          </span>
        </div>
      ) : null}
    </>
  );
};

const MODAL_KEY = 'giveGlobalBidModal';
const GiveGlobalBidModal = () => {
  const { refreshHooks } = useContext(DataRefreshContext);
  const { modal } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const [BNBAmount, setBNBAmount] = useState('');
  const [usdAmount, setUsdAmount] = useState(0);
  const [expiration, setExpiration] = useState(1);
  const [globalBidAmount, setGlobalBidAmount] = useState('');
  const [orderToSign, setOrderToSign] = useState({});
  const [traits, setTraits] = useState({});
  const [rarity, setRarity] = useState(null);
  const [traitFilterCount, setTraitFilterCount] = useState(null);
  const collectionInfo = useGetCollection(modal?.data).data;
  const { chain, isChainActive } = useGetChain(collectionInfo?.chain);

  const traitsEnabled = modal?.traits;
  const rarityEnabled = modal?.rarity;

  let text = traitsEnabled ? 'Place a Global Trait Bid' : 'Place a Global Bid';
  text = rarityEnabled ? 'Place a Global Rarity Bid' : text;

  const [buttonText, setButtonText] = useState(text);

  const { address } = useAccount();

  let { data: nativeBalance } = useBalance({ address });

  const wrappedToken = getCoinGeckoWrappedTokenId(collectionInfo?.chain);
  let { data: wrappedTokenBalance } = useBalance({
    address,
    token: chain?.wrappedToken,
  });
  const { usdPrice: tokenPrice } = useGetTokenPrice(collectionInfo?.chain);

  const signer = useWalletClient();
  const publicClient = usePublicClient();

  const { data: isPrivileged } = useGetIsPrivileged(address, collectionInfo?.chain);
  const wbnbContract = getContract({
    address: chain?.wrappedToken,
    abi: wbnbABI,
    publicClient,
    walletClient: signer.data,
  });

  const handleAmountChanged = (newAmount) => {
    newAmount = newAmount.replace(',', '.');
    const zeroCounts = newAmount.split('.');

    if (zeroCounts?.length >= 1 && zeroCounts[1]?.length > 4) return;

    setBNBAmount(newAmount);
  };

  const handleConvert = async () => {
    try {
      const diff = balanceDifference();

      if (BNBAmount > parseFloat(wrappedTokenBalance?.formatted) && 0 > diff) {
        const tx = await wbnbContract.write.deposit({
          value: parseEther(Math.abs(diff).toString()),
        });
        await publicClient.waitForTransactionReceipt({
          confirmations: 2,
          hash: tx,
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const tokenContract = getContract({
    address: chain?.wrappedToken,
    abi: erc20ABI,
    publicClient,
    walletClient: signer.data,
  });

  const { signTypedDataAsync } = useSignTypedData({
    domain: dynamicDomain(chain?.id),
    message: orderToSign,
    types: types,
    primaryType: 'Order',
  });

  useEffect(() => {
    if (BNBAmount && globalBidAmount && address)
      setOrderToSign({
        issuer: getAddress(address),
        nftAddress: modal?.data,
        tokenId: '0',
        paymentToken: chain?.wrappedToken,
        price: parseEther(BNBAmount).toString(),
        end: Math.floor(Date.now() / 1000) + expiration * 24 * 60 * 60,
        kind: 0,
        tokenKind:
          collectionInfo?.contractType === 'ERC1155' // 0 means ERC-721 and 1 means ERC-1155 2 means ERC-404
            ? 1
            : collectionInfo?.contractType === 'ERC404'
            ? 2
            : 0,
        globalBidAmount: Number(globalBidAmount),
        privileges:
          isPrivileged && isPrivileged?.collectionAddress && isPrivileged?.tokenId
            ? {
                privilegedCollection: isPrivileged?.collectionAddress,
                privilegedTokenId: isPrivileged?.tokenId,
              }
            : {
                privilegedCollection: ethers.constants.AddressZero,
                privilegedTokenId: String(0),
              },
        traitFilter: traitsEnabled ? traits : null,
        rarityFilter: rarityEnabled ? rarity : null,
      });
  }, [
    globalBidAmount,
    BNBAmount,
    address,
    modal?.data,
    expiration,
    collectionInfo?.contractType,
    isPrivileged,
    traits,
    wrappedToken,
    traitsEnabled,
  ]);

  useEffect(() => {
    if (modal?.active) {
      document.body.style.setProperty('overflow', 'hidden', 'important');
    }
    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, [modal?.active]);

  const handlePlaceBid = async () => {
    if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
    if (buttonDisabled) return errorToast('Please fill all inputs.');
    if (!(BNBAmount && globalBidAmount && address)) return errorToast('Please fill all inputs.');

    try {
      setButtonText('Converting tokens...');
      await handleConvert();
      setButtonText('Approving tokens...');
      await approveERC20(tokenContract, address, orderToSign?.price, chain?.marketplace);
      setButtonText('Giving the bid...');
      const signature = await signTypedDataAsync();
      orderToSign.signature = signature;

      // returns boolean
      const res = (
        await axios.post(`${constants.api.url_new}/orders/giveBid`, {
          order: orderToSign,
          chain: collectionInfo?.chain.toString(),
        })
      ).data.data;

      if (!res) throw 'Failed to give bid';
      successToast(`Global bid given!`);

      refreshHooks();
      dispatch(closeModal());
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setButtonText(text);
    }
  };

  const handleEThAmount = (e) => {
    e.preventDefault();
    handleAmountChanged(e?.target?.value);

    const usd = Number(tokenPrice * e.target.value).toFixed(2);
    setUsdAmount(usd);
  };

  const balanceDifference = () => {
    try {
      if (!BNBAmount || !wrappedTokenBalance) return 0;

      return (parseFloat(wrappedTokenBalance?.formatted) - BNBAmount * globalBidAmount).toFixed(18);
    } catch (err) {
      return 1;
    }
  };

  const totalBalance =
    parseFloat(nativeBalance?.formatted) + parseFloat(wrappedTokenBalance?.formatted);

  const buttonDisabled =
    buttonText != text ||
    (rarityEnabled && (!rarity || (!rarity?.min && !rarity?.max) || rarity?.min > rarity?.max));

  return (
    <ModalContainer modalKey={MODAL_KEY} title={text}>
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        <div className='mb-2 flex items-center justify-between'>
          <span className='text font-display font-semibold text-jacarta-700 dark:text-white'>
            {modal?.data?.name ?? modal?.data?.metadata?.name ?? ''}
          </span>
        </div>

        {!rarityEnabled && (
          <>
            <div className=''>
              NOTE:{' '}
              {traitsEnabled ? 'You are giving a global trait bid' : 'You are giving a global bid'}
            </div>
            <br />
          </>
        )}
        {traitsEnabled && (
          <>
            <div className='mb-2 flex items-center justify-between'>
              <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
                Traits
              </span>
            </div>
            <div className='my-5 max-h-[200px] overflow-auto rounded-md border border-jacarta-100 py-3 px-6 dark:border-jacarta-600'>
              <DropDownItemProperties
                singleSelect
                value={traits}
                onSelect={async (val) => {
                  const { data: filterCount } = await axios.post(
                    `${constants.api.url_new}/collections/checkTraitBids`,
                    {
                      criteria: val,
                      collectionAddress: collectionInfo?.address,
                    },
                  );
                  setTraitFilterCount(filterCount.data);
                  setTraits(val);
                }}
                collectionAddress={collectionInfo?.address}
                disableScroll
              />
            </div>
            <div className='flex flex-wrap'>
              {Object.keys(traits).map((trait, i) => {
                return (
                  <>
                    <span key={trait} className='my-[6px] mr-2 font-display text-sm font-semibold'>
                      {trait}:
                    </span>
                    <span className='mt-[3px]'>(</span>
                    {traits[trait].map((t, i) => (
                      <>
                        <div
                          key={trait}
                          className='my-1 mx-1 flex w-fit rounded-lg bg-accent-dark px-2 py-1 text-white dark:text-jacarta-700'
                        >
                          <span className='text-sm'>{t}</span>
                        </div>
                        {traits[trait].length - 1 > i && <span className='mr-1 w-fit pt-1'>,</span>}
                      </>
                    ))}
                    <span className='mt-[3px]'>)</span>
                    {Object.keys(traits).length - 1 > i && (
                      <span className='my-2 w-full text-left text-jacarta-700'>AND</span>
                    )}
                  </>
                );
              })}
            </div>
          </>
        )}
        {traitsEnabled && traitFilterCount !== null && (
          <div className='mb-2 flex items-center justify-between'>
            <span
              className={`w-full py-3 text-center font-display text-sm font-semibold text-jacarta-700 ${
                traitFilterCount === 0 ? '!text-red' : ''
              } dark:text-white`}
            >
              {traitFilterCount === 0 ? (
                'This Filter Has No Corresponding NFTs'
              ) : (
                <>
                  There are <span className='font-bolder'>({traitFilterCount})</span> NFTs with this
                  filter
                </>
              )}
            </span>
          </div>
        )}
        {rarityEnabled && <RaritySelector onChange={setRarity} collectionInfo={collectionInfo} />}

        <div className='mb-2 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            Price
          </span>
        </div>

        <div className='relative mb-2 flex items-center overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600'>
          <div className='flex items-center justify-center self-stretch border-r border-jacarta-100 bg-jacarta-50 px-5 px-2 dark:border-jacarta-600 dark:bg-jacarta-700'>
            <ChainIcon name={collectionInfo?.chain} tooltip={getTokenName(collectionInfo?.chain)} />
          </div>

          <input
            type='number'
            className='h-12 w-full flex-[3] border-0 focus:ring-0 dark:text-jacarta-700'
            placeholder='Price'
            value={BNBAmount}
            onChange={(e) => handleEThAmount(e)}
            onWheel={(e) => e.target.blur()}
            data-testid='giveGlobalBidPrice'
          />

          <div className='flex flex-1 justify-end self-stretch border-l border-jacarta-100 bg-jacarta-50 dark:text-jacarta-700'>
            <span className='self-center px-2 text-sm'>~${usdAmount}</span>
          </div>
        </div>

        <div className='mt-5 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            Amount
          </span>
        </div>
        <div className='relative mt-3 mb-2 flex items-center overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600'>
          <input
            type='text'
            className='focus:ring-inse h-12 w-full flex-[3] border-0 focus:ring-accent dark:text-jacarta-700'
            placeholder='Global Bid Amount'
            value={globalBidAmount}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setGlobalBidAmount(value);
              }
            }}
            onWheel={(e) => e.target.blur()}
            data-testid='giveGlobalBidAmount'
          />
        </div>

        <div className='mt-5 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            Expiration
          </span>
        </div>
        <div className='mt-3 mb-6'>
          <select
            className='h-12 w-full rounded-lg border border-jacarta-100 bg-jacarta-50 px-4 text-sm text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:text-white'
            value={expiration}
            onChange={(e) => setExpiration(e.target.value)}
            data-testid='giveGlobalBidExpiration'
          >
            <option value={1}>1 day</option>
            <option value={3}>3 days</option>
            <option value={7}>7 days</option>
            <option value={30}>30 days</option>
            <option value={180}>6 months</option>
          </select>
        </div>

        <div>
          <div className='flex items-center justify-end'>
            Balance:&nbsp;{' '}
            <Icon noSideGap name={`icon-${wrappedToken}`} tooltip={wrappedToken.toUpperCase()} />
            <span className='pb-0.5 text-sm dark:text-jacarta-400'>
              {normalizeEther(wrappedTokenBalance?.formatted)}
            </span>
          </div>
          <div className='flex items-center justify-end'>
            Total:&nbsp;{' '}
            <Icon noSideGap name={`icon-${wrappedToken}`} tooltip={wrappedToken.toUpperCase()} />
            <span className='text-md dark:text-jacarta-400'>
              {normalizeNumber(BNBAmount * globalBidAmount)}
            </span>
          </div>
        </div>

        {/* <!-- Terms --> */}

        <div className='alert-info mt-2 w-full rounded-lg p-2'>
          Note: Bit5 does not escrow your money. Which means, you can bid without paying for gas
          fees. Conversion from {getTokenName(collectionInfo?.chain)} to{' '}
          {getCoinGeckoWrappedTokenId(collectionInfo?.chain).toUpperCase()} is done during the offer
          and if the {getCoinGeckoWrappedTokenId(collectionInfo?.chain).toUpperCase()} balance falls
          below the offered value, the offer cannot be accepted.
        </div>
      </div>
      {/* <!-- end body --> */}

      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {!isChainActive ? (
            <SwitchNetworkButton chainId={chain?.id} />
          ) : totalBalance > BNBAmount * globalBidAmount ? (
            traitsEnabled &&
            (traitFilterCount === 0 || !traitFilterCount || Object.keys(traits).length === 0) ? (
              'Please select traits'
            ) : (
              <Button
                key={'give_bid_button'}
                onClick={handlePlaceBid}
                text={buttonText}
                disabled={buttonDisabled}
                data-testid='giveGloablBidButton'
              />
            )
          ) : (
            'Insufficient Funds'
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default GiveGlobalBidModal;
