import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAccount, useBalance, useWalletClient } from 'wagmi';
import { getContract } from 'wagmi/actions';
import abi from '../../helpers/abi';
import { normalizeEther, normalizeNumber } from '../../helpers/digits';
import useGetCollection from '../../hooks/useGetCollection';
import { ChainIcon } from '../Icon';
import { getTokenName } from '../../helpers/getChainName';
import useGetTokenPrice from '../../hooks/useGetTokenPrice';
import { ModalContainer } from './modalContainer';
import useGetListing from '../../hooks/useGetListing';
import { formatEther } from 'ethers/lib/utils.js';
import { ModalItemDetails } from './modalComponents/modalItemDetails';
import { errorToast } from '../toast';
import { useCatchTxError } from '../../hooks/useCatchTxError';
import { Button, SwitchNetworkButton } from '../Button';
import { useGetChain } from '../../hooks/useGetChain';

const MODAL_KEY = 'buyModal';
const BuyModal = () => {
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient();
  const { fetchWithCatchTxError } = useCatchTxError();
  const { modal } = useSelector((state) => state.counter);
  const data = modal?.data;
  const { chain, isChainActive } = useGetChain(data?.chain);
  const defaultButtonText = 'Confirm Checkout';
  const [buttonText, setButtonText] = useState(defaultButtonText);

  const listing = useGetListing({
    collectionAddress: data?.collectionAddress,
    itemId: data?.itemId,
    signature: data?.signature,
  }).data;

  const price = formatEther(listing?.price || '0');
  const { usdPrice: tokenPrice } = useGetTokenPrice(data?.chain);

  let { data: nativeBalance } = useBalance({ address });
  let { data: wrappedTokenBalance } = useBalance({
    address,
    token: chain?.wrappedToken,
  });

  const collectionInfo = useGetCollection(data?.collectionAddress).data;
  const usdPrice = normalizeNumber(tokenPrice * price, 3);
  const marketplaceContract = getContract({
    address: chain?.marketplace,
    abi,
    walletClient,
  });

  const handleBuy = async () => {
    try {
      if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
      setButtonText('Confirming the checkout...');

      let newSignature = listing?.signature;
      if (newSignature?.endsWith('00')) newSignature = newSignature?.slice(0, -2) + '1B';
      if (newSignature?.endsWith('01')) newSignature = newSignature?.slice(0, -2) + '1C';

      const order = {
        issuer: listing?.issuer,
        nftAddress: listing?.collectionAddress,
        paymentToken: listing?.paymentToken,
        price: listing?.price,
        tokenId: listing?.itemId,
        end: listing?.end,
        kind: listing?.kind,
        tokenKind: listing?.tokenKind,
        globalBidAmount: listing?.globalBidAmount,
        privileges: {
          privilegedCollection: listing?.privilegedCollection,
          privilegedTokenId: listing?.privilegedTokenId,
        },
        proof: listing.proof ? JSON.parse(listing.proof) : [],
        signature: newSignature,
      };

      await fetchWithCatchTxError({
        callTx: () =>
          marketplaceContract?.write?.buy?.([order], {
            value: listing?.price,
          }),
        toastMessage: 'Purchase successful!',
        chainId: chain?.id,
      });
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setButtonText(defaultButtonText);
    }
  };

  const totalBalance =
    parseFloat(nativeBalance?.formatted) + parseFloat(wrappedTokenBalance?.formatted);

  const disabled = totalBalance < price || buttonText !== defaultButtonText;
  const BuyButton = () =>
    totalBalance < price ? (
      <span>Insufficient Funds</span>
    ) : (
      <Button
        key={'buy_button'}
        onClick={handleBuy}
        disabled={disabled}
        text={buttonText}
        data-testid='buyButton'
      />
    );

  return (
    <ModalContainer modalKey={MODAL_KEY} title='Complete checkout'>
      {/* <!-- Buy Now Modal --> */}
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        <ModalItemDetails item={data} collection={collectionInfo} isGlobal={false} />
        {/* <!-- Creator Earnings --> */}
        <div className='flex flex-wrap items-center'>
          <span className='mr-1 block text-sm text-jacarta-500 dark:text-jacarta-300'>
            Creator Earnings: {collectionInfo?.totalCreatorRoyalty}%
          </span>
          <span data-tippy-content='The creator of this collection will receive 5% of the sale total from future sales of this item.'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              width='24'
              height='24'
              className='h-4 w-4 fill-jacarta-700 dark:fill-jacarta-300'
            >
              <path fill='none' d='M0 0h24v24H0z' />
              <path d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z' />
            </svg>
          </span>
        </div>

        {/* <!-- Total --> */}
        <div className='flex items-center justify-between py-2.5'>
          <span className='font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white'>
            Total
          </span>
          <div className='ml-auto'>
            <span className='flex items-center justify-end whitespace-nowrap'>
              <ChainIcon name={data?.chain} tooltip={getTokenName(chain.id)} sideGap={true} />
              <span className='font-medium tracking-tight text-green'>{normalizeEther(price)}</span>
            </span>
            <div className='text-right dark:text-jacarta-300'>~ ${usdPrice}</div>
          </div>
        </div>

        {/* <!-- Terms --> */}
      </div>
      {/* <!-- end body --> */}

      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {!isChainActive ? <SwitchNetworkButton chainId={chain.id} /> : <BuyButton />}
        </div>
      </div>
    </ModalContainer>
  );
};

export default BuyModal;
