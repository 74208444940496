import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { ethers } from 'ethers';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export default function useGetFeaturedCollections() {
  const { refreshDate } = useContext(DataRefreshContext);
  const config = {
    url: `${constants.api.url_new}/collections/featuredCollections`,
    //url: `${constants.api.url}/collections/featuredCollections`,
    method: constants.httpMethods.get,
    params: { refreshDate },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return useAdaptor(axios.getUri(config), fetcher);
}
