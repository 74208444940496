import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { ethers } from 'ethers';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export function getItemAttributesFetcher(collectionAddress, itemId, refreshDate) {
  let config;

  if (!ethers.utils.isAddress(collectionAddress)) {
    config = {};
  } else {
    config = {
      url: `${constants.api.url_new}/items/getAttributes`,
      method: constants.httpMethods.get,
      params: { collectionAddress, itemId, refreshDate },
    };
  }

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data?.data || [];
    });

  return { config, fetcher };
}

export default function useGetItemAttributes(collectionAddress, itemId) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, fetcher } = getItemAttributesFetcher(collectionAddress, itemId, refreshDate);
  return useAdaptor(axios.getUri(config), fetcher);
}
