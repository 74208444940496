import { useMemo, useState } from 'react';
import { web3Name } from '../components/web3name';
import { useNetwork } from 'wagmi';
import { getSupportedChain } from '../helpers/getSupportedChain';
import { getAddress, isAddress } from 'viem';
import { constants } from '../helpers/constants';
import axios from '../lib/axios';

export const useGetUserInfo = (address) => {
  const [user, setUser] = useState(null);
  const [isEns, setIsEns] = useState(false);
  const { chain: activeChain } = useNetwork();
  const chain = getSupportedChain(activeChain?.hexId);
  const scanLink = `${chain?.scan_url}/address/${address}`;

  const getUserProfile = async () => {
    try {
      setUser(null);
      if (!address || !isAddress(address)) return;
      const response = await axios.get(`${constants.api.url_new}/users/get`, {
        params: { walletAddress: address },
      });
      if (response?.data?.data) setUser((user) => ({ ...user, ...response.data.data, scanLink }));
    } catch (e) {
      console.error('getUserProfile error: ', e);
    }
  };

  const getEnsName = async () => {
    try {
      setUser((user) => ({ ...user, ensName: null, scanLink }));
      if (!address || !isAddress(address)) return;
      const name = await web3Name.getDomainName({
        address,
        queryChainIdList: [chain.id],
        rpcUrl: chain?.rpcUrls?.default?.http?.[0],
      });
      if (name && getAddress(address) === getAddress(await web3Name.getAddress(name))) {
        setUser((user) => ({
          ...user,
          ensName: name,
          scanLink,
        }));
        setIsEns(true);
      }
    } catch (e) {
      console.error('getEnsName error: ', e);
    }
  };
  useMemo(getEnsName, [address, chain?.id]);
  useMemo(getUserProfile, [address]);
  return { user, isEns };
};
