import { constants } from '../helpers/constants';

export const getCdnUrl = (path = '', imagesize = 'detail', updatedAt) => {
  if (path && (path.includes('http') || path.includes('https'))) return path;
  // return constants.cdn.path + path + constants.cdn[imagesize] + constants.cdn.version;
  return path
    ? constants.cdn.path +
        path +
        (constants.cdn[imagesize] || `?tr=w-${imagesize}`) +
        constants.cdn.version +
        // (updatedAt ? '&dua=' + updatedAt.split('T')[0].split('-').join('') : '')
        (updatedAt ? '&dua=' + new Date(updatedAt).getTime() : '')
    : '';
};
