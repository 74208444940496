import { getSupportedChain } from './getSupportedChain';

export default function getChainName(chain) {
  return `Chain: ${getSupportedChain(chain)?.name}`;
}

export const getTokenName = (chain) => {
  return getSupportedChain(chain)?.nativeCurrency?.symbol;
};

export const getCoinGeckoId = (chain) => {
  switch (chain) {
    case '0x01':
      return 'ethereum';
    case '0x38':
      return 'binancecoin';
    case '0x61':
      return 'binancecoin';
    case '0x05':
      return 'ethereum';
    case '0xaa36a7':
      return 'ethereum';
    case '0xa86a':
      return 'avalanche-2';
    case '0xa869':
      return 'avalanche-2';
    case 'weth':
      return 'ethereum';
    case 'wbnb':
      return 'binancecoin';
    case 'wavax':
      return 'avalanche-2';
    case 'wmatic':
      return 'wmatic';
    case 'dons':
      return 'dons';
    case '0x144':
      return 'ethereum';
    case '0x118':
      return 'ethereum';
    case '0x5a2':
      return 'ethereum';
    case '0x44d':
      return 'ethereum';
    case '0x89':
      return 'matic-network';
    case '0x13e31':
      return 'ethereum';
    case '0xa0c71fd':
      return 'ethereum';
    default:
      return '';
  }
};

export const getCoinGeckoWrappedTokenId = (chain) => {
  switch (chain) {
    case '0x01':
      return 'weth';
    case '0x38':
      return 'wbnb';
    case '0xa86a':
      return 'wrapped-avax';
    case '0xa869':
      return 'wrapped-avax';
    case '0x61':
      return 'wbnb';
    case '0x05':
      return 'weth';
    case '0xaa36a7':
      return 'weth';
    case '0x144':
      return 'weth';
    case '0x118':
      return 'weth';
    case '0x5a2':
      return 'weth';
    case '0x44d':
      return 'weth';
    case '0x89':
      return 'wmatic';
    case '0x13e31':
      return 'weth';
    case '0xa0c71fd':
      return 'weth';
    default:
      return '';
  }
};
