import { useNetwork } from 'wagmi';
import { getSupportedChain } from '../helpers/getSupportedChain';

export const useGetChain = (chainId) => {
  const { chain: activeChain } = useNetwork();
  const chain = getSupportedChain(chainId);

  return {
    chain,
    activeChain,
    isChainActive: activeChain?.id === chain?.id && !activeChain?.unsupported,
  };
};
