import axios from 'axios';
import abi from '../../helpers/abi';
import { ethers } from 'ethers';
import { prepareWriteContract, writeContract } from 'wagmi/actions';
import { getSupportedChain } from '../../helpers/getSupportedChain';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAccount, useBalance } from 'wagmi';
import { constants } from '../../helpers/constants';
import { getNftMetadataFetcher } from '../../hooks/useGetNftMetadata';
import { normalizeEther } from '../../helpers/digits';
import { getCoinGeckoId } from '../../helpers/getChainName';
import { DataRefreshContext } from '../../components/refreshContext';
import { getListingFetcher } from '../../hooks/useGetListing';
import { errorToast, successToast } from '../../components/toast';
import { useCatchTxError } from '../../hooks/useCatchTxError';

export const CartContext = createContext({});

export const useCartFunctions = () => {
  const { address } = useAccount();
  const [chainId, setChainId] = useState('');
  const [coinUsdPrice, setCoinUsdPrice] = useState();
  const { fetchWithCatchTxError } = useCatchTxError();
  const { refreshDate } = useContext(DataRefreshContext);
  const {
    itemList,
    setCartContextData,
    isCartOpen,
    buyEnabled,
    buyStatusText,
    totalEtherPrice,
    totalUsdPrice,
    cartChainId,
  } = useContext(CartContext);

  const { data: nativeBalance } = useBalance({ address });

  const getUSDData = async (config) => {
    const response = await axios(config);
    setCoinUsdPrice(Object.values(response.data)?.[0]?.usd);
  };

  useEffect(() => {
    if (chainId) {
      let config = {
        url: `https://api.coingecko.com/api/v3/simple/price`,
        method: constants.httpMethods.get,
        params: { ids: getCoinGeckoId(chainId), vs_currencies: 'usd', refreshDate },
      };
      getUSDData(config);
    }
  }, [chainId]);

  useEffect(() => {
    if (itemList && coinUsdPrice) {
      itemList.map((item) => {
        return {
          ...item,
          usdPrice: getPriceUSD(item.price),
        };
      });

      setCartContextData((current) => ({
        ...current,
        itemList: itemList,
        totalUsdPrice: itemList.reduce(function (acc, item) {
          return acc + item.usdPrice;
        }, 0),
      }));
    }
  }, [itemList, coinUsdPrice]);

  const getPriceUSD = (_price) => {
    const normalizedAmount = normalizeEther(_price);
    const price = Number(coinUsdPrice * normalizedAmount);
    return isNaN(price) ? 0 : price;
  };

  const refreshItemList = async () => {
    try {
      const response = await axios.request({
        method: 'get',
        url: `/api/cart?walletAddress=${address}`,
      });

      let cartItem = response.data.data;

      if (cartItem.items) {
        let items = [];
        if (cartItem.chainId) {
          setChainId(cartItem.chainId);
        }

        for (let i = 0; i < cartItem.items.length; i++) {
          let metaDataResponse = await getNftMetadataFetcher(
            cartItem.items[i].collectionAddress,
            cartItem.items[i].itemId,
          ).fetcher();
          metaDataResponse.metadata = JSON.parse(metaDataResponse.metadata);
          /*          if (metaDataResponse.isOnSale) {
            items.push(metaDataResponse);
          } else removeItemFromCart(cartItem.items[i]); */
          items.push(metaDataResponse);
        }

        setCartContextData((current) => ({
          ...current,
          itemList: items,
          totalEtherPrice: items.reduce(function (acc, item) {
            return acc + item.price;
          }, 0),
          cartChainId: parseInt(cartItem.chainId, 16),
        }));
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const addItemToCart = async (items) => {
    const _items = items.map((item) => {
      return { itemId: item.itemId, collectionAddress: item.collectionAddress };
    });

    try {
      await axios
        .request({
          method: 'post',
          maxBodyLength: Infinity,
          url: '/api/cart/add-item',
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            walletAddress: address,
            chainId: items[0].chain,
            items: _items,
          },
        })
        .then(async () => {
          await refreshItemList();
        });

      successToast('Item added to Cart.');
      setChainId(items[0].chain);
    } catch (err) {
      errorToast(err.response.data.message);
    }
  };

  const removeItemFromCart = async (item) => {
    await axios
      .request({
        method: 'delete',
        maxBodyLength: Infinity,
        url: '/api/cart/remove-item',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          walletAddress: address,
          itemId: item.itemId,
          collectionAddress: item.collectionAddress,
        },
      })
      .then(async () => {
        await refreshItemList();
      });

    successToast('Item removed from Cart.');
  };

  const removeMultiFromCart = async (items) => {
    await axios
      .request({
        method: 'delete',
        maxBodyLength: Infinity,
        url: '/api/cart/remove-multi-item',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          walletAddress: address,
          items: items.map((item) => ({
            itemId: item.itemId,
            collectionAddress: item.collectionAddress,
          })),
        },
      })
      .then(async () => {
        await refreshItemList();
      });

    successToast('Items removed from Cart.');
  };

  const clearCart = async (useNot) => {
    await axios
      .request({
        method: 'delete',
        maxBodyLength: Infinity,
        url: '/api/cart/clear',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          walletAddress: address,
        },
      })
      .then(async () => {
        await refreshItemList();
      });
    if (useNot) successToast('Removed all items from Cart!');
  };

  const toggleCart = () => {
    setCartContextData((current) => ({
      ...current,
      isCartOpen: !isCartOpen,
    }));
  };

  const buyBatch = async (chainId, items) => {
    try {
      let hexChainId = `0x${chainId.toString(16)}`;
      const chain = getSupportedChain(hexChainId);

      setCartContextData((current) => ({
        ...current,
        buyEnabled: false,
        buyStatusText: 'Confirming the checkout...',
      }));

      let orders = await Promise.all(
        items.map(async (item) => {
          const { fetcher } = getListingFetcher(
            item.collectionAddress,
            item.itemId,
            /* item.listingSignature || */ item.bulkListingSignature,
          );
          const listing = await fetcher();

          return {
            issuer: listing?.issuer,
            nftAddress: listing?.collectionAddress,
            paymentToken: listing?.paymentToken,
            price: listing?.price,
            tokenId: listing?.itemId,
            end: listing?.end,
            kind: listing?.kind,
            tokenKind: listing?.tokenKind,
            globalBidAmount: listing?.globalBidAmount,
            privileges: {
              privilegedCollection: listing?.privilegedCollection,
              privilegedTokenId: listing?.privilegedTokenId,
            },
            proof: JSON.parse(listing.proof)
              ? /* listing.proof.split(',') */ JSON.parse(listing.proof)
              : [],
            signature: listing.bulkSignature ?? listing.signature,
          };
        }),
      );

      let totalWei = ethers.BigNumber.from(0);

      orders.forEach((order) => {
        const priceWei = ethers.BigNumber.from(order.price);
        totalWei = totalWei.add(priceWei);
      });

      /*       const tx = await marketplaceContract.write.buyBatch([orders], {
        value: totalWei,
      }); */

      const { request } = await prepareWriteContract({
        address: chain.marketplace,
        abi,
        functionName: 'buyBatch',
        args: [orders],
        value: totalWei,
      });
      setCartContextData((current) => ({
        ...current,
        buyEnabled: false,
        buyStatusText: 'Awaiting blockchain...',
      }));
      await fetchWithCatchTxError({
        callTx: () => writeContract(request),
        chainId: chain?.id,
        toastMessage: 'Purchase successful!',
      });
      clearCart();
    } catch (err) {
      console.log(err);
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setCartContextData((current) => ({
        ...current,
        buyEnabled: true,
      }));
    }
  };

  return {
    itemList,
    isCartOpen,
    addItemToCart,
    removeItemFromCart,
    removeMultiFromCart,
    clearCart,
    toggleCart,
    refreshItemList,
    buyEnabled,
    buyStatusText,
    buyBatch,
    totalEtherPrice,
    totalUsdPrice,
    cartChainId,
    nativeBalance,
  };
};
