import ContentLoader from 'react-content-loader';

export function NftSkeleton({ ...props }) {
  const { count } = props;
  return (
    <div className='w-full grid gap-[0.875rem] grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-8 p-2'>
      {Array(count)
        .fill()
        .map((_, index) => (
          <div
            key={'' + index}
            className='dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[0px] transition-shadow hover:shadow-lg overflow-hidden'
          >
            <ContentLoader
              speed={2}
              width='100%'
              height='100%'
              viewBox='0 0 500 550'
              backgroundColor='#f3f3f3'
              foregroundColor='#ecebeb'
              {...props}
            >
              <rect x='0' y='0' rx='0' ry='0' width='100%' height='412' />
              <rect x='30' y='470' rx='0' ry='0' width='300' height='30' />
            </ContentLoader>
          </div>
        ))}
    </div>
  );
}

export function ActivitySkeleton({ ...props }) {
  const { count } = props;
  return (
    <div className='w-full'>
      {Array(count)
        .fill()
        .map((_, index) => (
          <div
            key={'' + index}
            className='dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 block border bg-white transition-shadow hover:shadow-lg overflow-hidden'
          >
            <ContentLoader
              speed={2}
              width='100%'
              height={81}
              backgroundColor='#f3f3f3'
              foregroundColor='#ecebeb'
              {...props}
            >
              <rect x='80' y='30' width='25' height='25' />
              <rect x='205' y='20' width='50' height='50' />
              <rect x='270' y='35' width='80' height='20' />
              <rect x='400' y='35' width='20' height='20' />
              <rect x='590' y='35' width='75' height='20' />
              <rect x='790' y='35' width='75' height='20' />
              <rect x='980' y='35' width='75' height='20' />
            </ContentLoader>
          </div>
        ))}
    </div>
  );
}

export function TopCollectionsSkeleton({ ...props }) {
  const { count } = props;
  return Array(count)
    .fill()
    .map((_, index) => (
      <div
        key={'' + index}
        className='dark:bg-jacarta-700 dark:border-jacarta-700 rounded-2xl border-jacarta-100 block border bg-white transition-shadow hover:shadow-lg overflow-hidden'
      >
        <ContentLoader
          speed={2}
          width='100%'
          height={81}
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
          {...props}
        >
          <rect x='30' y='20' rx={12} width='50' height='50' />
          <rect x='100' y='20' width='100' height='20' />
          <rect x='100' y='50' width='60' height='20' />
        </ContentLoader>
      </div>
    ));
}
