import { toast } from 'react-hot-toast';

export const successToast = (message) => {
  return toast.success(
    (t) => (
      <span
        className='break-words'
        style={{ whiteSpace: 'pre-line' }}
        onClick={() => toast.dismiss(t.id)}
      >
        {message}
      </span>
    ),
    {
      duration: 2500,
    },
  );
};

export const errorToast = (message) => {
  return toast.error(
    (t) => (
      <span
        className='break-words'
        style={{ whiteSpace: 'pre-line' }}
        onClick={() => toast.dismiss(t.id)}
      >
        {message}
      </span>
    ),
    {
      duration: 2500,
    },
  );
};
