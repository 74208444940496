import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useWalletClient, useSignTypedData, useNetwork } from 'wagmi';
import { getContract } from 'wagmi/actions';
import abi from '../../helpers/abi';
import { AddressZero, getTokenBalance, cancelAllOrders } from '../../helpers/blockchainOperations';
import { constants } from '../../helpers/constants';
import { normalizeEther } from '../../helpers/digits';
import wbnbABI from '../../helpers/wbnbABI';
import useGetCollection from '../../hooks/useGetCollection';
import useGetIsPrivileged from '../../hooks/useGetIsPrivileged';
import { dynamicDomain, types } from '../../lib/sign';
import { closeModal } from '../../redux/counterSlice';
import Icon, { ChainIcon } from '../Icon';
import { getCoinGeckoWrappedTokenId, getTokenName } from '../../helpers/getChainName';
import useGetTokenPrice from '../../hooks/useGetTokenPrice';
import { DataRefreshContext } from '../refreshContext';
import { useSwitchNetworkWrapper } from '../Web3WalletProvider';
import { ModalContainer } from './modalContainer';
import { getAddress, parseEther } from 'viem';
import { sendClientError } from '../../helpers/sendClientError';
import BulkToolBulkSell from '../bulkTool/BulkToolBulkSell';
import { hashOrder } from '../../helpers/hashOrder';
import useGetListingBulk from '../../hooks/bulktool/useGetListingsBulk';
import { BigNumber, ethers } from 'ethers';
import { useCatchTxError } from '../../hooks/useCatchTxError';
import { useGetChain } from '../../hooks/useGetChain';
import { Tooltip } from '@mui/material';
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

const MODAL_KEY = 'bulkUpdateSellModal';
const BulkUpdateSellModal = () => {
  const { refreshHooks } = useContext(DataRefreshContext);
  const { address, connector } = useAccount();
  const { modal } = useSelector((state) => state.counter);
  const data = modal?.data;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [BNBAmount, setBNBAmount] = useState('');
  const [usdAmount, setUsdAmount] = useState(0);
  const [expiration, setExpiration] = useState(1);
  const [serviceFee, setServiceFee] = useState(2);
  const [privilegedFee, setPrivilegedFee] = useState(serviceFee);
  const [isPrivilegedFeeLoading, setIsPrivilegedFeeLoading] = useState(true);

  const wrappedToken = getCoinGeckoWrappedTokenId(data?.chain);
  const { switchNetwork } = useSwitchNetworkWrapper();
  const { supportedChains } = constants.helpers;
  const { nfts, collectionAddress, isUpdateBulkOrder, chain } = data;
  const { chain: currentChain, isChainActive } = useGetChain(chain);

  const collectionsWithItems = nfts.reduce((acc, item) => {
    // Check if a collection with the same address already exists in the accumulator
    const existingCollection = acc.find((col) => col.collectionAddress === item.collectionAddress);

    // If it exists, push the itemId to the existing collection's itemIds
    if (existingCollection) {
      existingCollection.itemIds.push(item.itemId);
    } else {
      // If it doesn't exist, create a new collection object
      acc.push({
        collectionAddress: item.collectionAddress,
        itemIds: [item.itemId],
      });
    }

    return acc;
  }, []);

  const listings = useGetListingBulk({
    walletAddress: address,
    collectionAddress: collectionsWithItems,
    findMany: true,
  }).data;

  const bulkListings = listings?.filter(
    (listing) => listing.bulkSignature !== undefined && listing.bulkSignature !== null,
  );
  const singleListings = listings?.filter(
    (listing) => listing.bulkSignature === undefined || listing.bulkSignature === null,
  );

  const { chain: currentChainId } = useNetwork();
  const [privilegedItem, setPrivilegedItem] = useState(null);
  // const { data: highestGlobalBidData /* isLoading  */ } = useGetHighestGlobalBid({
  //    collectionAddress: collectionAddress,
  // });
  const isLoading = false;

  // const highestGlobalBid = highestGlobalBidData?.length ? highestGlobalBidData[0] : null;

  const totalListingPrivilegedNFTs = nfts.reduce(
    (count, nft) => (nft?.collection?.isPrivileged ? count + 1 : count),
    0,
  );

  const buttonTexts = ['Update Listings'];

  const [buttonText, setButtonText] = useState('Update Listings');

  const collectionInfo = useGetCollection(collectionAddress).data;

  const [wbnbBalance, setWbnbBalance] = useState(0);

  const { data: walletClient } = useWalletClient();

  let isPrivileged = useGetIsPrivileged(address, currentChainId.hexId).data;

  let onLastPrivilegedItem = false;
  if (
    isPrivileged?.privilegedItemCount -
      totalListingPrivilegedNFTs -
      isPrivileged?.privilegedListedItemCount <
    0
  ) {
    onLastPrivilegedItem = true;
  }
  const wbnbContract = getContract({
    address: currentChain?.wrappedToken,
    abi: wbnbABI,
    walletClient,
    chainId: Number(data?.chain),
  });

  const { fetchWithCatchTxError } = useCatchTxError();

  const { usdPrice: tokenPrice } = useGetTokenPrice(modal?.data?.chain);
  useEffect(() => {
    const updateWbnbBalance = async () => {
      const b = await getTokenBalance(wbnbContract, address);
      setWbnbBalance(normalizeEther(b));
    };

    if (address) updateWbnbBalance();
  }, [address, wbnbBalance, wbnbContract]);

  const marketplaceContract = getContract({
    address: currentChain?.marketplace,
    abi,
    walletClient,
    chainId: Number(chain),
  });

  const { signTypedDataAsync } = useSignTypedData();

  const handleUpdate = async () => {
    if (currentChain.hexId !== currentChainId?.hexId)
      return toast.error('Please switch to the correct chain');

    setLoading(true);

    try {
      setButtonText('Listing the NFT...');

      const bulkListingsToCancel = bulkListings?.filter((listing) => {
        const itemFound = nfts.find((nft) => {
          return nft.itemId == listing.itemId;
        });

        return (
          listing?.status === 'not processed' &&
          listing?.kind === 1 &&
          BigNumber.from('' + listing?.price).lt(ethers.utils.parseEther('' + itemFound?.price))
        );
      });

      const singleListingsToCancel = singleListings?.filter((listing) => {
        const itemFound = nfts.find((nft) => {
          return nft.itemId == listing.itemId;
        });
        return (
          listing?.status === 'not processed' &&
          listing?.kind === 1 &&
          BigNumber.from(listing?.price).lt(ethers.utils.parseEther(itemFound?.price))
        );
      });

      if (bulkListingsToCancel.length > 0 || singleListingsToCancel.length > 0) {
        setButtonText('Canceling the old sell...');

        const contractFn = await cancelAllOrders(
          singleListingsToCancel,
          bulkListingsToCancel,
          currentChain,
        );

        const receipt = await fetchWithCatchTxError({
          callTx: contractFn,
          chainId: chain,
          toastMessage: 'Listing cancelled!',
          keepModalOpen: true,
        });

        if (!receipt) return;
      }

      let orders = nfts.map((order) => {
        console.log('order', order);
        return {
          issuer: getAddress(address),
          nftAddress: order?.collectionAddress,
          tokenId: order?.itemId,
          paymentToken: currentChain?.wrappedToken,
          price: parseEther('' + order.price).toString(),
          end: Math.floor(Date.now() / 1000) + expiration * 24 * 60 * 60,
          kind: 1,
          tokenKind: order.collection.contractType === 'ERC1155' ? 1 : 0,
          globalBidAmount: 0,
          privileges: privilegedItem
            ? {
                privilegedCollection: privilegedItem?.collectionAddress,
                privilegedTokenId: String(privilegedItem?.itemId),
              }
            : {
                privilegedCollection: AddressZero,
                privilegedTokenId: String(0),
              },
        };
      });

      const leaves = orders.map((order) => {
        return hashOrder(order);
      });

      const merkleTree = new MerkleTree(leaves, keccak256, { sort: true });
      const merkleRoot = merkleTree.getHexRoot();

      const signature = await signTypedDataAsync({
        domain: dynamicDomain(currentChainId.hexId),
        message: { root: merkleRoot },
        types: types,
        primaryType: 'Root',
      });

      const ordersWithProofs = orders.map((order) => {
        const proof = merkleTree.getHexProof(hashOrder(order));
        proof.unshift(merkleRoot);
        return {
          ...order,
          proof: proof,
          bulkSignature: signature,
          orderHash: hashOrder(order),
        };
      });

      // returns boolean
      const res = (
        await axios.post(`${constants.api.url_new}/orders/listBulkItems`, {
          orders: ordersWithProofs,
          chain: modal?.data?.chain,
        })
      ).data;

      if (!res) {
        throw 'Something went wrong on the server side. Try again.';
      }
      toast.success(`${orders.length} items listed successfully`);
      refreshHooks();
      dispatch(closeModal());
    } catch (err) {
      toast.error(err.reason ? err.reason : 'err:' + err);
      console.error(err);
    } finally {
      setLoading(false);
      // setButtonText(isUpdateBulkOrder ? 'Update Listing' : 'Sell');
      // data.setCompleteSell(true);
    }
  };

  useEffect(() => {
    let totalBalance = 0;
    nfts?.map((nft) => {
      const newAmount = String(nft?.price)?.replace(',', '.');
      return (totalBalance += Number(newAmount));
    });
    setBNBAmount(totalBalance);
    const usd = Number(tokenPrice * totalBalance).toFixed(2);
    setUsdAmount(usd);
  }, []);

  useEffect(() => {
    const updateServiceFee = async () => {
      try {
        const _fee = await marketplaceContract.read.FEE();

        if (isPrivileged) {
          setPrivilegedFee(parseFloat((_fee * BigInt(isPrivileged?.privilege)) / 10000n) / 100);
        }

        setServiceFee(parseFloat(_fee) / 100);
        setIsPrivilegedFeeLoading(false);
      } catch (e) {
        const error = JSON.stringify(e, Object.getOwnPropertyNames(e));
        sendClientError({
          functionName: 'updateServiceFee',
          contractName: 'Marketplace',
          contractAddress: chain?.marketplace,
          walletAddress: address,
          walletId: connector?.id,
          walletName: connector?.name,
          isWalletReady: connector?.ready,
          error,
        });
      }
    };

    updateServiceFee();
  }, [isPrivileged, marketplaceContract, serviceFee]);

  const youWillGetApprx = nfts.reduce(
    (acc, nft) =>
      acc +
      (nft?.price -
        (nft?.price * (isPrivileged ? privilegedFee : serviceFee)) / 100 -
        (nft?.price * nft?.collection?.totalCreatorRoyalty) / 100),
    0,
  );

  const isButtonDisabled = data?.isUpdateBulkOrder
    ? !BNBAmount || BNBAmount == 0 || !buttonTexts.includes(buttonText) || isLoading
    : false;
  return (
    <ModalContainer modalKey={MODAL_KEY} title='Update Items'>
      <div className='modal-body p-6'>
        <div className='max-h-[30vh] overflow-y-auto'>
          {nfts?.map((item, index) => (
            <div className='mt-4 flex flex-col' key={index}>
              <BulkToolBulkSell item={item} tokenPrice={tokenPrice} />
            </div>
          ))}
        </div>
        <div className='mb-2 flex flex-wrap items-center text-sm text-jacarta-base dark:text-jacarta-200'>
          You get approx&nbsp;
          <ChainIcon
            width={12}
            name={data?.chain}
            tooltip={getTokenName(chain.id)}
            sideGap={true}
          />
          <Tooltip title={youWillGetApprx}>
            <span>{normalizeEther(youWillGetApprx)}</span>
          </Tooltip>
          <span
            className={`${isPrivileged && !isPrivilegedFeeLoading && 'text-red line-through'} ml-2`}
          >
            service fee {serviceFee}%
          </span>
        </div>

        {isPrivileged && (
          <span className={`text-green`}>
            🎉 You got {isPrivileged?.collectionName}, here is your service fee: {privilegedFee}%.
          </span>
        )}

        {onLastPrivilegedItem && (
          <span className={`text-red`}>
            You are listing your last item privileged item, you might lose your privileges.
          </span>
        )}

        <div className='mt-5 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            Expiration
          </span>
        </div>
        <div className='mt-3 mb-6'>
          <select
            className='rounded-xl h-12 w-full border border-jacarta-100 bg-jacarta-50 px-4 text-sm text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white'
            value={expiration}
            onChange={(e) => setExpiration(e.target.value)}
            data-testid='bulkToolExpiration'
          >
            <option value={1}>1 day</option>
            <option value={3}>3 day</option>
            <option value={7}>7 days</option>
            <option value={30}>30 days</option>
            <option value={180}>6 months</option>
          </select>
        </div>

        <div className='flex items-center justify-end'>
          Balance:&nbsp; <Icon name={`icon-${wrappedToken}`} tooltip={wrappedToken.toUpperCase()} />
          <span className='text-sm dark:text-jacarta-400'>{wbnbBalance}</span>
        </div>

        <div className='alert-info mt-2 w-full rounded-lg p-2'>
          Note: Bit5 does not escrow your NFT. Which means, you can list your token without paying
          for gas fees.
        </div>
      </div>
      {/* <!-- end body --> */}
      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {modal?.data?.chain !== currentChainId?.hexId ? (
            <button
              type='button'
              className='rounded-xl bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark'
              onClick={() => switchNetwork(modal?.data?.chain)}
            >
              Switch to {supportedChains.find((c) => c.hexId === modal?.data?.chain)?.name}
            </button>
          ) : (
            <>
              <CustomButton isDisabled={isButtonDisabled || loading} onClick={handleUpdate}>
                {buttonText}
              </CustomButton>
            </>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

const CustomButton = ({ children, isDisabled, ...props }) => {
  return (
    <button
      type='button'
      className={`${
        isDisabled ? 'opacity-40 ' : ''
      }rounded-xl bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark`}
      disabled={isDisabled}
      {...props}
      data-testid='button'
    >
      {children}
    </button>
  );
};

export default BulkUpdateSellModal;
