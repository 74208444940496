import { useState, useEffect } from 'react';
import { useNetwork, useWalletClient } from 'wagmi';
import { getSupportedChain } from '../helpers/getSupportedChain';
import { filterBidsByFloorPrice, getBidsWithBalance } from '../helpers/checkBalance';

/**
 * @typedef {Object} GetBidsWithBalanceParams
 * @property {Bid[]} offers
 * @property {string} chainId
 * @property {string} floorPrice
 * @property {Object} contract
 * @property {boolean} sortByPrice
 *
 * @param {GetBidsWithBalanceParams} params
 * @returns {BidWithBalance[]}
 */
export const useGetBidsWithBalance = ({
  offers,
  chainId,
  floorPrice,
  contract,
  sortByPrice = false,
}) => {
  const { data: walletClient } = useWalletClient();
  const { chain: defaultChain } = useNetwork();
  const [bids, setBids] = useState(
    offers ? offers?.map((offer) => ({ ...offer, isReady: false })) : [],
  );
  const currentChain = getSupportedChain(chainId) || defaultChain;

  useEffect(() => {
    if (offers) {
      try {
        let bidsWithBalance = offers;
        if (bidsWithBalance.length)
          bidsWithBalance = filterBidsByFloorPrice({ bids: bidsWithBalance, floorPrice });

        if (!contract || !bidsWithBalance.length) return setBids(bidsWithBalance);

        getBidsWithBalance({
          contract,
          bids: bidsWithBalance,
          chain: currentChain,
          sortByPrice,
        }).then((bidsWithBalance) => {
          setBids(bidsWithBalance);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [offers, currentChain, floorPrice, walletClient]);

  return bids;
};

/**
 * @typedef {Object} Bid
 * @property {string} id
 * @property {string} chain
 * @property {string} collectionAddress
 * @property {string} createdAt
 * @property {number} end
 * @property {number} filledGlobalBids
 * @property {number} globalBidAmount
 * @property {boolean} isActive
 * @property {boolean} isExpired
 * @property {string} issuer
 * @property {string} itemId
 * @property {boolean} isTransferred
 * @property {string} paymentToken
 * @property {string} price
 * @property {string} signature
 * @property {string} status
 * @property {string} updatedAt
 * @property {string} version
 * @property {string} privilegedCollection
 * @property {string} privilegedTokenId
 * @property {number} kind
 * @property {number} tokenKind
 * @property {string} identifier
 * @property {string} eventType
 * @property {string} traitFilter
 * @property {Object} collection
 * @property {string} collection.headerPhotoPath
 * @property {string} collection.coverPhotoPath
 * @property {string} collection.profilePhotoPath
 * @property {string} collection.pathName
 * @property {Object} fromUser
 * @property {string} fromUser.walletAddress
 * @property {string} fromUser.name
 * @property {string} fromUser.profilePhoto
 *
 * @typedef {Bid & { balance: number, hasEnoughBalance: boolean, isReady: boolean, isApprovedEnough: boolean, priceBigInt: number }} BidWithBalance
 *
 * @typedef {BidWithBalance[]} BidWithBalance[]
 */
