import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useContext, useEffect, useState } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export default function useGetOrder(id, kind, chain) {
  const [enabled, setEnabled] = useState(false);
  const { refreshDate } = useContext(DataRefreshContext);

  useEffect(() => {
    if (!id || typeof kind === 'undefined' || !chain) return setEnabled(false);
    setEnabled(true);
  }, [id, kind, chain]);

  const config = {
    url: `${constants.api.url_new}/orders/getOrder`,
    method: constants.httpMethods.get,
    params: {
      id,
      kind,
      chain,
      refreshDate,
    },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return useAdaptor(enabled ? axios.getUri(config) : null, fetcher);
}
