import Link from 'next/link';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';

const USER_CONSENT_COOKIE_KEY = 'cookie_consent_is_true';
const USER_CONSENT_COOKIE_EXPIRE_DATE = 365;

const CookieConsent = () => {
  const [cookieConsentIsTrue, setCookieConsentIsTrue] = useState(true);

  useEffect(() => {
    const consentIsTrue = Cookies.get(USER_CONSENT_COOKIE_KEY) === 'true';
    setCookieConsentIsTrue(consentIsTrue);
  }, []);

  const onClick = (e) => {
    e.preventDefault();

    if (!cookieConsentIsTrue) {
      Cookies.set(USER_CONSENT_COOKIE_KEY, 'true', {
        expires: USER_CONSENT_COOKIE_EXPIRE_DATE,
      });
      setCookieConsentIsTrue(true);
    }
  };

  if (cookieConsentIsTrue) {
    return null;
  }

  return (
    <section className='fixed bottom-4 left-4 z-[99999999999999] w-[calc(100%-32px)] rounded-2lg border border-jacarta-300 bg-jacarta-700 py-2 text-jacarta-100 opacity-95 shadow-lg sm:w-96 sm:py-4'>
      <div className='bg-gray-200 flex flex-col items-start space-y-4 px-6 py-3 sm:items-stretch sm:space-y-10'>
        <div className='text-gray-900 flex flex-grow items-center'>
          <p className='text-sm font-medium'>
            This site uses services that use cookies to deliver better experience and analyze
            traffic. You can learn more about the services we use at our{' '}
            <a
              className='text-sm text-accent-light underline transition duration-200 ease-in-out hover:text-accent-dark'
              href='https://docs.bit5.com/legal/privacy-policy'
              target='_blank'
              rel='noreferrer'
            >
              privacy policy
            </a>
            .
          </p>
        </div>
        <div className='flex w-full items-center justify-end'>
          <button
            className='focus:ring-green-500 w-full whitespace-nowrap rounded-md bg-accent py-2 px-4 text-sm font-bold uppercase text-white shadow-md transition duration-300 ease-in-out hover:bg-accent-dark focus:outline-none focus:ring-2 focus:ring-opacity-75 sm:w-1/2'
            onClick={onClick}
            data-testid='consent'
          >
            I understand
          </button>
        </div>
      </div>
    </section>
  );
};

export default CookieConsent;
