import React, { useState } from 'react';
import { constants } from '../../../helpers/constants';
import { ClickAwayListener } from '@mui/material';

export const AssetToBorrowDropdown = ({ assetToBorrow, setAssetToBorrow }) => {
  const [dropdown, setDropdown] = useState(false);

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  return (
    <>
      <div
        className='dropdown-toggle show relative z-50 flex h-12 items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300'
        onClick={() => toggleDropdown()}
      >
        {assetToBorrow ? (
          <span className=''>{assetToBorrow.name}</span>
        ) : (
          <span className=''>Select Currency</span>
        )}
      </div>
      <ClickAwayListener
        onClickAway={() => {
          setDropdown(false);
        }}
        mouseEvent='onMouseDown'
        touchEvent='onTouchStart'
      >
        <div
          className={
            dropdown
              ? 'rounded-xl show absolute right-0 z-50 w-full min-w-fit whitespace-nowrap bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800'
              : 'rounded-xl absolute right-0 z-50 hidden w-full min-w-fit whitespace-nowrap bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800'
          }
        >
          <ul className='scrollbar-custom max-h-48 flex flex-col overflow-y-auto'>
            {constants.helpers.lending.ASSETS_TO_BORROW.map((asset, i) => {
              return (
                <li key={i}>
                  <button
                    className='dropdown-item rounded-xl flex w-full items-center justify-between space-x-3 px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600'
                    onClick={() => {
                      setAssetToBorrow(asset);
                      toggleDropdown();
                    }}
                  >
                    <span className='flex items-center space-x-3'>
                      <span className='text-jacarta-700 dark:text-white'>{asset.name}</span>
                    </span>
                    {assetToBorrow && assetToBorrow.name === asset.name && (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width='24'
                        height='24'
                        className='mb-[3px] h-4 w-4 fill-accent'
                      >
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z' />
                      </svg>
                    )}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </ClickAwayListener>
    </>
  );
};
