import { Tooltip } from '@mui/material';
import { SourceBadges } from '../SourceBadges';
import { CheckBadge } from './CheckBadge';

const ItemSourceBadge = ({ item, bidCount, showCheckBadge }) => {
  if (!item) return null;
  const orderSource = item?.orders && item?.orders[0]?.orderSource;

  if ((!orderSource || !SourceBadges[orderSource]) && !bidCount && !showCheckBadge) return null;

  return (
    <div className='absolute right-3 top-3 flex flex-col gap-1'>
      {showCheckBadge ? <CheckBadge item={item} /> : null}
      {orderSource && SourceBadges[orderSource] ? (
        <Tooltip
          title={
            <div>
              <div className='flex w-full'>
                This Listing has been made on &nbsp;{' '}
                <span className='font-bold'>{orderSource}</span>
              </div>
            </div>
          }
        >
          <div className='h-[32px] w-[32px]'>
            <span className='relative z-10 font-display text-sm'>{SourceBadges[orderSource]}</span>
          </div>
        </Tooltip>
      ) : null}
      {bidCount && bidCount > 0 ? (
        <Tooltip title={`There are ${`${bidCount} bid${bidCount > 1 ? 's' : ''} on this item`}`}>
          <svg className={`icon relative z-10 h-[30px] w-[30px] md:h-[32px] md:w-[32px]`}>
            <use xlinkHref={`/icons.svg#item-card-bids`} />
          </svg>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default ItemSourceBadge;
