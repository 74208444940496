import React from 'react';

export const VerticalFilterContainer = ({ children }) => {
  return (
    <div className='mb-3 flex flex-wrap items-start justify-between overflow-auto md:sticky md:top-[88px] md:mr-3 md:max-h-navbar'>
      {/*<JsonStringify object={filterObject}/>*/}
      <ul className='relative flex w-full flex-wrap items-center'>{children}</ul>
    </div>
  );
};
