import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useWalletClient } from 'wagmi';
import { getContract } from 'wagmi/actions';
import uwManagerABI from '../../helpers/uwManagerABI';
import useGetOwnerOfNFT from '../../hooks/useGetOwnerOfNFT';
import { closeModal } from '../../redux/counterSlice';
import { ModalContainer } from './modalContainer';
import { errorToast } from '../toast';
import { useCatchTxError } from '../../hooks/useCatchTxError';
import { useGetChain } from '../../hooks/useGetChain';
import { Button, SwitchNetworkButton } from '../Button';
import BulkToolBatchTransferNfts from '../bulkTool/BulkToolBatchTransferNfts';
import { onlyNumber } from '../../lib/keyevent';
import { formatEther, parseEther } from 'viem';

const MODAL_KEY = 'uwWrapModal';
const UwWrapModal = () => {
  const dispatch = useDispatch();
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient();
  const { connector: activeConnector } = useAccount();
  const { fetchWithCatchTxError } = useCatchTxError();
  const { modal } = useSelector((state) => state.counter);
  const { chain: chainId, collectionInfo, collectionAddress, itemId } = modal?.data ?? {};
  const [isFocused, setIsFocused] = useState(false);
  const [tokenAmount, setTokenAmount] = useState(1);
  const [buttonText, setButtonText] = useState('Wrap NFTs');
  const [loading, setLoading] = useState(false);
  const { chain, isChainActive } = useGetChain(chainId);
  const [wrapFee, setWrapFee] = useState(0);

  const itemOwner = useGetOwnerOfNFT(collectionAddress, itemId, address).data;

  const modalDataArray = Object.keys(modal?.data)
    .map((key) => modal?.data[key])
    .slice(0, -2);

  const uwManagerContract = getContract({
    address: chain?.uwManager,
    abi: uwManagerABI,
    walletClient,
  });

  const handleBatchWrap = async () => {
    if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
    if (tokenAmount == '0' || !tokenAmount) return errorToast('Invalid token amount');
    if (modalDataArray.length > 15) return errorToast('You can only unwrap 15 NFTs at a time');
    // if modal data array has different collection addresses, we need to wrap them separately
    // Throw error toast if this is the case lol
    //if (modalDataArray.length > 1) {
    //  const collectionAddresses = modalDataArray.map((item) => item.collectionAddress);
    //  if (new Set(collectionAddresses).size !== 1) {
    //    return errorToast('Please select NFTs from the same collection');
    //  }
    //}

    // Get the collection address from the first item in the array

    // When we get collection addresses we need to seperate them using.
    // a map. End result should be two arrays of collection addresses and tokenIds
    // Example output: {collectionAddress: ['0x123', '0x123'], tokenIds: [[1,2], [1,2]]}

    const collectionAddresses = modalDataArray.map((item) => item.collectionAddress);

    // Deduplicate collection addresses
    const uniqueCollectionAddresses = [...new Set(collectionAddresses)];

    // Create a map with collection addresses as keys and tokenIds as values
    const newMap = new Map();

    uniqueCollectionAddresses.forEach((collectionAddress) => {
      newMap.set(collectionAddress, []);
    });

    // Fill the map with tokenIds
    modalDataArray.forEach((item) => {
      newMap.get(item.collectionAddress).push(item.itemId);
    });

    // Convert map to two arrays
    const mfDoom = [];

    uniqueCollectionAddresses.forEach((collectionAddress) => {
      mfDoom.push({
        collectionAddress,
        tokenIds: newMap.get(collectionAddress),
      });
    });

    const tokenIdArrays = mfDoom.map((item) => item.tokenIds.map((tokenId) => BigInt(tokenId)));

    const collectionAddressesArray = mfDoom.map((item) => item.collectionAddress);

    const finalizedArray = [[...collectionAddressesArray], [...tokenIdArrays]];

    const toFlatMap = finalizedArray[1];

    const itemCount = toFlatMap.reduce((acc, val) => acc + val.length, 0);

    try {
      setLoading(true);

      await fetchWithCatchTxError({
        callTx: () =>
          uwManagerContract.write.batchWrapMultiCollectionNFT(finalizedArray, {
            value: BigInt(itemCount) * BigInt(wrapFee),
          }),
        chainId,
        toastMessage: 'Wrapping Successful!',
      });
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setLoading(false);
    }
  };

  // const isDisabled =
  //   buttonText !== 'Transfer Token' || !isAddress(toAddress) || address === toAddress;
  const isDisabled = loading;

  useEffect(() => {
    const handleConnectorUpdate = ({ account, chain }) => {
      if (account) {
        dispatch(closeModal());
      }
    };

    if (activeConnector) {
      activeConnector.on('change', handleConnectorUpdate);
    }

    return () => activeConnector?.off('change', handleConnectorUpdate);
  }, [activeConnector]);

  useEffect(() => {
    const getWrapFee = async () => {
      try {
        const fee = await uwManagerContract.read.getWrapFee();
        setWrapFee(fee);
      } catch (err) {
        console.log('Error getting wrap fee: ', err);
      }
    };

    if (uwManagerContract) {
      getWrapFee();
    }
  }, [chain, address]);

  return (
    <ModalContainer modalKey={MODAL_KEY} title='Wrap Tokens'>
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        <div className='mb-2 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            {modal?.data?.name ?? modal?.data?.metadata?.name ?? ''}
          </span>
        </div>

        {modal?.data?.useBatchWrap
          ? modalDataArray?.map((item, index) => (
              <div className='mt-4 flex flex-col' key={index}>
                <BulkToolBatchTransferNfts item={item} />
              </div>
            ))
          : ''}
        <div className='my-2 flex items-center justify-between'>
          {/**
          <input
            type='text'
            className='h-12 w-full rounded-lg focus:outline-0 focus:ring-0 dark:bg-jacarta-700 dark:text-white'
            placeholder='To'
            value={toAddress}
            onFocus={() => setIsFocused(true)}
            data-testid='transferInput'
            onChange={(e) => {
              const newAddress = e.target.value;
              if (newAddress.length === 42 && isAddress(newAddress)) {
                setToAddress(getAddress(newAddress));
              } else {
                setToAddress(e.target.value);
              }
            }}
            onWheel={(e) => e.target.blur()}
          />
           */}
        </div>
        {collectionInfo?.contractType === 'DELETE_ME' && !modal?.data?.useBatchWrap && (
          <div className='mb-2 flex items-center justify-between'>
            <input
              type='text'
              onKeyDown={onlyNumber}
              className='border-1 focus:ring-inse h-12 w-full flex-[3] focus:ring-accent dark:text-jacarta-700'
              placeholder='Amount'
              value={tokenAmount}
              onFocus={() => setIsFocused(true)}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                if (itemOwner?.amount >= e.target.value) setTokenAmount(e.target.value);
              }}
              data-testid='transferAmount'
            />
          </div>
        )}

        {/* <!-- Terms --> */}
        {modal?.data?.useBatchWrap && modalDataArray?.length && wrapFee ? (
          <div className='alert-info rounded-lg px-2 py-1.5 text-center text-sm'>
            Wrapping fee per NFT is {formatEther(wrapFee)} {chain.nativeCurrency.symbol}. This wrap
            process will cost {formatEther(BigInt(wrapFee) * BigInt(modalDataArray.length))}{' '}
            {chain.nativeCurrency.symbol}
          </div>
        ) : null}
      </div>
      {/* <!-- end body --> */}
      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {!isChainActive ? (
            <SwitchNetworkButton chainId={chain.id} />
          ) : (
            <Button
              key={'transfer_button'}
              onClick={handleBatchWrap}
              text={buttonText}
              disabled={isDisabled}
              data-testid='transferButton'
            />
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default UwWrapModal;
