import PropTypes from 'prop-types';
import { useSwitchNetworkWrapper } from './Web3WalletProvider';
import { useGetChain } from '../hooks/useGetChain';

export const Button = ({ children, onClick, disabled, text, ...props }) => (
  <button
    type='button'
    className={`${
      disabled && 'opacity-40'
    } rounded-xl bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark`}
    onClick={onClick}
    disabled={disabled}
    {...props}
  >
    {text}
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string,
};

export const SwitchNetworkButton = ({ chainId }) => {
  const { switchNetwork } = useSwitchNetworkWrapper();
  const { chain, isChainActive } = useGetChain(chainId);

  return (
    <button
      type='button'
      className='rounded-xl bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark'
      onClick={() => switchNetwork(chain.id)}
      disabled={isChainActive}
    >
      Switch to {chain?.name}
    </button>
  );
};

SwitchNetworkButton.propTypes = {
  chainId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
