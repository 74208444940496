import Link from 'next/link';
import { getSupportedChain } from '../helpers/getSupportedChain';
import { getBlockExploreLink } from '../helpers/utils';
import Icon from './Icon';
import PropTypes from 'prop-types';

export const ToastDescriptionWithTx = ({ external, txHash, txChainId, children }) => {
  const chain = getSupportedChain(txChainId);
  return (
    <>
      {typeof children === 'string' ? <p>{children}</p> : children}
      {txHash && (
        <div className='flex flex-row items-center justify-center'>
          Here is your&nbsp;
          <Link href={getBlockExploreLink(txHash, 'transaction', chain?.id)}>
            <a
              className='flex flex-row items-center justify-center font-bold'
              {...(external && { target: '_blank', rel: 'noopener noreferrer' })}
            >
              <span className='text-[#000] dark:text-white'>
                <Icon name={'scan-global'} />
              </span>
              <span>transaction.</span>
            </a>
          </Link>
        </div>
      )}
    </>
  );
};

ToastDescriptionWithTx.propTypes = {
  external: PropTypes.bool,
  txHash: PropTypes.string,
  txChainId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
};
