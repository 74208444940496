import React, { useReducer } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useNetwork, useSignTypedData } from 'wagmi';
import { ModalContainer } from './modalContainer';
import { closeModal } from '../../redux/counterSlice';
import { dynamicDomain, types } from '../../lib/sign';
import { errorToast } from '../toast';
import { createReport } from '../../services/report';
import { useChainModal } from '@rainbow-me/rainbowkit';

const MODAL_KEY = 'reportModal';
const ReportModal = () => {
  const { modal } = useSelector((state) => state.counter);
  const { chain } = useNetwork();
  const item = modal?.data?.item;
  const { address } = useAccount();
  const { openChainModal } = useChainModal();
  const [reportReducer, dispatchReport] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'reason':
          return { ...state, reason: action.payload };
        case 'description':
          return { ...state, description: action.payload };
        case 'wallet_app':
          return { ...state, wallet_app: action.payload };
        case 'device':
          return { ...state, device: action.payload };
        case 'reset':
          return { reason: '', description: '', wallet_app: '', device: '' };
        default:
          return state;
      }
    },
    {
      reason: '',
      description: '',
    },
  );
  const dispatch = useDispatch();

  const { signTypedDataAsync } = useSignTypedData({
    domain: dynamicDomain(chain?.hexId),
    types,
    primaryType: 'Report',
  });

  const report = async () => {
    if (!address || !reportReducer?.reason || !reportReducer?.description) {
      return errorToast('Please fill all the fields');
    }
    const input = JSON.stringify({
      reason: reportReducer?.reason,
      reasonDescription: reportReducer?.description,
      wallet_app: reportReducer?.wallet_app,
      device: reportReducer?.device,
      userAddress: address,
      itemAddress: item?.collectionAddress + '/' + item?.itemId,
    });

    const signature = await signTypedDataAsync({ message: { Report: input } });

    createReport({
      itemAddress: item?.collectionAddress + '/' + item?.itemId,
      input,
      signature,
      chainId: chain?.hexId,
    }).then((res) => {
      if (res) {
        toast.success('Report submitted successfully');
        dispatchReport({
          type: 'reset',
        });
        dispatch(closeModal());
      } else {
        errorToast('Something went wrong');
      }
    });
  };

  const switchChain = chain?.unsupported;

  if (modal?.key !== MODAL_KEY) return null;

  return (
    <ModalContainer modalKey={MODAL_KEY} title='Report this item'>
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        <div className='mb-2 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            Reason
          </span>
        </div>
        <div className='relative mb-2 flex items-center overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600'>
          <input
            onChange={(e) => {
              dispatchReport({
                type: 'reason',
                payload: e.target.value,
              });
            }}
            value={reportReducer.reason}
            placeholder='Reason for reporting'
            type='text'
            className='focus:ring-inse h-12 w-full flex-[3] border-0 focus:ring-accent dark:text-jacarta-700'
          />
        </div>
        <div className='mb-2 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            Wallet Application
          </span>
        </div>
        <div className='relative mb-2 flex items-center overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600'>
          <input
            onChange={(e) => {
              dispatchReport({
                type: 'wallet_app',
                payload: e.target.value,
              });
            }}
            placeholder='Wallet Application You Are Using'
            value={reportReducer.wallet_app}
            type='text'
            className='focus:ring-inse h-12 w-full flex-[3] border-0 focus:ring-accent dark:text-jacarta-700'
          />
        </div>
        <div className='mb-2 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            Device Name/OS
          </span>
        </div>
        <div className='relative mb-2 flex items-center overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600'>
          <input
            onChange={(e) => {
              dispatchReport({
                type: 'device',
                payload: e.target.value,
              });
            }}
            placeholder='Device Operating System or Model'
            value={reportReducer.device}
            type='text'
            className='focus:ring-inse h-12 w-full flex-[3] border-0 focus:ring-accent dark:text-jacarta-700'
          />
        </div>
        <div className='mb-2 mt-5 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            Description
          </span>
        </div>
        <div className='relative mb-2 flex items-center overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600'>
          <textarea
            onChange={(e) => {
              dispatchReport({
                type: 'description',
                payload: e.target.value,
              });
            }}
            placeholder='Details'
            value={reportReducer.description}
            className='focus:ring-inse h-12 min-h-[100px] w-full flex-[3] border-0 focus:ring-accent dark:text-jacarta-700'
            minLength={10}
            maxLength={500}
          />
        </div>
      </div>
      {/* <!-- end body --> */}

      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          <button
            // onClick={report}
            onClick={() => {
              if (switchChain) {
                console.log('switching network: ', switchChain, chain?.id);
                // switchNetwork(chain?.id);
                openChainModal();
              } else {
                report();
              }
            }}
            className='rounded-xl bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark'
          >
            {/* Report */}
            {switchChain ? `Switch Network` : 'Report'}
          </button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ReportModal;
