import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { ethers } from 'ethers';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export default function useGetUSDPriceOfToken(tokenName, amount) {
  const { refreshDate } = useContext(DataRefreshContext);
  let config;

  if (amount === 0 || amount === '0') {
    config = {};
  } else {
    config = {
      url: `https://api.coingecko.com/api/v3/simple/price`,
      method: constants.httpMethods.get,
      params: { ids: tokenName, vs_currencies: 'usd', refreshDate },
    };
  }

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data;
    });

  return useAdaptor(amount != 0 ? axios.getUri(config) : null, fetcher);
}
