export const CheckInput = ({ label, checked, onChange }) => {
  return (
    <label className='relative mx-1 inline-flex cursor-pointer items-center space-x-3'>
      <span className='text-gray-900 dark:text-gray-300 font-bold'>{label}</span>

      <div className='relative'>
        <input
          type='checkbox'
          checked={checked}
          className='peer sr-only'
          onChange={(e) => onChange(e.target.checked)}
        />
        <div className="bg-gray-200 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:bg-blue-600 peer h-6 w-11 rounded-full border border-jacarta-400 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-jacarta-400 after:bg-white after:transition-all after:content-[''] peer-checked:bg-accent-dark peer-checked:after:translate-x-full peer-focus:outline-none peer-focus:ring-4 dark:border-jacarta-600 after:dark:border-jacarta-600" />
      </div>
    </label>
  );
};

export const CheckInputWithClass = ({ label, checked, onChange, className }) => {
  return (
    <label
      className={`relative mx-1 inline-flex cursor-pointer items-center space-x-3 ${
        className ? className : ''
      }`}
    >
      <span className='text-gray-900 dark:text-gray-300'>{label}</span>

      <div className='relative'>
        <input
          type='checkbox'
          checked={checked}
          className='peer sr-only'
          onChange={(e) => onChange(e.target.checked)}
          data-testid='checkInput'
        />
        <div className="bg-gray-200 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:bg-blue-600 peer h-6 w-11 rounded-full border border-jacarta-400 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-jacarta-400 after:bg-white after:transition-all after:content-[''] peer-checked:bg-accent-dark peer-checked:after:translate-x-full peer-focus:outline-none peer-focus:ring-4 dark:border-jacarta-600 after:dark:border-jacarta-600" />
      </div>
    </label>
  );
};
