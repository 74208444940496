import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { ethers } from 'ethers';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export function getGlobalBidsFetcher(
  collectionAddress,
  refreshDate,
  traitBids = false,
  rarityBids = false,
) {
  let config;

  if (!ethers.utils.isAddress(collectionAddress)) {
    config = {};
  } else {
    config = {
      url: `${constants.api.url_new}/orders/getGlobalBids`,
      method: constants.httpMethods.get,
      params: { collectionAddress, refreshDate, traitBids, rarityBids },
    };
  }

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return { config, fetcher };
}

export default function useGetGlobalBids(collectionAddress, traitBids = false, rarityBids = false) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, fetcher } = getGlobalBidsFetcher(
    collectionAddress,
    refreshDate,
    traitBids,
    rarityBids,
  );
  return useAdaptor(axios.getUri(config), fetcher);
}

export function getGlobalBidsBySlugFetcher(
  slug,
  refreshDate,
  traitBids = false,
  rarityBids = false,
) {
  const config = {
    url: `${constants.api.url_new}/orders/getGlobalBidsBySlug`,
    method: constants.httpMethods.get,
    params: { slug, refreshDate, traitBids: traitBids, rarityBids },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return { config: slug ? config : {}, fetcher };
}

export function useGetGlobalBidsBySlug(slug, traitBids = false, rarityBids = false) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, fetcher } = getGlobalBidsBySlugFetcher(slug, refreshDate, traitBids, rarityBids);
  return useAdaptor(axios.getUri(config), fetcher);
}
