import { Tooltip } from '@mui/material';
import axios from 'axios';
import { BigNumber, ethers } from 'ethers';
import { getAddress, parseEther } from 'ethers/lib/utils';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useWalletClient, useSignTypedData } from 'wagmi';
import { getContract } from '@wagmi/core';
import abi from '../../helpers/abi';
import { approveNFT, cancelAllOrders } from '../../helpers/blockchainOperations';
import { constants } from '../../helpers/constants';
import { normalizeEther } from '../../helpers/digits';
import erc721ABI from '../../helpers/erc721ABI';
import useGetCollection from '../../hooks/useGetCollection';
import useGetIsPrivileged from '../../hooks/useGetIsPrivileged';
import { dynamicDomain, types } from '../../lib/sign';
import { closeModal } from '../../redux/counterSlice';
import { ChainIcon } from '../Icon';
import { getTokenName } from '../../helpers/getChainName';
import useGetTokenPrice from '../../hooks/useGetTokenPrice';
import { DataRefreshContext } from '../refreshContext';
import { ModalContainer } from './modalContainer';
import { ModalItemDetails } from './modalComponents/modalItemDetails';
import useGetListing from '../../hooks/useGetListing';
import moment from 'moment';
import { CheckInputWithClass } from '../checkInput';
import { errorToast, successToast } from '../toast';
import { sendClientError } from '../../helpers/sendClientError';
import { PrivilegeInfo } from '../privilegeInfo';
import { useCatchTxError } from '../../hooks/useCatchTxError';
import { useGetChain } from '../../hooks/useGetChain';
import { Button, SwitchNetworkButton } from '../Button';

const MODAL_KEY = 'updateSellModal';
const UpdateSellModal = () => {
  const { data: walletClient } = useWalletClient();
  const { signTypedDataAsync } = useSignTypedData();
  const { fetchWithCatchTxError } = useCatchTxError();
  const { refreshHooks } = useContext(DataRefreshContext);
  const { address, connector } = useAccount();
  const { modal } = useSelector((state) => state.counter);
  const data = modal?.data;
  const dispatch = useDispatch();
  const [BNBAmount, setBNBAmount] = useState('');
  const [usdAmount, setUsdAmount] = useState(0);
  const [expiration, setExpiration] = useState(1);
  const [serviceFee, setServiceFee] = useState(2);
  const [privilegedFee, setPrivilegedFee] = useState(serviceFee);
  const [updateExpireDate, setUpdateExpireDate] = useState(false);
  const [isPrivilegedFeeLoading, setIsPrivilegedFeeLoading] = useState(true);
  const { chain, isChainActive } = useGetChain(data?.chain);

  const listings = useGetListing({
    collectionAddress: data?.collectionAddress,
    itemId: data?.itemId,
    walletAddress: address,
    findMany: true,
  }).data;
  const bulkListings = listings?.filter(
    (listing) => listing.bulkSignature !== undefined && listing.bulkSignature !== null,
  );
  const singleListings = listings?.filter(
    (listing) => listing.bulkSignature === undefined || listing.bulkSignature === null,
  );

  const listing = listings?.[0];
  const isListed = listings?.some((l) => l?.status === 'not processed' && l?.kind === 1);
  const [buttonText, setButtonText] = useState('Update');
  const isButtonDisabled =
    (BNBAmount && parseEther(BNBAmount).toString() === listing?.price) ||
    !isListed ||
    !BNBAmount ||
    BNBAmount == 0 ||
    buttonText !== 'Update';

  const collectionInfo = useGetCollection(data?.collectionAddress).data;

  const { data: isPrivileged, isLoading: isPrivilegeLoading } = useGetIsPrivileged(
    address,
    data?.chain,
  );

  const { usdPrice: tokenPrice } = useGetTokenPrice(data?.chain);

  const marketplaceContract = getContract({
    address: chain?.marketplace,
    abi,
    walletClient,
  });

  const nftContract = getContract({
    address: data?.collectionAddress,
    abi: erc721ABI,
    walletClient,
  });

  const handleUpdate = async () => {
    if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
    if (!BNBAmount || BNBAmount == 0) return errorToast('Please enter a price.');

    if (parseEther(BNBAmount).toString() === listing?.price)
      return errorToast('Please enter a different price.');

    if (!isListed) return errorToast('Please list the item first.');

    setButtonText('Approving the NFT...');
    await approveNFT(nftContract, address, chain?.marketplace);

    try {
      /*      const listingsToCancel = listings?.filter(
        (l) =>
          l?.status === 'not processed' &&
          l?.kind === 1 &&
          BigNumber.from(l?.price).lt(parseEther(BNBAmount)),
      ); */
      const bulkListingsToCancel = bulkListings?.filter(
        (listing) =>
          listing?.status === 'not processed' &&
          listing?.kind === 1 &&
          BigNumber.from(listing?.price).lt(parseEther(BNBAmount)),
      );
      const singleListingsToCancel = singleListings?.filter(
        (listing) =>
          listing?.status === 'not processed' &&
          listing?.kind === 1 &&
          BigNumber.from(listing?.price).lt(parseEther(BNBAmount)),
      );

      /*       if (listingsToCancel?.length) {
        try {
          for (const listingToCancel of listingsToCancel) {
            setButtonText(
              listingsToCancel.length > 1
                ? `Canceling the old sell ${listingsToCancel?.indexOf(listingToCancel) + 1}/${
                    listingsToCancel?.length
                  }`
                : 'Canceling the old sell...',
            );
            const contractFn = await cancelOrder({
              marketplaceContract,
              signature: listingToCancel?.signature,
              id: listingToCancel?.id,
              kind: 1,
              chain: data?.chain,
              paymentToken: chain?.wrappedToken,
            });
            const receipt = await fetchWithCatchTxError({
              callTx: contractFn,
              chainId: chain.id,
              toastMessage: 'Listing cancelled!',
              keepModalOpen: true,
            });
            if (receipt) listingToCancel.isCanceled = true;
          }
        } catch (err) {
          return errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
        }
      }
      if (listingsToCancel?.some((l) => !l?.isCanceled))
        return errorToast('Please wait for the old listing to be canceled.');
 */

      if (bulkListingsToCancel.length > 0 || singleListingsToCancel.length > 0) {
        setButtonText('Canceling the old sell...');
        /*         const tx = await cancelAllOrders(
          marketplaceContract,
          setButtonText,
          singleListingsToCancel,
          bulkListingsToCancel,
        ); */
        const contractFn = await cancelAllOrders(
          singleListingsToCancel,
          bulkListingsToCancel,
          chain,
        );
        const receipt = await fetchWithCatchTxError({
          callTx: contractFn,
          chainId: chain.id,
          toastMessage: 'Listing cancelled!',
          keepModalOpen: true,
        });
        if (!receipt) return;
      }
      setButtonText('Listing the NFT...');
      const order = {
        issuer: getAddress(address),
        nftAddress: data?.collectionAddress,
        tokenId: data?.itemId,
        paymentToken: chain?.wrappedToken,
        price: parseEther(BNBAmount).toString(),
        // end: Math.floor(Date.now() / 1000) + expiration * 24 * 60 * 60,
        end: updateExpireDate
          ? Math.floor(Date.now() / 1000) + expiration * 24 * 60 * 60
          : listing?.end,
        kind: 1,
        tokenKind:
          collectionInfo?.contractType === 'ERC1155'
            ? 1
            : collectionInfo?.contractType === 'ERC404'
            ? 2
            : 0,
        globalBidAmount: 0,
        privileges: isPrivileged
          ? {
              privilegedCollection: isPrivileged?.collectionAddress,
              privilegedTokenId: String(isPrivileged?.itemId),
            }
          : {
              privilegedCollection: ethers.constants.AddressZero,
              privilegedTokenId: String(0),
            },
      };
      const signature = await signTypedDataAsync({
        domain: dynamicDomain(chain.id),
        message: order,
        types: types,
        primaryType: 'Order',
      });
      order.signature = signature;

      // returns boolean
      let res;
      try {
        res = (
          await axios.post(`${constants.api.url_new}/orders/listItem`, {
            order,
            chain: data?.chain,
          })
        )?.data;
      } catch (err) {
        if (err?.response?.data?.message) throw err?.response?.data?.message;
        throw err;
      }

      if (!res) {
        throw new Error('Something went wrong on the server side. Try again.');
      }
      successToast(`${data?.metadata?.name} listed successfully for ${BNBAmount} BNBs`);
      refreshHooks();
      dispatch(closeModal());
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setButtonText('Update');
    }
  };

  const handleEThAmount = (e) => {
    e.preventDefault();
    const newAmount = e?.target?.value?.replace(',', '.');
    const zeroCounts = newAmount.split('.');

    if (zeroCounts?.length >= 1 && zeroCounts[1]?.length > 4) return;

    setBNBAmount(newAmount);

    const usd = Number(tokenPrice * e.target.value).toFixed(2);
    setUsdAmount(usd);
  };

  useEffect(() => {
    const updateServiceFee = async () => {
      try {
        const _fee = await marketplaceContract.read.FEE();

        if (isPrivileged) {
          setPrivilegedFee(parseFloat((_fee * BigInt(isPrivileged?.privilege)) / 10000n) / 100);
        }

        setServiceFee(parseFloat(_fee) / 100);
        setIsPrivilegedFeeLoading(false);
      } catch (e) {
        const error = JSON.stringify(e, Object.getOwnPropertyNames(e));
        sendClientError({
          functionName: 'updateServiceFee',
          contractName: 'Marketplace',
          contractAddress: chain?.marketplace,
          walletAddress: address,
          walletId: connector?.id,
          walletName: connector?.name,
          isWalletReady: connector?.ready,
          error,
        });
      }
    };

    updateServiceFee();
  }, [isPrivileged, marketplaceContract, serviceFee]);

  const youWillGetApprx =
    BNBAmount -
    (BNBAmount * (isPrivileged ? privilegedFee : serviceFee)) / 100 -
    (BNBAmount * collectionInfo?.totalCreatorRoyalty) / 100;

  return (
    <ModalContainer modalKey={MODAL_KEY} title='Update Listing'>
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        <ModalItemDetails item={modal?.data} collection={collectionInfo} />
        <div className='alert-info w-full rounded-lg p-3 text-sm'>
          <div className='flex flex-col'>
            <div>
              If you want to increase the price, you need to cancel the old listing first and it
              will cost you gas fee.
            </div>
          </div>
        </div>
        <div className='relative flex border-jacarta-100 pb-4 dark:border-jacarta-600'>
          <div className='mt-2 w-full'>
            <div className='flex w-full items-center'>
              <div className='font-semibold'>Current Listing Price:</div>
              <div className='ml-auto flex w-fit space-x-1'>
                <span className='ml-auto flex items-center whitespace-nowrap'>
                  <ChainIcon
                    width={14}
                    name={data?.chain}
                    tooltip={getTokenName(chain.id)}
                    sideGap={true}
                  />
                  <span className='text-sm font-medium tracking-tight text-green'>
                    {normalizeEther(listing?.price)}
                  </span>
                </span>
                <div className='whitespace-nowrap text-sm leading-7 dark:text-jacarta-300'>
                  ~ ${normalizeEther(tokenPrice * normalizeEther(listing?.price))}
                </div>
              </div>
            </div>
            <div className='flex w-full items-center justify-between space-x-1'>
              <div className='font-semibold'>Expires:</div>
              <div className='ml-auto flex w-fit space-x-1'>
                <span className='ml-auto flex items-center whitespace-nowrap text-sm font-medium tracking-tight'>
                  {moment.unix(listing?.end).format('DD MMM YYYY')}
                </span>
                <div className='text-sm leading-7 dark:text-jacarta-300'>
                  {moment.unix(listing?.end).format('HH:mm')} UTC
                  {/* <span className='text-jacarta-300'> </span>{' '} */}
                  {/* <span className='text-jacarta-300'>({moment.unix(listing?.end).fromNow()}) </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mb-2 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            Price
          </span>
        </div>

        <div className='relative mb-2 flex items-center overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600'>
          <div className='flex items-center justify-center self-stretch border-r border-jacarta-100 bg-jacarta-50 px-5 dark:border-jacarta-600 dark:bg-jacarta-700'>
            <ChainIcon
              name={data?.chain}
              tooltip={getTokenName(chain.id)}
              width={20}
              sideGap={false}
            />
          </div>

          <input
            type='number'
            className='h-12 w-full flex-[3] border-0 focus:ring-0 dark:text-jacarta-700'
            placeholder='Price'
            value={BNBAmount}
            onChange={(e) => handleEThAmount(e)}
            onWheel={(e) => e.target.blur()}
            data-testid='updatePriceModalInput'
          />

          <div className='flex flex-1 justify-end self-stretch border-l border-jacarta-100 bg-jacarta-50 dark:text-jacarta-700'>
            <span className='self-center px-2 text-sm'>~${usdAmount}</span>
          </div>
        </div>

        <div className='mb-2 flex flex-wrap items-center text-sm text-jacarta-base dark:text-jacarta-200'>
          You get approx&nbsp;
          <ChainIcon
            width={13}
            name={data?.chain}
            tooltip={getTokenName(chain.id)}
            sideGap={true}
          />
          <Tooltip title={youWillGetApprx}>
            <span>{normalizeEther(youWillGetApprx)}</span>
          </Tooltip>
          , creator royalty&nbsp;
          {collectionInfo?.totalCreatorRoyalty}%,&nbsp;
          <span className={`${isPrivileged && !isPrivilegeLoading && 'text-red line-through'}`}>
            service fee {serviceFee}%
          </span>
        </div>
        {!isPrivilegeLoading && !isPrivilegedFeeLoading && (
          <PrivilegeInfo isPrivileged={isPrivileged} privilegedFee={privilegedFee} />
        )}

        <CheckInputWithClass
          label={'Update expiration date'}
          checked={updateExpireDate}
          onChange={setUpdateExpireDate}
          className={'w-full select-none justify-between pb-2 pt-2 font-semibold'}
        />

        {updateExpireDate && (
          <div>
            <div className='flex items-center justify-between'>
              <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
                Expiration
              </span>
            </div>
            <div className='mb-6 mt-3'>
              <select
                className='rounded-xl h-12 w-full border border-jacarta-100 bg-jacarta-50 px-4 text-sm text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white'
                value={expiration}
                onChange={(e) => setExpiration(e.target.value)}
                data-testid='updatePriceModalExpiration'
              >
                <option value={1}>1 day</option>
                <option value={3}>3 day</option>
                <option value={7}>7 days</option>
                <option value={30}>30 days</option>
                <option value={180}>6 months</option>
              </select>
            </div>
          </div>
        )}

        {/* <div className='flex items-center justify-end'>
          Balance:&nbsp; <Icon name={`icon-${wrappedToken}`} tooltip={wrappedToken.toUpperCase()} />
          <span className='text-sm dark:text-jacarta-400'>{wbnbBalance}</span>
        </div> */}

        {/* <!-- Terms --> */}

        <div className='alert-info w-full rounded-lg p-2 text-sm'>
          <div className='flex flex-col'>
            <div className='font-semibold'>Note:</div>
            Bit5 does not escrow your NFT. Which means, you can list your token without paying for
            gas fees.
          </div>
        </div>
      </div>
      {/* <!-- end body --> */}

      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {!isChainActive ? (
            <SwitchNetworkButton chainId={chain.id} />
          ) : (
            <Button
              key={'update_sell_button'}
              onClick={handleUpdate}
              disabled={isButtonDisabled}
              text={buttonText}
              data-testid='updateSellButton'
            />
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default UpdateSellModal;
