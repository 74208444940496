import React, { useEffect, useState } from 'react';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';
import { getCdnUrl } from '../../lib/cdnUrl';

const BulkToolBatchTransferNfts = ({ item }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    setImage(getCdnUrl(item?.imageFtpPath, 'thumbnail', item?.updatedAt));
  }, [item.imageFtpPath]);

  return (
    <div className='flex items-start'>
      <div className='mr-3 h-12 w-12 rounded '>
        <ImageFixedAO image={image} alt={item?.collectionAddress} bottomRounded />
      </div>
      <div className='flex flex-col'>
        <div className='text-base font-bold '>{item?.collection?.name}</div>
        <div className='text-sm font-normal'>{item?.name}</div>
      </div>
    </div>
  );
};

export default BulkToolBatchTransferNfts;
