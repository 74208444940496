import { defineChain } from 'viem';

/* export const blastSepoliaTestnet = defineChain({
  id: 168587773,
  name: 'Blast Sepolia',
  network: 'blastSepolia',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://sepolia.blast.io', 'https://blast-sepolia.blockpi.network/v1/rpc/public'],
    },
    public: {
      http: ['https://sepolia.blast.io', 'https://blast-sepolia.blockpi.network/v1/rpc/public'],
    },
  },
  blockExplorers: {
    etherscan: { name: 'Blastscan Sepolia', url: 'https://testnet.blastscan.io' },
    default: { name: 'Blastscan Sepolia', url: 'https://testnet.blastscan.io' },
  },
  contracts: {
    multicall3: '0xca11bde05977b3631167028862be2a173976ca11',
  },
}); */

export const blastSepoliaTestnet = {
  id: 168587773,
  name: 'Blast Sepolia',
  network: 'blastSepolia',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://sepolia.blast.io', 'https://blast-sepolia.blockpi.network/v1/rpc/public'],
    },
    public: {
      http: ['https://sepolia.blast.io', 'https://blast-sepolia.blockpi.network/v1/rpc/public'],
    },
  },
  blockExplorers: {
    etherscan: { name: 'Blastscan Sepolia', url: 'https://sepolia.blastscan.io' },
    default: { name: 'Blastscan Sepolia', url: 'https://sepolia.blastscan.io' },
  },
};
