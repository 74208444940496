import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAccount } from 'wagmi';
import { setModal } from '../redux/counterSlice';
import useGetAcceptTermSignature from '../hooks/useGetAcceptTermSignature';
import PropTypes from 'prop-types';

export const AcceptTermsWrapper = ({ children }) => {
  const { address, isConnected } = useAccount();
  const dispatch = useDispatch();
  const { data: acceptTerms, isLoading } = useGetAcceptTermSignature(address, isConnected);

  useEffect(() => {
    if (isConnected && !!address && !isLoading && !!acceptTerms && !acceptTerms.isAccepted)
      dispatch(setModal({ key: 'acceptTermsModal' }));
  }, [isConnected, address, acceptTerms, isLoading]);
  return <div>{children}</div>;
};

AcceptTermsWrapper.propTypes = {
  children: PropTypes.node,
};
