import Link from 'next/link';
import { ImageFixedAO } from './Image_fixed_aspect_ratio';
import { getCollectionPath, getItemPath } from '../helpers/path';
import { getCdnUrl } from '../lib/cdnUrl';
import useGetItemsByCollectionAddress from '../hooks/useGetItemsByCollectionAddress';
import useGetCollections from '../hooks/useGetCollections';
import { useEffect } from 'react';
import { useAccount } from 'wagmi';
import { useState } from 'react';
import { useRouter } from 'next/router';
import NFTItemCard from './categories/nftItemCard';
import { constants } from 'ethers';

export const MainSearchResults = ({ search, setIsOpen, mobileOpen, inputRef }) => {
  const router = useRouter();
  const { address: walletAddress } = useAccount();
  const { data: collectionswr } = useGetCollections({
    filter: { search: search, includeChildCollections: true },
    limit: 3,
    page: 1,
  });
  const { results: collections } = collectionswr || {};
  const { data: swr } = useGetItemsByCollectionAddress(
    null,
    { search: search, walletAddress, includeAll: true },
    10,
    1,
  );
  const { results: data } = swr || {};
  const [lastData, setLastData] = useState(null);

  useEffect(() => {
    if (data) {
      setLastData(data);
      console.log(data);
    }
  }, [data]);

  useEffect(() => {
    if (mobileOpen) {
      inputRef.current.focus();
    }
  }, [mobileOpen]);

  return (
    <div
      className={`rounded-b-3xl md:rounded-3xl fixed left-0 top-0 ${
        mobileOpen ? 'mt-[95px]' : 'mt[115px]'
      } max-h-[80vh] w-[100%] w-11 min-w-[0%] overflow-hidden border border-jacarta-100 bg-white dark:border-jacarta-500 dark:bg-jacarta-800 md:absolute md:-left-3 md:-top-3 md:mt-0 md:w-[106%] md:min-w-[350px]`}
    >
      <div
        className={
          'md:min-h-0 mb-3 h-full max-h-[calc(100vh_-_200px)] w-full overflow-auto px-3 md:mt-[70px] md:max-h-[50vh]'
        }
      >
        <h1 className='px-3'>Results With "{search}"</h1>
        {collections && collections.length > 0 && (
          <h1 className='mt-3 px-3 font-display text-base text-jacarta-700 group-hover:text-accent dark:text-white'>
            Collections
          </h1>
        )}
        {collections &&
          collections.map((c, i) => (
            <article
              key={`collection ${c?.address} ${i}`}
              className={
                '/*dark:border-jacarta-700 /*border-jacarta-100 /*border rounded-2.5xl group relative mt-3 block flex cursor-pointer bg-white px-3 py-2 transition-shadow dark:bg-jacarta-700'
              }
              onClick={() => {
                setIsOpen(false);
                router.push(getCollectionPath(c.chain, c.pathName));
              }}
              data-testid='mainSearchResultsCollections'
            >
              <figure className='relative flex w-[60px] items-center'>
                <a className={'w-full'}>
                  <ImageFixedAO
                    bottomRounded
                    image={getCdnUrl(c.profilePhotoPath, 'thumbnail', c?.updatedAt)}
                    alt={c.address}
                  />
                </a>
              </figure>
              <div className={'px-[1.1875rem]'}>
                <div className='mt-1 flex items-center justify-between'>
                  <Link
                    href={getCollectionPath(c.chain, c.pathName)}
                    onClick={() => setIsOpen(false)}
                  >
                    <a>
                      <span
                        className={`font-display text-base text-jacarta-700 group-hover:text-accent dark:text-white`}
                      >
                        {c.name}
                      </span>
                    </a>
                  </Link>
                </div>
                <div className='mt-1 text-sm'>
                  <span className='text-jacarta-500 dark:text-jacarta-300 '>
                    View this Collection
                  </span>
                </div>
              </div>
            </article>
          ))}
        {lastData && lastData.length > 0 && (
          <h1 className='mt-3 px-3 font-display text-base text-jacarta-700 group-hover:text-accent dark:text-white'>
            NFTs
          </h1>
        )}
        {lastData &&
          lastData?.map((d, i) => (
            <article
              key={`mainsrc ${JSON.stringify(d.metadata)} ${i}`}
              className={
                '/*dark:border-jacarta-700 /*border-jacarta-100 /*border rounded-2.5xl group relative mt-3 block flex cursor-pointer bg-white px-3 py-2 transition-shadow dark:bg-jacarta-700'
              }
              onClick={() => {
                setIsOpen(false);
              }}
              data-testid='mainSearchResultsNfts'
            >
              <figure className='relative flex w-[60px] items-center'>
                <a className={'w-full'}>
                  <ImageFixedAO
                    bottomRounded
                    image={getCdnUrl(d?.imageFtpPath, 'thumbnail', d?.updatedAt)}
                  />
                </a>
              </figure>
              <div className={'px-[1.1875rem]'}>
                <div className='mt-1 flex items-center justify-between'>
                  <Link
                    // href={getCollectionPath(c.chain, c.pathName)}
                    href={
                      d?.chain && d?.collectionAddress && d?.itemId
                        ? getItemPath(d?.chain, d?.collectionAddress, d?.itemId)
                        : ''
                    }
                    onClick={() => setIsOpen(false)}
                  >
                    <a
                      href={
                        d?.chain && d?.collectionAddress && d?.itemId
                          ? getItemPath(d?.chain, d?.collectionAddress, d?.itemId)
                          : ''
                      }
                    >
                      <span
                        className={`font-display text-base text-jacarta-700 group-hover:text-accent dark:text-white`}
                      >
                        {d.name}
                      </span>
                    </a>
                  </Link>
                </div>
                <div className='mt-1 text-sm'>
                  <span className='text-jacarta-500 dark:text-jacarta-300 '>
                    View this Collection
                  </span>
                </div>
              </div>
            </article>
          ))}
      </div>
    </div>
  );
};
