import React from 'react';

const DropDownItemTags = ({ selectedTags = [], onChange, tags }) => {
  return (
    <>
      <div className={'w-full'}>
        {tags?.map((tag, i) => {
          const { fieldToCheck, name, rangeMax, rangeMin } = tag;
          const fields = {
            fieldToCheck,
            name,
            rangeMax,
            rangeMin,
          };

          return (
            <div
              key={i}
              className={`flex cursor-pointer items-center justify-between px-2 py-3 text-jacarta-500 dark:text-jacarta-200`}
              onClick={() => {
                if (selectedTags.some((n) => n.name === name)) {
                  onChange(selectedTags.filter((n) => n.name !== name));
                } else {
                  onChange([...selectedTags, fields]);
                }
              }}
            >
              <div className={'flex items-center'}>
                <input
                  type='checkbox'
                  className='mr-2 h-5 w-5 cursor-pointer rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600'
                  defaultChecked={selectedTags.some((n) => n.name === name)}
                  checked={selectedTags.some((n) => n.name === name)}
                />
                <div className={'ml-2 text-sm'}>{name}</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DropDownItemTags;
