import { getAddress, isAddress } from 'ethers/lib/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useWalletClient } from 'wagmi';
import { getContract } from 'wagmi/actions';
import abi from '../../helpers/abi';
import { cancelOrder } from '../../helpers/blockchainOperations';
import erc1155ABI from '../../helpers/erc1155ABI';
import erc721ABI from '../../helpers/erc721ABI';
import useGetOrder from '../../hooks/useGetOrder';
import useGetOwnerOfNFT from '../../hooks/useGetOwnerOfNFT';
import { closeModal } from '../../redux/counterSlice';
import { ModalContainer } from './modalContainer';
import { errorToast } from '../toast';
import { useCatchTxError } from '../../hooks/useCatchTxError';
import { useGetChain } from '../../hooks/useGetChain';
import { Button, SwitchNetworkButton } from '../Button';
import BulkToolBatchTransferNfts from '../bulkTool/BulkToolBatchTransferNfts';
import useGetUser from '../../hooks/useGetUser';
import { UserImage } from '../userImage';
import { onlyNumber } from '../../lib/keyevent';

const MODAL_KEY = 'transferModal';
const TransferModal = () => {
  const dispatch = useDispatch();
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient();
  const { connector: activeConnector } = useAccount();
  const { fetchWithCatchTxError } = useCatchTxError();
  const { modal } = useSelector((state) => state.counter);
  const {
    chain: chainId,
    collectionInfo,
    collectionAddress,
    itemId,
    listingSignature,
  } = modal?.data ?? {};
  const [toAddress, setToAddress] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [tokenAmount, setTokenAmount] = useState(1);
  const [buttonText, setButtonText] = useState('Transfer Token');
  const { chain, isChainActive } = useGetChain(chainId);
  const { data: toUser } = useGetUser(toAddress);

  const itemOwner = useGetOwnerOfNFT(collectionAddress, itemId, address).data;

  const modalDataArray = Object.keys(modal?.data)
    .map((key) => modal?.data[key])
    .slice(0, -2);

  const nftContract721 = getContract({
    address: collectionAddress,
    abi: erc721ABI,
    walletClient,
  });

  const nftContract1155 = getContract({
    address: collectionAddress,
    abi: erc1155ABI,
    walletClient,
  });

  const marketplaceContract = getContract({
    address: chain?.marketplace,
    abi,
    walletClient,
  });

  const listingOrder = useGetOrder(listingSignature, 1, chainId).data;

  const handleTransferToken = async () => {
    if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
    if (tokenAmount === '0' || !tokenAmount) return errorToast('Invalid token amount');

    try {
      if (
        parseInt(tokenAmount) + parseInt(listingOrder?.amount) > itemOwner?.amount &&
        listingOrder?.issuer === address
      ) {
        setButtonText('Cancelling the listing...');
        const contractFn = await cancelOrder({
          marketplaceContract,
          signature: listingSignature,
          kind: 1,
          chain: chainId,
          paymentToken: chain?.wrappedToken,
        });
        await fetchWithCatchTxError({
          callTx: contractFn,
          chainId,
          toastMessage: 'Listing cancelled!',
          keepModalOpen: true,
        });
      }

      if (modal?.data?.useBatchTransfer) {
        setButtonText('Transferring the NFT...');
        const params = [];
        for (let i = 0; i < modalDataArray.length; i++) {
          const item = modalDataArray[i];

          const existingItemIndex = params.findIndex(
            (e) => e.collectionAddress === item.collectionAddress,
          );

          if (existingItemIndex !== -1) {
            // If the collectionAddress already exists in params, update amounts and tokenIds.
            params[existingItemIndex].amounts.push(1);
            params[existingItemIndex].tokenIds.push(item.itemId);
          } else {
            // If collectionAddress doesn't exist, create a new entry.
            params.push({
              collectionAddress: item.collectionAddress,
              amounts: [1],
              tokenIds: [item.itemId],
            });
          }
        }
        await fetchWithCatchTxError({
          callTx: () => marketplaceContract?.write.transferBatchNfts([params, toAddress]),
          toastMessage: 'Transfer successful!',
          chainId,
        });
      } else {
        setButtonText('Transferring the NFT...');
        setButtonText('Awaiting blockchain...');
        const isERC721 =
          collectionInfo?.contractType === 'ERC721' ||
          collectionInfo?.contractType === 'ERC404' ||
          collectionInfo?.contractType === 'DN404';
        const contractData = [getAddress(address), getAddress(toAddress), itemId];
        await fetchWithCatchTxError({
          callTx: () =>
            (isERC721 ? nftContract721 : nftContract1155)?.write?.safeTransferFrom?.(
              isERC721 ? contractData : [...contractData, tokenAmount, ''],
            ),
          toastMessage: 'Transfer successful!',
          chainId,
        });
      }
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setButtonText('Transfer Token');
    }
  };

  const isDisabled =
    buttonText !== 'Transfer Token' || !isAddress(toAddress) || address === toAddress;

  useEffect(() => {
    const handleConnectorUpdate = ({ account, chain }) => {
      if (account) {
        dispatch(closeModal());
      }
    };

    if (activeConnector) {
      activeConnector.on('change', handleConnectorUpdate);
    }

    return () => activeConnector?.off('change', handleConnectorUpdate);
  }, [activeConnector]);

  return (
    <ModalContainer modalKey={MODAL_KEY} title='Transfer Token'>
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        <div className='mb-2 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            {modal?.data?.name ?? modal?.data?.metadata?.name ?? ''}
          </span>
        </div>

        {modal?.data?.useBatchTransfer
          ? modalDataArray?.map((item, index) => (
              <div className='mt-4 flex flex-col' key={index}>
                <BulkToolBatchTransferNfts item={item} />
              </div>
            ))
          : ''}
        <div className='my-2 flex items-center justify-between'>
          <input
            type='text'
            className='h-12 w-full rounded-lg focus:outline-0 focus:ring-0 dark:bg-jacarta-700 dark:text-white'
            placeholder='To'
            value={toAddress}
            onFocus={() => setIsFocused(true)}
            data-testid='transferInput'
            onChange={(e) => {
              const newAddress = e.target.value;
              if (newAddress.length === 42 && isAddress(newAddress)) {
                setToAddress(getAddress(newAddress));
              } else {
                setToAddress(e.target.value);
              }
            }}
            onWheel={(e) => e.target.blur()}
          />
        </div>
        {collectionInfo?.contractType === 'ERC1155' && !modal?.data?.useBatchTransfer && (
          <div className='mb-2 flex items-center justify-between'>
            <input
              type='text'
              onKeyDown={onlyNumber}
              className='border-1 focus:ring-inse h-12 w-full flex-[3] focus:ring-accent dark:text-jacarta-700'
              placeholder='Amount'
              value={tokenAmount}
              onFocus={() => setIsFocused(true)}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                if (itemOwner?.amount >= e.target.value) setTokenAmount(e.target.value);
              }}
              data-testid='transferAmount'
            />
          </div>
        )}

        {isFocused && toAddress && !isAddress(toAddress) && (
          <div className='text-sm text-red'>Invalid Address Provided</div>
        )}
        {isFocused && toAddress && isAddress(toAddress) && (
          <div className='mt-4 flex items-center justify-center'>
            <UserImage
              newTab
              responsive
              showName
              showAddress
              user={toUser || { walletAddress: toAddress }}
            />
          </div>
        )}

        {/* <!-- Terms --> */}
      </div>
      {/* <!-- end body --> */}

      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {!isChainActive ? (
            <SwitchNetworkButton chainId={chain.id} />
          ) : (
            <Button
              key={'transfer_button'}
              onClick={handleTransferToken}
              text={buttonText}
              disabled={isDisabled}
              data-testid='transferButton'
            />
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default TransferModal;
