export const lendingABI = [
  {
    inputs: [
      {
        internalType: 'address[]',
        name: '_paymentTokens',
        type: 'address[]',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [],
    name: 'AlreadyProcessed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'CannotProcessOwnOrder',
    type: 'error',
  },
  {
    inputs: [],
    name: 'DeadlineReached',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'executeTime',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'deadline',
        type: 'uint256',
      },
    ],
    name: 'DidNotReachedDeadlineYet',
    type: 'error',
  },
  {
    inputs: [],
    name: 'InvalidOperation',
    type: 'error',
  },
  {
    inputs: [],
    name: 'InvalidPaymentToken',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'enum OrderStatus',
        name: 'expected',
        type: 'uint8',
      },
      {
        internalType: 'enum OrderStatus',
        name: 'real',
        type: 'uint8',
      },
    ],
    name: 'InvalidStatus',
    type: 'error',
  },
  {
    inputs: [],
    name: 'OfferIsNotGlobal',
    type: 'error',
  },
  {
    inputs: [],
    name: 'OrderExpired',
    type: 'error',
  },
  {
    inputs: [],
    name: 'SenderCannotPayThisDebt',
    type: 'error',
  },
  {
    inputs: [],
    name: 'TokenTransferFailed',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'lender',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'executer',
        type: 'address',
      },
    ],
    name: 'UnauthorizedLiquidate',
    type: 'error',
  },
  {
    inputs: [],
    name: 'WrongFunctionCall',
    type: 'error',
  },
  {
    inputs: [],
    name: 'WrongSignature',
    type: 'error',
  },
  {
    inputs: [],
    name: 'WrongSizeOfParameters',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newFee',
        type: 'uint256',
      },
    ],
    name: 'FeeChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'liquidater',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address[]',
            name: 'nftAddresses',
            type: 'address[]',
          },
          {
            internalType: 'uint256[]',
            name: 'colleteralTokenIDs',
            type: 'uint256[]',
          },
          {
            internalType: 'uint256[]',
            name: 'amounts',
            type: 'uint256[]',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'borrowAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'signingTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'expiration',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'duration',
                type: 'uint256',
              },
            ],
            internalType: 'struct Times',
            name: 'times',
            type: 'tuple',
          },
          {
            internalType: 'enum OrderKind',
            name: 'orderKind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind[]',
            name: 'tokenKinds',
            type: 'uint8[]',
          },
          {
            internalType: 'bool',
            name: 'isGlobal',
            type: 'bool',
          },
        ],
        indexed: false,
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
    ],
    name: 'Liquidated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'bytes',
        name: 'sig',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'address[]',
        name: 'nftAddresses',
        type: 'address[]',
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'tokenIds',
        type: 'uint256[]',
      },
    ],
    name: 'ListingCanceled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'bytes',
        name: 'sig',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'address[]',
        name: 'nftAddresses',
        type: 'address[]',
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'tokenIds',
        type: 'uint256[]',
      },
    ],
    name: 'OfferCanceled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address[]',
            name: 'nftAddresses',
            type: 'address[]',
          },
          {
            internalType: 'uint256[]',
            name: 'colleteralTokenIDs',
            type: 'uint256[]',
          },
          {
            internalType: 'uint256[]',
            name: 'amounts',
            type: 'uint256[]',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'borrowAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'signingTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'expiration',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'duration',
                type: 'uint256',
              },
            ],
            internalType: 'struct Times',
            name: 'times',
            type: 'tuple',
          },
          {
            internalType: 'enum OrderKind',
            name: 'orderKind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind[]',
            name: 'tokenKinds',
            type: 'uint8[]',
          },
          {
            internalType: 'bool',
            name: 'isGlobal',
            type: 'bool',
          },
        ],
        indexed: false,
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
    ],
    name: 'OrderProcessed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'Paused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address[]',
            name: 'nftAddresses',
            type: 'address[]',
          },
          {
            internalType: 'uint256[]',
            name: 'colleteralTokenIDs',
            type: 'uint256[]',
          },
          {
            internalType: 'uint256[]',
            name: 'amounts',
            type: 'uint256[]',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'borrowAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'signingTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'expiration',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'duration',
                type: 'uint256',
              },
            ],
            internalType: 'struct Times',
            name: 'times',
            type: 'tuple',
          },
          {
            internalType: 'enum OrderKind',
            name: 'orderKind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind[]',
            name: 'tokenKinds',
            type: 'uint8[]',
          },
          {
            internalType: 'bool',
            name: 'isGlobal',
            type: 'bool',
          },
        ],
        indexed: false,
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
    ],
    name: 'PayedBack',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'status',
        type: 'bool',
      },
    ],
    name: 'PaymentTokenStatusChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'Unpaused',
    type: 'event',
  },
  {
    inputs: [],
    name: 'FEE',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address[]',
            name: 'nftAddresses',
            type: 'address[]',
          },
          {
            internalType: 'uint256[]',
            name: 'colleteralTokenIDs',
            type: 'uint256[]',
          },
          {
            internalType: 'uint256[]',
            name: 'amounts',
            type: 'uint256[]',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'borrowAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'signingTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'expiration',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'duration',
                type: 'uint256',
              },
            ],
            internalType: 'struct Times',
            name: 'times',
            type: 'tuple',
          },
          {
            internalType: 'enum OrderKind',
            name: 'orderKind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind[]',
            name: 'tokenKinds',
            type: 'uint8[]',
          },
          {
            internalType: 'bool',
            name: 'isGlobal',
            type: 'bool',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'acceptGlobalOffer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address[]',
            name: 'nftAddresses',
            type: 'address[]',
          },
          {
            internalType: 'uint256[]',
            name: 'colleteralTokenIDs',
            type: 'uint256[]',
          },
          {
            internalType: 'uint256[]',
            name: 'amounts',
            type: 'uint256[]',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'borrowAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'signingTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'expiration',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'duration',
                type: 'uint256',
              },
            ],
            internalType: 'struct Times',
            name: 'times',
            type: 'tuple',
          },
          {
            internalType: 'enum OrderKind',
            name: 'orderKind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind[]',
            name: 'tokenKinds',
            type: 'uint8[]',
          },
          {
            internalType: 'bool',
            name: 'isGlobal',
            type: 'bool',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
    ],
    name: 'cancelOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'newFee',
        type: 'uint256',
      },
    ],
    name: 'changeFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    name: 'globalOfferTokenIds',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address[]',
            name: 'nftAddresses',
            type: 'address[]',
          },
          {
            internalType: 'uint256[]',
            name: 'colleteralTokenIDs',
            type: 'uint256[]',
          },
          {
            internalType: 'uint256[]',
            name: 'amounts',
            type: 'uint256[]',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'borrowAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'signingTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'expiration',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'duration',
                type: 'uint256',
              },
            ],
            internalType: 'struct Times',
            name: 'times',
            type: 'tuple',
          },
          {
            internalType: 'enum OrderKind',
            name: 'orderKind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind[]',
            name: 'tokenKinds',
            type: 'uint8[]',
          },
          {
            internalType: 'bool',
            name: 'isGlobal',
            type: 'bool',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
    ],
    name: 'liquidate',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [
      {
        internalType: 'bytes4',
        name: '',
        type: 'bytes4',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    name: 'onERC1155Received',
    outputs: [
      {
        internalType: 'bytes4',
        name: '',
        type: 'bytes4',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    name: 'onERC721Received',
    outputs: [
      {
        internalType: 'bytes4',
        name: '',
        type: 'bytes4',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    name: 'orderStatus',
    outputs: [
      {
        internalType: 'enum OrderStatus',
        name: '',
        type: 'uint8',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    name: 'orders',
    outputs: [
      {
        internalType: 'address',
        name: 'lender',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'borrower',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'paused',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address[]',
            name: 'nftAddresses',
            type: 'address[]',
          },
          {
            internalType: 'uint256[]',
            name: 'colleteralTokenIDs',
            type: 'uint256[]',
          },
          {
            internalType: 'uint256[]',
            name: 'amounts',
            type: 'uint256[]',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'borrowAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'signingTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'expiration',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'duration',
                type: 'uint256',
              },
            ],
            internalType: 'struct Times',
            name: 'times',
            type: 'tuple',
          },
          {
            internalType: 'enum OrderKind',
            name: 'orderKind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind[]',
            name: 'tokenKinds',
            type: 'uint8[]',
          },
          {
            internalType: 'bool',
            name: 'isGlobal',
            type: 'bool',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
    ],
    name: 'payback',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'paymentTokens',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address[]',
            name: 'nftAddresses',
            type: 'address[]',
          },
          {
            internalType: 'uint256[]',
            name: 'colleteralTokenIDs',
            type: 'uint256[]',
          },
          {
            internalType: 'uint256[]',
            name: 'amounts',
            type: 'uint256[]',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'borrowAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'signingTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'expiration',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'duration',
                type: 'uint256',
              },
            ],
            internalType: 'struct Times',
            name: 'times',
            type: 'tuple',
          },
          {
            internalType: 'enum OrderKind',
            name: 'orderKind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind[]',
            name: 'tokenKinds',
            type: 'uint8[]',
          },
          {
            internalType: 'bool',
            name: 'isGlobal',
            type: 'bool',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
    ],
    name: 'processOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: 'available',
        type: 'bool',
      },
    ],
    name: 'setPaymentToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    name: 'startTime',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes4',
        name: 'interfaceId',
        type: 'bytes4',
      },
    ],
    name: 'supportsInterface',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'unpause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address[]',
            name: 'nftAddresses',
            type: 'address[]',
          },
          {
            internalType: 'uint256[]',
            name: 'colleteralTokenIDs',
            type: 'uint256[]',
          },
          {
            internalType: 'uint256[]',
            name: 'amounts',
            type: 'uint256[]',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'borrowAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'signingTime',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'expiration',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'duration',
                type: 'uint256',
              },
            ],
            internalType: 'struct Times',
            name: 'times',
            type: 'tuple',
          },
          {
            internalType: 'enum OrderKind',
            name: 'orderKind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind[]',
            name: 'tokenKinds',
            type: 'uint8[]',
          },
          {
            internalType: 'bool',
            name: 'isGlobal',
            type: 'bool',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
      {
        internalType: 'bytes',
        name: '_signature',
        type: 'bytes',
      },
    ],
    name: 'verify',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'withdrawBNB',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'withdrawToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
