import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAccount, useWalletClient } from 'wagmi';
import { getContract } from 'wagmi/actions';
import abi from '../../helpers/abi';
import { approveNFT } from '../../helpers/blockchainOperations';
import { normalizeEther, normalizeNumber } from '../../helpers/digits';
import erc721ABI from '../../helpers/erc721ABI';
import { getTokenName } from '../../helpers/getChainName';
import useGetCollection from '../../hooks/useGetCollection';
import useGetIsPrivileged from '../../hooks/useGetIsPrivileged';
import useGetOrder from '../../hooks/useGetOrder';
import useGetTokenPrice from '../../hooks/useGetTokenPrice';
import { ChainIcon } from '../Icon';
import { ModalContainer } from './modalContainer';
import { sendClientError } from '../../helpers/sendClientError';
import { PrivilegeInfo } from '../privilegeInfo';
import { errorToast } from '../toast';
import { useCatchTxError } from '../../hooks/useCatchTxError';
import { useGetChain } from '../../hooks/useGetChain';
import { Button, SwitchNetworkButton } from '../Button';
import { ModalItemDetails } from './modalComponents/modalItemDetails';

const MODAL_KEY = 'acceptModal';
const AcceptModal = () => {
  const { data: walletClient } = useWalletClient();
  const { fetchWithCatchTxError } = useCatchTxError();
  const { modal } = useSelector((state) => state.counter);
  const defaultButtonText = 'Accept Bid';
  const [buttonText, setButtonText] = useState(defaultButtonText);
  const [serviceFee, setServiceFee] = useState(2);
  const [privilegedFee, setPrivilegedFee] = useState(serviceFee);
  const [isPrivilegedFeeLoading, setIsPrivilegedFeeLoading] = useState(true);
  const { id, kind, chain: chainId, other: item } = modal?.data ?? {};
  const { chain, isChainActive } = useGetChain(chainId);
  const { address, connector } = useAccount();

  const { usdPrice: tokenPrice } = useGetTokenPrice(chainId);

  const bid = useGetOrder(id, kind, chainId).data;
  const bidUSD = tokenPrice * normalizeEther(bid?.price);

  const collectionInfo = useGetCollection(item?.collectionAddress).data;

  const { data: isPrivileged, isLoading: isPrivilegeLoading } = useGetIsPrivileged(
    address,
    chainId,
  );

  const marketplaceContract = getContract({
    address: chain?.marketplace,
    abi,
    walletClient,
  });

  const nftContract = getContract({
    address: bid?.collectionAddress,
    abi: erc721ABI,
    walletClient,
  });

  useEffect(() => {
    const updateServiceFee = async () => {
      try {
        const _fee = await marketplaceContract.read.FEE();

        if (isPrivileged) {
          setPrivilegedFee(parseFloat((_fee * BigInt(isPrivileged?.privilege)) / 10000n) / 100);
        }

        setServiceFee(parseFloat(_fee) / 100);
        setIsPrivilegedFeeLoading(false);
      } catch (e) {
        const error = JSON.stringify(e, Object.getOwnPropertyNames(e));
        sendClientError({
          functionName: 'updateServiceFee',
          contractName: 'Marketplace',
          contractAddress: chain?.marketplace,
          walletAddress: address,
          walletId: connector?.id,
          walletName: connector?.name,
          isWalletReady: connector?.ready,
          error,
        });
      }
    };

    updateServiceFee();
  }, [serviceFee, isPrivileged, marketplaceContract]);

  const handleAccept = async () => {
    try {
      if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
      setButtonText('Approving the NFT...');
      await approveNFT(nftContract, address, chain?.marketplace);
      setButtonText('Accepting the bid...');
      const order = {
        issuer: bid?.issuer,
        nftAddress: bid?.collectionAddress,
        paymentToken: bid?.paymentToken,
        price: bid?.price,
        tokenId: bid?.itemId,
        end: bid?.end,
        kind: bid?.kind,
        tokenKind: bid?.tokenKind,
        globalBidAmount: bid?.globalBidAmount,
        privileges: {
          privilegedCollection: bid?.privilegedCollection,
          privilegedTokenId: bid?.privilegedTokenId,
        },
        proof: bid.proof ? JSON.parse(bid.proof) : [],
        signature: bid.signature,
      };
      await fetchWithCatchTxError({
        callTx: () => marketplaceContract.write.acceptBid([order]),
        toastMessage: 'Bid accepted!',
        chainId: chain.id,
      });
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setButtonText(defaultButtonText);
    }
  };

  const isButtonDisabled = buttonText !== defaultButtonText;

  return (
    <ModalContainer modalKey={MODAL_KEY} title='Accept Bid'>
      {/* <Toaster position='top-right' /> */}
      {/* <!-- Buy Now Modal --> */}
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        <ModalItemDetails item={item} collection={collectionInfo} isGlobal={false} />
        <div className='mb-1 flex flex-wrap items-center gap-1'>
          <span className='block text-sm text-jacarta-500 dark:text-jacarta-300'>
            Creator Earnings: {collectionInfo?.totalCreatorRoyalty}%
          </span>

          <span data-tippy-content='The creator of this collection will receive 5% of the sale total from future sales of this item.'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              width='24'
              height='24'
              className='h-4 w-4 fill-jacarta-700 dark:fill-jacarta-300'
            >
              <path fill='none' d='M0 0h24v24H0z' />
              <path d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z' />
            </svg>
          </span>
        </div>
        {!isPrivilegeLoading && !isPrivilegedFeeLoading && (
          <PrivilegeInfo isPrivileged={isPrivileged} privilegedFee={privilegedFee} />
        )}

        {/* <!-- Total --> */}
        <div className='flex items-center justify-between py-2.5'>
          <span className='font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white'>
            Total
          </span>
          <div className='ml-auto'>
            <span className='flex items-center justify-end whitespace-nowrap'>
              <ChainIcon width={16} name={item?.chain} tooltip={getTokenName(chain.id)} sideGap />
              <span className='font-medium tracking-tight text-green'>
                {normalizeEther(bid?.price)}
              </span>
            </span>
            <div className='text-right dark:text-jacarta-300'>~ ${normalizeNumber(bidUSD)}</div>
          </div>
        </div>

        {/* <!-- Terms --> */}
      </div>
      {/* <!-- end body --> */}

      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {!isChainActive ? (
            <SwitchNetworkButton chainId={chain.id} />
          ) : (
            <Button
              key={'accept_button'}
              onClick={handleAccept}
              text={buttonText}
              disabled={isButtonDisabled}
              data-testid='acceptButton'
            />
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default AcceptModal;
