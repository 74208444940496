import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';
import { isAddress } from 'ethers/lib/utils.js';

export const getMintingFetcher = (collectionAddress, refreshDate) => {
  const enabled = !!collectionAddress && isAddress(collectionAddress);

  const config = {
    url: `${constants.api.url_new}/minting/getMintingByCollectionAddress`,
    method: constants.httpMethods.get,
    params: { collectionAddress, refreshDate },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return { config, fetcher, enabled };
};

export default function useGetMinting(collectionAddress) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, enabled, fetcher } = getMintingFetcher(collectionAddress, refreshDate);
  return useAdaptor(enabled ? axios.getUri(config) : null, fetcher);
}
