// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    inputs: [],
    name: 'AlreadyDefined',
    type: 'error',
  },
  {
    inputs: [],
    name: 'AlreadyProcessed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'CanNotBuyOwnedToken',
    type: 'error',
  },
  {
    inputs: [],
    name: 'CannotAddRoyalties',
    type: 'error',
  },
  {
    inputs: [],
    name: 'CollectionIsNotPrivileged',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ExceedsMaxRoyaltyPercentage',
    type: 'error',
  },
  {
    inputs: [],
    name: 'InsufficientFunds',
    type: 'error',
  },
  {
    inputs: [],
    name: 'InvalidPaymentToken',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotEnoughGlobalBids',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NotSupportedTokenType',
    type: 'error',
  },
  {
    inputs: [],
    name: 'OrderExpired',
    type: 'error',
  },
  {
    inputs: [],
    name: 'TokenTransferFailed',
    type: 'error',
  },
  {
    inputs: [],
    name: 'Unauthorized',
    type: 'error',
  },
  {
    inputs: [],
    name: 'UnsufficientTreasuryFunds',
    type: 'error',
  },
  {
    inputs: [],
    name: 'WrongOrder',
    type: 'error',
  },
  {
    inputs: [],
    name: 'WrongOrderKind',
    type: 'error',
  },
  {
    inputs: [],
    name: 'WrongSignature',
    type: 'error',
  },
  {
    inputs: [],
    name: 'WrongTokenKind',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'bytes',
        name: 'sig',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'hash',
        type: 'bytes32',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'nftAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'seller',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'buyer',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'price',
        type: 'uint256',
      },
    ],
    name: 'BidAccepted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'bytes',
        name: 'sig',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'hash',
        type: 'bytes32',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'nftAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'BidCanceled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'bytes',
        name: 'sig',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'hash',
        type: 'bytes32',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'nftAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'seller',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'buyer',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'price',
        type: 'uint256',
      },
    ],
    name: 'Buy',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newFee',
        type: 'uint256',
      },
    ],
    name: 'FeeChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint8',
        name: 'version',
        type: 'uint8',
      },
    ],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address[]',
        name: 'paymentTokens',
        type: 'address[]',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'treasury',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
    ],
    name: 'Initilaze',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'bytes',
        name: 'sig',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'hash',
        type: 'bytes32',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'nftAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'ListingCanceled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'Paused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'status',
        type: 'bool',
      },
    ],
    name: 'PaymentTokenStatusChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newPP',
        type: 'uint256',
      },
    ],
    name: 'PrivilegedPercentageChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'collection',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newTP',
        type: 'uint256',
      },
    ],
    name: 'TreasuryPercentageChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'Unpaused',
    type: 'event',
  },
  {
    inputs: [],
    name: 'Bit5_TREASURY',
    outputs: [
      {
        internalType: 'contract IBit5Treasury',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'FEE',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'PRIVILEGEPERCENTAGE',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: '_orderValid',
    outputs: [],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'acceptBid',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
      {
        internalType: 'uint256[]',
        name: 'tokenIds',
        type: 'uint256[]',
      },
    ],
    name: 'acceptGlobalBid',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
      {
        internalType: 'uint256[]',
        name: 'tokenIds',
        type: 'uint256[]',
      },
      {
        internalType: 'address',
        name: '_nftOwner',
        type: 'address',
      },
    ],
    name: 'acceptGlobalBidAsOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_privilegeCollections',
        type: 'address',
      },
    ],
    name: 'addPrivilegeCollection',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'collection',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'percentage',
        type: 'uint256',
      },
    ],
    name: 'addRoyaltier',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'buy',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order[]',
        name: 'orders',
        type: 'tuple[]',
      },
    ],
    name: 'buyBatch',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order[]',
        name: 'oldOrders',
        type: 'tuple[]',
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order[]',
        name: 'newOrders',
        type: 'tuple[]',
      },
    ],
    name: 'cancelAllOrders',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'cancelOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order[]',
        name: 'orders',
        type: 'tuple[]',
      },
    ],
    name: 'cancelOrderBatch',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order[]',
        name: 'orders',
        type: 'tuple[]',
      },
    ],
    name: 'cancelOrders',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'newFee',
        type: 'uint256',
      },
    ],
    name: 'changeFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'newPrivilegePercentage',
        type: 'uint256',
      },
    ],
    name: 'changePrivilegePercentage',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'collection',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'pp',
        type: 'uint256',
      },
    ],
    name: 'changeTreasuryPercentage',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_privilegeCollections',
        type: 'address',
      },
    ],
    name: 'deletePrivilegeCollection',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'collection',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
    ],
    name: 'deleteRoyaltier',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order[]',
        name: 'orders',
        type: 'tuple[]',
      },
    ],
    name: 'getOrder',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order',
        name: '',
        type: 'tuple',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: '_paymentTokens',
        type: 'address[]',
      },
      {
        internalType: 'address',
        name: 'treasury',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_owner',
        type: 'address',
      },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'maxRoyalty',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    name: 'orderStatus',
    outputs: [
      {
        internalType: 'enum OrderStatus',
        name: '',
        type: 'uint8',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    name: 'orderStatus32',
    outputs: [
      {
        internalType: 'enum OrderStatus',
        name: '',
        type: 'uint8',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'paused',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'paymentTokens',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'privilegeCollections',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'privileges',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    name: 'processedGlobalBids',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'royalityOwnerShare',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'collection',
        type: 'address',
      },
    ],
    name: 'royaltiers',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'addr',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'percentage',
            type: 'uint256',
          },
        ],
        internalType: 'struct Bit5_V3.Royaltier[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'royalties',
    outputs: [
      {
        internalType: 'address',
        name: 'addr',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'percentage',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'treasuryAddress',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: 'available',
        type: 'bool',
      },
    ],
    name: 'setPaymentToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: '_privilegeCollections',
        type: 'address[]',
      },
    ],
    name: 'setup',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'collection',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'percentage',
        type: 'uint256',
      },
    ],
    name: 'shareRoyalityToTreasury',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'collectionAddress',
            type: 'address',
          },
          {
            internalType: 'uint256[]',
            name: 'amounts',
            type: 'uint256[]',
          },
          {
            internalType: 'uint256[]',
            name: 'tokenIds',
            type: 'uint256[]',
          },
        ],
        internalType: 'struct BatchTransferList[]',
        name: 'collectionsToTransfer',
        type: 'tuple[]',
      },
      {
        internalType: 'address',
        name: '_to',
        type: 'address',
      },
    ],
    name: 'transferBatchNfts',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnershipProxy',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'treasuryPercentages',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'collection',
        type: 'address',
      },
    ],
    name: 'tresuaryRoyaltyShares',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'unpause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'verify',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'verifyOrder',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'issuer',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'nftAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'paymentToken',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'end',
            type: 'uint256',
          },
          {
            internalType: 'enum OrderKind',
            name: 'kind',
            type: 'uint8',
          },
          {
            internalType: 'enum TokenKind',
            name: 'tokenKind',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'globalBidAmount',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'privilegedCollection',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'privilegedTokenId',
                type: 'uint256',
              },
            ],
            internalType: 'struct PrivilegedInformation',
            name: 'privileges',
            type: 'tuple',
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        internalType: 'struct Order',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'verifyOrderSignature',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'withdrawBNB',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'withdrawToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
