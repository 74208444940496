import { getAddress } from 'viem';

export const shortenAddress = (address, length) => {
  if (address) {
    return (
      address?.substring(0, length || 4) +
      '...' +
      address?.substring(address.length - 3, address.length)
    );
  }
  return '';
};

export const isSameAddress = (address1, address2) => {
  return getAddress(address1) === getAddress(address2);
};
