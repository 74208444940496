import React from 'react';
import Head from 'next/head';

const Meta = ({ title, keyword, desc, children }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel='icon' href='/favicon.png' />
        <meta name='description' content={desc} />
        <meta name='keyword' content={keyword} />
        {children}
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: 'Bit5 | Create. Collect. Connect.',
  keyword:
    'bitcoin, blockchain, crypto, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, nft, dnft, dynamic nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset, wallet',
  desc: 'Bit5 marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items.',
};

export default Meta;
