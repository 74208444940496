import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useWalletClient } from 'wagmi';
import { getContract } from 'wagmi/actions';
import uwManagerABI from '../../helpers/uwManagerABI';
import { closeModal } from '../../redux/counterSlice';
import { ModalContainer } from './modalContainer';
import { errorToast } from '../toast';
import { useCatchTxError } from '../../hooks/useCatchTxError';
import { useGetChain } from '../../hooks/useGetChain';
import { Button, SwitchNetworkButton } from '../Button';
import BulkToolBatchTransferNfts from '../bulkTool/BulkToolBatchTransferNfts';
import { parseEther } from 'viem';

const MODAL_KEY = 'uwUnwrapModal';
const UwUnwrapModal = () => {
  const dispatch = useDispatch();
  const { data: walletClient } = useWalletClient();
  const { connector: activeConnector } = useAccount();
  const { fetchWithCatchTxError } = useCatchTxError();
  const { modal } = useSelector((state) => state.counter);
  const { chain: chainId } = modal?.data ?? {};
  const [tokenAmount, setTokenAmount] = useState(1);
  const [buttonText, setButtonText] = useState('Unwrap NFTs');
  const [loading, setLoading] = useState(false);
  const { chain, isChainActive } = useGetChain(chainId);

  const modalDataArray = Object.keys(modal?.data)
    .map((key) => modal?.data[key])
    .slice(0, -2);

  const uwManagerContract = getContract({
    address: chain?.uwManager,
    abi: uwManagerABI,
    walletClient,
  });

  const handleBatchUnwrap = async () => {
    if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
    if (tokenAmount === '0' || !tokenAmount) return errorToast('Invalid token amount');
    if (modalDataArray.length > 15) return errorToast('You can only unwrap 15 NFTs at a time');
    // if modal data array has different collection addresses, we need to wrap them separately
    // Throw error toast if this is the case lol

    /**
    if (modalDataArray.length > 1) {
      const collectionAddresses = modalDataArray.map((item) => item.collectionAddress);
      if (new Set(collectionAddresses).size !== 1) {
        return errorToast('Please select NFTs from the same collection');
      }
    }
 */
    // Get the collection address from the first item in the array

    // If any of the items in the array have a different chainId, return a toast
    if (modalDataArray.some((item) => item.collection.chain !== chainId)) {
      return errorToast('Please select NFTs from the same chain');
    }

    // Collection Addresses and Ids should look like this

    const collectionAddresses = modalDataArray.map((item) => item.collectionAddress);

    // Deduplicate collection addresses
    const uniqueCollectionAddresses = [...new Set(collectionAddresses)];

    // Create a map with collection addresses as keys and tokenIds as values
    const newMap = new Map();

    uniqueCollectionAddresses.forEach((collectionAddress) => {
      newMap.set(collectionAddress, []);
    });

    // Fill the map with tokenIds
    modalDataArray.forEach((item) => {
      newMap.get(item.collectionAddress).push(item.itemId);
    });

    // Convert map to two arrays
    const mfDoom = [];

    uniqueCollectionAddresses.forEach((collectionAddress) => {
      mfDoom.push({
        collectionAddress,
        tokenIds: newMap.get(collectionAddress),
      });
    });

    const tokenIdArrays = mfDoom.map((item) => item.tokenIds.map((tokenId) => BigInt(tokenId)));

    const collectionAddressesArray = mfDoom.map((item) => item.collectionAddress);

    const finalizedArray = [[...collectionAddressesArray], [...tokenIdArrays]];

    const toFlatMap = finalizedArray[1];

    const itemCount = toFlatMap.reduce((acc, val) => acc + val.length, 0);

    try {
      setLoading(true);
      const fee = await uwManagerContract.read.getUnwrapFee();

      await fetchWithCatchTxError({
        callTx: () =>
          uwManagerContract.write.batchUnwrapMultiCollectionNFT(finalizedArray, {
            value: BigInt(itemCount) * BigInt(fee),
          }),
        chainId,
        toastMessage: 'Wrapping Successful!',
      });
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setLoading(false);
    }
  };

  // const isDisabled =
  //   buttonText !== 'Transfer Token' || !isAddress(toAddress) || address === toAddress;
  const isDisabled = loading;

  useEffect(() => {
    const handleConnectorUpdate = ({ account, chain }) => {
      if (account) {
        dispatch(closeModal());
      }
    };

    if (activeConnector) {
      activeConnector.on('change', handleConnectorUpdate);
    }

    return () => activeConnector?.off('change', handleConnectorUpdate);
  }, [activeConnector]);

  return (
    <ModalContainer modalKey={MODAL_KEY} title='Unwrap Tokens'>
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        <div className='mb-2 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            {modal?.data?.name ?? modal?.data?.metadata?.name ?? ''}
          </span>
        </div>

        {modal?.data?.useBatchUnwrap
          ? modalDataArray?.map((item, index) => (
              <div className='mt-4 flex flex-col' key={index}>
                <BulkToolBatchTransferNfts item={item} />
              </div>
            ))
          : ''}

        {/* <!-- Terms --> */}
      </div>
      {/* <!-- end body --> */}

      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {!isChainActive ? (
            <SwitchNetworkButton chainId={chain.id} />
          ) : (
            <Button
              key={'unwrap_button'}
              onClick={handleBatchUnwrap}
              text={buttonText}
              disabled={isDisabled}
              data-testid='unwrapButton'
            />
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default UwUnwrapModal;
