import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { ethers } from 'ethers';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export const getCollectionFetcher = (collectionAddress, refreshDate) => {
  let config;

  if (!ethers.utils.isAddress(collectionAddress)) {
    config = {};
  } else {
    config = {
      url: `${constants.api.url_new}/collections/getCollectionByAddress`,
      //url: `${constants.api.url}/collections/get`,
      method: constants.httpMethods.post,
      data: { collectionAddress, refreshDate },
    };
  }

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });
  return { config, fetcher };
};
export default function useGetCollection(collectionAddress) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, fetcher } = getCollectionFetcher(collectionAddress, refreshDate);
  return useAdaptor(axios.getUri(config), fetcher);
}

export const getCollectionFetcherGet = (collectionAddress, refreshDate) => {
  let config;

  if (!ethers.utils.isAddress(collectionAddress)) {
    config = {};
  } else {
    config = {
      url: `${constants.api.url_new}/collections/getCollectionByAddress`,
      //url: `${constants.api.url}/collections/get`,
      method: constants.httpMethods.get,
      params: { collectionAddress, refreshDate },
    };
  }

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });
  return { config, fetcher };
};
export function useGetCollectionGet(collectionAddress) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, fetcher } = getCollectionFetcherGet(collectionAddress, refreshDate);
  return useAdaptor(axios.getUri(config), fetcher);
}

export const getCollectionBySlugFetcher = (slug, refreshDate) => {
  const config = {
    url: `${constants.api.url_new}/collections/getCollectionBySlug`,
    //url: `${constants.api.url}/collections/get`,
    method: constants.httpMethods.get,
    params: { slug, refreshDate },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });
  return { config: slug ? config : {}, fetcher };
};
export function useGetCollectionBySlug(slug) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, fetcher } = getCollectionFetcher(slug, refreshDate);
  return useAdaptor(axios.getUri(config), fetcher);
}
