import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useEffect, useState } from 'react';
import { isAddress } from 'ethers/lib/utils.js';

export default function useGetOwnedPrivilegedNftsByWallet({
  walletAddress,
  itemId,
  page = 1,
  limit = 10,
  selectedNft,
}) {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (!walletAddress) return setEnabled(false);
    setEnabled(isAddress(walletAddress));
  }, [walletAddress]);

  const config = {
    url: `${constants.api.url_new}/users/getPrivilegedNfts`,
    method: constants.httpMethods.get,
    params: { walletAddress, itemId, page, limit, selectedNft },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data;
    });

  return useAdaptor(enabled ? axios.getUri(config) : null, fetcher);
}
