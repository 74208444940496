import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { ethers } from 'ethers';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export function getIsPrivilegedFetcher(walletAddress, chainId, refreshDate) {
  let config;

  if (!ethers.utils.isAddress(walletAddress)) {
    config = {};
  } else {
    config = {
      url: `${constants.api.url_new}/users/isPrivileged`,
      method: constants.httpMethods.get,
      params: { walletAddress, chainId, refreshDate },
    };
  }

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return { config, fetcher };
}

export default function useGetIsPrivileged(walletAddress, chainId) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, fetcher } = getIsPrivilegedFetcher(walletAddress, chainId, refreshDate);
  return useAdaptor(axios.getUri(config), fetcher);
}
