import axios from '../../lib/axios';
import { constants } from '../../helpers/constants';
import useAdaptor from '../../lib/swr';
import { useContext } from 'react';
import { DataRefreshContext } from '../../components/refreshContext';
import { utils } from 'ethers';

export const getListingBulkFetcher = (
  collectionAddress,
  signature,
  walletAddress,
  findMany = false,
  refreshDate,
) => {
  let enabled = false;
  if (!collectionAddress) {
    enabled = false;
    return;
  }

  enabled = collectionAddress.every(({ collectionAddress }) => utils.isAddress(collectionAddress));

  const config = {
    url: `${constants.api.url_new}/items/getListingBulk`,
    method: constants.httpMethods.post,
    data: {
      collectionsWithItems: collectionAddress,
      refreshDate,
      ...(signature ? { signature } : {}),
      ...(walletAddress ? { walletAddress } : {}),
      ...(findMany ? { findMany } : {}),
    },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return { config, fetcher, enabled };
};

export default function useGetListingBulk({
  collectionAddress,
  signature,
  walletAddress,
  findMany = false,
}) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, enabled, fetcher } = getListingBulkFetcher(
    collectionAddress,
    signature,
    walletAddress,
    findMany,
    refreshDate,
  );
  return useAdaptor(enabled ? axios.getUri(config) : null, fetcher);
}
