import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAccount, useWalletClient } from 'wagmi';
import { getContract } from 'wagmi/actions';
import abi from '../../../helpers/abi';
import { approveNFT } from '../../../helpers/blockchainOperations';
import { constants } from '../../../helpers/constants';
import { normalizeEther, normalizeNumber } from '../../../helpers/digits';
import erc721ABI from '../../../helpers/erc721ABI';
import { getTokenName } from '../../../helpers/getChainName';
import useGetIsPrivileged from '../../../hooks/useGetIsPrivileged';
import useGetTokenPrice from '../../../hooks/useGetTokenPrice';
import { ChainIcon } from '../../Icon';
import { ModalContainer } from '../modalContainer';
import { ModalItemDetails } from '../modalComponents/modalItemDetails';
import { SelectNfts } from './selectNfts';
import axios from '../../../lib/axios';
import { sendClientError } from '../../../helpers/sendClientError';
import { PrivilegeInfo } from '../../privilegeInfo';
import { errorToast } from '../../toast';
import { useCatchTxError } from '../../../hooks/useCatchTxError';
import { useGetChain } from '../../../hooks/useGetChain';
import { Button, SwitchNetworkButton } from '../../Button';
import { formatErc404TokenId } from '../../../helpers/erc404';

const MODAL_KEY = 'acceptBidModal';
const AcceptModal = () => {
  const { address, connector } = useAccount();
  const { data: walletClient } = useWalletClient();
  const { fetchWithCatchTxError } = useCatchTxError();
  const { modal } = useSelector((state) => state.counter);
  const { offer, item, itemId, collectionInfo } = modal?.data ?? {};
  const { chain: chainId, signature, bulkSignature, kind } = offer || {};
  const isGlobal = offer?.globalBidAmount && offer?.globalBidAmount > 0;
  const [isPrivilegedFeeLoading, setIsPrivilegedFeeLoading] = useState(true);
  const [selectedNfts, setSelectedNfts] = useState(itemId ? [itemId] : []);
  const defaultButtonText = `Accept ${isGlobal ? 'Global ' : ''}Bid`;
  const [buttonText, setButtonText] = useState(defaultButtonText);
  const [serviceFee, setServiceFee] = useState(2);
  const [privilegedFee, setPrivilegedFee] = useState(serviceFee);
  const { chain, isChainActive } = useGetChain(chainId);

  const { usdPrice: tokenPrice } = useGetTokenPrice(chainId);
  const { data: isPrivileged, isLoading: isPrivilegeLoading } = useGetIsPrivileged(
    address,
    chainId,
  );

  const bidUSD = tokenPrice * normalizeEther(offer?.price);

  useEffect(() => {
    const updateServiceFee = async () => {
      try {
        const _fee = await marketplaceContract.read.FEE();
        if (isPrivileged)
          setPrivilegedFee(parseFloat((_fee * BigInt(isPrivileged?.privilege)) / 10000n) / 100);

        setServiceFee(parseFloat(_fee) / 100);
        setIsPrivilegedFeeLoading(false);
      } catch (e) {
        const error = JSON.stringify(e, Object.getOwnPropertyNames(e));
        sendClientError({
          functionName: 'updateServiceFee',
          contractName: 'Marketplace',
          contractAddress: chain?.marketplace,
          walletAddress: address,
          walletId: connector?.id,
          walletName: connector?.name,
          isWalletReady: connector?.ready,
          error,
        });
      }
    };

    updateServiceFee();
  }, [serviceFee, isPrivileged, marketplaceContract]);

  const marketplaceContract = getContract({
    address: chain?.marketplace,
    abi,
    walletClient,
  });

  const nftContract = getContract({
    address: offer?.collectionAddress,
    abi: erc721ABI,
    walletClient,
  });

  const handleAccept = async () => {
    try {
      if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
      if (isGlobal && (selectedNfts.length === 0 || !selectedNfts))
        return errorToast('Please select NFTs');
      setButtonText('Approving the NFT...');
      await approveNFT(nftContract, address, chain?.marketplace);
      setButtonText(`Accepting ${isGlobal ? 'Global ' : ''}Bid...`);
      let available = true;
      if (isGlobal && offer?.traitFilter) {
        if (!selectedNfts || selectedNfts.length === 0) return errorToast('Please select NFTs');
        for (const nft of selectedNfts) {
          try {
            const tokenUri = await nftContract?.read?.tokenURI?.([nft]);
            let url = tokenUri?.includes('ipfs://')
              ? `${constants.api.ipfs_url}/${tokenUri.split('ipfs://').pop()}`
              : tokenUri;

            if (url.startsWith('https://ikzttp.mypinata.cloud')) {
              url = url.replace('https://ikzttp.mypinata.cloud', 'https://ipfs.bit5.com');
            }

            const metadata = await axios.get(url);
            const traits = metadata?.data?.attributes;
            let traitFilter = offer?.traitFilter;
            available = Object.keys(traitFilter).every((traitType) => {
              const traitValues = traitFilter[traitType];
              return traitValues.some((traitValue) =>
                traits.some(
                  (trait) => trait.trait_type === traitType && trait.value === traitValue,
                ),
              );
            });
            if (!available) return errorToast('NFT/s is not available for bid');
          } catch (e) {
            console.log('e', e);
            return errorToast('NFT/s is not available for bid');
          }
        }
      }
      setButtonText('Awaiting blockchain...');
      const data = {
        issuer: offer?.issuer,
        nftAddress: offer?.collectionAddress,
        paymentToken: offer?.paymentToken,
        price: offer?.price,
        tokenId: isGlobal ? '0' : offer?.itemId,
        end: offer?.end,
        kind: offer?.kind,
        tokenKind: offer?.tokenKind,
        globalBidAmount: offer?.globalBidAmount,
        privileges: {
          privilegedCollection: offer?.privilegedCollection,
          privilegedTokenId: offer?.privilegedTokenId,
        },
        proof: offer.proof ? JSON.parse(offer.proof) : [],
        signature: signature ?? bulkSignature,
      };
      await fetchWithCatchTxError({
        callTx: () =>
          marketplaceContract?.write?.[isGlobal ? 'acceptGlobalBid' : 'acceptBid']?.([
            data,
            ...(isGlobal ? [selectedNfts] : []),
          ]),
        toastMessage: `${isGlobal ? 'Global bid' : 'Bid'} accepted!`,
        chainId: chainId,
      });
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setButtonText(defaultButtonText);
    }
  };

  const isButtonDisabled = buttonText !== defaultButtonText;

  return (
    <ModalContainer modalKey={MODAL_KEY} title={defaultButtonText}>
      {/* <Toaster position='top-right' /> */}
      {/* <!-- Buy Now Modal --> */}
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        <ModalItemDetails collection={collectionInfo} item={item} isGlobal={isGlobal} />
        {!isGlobal ? (
          <div className='relative flex border-jacarta-100 pb-4 dark:border-jacarta-600'>
            <div className='mt-2 w-full'>
              <div className='flex w-full'>
                <div className=''>Offer Price:</div>
                <div className='ml-auto flex w-fit space-x-1'>
                  <span className='ml-auto flex items-center whitespace-nowrap'>
                    <div className='hidden pt-0.5' />
                    <ChainIcon
                      width={16}
                      name={offer?.chain || item?.chain}
                      tooltip={getTokenName(offer?.chain || item?.chain)}
                      sideGap={true}
                    />
                    <span className='text-sm font-medium tracking-tight dark:text-jacarta-100'>
                      {normalizeEther(offer?.price)}
                    </span>
                  </span>
                  <div className='mt-1 text-sm dark:text-jacarta-300'>
                    ~ ${normalizeEther(tokenPrice * normalizeEther(offer?.price))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div>
          <div className='flex flex-wrap items-center'>
            <span className='mr-1 block text-sm text-jacarta-500 dark:text-jacarta-300'>
              Creator Earnings: {collectionInfo?.totalCreatorRoyalty}%
            </span>

            <span data-tippy-content='The creator of this collection will receive 5% of the sale total from future sales of this item.'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
                className='h-4 w-4 fill-jacarta-700 dark:fill-jacarta-300'
              >
                <path fill='none' d='M0 0h24v24H0z' />
                <path d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z' />
              </svg>
            </span>
          </div>
        </div>

        {isGlobal ? (
          <div className='flex items-center justify-between py-2.5'>
            <span className='font-semibold text-jacarta-700 dark:text-white'>
              Filled Global Bids
            </span>
            <div className='ml-auto'>
              <span className='font-medium tracking-tight text-green'>
                {offer?.filledGlobalBids} / {offer?.globalBidAmount}
              </span>
            </div>
          </div>
        ) : null}

        {isGlobal ? (
          <SelectNfts
            collectionAddress={offer?.collectionAddress}
            walletAddress={address}
            amount={offer?.globalBidAmount - offer?.filledGlobalBids}
            setNfts={setSelectedNfts}
            itemId={itemId || null}
            hideSelect={true}
            traitFilter={offer?.traitFilter}
            rarityFilter={offer?.rarityFilter}
            collectionInfo={collectionInfo}
          />
        ) : null}

        {/* {isGlobal && offer?.traitFilter ? (
          <div>
            Your NFTs in this collection:
            <div className='max-h-[450px] overflow-auto'>
              {userNfts?.map((item, index) => {
                item = { ...item, ...item.item };
                if (typeof item?.metadata === 'string') {
                  item.metadata = JSON.parse(item?.metadata);
                }

                const image = item?.imageFtpPath
                  ? constants.cdn.path +
                    item?.imageFtpPath +
                    constants.cdn.thumbnail +
                    constants.cdn.version
                  : item?.metadata?.image
                  ? item?.metadata?.image
                  : constants.helpers.noImage;

                return (
                  <div
                    key={'userNFT-' + index}
                    className='relative flex items-center border-t border-b border-jacarta-100 py-4 dark:border-jacarta-600'
                  >
                    <figure className='mr-5 self-start'>
                      <img
                        src={`${image}`}
                        width={50}
                        height={50}
                        alt='avatar 2'
                        className='rounded-2lg'
                        loading='lazy'
                      />
                    </figure>
                    <div>
                      {item?.metadata?.name}
                      <input
                        type='checkbox'
                        className='ml-4 h-5 w-5 cursor-pointer self-start rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600'
                        checked={selectedNfts.includes(Number(item?.itemId))}
                        onChange={() => {
                          const itemId = Number(item?.itemId);
                          if (
                            offer?.globalBidAmount - offer?.filledGlobalBids ===
                              selectedNfts.length &&
                            !selectedNfts.includes(itemId)
                          )
                            return;

                          if (selectedNfts.includes(itemId)) {
                            setSelectedNfts(selectedNfts.filter((id) => id != itemId));
                          } else {
                            setSelectedNfts([...selectedNfts, itemId]);
                          }
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null} */}

        {isGlobal && selectedNfts && selectedNfts.length > 0 ? (
          <div className='alert-success flex items-center justify-between border-b border-jacarta-100 px-2 py-2.5 dark:border-jacarta-600'>
            <span className='font-semibold text-jacarta-700 dark:text-white'>
              {/* Selected NFTs */}
              Selected NFT{selectedNfts.length > 1 ? 's' : ''} ({selectedNfts.length})
            </span>
            <div className='ml-auto'>
              <span className='font-medium tracking-tight text-jacarta-700 dark:text-white'>
                {collectionInfo &&
                  selectedNfts
                    .map((nftId) => {
                      if (collectionInfo?.contractType === 'ERC404') {
                        return `#${formatErc404TokenId(nftId, collectionInfo?.itemIdPrefix)}`;
                      } else {
                        return '#' + nftId;
                      }
                    })
                    .join(', ')}
              </span>
            </div>
          </div>
        ) : null}

        {!isPrivilegeLoading && !isPrivilegedFeeLoading && (
          <PrivilegeInfo isPrivileged={isPrivileged} privilegedFee={privilegedFee} />
        )}

        {/* <!-- Total --> */}
        <div className='flex items-center justify-between py-2.5'>
          <span className='font-display font-semibold text-jacarta-700 dark:text-white'>Total</span>
          <div className='ml-auto'>
            <span className='flex items-center justify-end whitespace-nowrap'>
              <ChainIcon
                width={16}
                name={offer?.chain || item?.chain}
                tooltip={getTokenName(offer?.chain || item?.chain)}
                sideGap
              />
              <span className='font-medium tracking-tight text-green'>
                {/* {normalizeEther(offer?.price)} */}
                {isGlobal
                  ? normalizeEther(offer?.price) * (selectedNfts?.length || 0)
                  : normalizeEther(offer?.price)}
              </span>
            </span>
            <div className='text-right dark:text-jacarta-300'>
              {/* ~${normalizeNumber(bidUSD)} */}
              ~$
              {isGlobal
                ? normalizeNumber(
                    bidUSD * (selectedNfts?.length || 0),
                    bidUSD * (selectedNfts?.length || 0) > 1000 ? 0 : 2,
                  )
                : normalizeNumber(bidUSD, bidUSD > 1000 ? 0 : 2)}
            </div>
          </div>
        </div>

        {/* <!-- Terms --> */}
      </div>
      {/* <!-- end body --> */}

      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {!isChainActive ? (
            <SwitchNetworkButton chainId={chain.id} />
          ) : selectedNfts.length > 0 || !isGlobal ? (
            <Button
              key={'accept_bid_button'}
              onClick={handleAccept}
              text={buttonText}
              disabled={isButtonDisabled}
              data-testid='acceptBidButton'
            />
          ) : (
            'Select NFTs'
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default AcceptModal;
