import { useNetwork } from 'wagmi';

export const useActiveChainId = () => {
  const { chain } = useNetwork();
  const chainId = chain?.id;

  return {
    chainId,
    isWrongNetwork: chain?.unsupported ?? false,
  };
};
