import { getCoinGeckoId } from '../helpers/getChainName';
import useGetUSDPriceOfToken from './useGetUSDPriceOfToken';

export default function useGetTokenPrice(chainId, tokenName) {
  const usdPrice = Object.values(
    useGetUSDPriceOfToken(getCoinGeckoId(tokenName ?? chainId), 1)?.data || {},
  )?.[0]?.usd;

  return { usdPrice };
}
