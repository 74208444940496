import { createPublicClient, http } from 'viem';
import { useCallback } from 'react';
import { waitForTransaction } from 'wagmi/actions';
import { constants } from '../helpers/constants';
import { useActiveChainId } from './useActiveChainId';

const CHAINS = constants?.helpers?.supportedChains;

const viemClientsPublicNodes = CHAINS.reduce((prev, cur) => {
  return {
    ...prev,
    [cur.id]: createPublicClient({
      chain: cur,
      transport: http(cur.rpcUrls.public.http[0], {
        timeout: 15_000,
      }),
      batch: {
        multicall: {
          batchSize: 1024 * 200,
        },
      },
    }),
  };
}, {});

export function usePublicNodeWaitForTransaction() {
  const { chainId } = useActiveChainId();

  const waitForTransaction_ = useCallback(
    async (opts) => {
      // our custom node might be late to sync up
      /*  if (viemClientsPublicNodes[chainId]) {
        return viemClientsPublicNodes[chainId].waitForTransactionReceipt(opts);
      } */
      return waitForTransaction({ ...opts, chainId });
    },
    [chainId],
  );

  return {
    waitForTransaction: waitForTransaction_,
  };
}
