import { useContext, useEffect, useState } from 'react';
import { useFloorSweep } from '../../floor-sweep/useFloorSweep';
import { DataRefreshContext } from '../../refreshContext';

export const CheckBadge = ({ item }) => {
  const { refreshDate } = useContext(DataRefreshContext);
  const [selected, setSelected] = useState(false);
  const { sweepingItems, sweepCount, setSweepingItems } = useFloorSweep();

  const updateSweepItem = () => {
    let sweepItemIndex = sweepingItems.findIndex(
      (f) => f.collectionAddress == item.collectionAddress && f.itemId == item.itemId,
    );

    if (sweepItemIndex == -1) {
      setSweepingItems([item, ...sweepingItems], 'check');
    } else {
      let _sweepItems = JSON.parse(JSON.stringify(sweepingItems));
      _sweepItems.splice(sweepItemIndex, 1);
      console.log('newSweepingItems', _sweepItems);
      setSweepingItems(_sweepItems, 'check');
    }
  };

  useEffect(() => {
    const _selected =
      sweepingItems.findIndex(
        (f) => f.collectionAddress == item.collectionAddress && f.itemId == item.itemId,
      ) > -1;
    setSelected(_selected);
  }, [sweepingItems, item?.collectionAddress, item?.itemId, refreshDate]);

  return (
    <div
      onClick={updateSweepItem}
      className={`${
        sweepCount > 0 ? 'flex' : 'hidden'
      } relative z-10 h-[30px] w-[30px] cursor-pointer items-center justify-between rounded-lg border-jacarta-100 bg-[#0000003D] px-2 py-1 transition-colors duration-300  hover:bg-accent group-hover/price:flex dark:text-[#FFF] md:h-[32px] md:w-[32px]`}
      data-testid='sweepCheckbox'
    >
      <svg viewBox='0 0 32 32' aria-hidden='true' className='text-white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='currentColor'
          {...(selected
            ? {
                d: 'M26 4H6C4.89543 4 4 4.89543 4 6V26C4 27.1046 4.89543 28 6 28H26C27.1046 28 28 27.1046 28 26V6C28 4.89543 27.1046 4 26 4ZM14 21.5L9 16.5L10.59 15L14 18.35L21.41 11L23 12.58L14 21.5Z',
              }
            : {
                d: 'M26 4H6C4.89543 4 4 4.89543 4 6V26C4 27.1046 4.89543 28 6 28H26C27.1046 28 28 27.1046 28 26V6C28 4.89543 27.1046 4 26 4ZM6 26V6H26V26H6Z',
              })}
        />
      </svg>
    </div>
  );
};
