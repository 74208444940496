import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';
import { isAddress } from 'ethers/lib/utils.js';

export function getNftMetadataFetcher(collectionAddress = null, tokenId, refreshDate) {
  const enabled = !!collectionAddress && !!tokenId && isAddress(collectionAddress);

  const config = {
    url: `${constants.api.url_new}/items/${collectionAddress}/${tokenId}`,
    method: constants.httpMethods.get,
    params: { collectionAddress, tokenId, refreshDate },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return { config, fetcher, enabled };
}

export default function useGetNftMetadata(collectionAddress = null, tokenId) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, fetcher, enabled } = getNftMetadataFetcher(
    (collectionAddress = null),
    tokenId,
    refreshDate,
  );
  return useAdaptor(enabled ? axios.getUri(config) : null, fetcher);
}
