import { useAccount, useBalance, useChainId } from 'wagmi';
import { normalizeEther } from '../../helpers/digits';
import { ChainIcon } from '../Icon';
import { getTokenName } from '../../helpers/getChainName';
import { useEffect, useState } from 'react';

export const CartSummary = ({ itemList, totalEtherPrice, totalUsdPrice, cartChainId }) => {
  const { address } = useAccount();
  const chainId = useChainId();
  const { data, isError, isLoading } = useBalance({
    address: address,
    chainId: cartChainId,
  });
  const [hexChainId, setHexChainId] = useState();
  useEffect(() => {
    if (cartChainId) {
      setHexChainId(`0x${cartChainId.toString(16)}`);
    }
  }, [cartChainId]);

  return (
    <div className='w-full'>
      {hexChainId && itemList.length > 0 ? (
        <>
          <div className='flex items-center border-y-2 border-jacarta-100 px-[16px] py-[12px]'>
            <div className=''>
              <div className='text-[24px] text-[#000] dark:text-white'>You Pay</div>
              <div className='text-[16px] text-[#8A8C95]'>for {itemList.length} NFTs</div>
            </div>

            <div className='ml-auto mr-2'>
              <div className='text-right text-[18px] text-[#000]'>
                <span className='flex items-center justify-end whitespace-nowrap'>
                  <ChainIcon name={hexChainId} tooltip={getTokenName(hexChainId)} sideGap={true} />
                  <span className='font-medium tracking-tight text-green '>
                    {totalEtherPrice ? totalEtherPrice.toFixed(5) : 0}
                  </span>
                </span>
              </div>
              <div className='text-right text-[16px] text-[#8A8C95]'>
                {totalUsdPrice ? `$${totalUsdPrice.toFixed(5)}` : 0}
              </div>
            </div>
          </div>
          {cartChainId == chainId && (
            <div className='mt-[4px] flex items-center px-[16px]'>
              <div className=''>
                <div className='text-[16px] font-bold text-[#000] dark:text-white'>
                  Your Balance
                </div>
              </div>

              <div className='ml-auto mr-2'>
                <div className='text-right text-[16px] font-bold text-[#000] dark:text-white'>
                  {normalizeEther(data?.value)} {data?.symbol}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <p className='mt-10 text-center'>Add items to get started.</p>
        </>
      )}
    </div>
  );
};
