import {
  // avalanche,
  // avalancheFuji,
  bsc,
  bscTestnet,
  // mainnet,
  polygon,
  // polygonZkEvm,
  // polygonZkEvmTestnet,
  // sepolia,
  // zkSync,
  // zkSyncTestnet,
} from 'wagmi/chains';
import { blast } from '../components/wagmi/blast';
import { blastSepoliaTestnet } from '../components/wagmi/blastSepoliaTestnet';
import { getAddress } from 'viem';

export const httpMethods = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

export const cdn = {
  cdn: {
    path: 'https://img.bit5.com/', //'https://ik.imagekit.io/bit5/',
    detail: '?tr=w-1000',
    thumbnail: '?tr=w-262',
    w350x192: '?tr=w-350,h-192',
    w1920x1080: '?tr=w-1920,h-1080',
    w100: '?tr=w-1000',
    w560: '?tr=w-560',
    w200: '?tr=w-2000',
    version: '&ua=20221121',
  },
  digitalOceanCdn: {
    path: 'https://cdn.bit5.com/',
  },
  cloudinary: {
    path: 'https://res.cloudinary.com/demo/image/fetch/',
  },
};

const supportedChains = {
  development: [
    {
      ...bscTestnet,
      name: 'BSC Testnet',
      scan_url: 'https://testnet.bscscan.com',
      hexId: '0x61',
      wrappedToken: getAddress('0xEf347ABbAEf4a2306e6e4a35237E0f62232b9734'),
      marketplace: getAddress('0x0550f19A7Ed4FAC1a1f238bDb2422F8Db3823576'),
      treasuryAddress: getAddress('0xB0071cCcB3CB65a3B4Bf49BD995e318abB71E15A'),
      lendingContract: getAddress('0x832Cc3ED03Cd93C2EfCF28b84fbe94e5b4E99E3C'),
      uwRegistar: getAddress('0x8ad76139ad181f2e9190f093b40f27b2fa168592'),
      uwVault: getAddress('0x26b9c7edff8639833c7136e8a858328b2b974bd0'),
      uwManager: getAddress('0xeb7a372b362ea84ffbcfa0f1b9c972bc2fb5c5d8'),
      uwSupport: true,
    },
    // {
    //   ...sepolia,
    //   scan_url: 'https://sepolia.etherscan.io',
    //   hexId: '0xaa36a7',
    //   wrappedToken: '0x641063bA1cA491de22668b38Cf26Df57699B11b7',
    //   marketplace: '0x08C9104485222AA2cE79e47583084f16646112d7',
    //   treasuryAddress: '0x9bCd5003715C936051aBD41E00B175b1756C2D90',
    //   lendingContract: '0x7CDEe851050EbD22Dd7dDb6dfc97c73C5c36238f',
    // },
    // {
    //   ...zkSyncTestnet,
    //   iconUrl: '/chains/0x144.svg',
    //   scan_url: 'https://goerli.explorer.zksync.io',
    //   hexId: '0x118',
    //   wrappedToken: '0x20b28B1e4665FFf290650586ad76E977EAb90c5D',
    //   marketplace: '0x2Cb7c02f657D07769da693fEf5DfdB5552295cc0',
    //   treasuryAddress: '0x78111354c9886EA25bF241c77891f424Dcb87C1E',
    //   lendingContract: '0xD0806E43C2E6fb42dDF7E32479F89eAebd9B9872',
    //   rpcUrls: {
    //     default: {
    //       http: [
    //         'https://testnet.era.zksync.dev',
    //         'https://zksync-era-testnet.blockpi.network/v1/rpc/public',
    //       ],
    //     },
    //     public: {
    //       http: [
    //         'https://testnet.era.zksync.dev',
    //         'https://zksync-era-testnet.blockpi.network/v1/rpc/public',
    //       ],
    //     },
    //   },
    // },
    // {
    //   ...polygonZkEvmTestnet,
    //   iconUrl: '/chains/0x44d.svg',
    //   scan_url: 'https://testnet-zkevm.polygonscan.com',
    //   hexId: '0x5a2',
    //   wrappedToken: '0x805278c50A3BeE7b3CfA2f704c6E23E06785fF5C',
    //   marketplace: '0xc7F6a48763b4C8d948e27fA74fe96802d90C7A09',
    //   treasuryAddress: '0x9bCd5003715C936051aBD41E00B175b1756C2D90',
    //   lendingContract: '0x7CDEe851050EbD22Dd7dDb6dfc97c73C5c36238f',
    // },
    {
      ...blastSepoliaTestnet,
      iconUrl: '/chains/0x13e31.svg',
      iconBackground: '#000',
      scan_url: 'https://sepolia.blastscan.io',
      hexId: '0xa0c71fd',
      wrappedToken: getAddress('0xa9CB302f9cF73eb87A6c2Eb947e468eD9bE8326F'),
      marketplace: getAddress('0x37a2065C4B8F0701fb551956A7364458FD8831D4'),
      treasuryAddress: getAddress('0x7743171843F2aA9a88408634648d4dFa3416f6AC'),
      lendingContract: getAddress('0xc8018D687667bF009e2aA92ed3453bBF21Fa021C'),
      uwRegistar: getAddress('0xe8ffa9b489c26b5422128ac8fe1d4a9e6dec10ca'),
      uwVault: getAddress('0xb1fdfa22f8e400d0e2db5b45e39dc3fc66e6769a'),
      uwManager: getAddress('0x66c240a50fb236d86e16bcc541b4513c7c5a721f'),
      uwSupport: true,
    },
  ],
  staging: [
    {
      ...bscTestnet,
      name: 'BSC Testnet',
      scan_url: 'https://testnet.bscscan.com',
      hexId: '0x61',
      wrappedToken: getAddress('0xEf347ABbAEf4a2306e6e4a35237E0f62232b9734'),
      marketplace: getAddress('0x0550f19A7Ed4FAC1a1f238bDb2422F8Db3823576'),
      treasuryAddress: getAddress('0xB0071cCcB3CB65a3B4Bf49BD995e318abB71E15A'),
      lendingContract: getAddress('0x832Cc3ED03Cd93C2EfCF28b84fbe94e5b4E99E3C'),
      uwRegistar: getAddress('0x8ad76139ad181f2e9190f093b40f27b2fa168592'),
      uwVault: getAddress('0x26b9c7edff8639833c7136e8a858328b2b974bd0'),
      uwManager: getAddress('0xeb7a372b362ea84ffbcfa0f1b9c972bc2fb5c5d8'),
      uwSupport: true,
    },
    // {
    //   ...sepolia,
    //   scan_url: 'https://sepolia.etherscan.io',
    //   hexId: '0xaa36a7',
    //   wrappedToken: '0x641063bA1cA491de22668b38Cf26Df57699B11b7',
    //   marketplace: '0x08C9104485222AA2cE79e47583084f16646112d7',
    //   treasuryAddress: '0x9bCd5003715C936051aBD41E00B175b1756C2D90',
    //   lendingContract: '0x7CDEe851050EbD22Dd7dDb6dfc97c73C5c36238f',
    // },
    // {
    //   ...zkSyncTestnet,
    //   iconUrl: '/chains/0x144.svg',
    //   scan_url: 'https://goerli.explorer.zksync.io',
    //   hexId: '0x118',
    //   wrappedToken: '0x20b28B1e4665FFf290650586ad76E977EAb90c5D',
    //   marketplace: '0x2Cb7c02f657D07769da693fEf5DfdB5552295cc0',
    //   treasuryAddress: '0x78111354c9886EA25bF241c77891f424Dcb87C1E',
    //   lendingContract: '0xD0806E43C2E6fb42dDF7E32479F89eAebd9B9872',
    //   rpcUrls: {
    //     default: {
    //       http: [
    //         'https://testnet.era.zksync.dev',
    //         'https://zksync-era-testnet.blockpi.network/v1/rpc/public',
    //       ],
    //     },
    //     public: {
    //       http: [
    //         'https://testnet.era.zksync.dev',
    //         'https://zksync-era-testnet.blockpi.network/v1/rpc/public',
    //       ],
    //     },
    //   },
    // },
    // {
    //   ...polygonZkEvmTestnet,
    //   iconUrl: '/chains/0x44d.svg',
    //   scan_url: 'https://testnet-zkevm.polygonscan.com',
    //   hexId: '0x5a2',
    //   wrappedToken: '0x805278c50A3BeE7b3CfA2f704c6E23E06785fF5C',
    //   marketplace: '0xc7F6a48763b4C8d948e27fA74fe96802d90C7A09',
    //   treasuryAddress: '0x9bCd5003715C936051aBD41E00B175b1756C2D90',
    //   lendingContract: '0x7CDEe851050EbD22Dd7dDb6dfc97c73C5c36238f',
    // },
    {
      ...blastSepoliaTestnet,
      iconUrl: '/chains/0x13e31.svg',
      iconBackground: '#000',
      scan_url: 'https://sepolia.blastscan.io',
      hexId: '0xa0c71fd',
      wrappedToken: getAddress('0xa9CB302f9cF73eb87A6c2Eb947e468eD9bE8326F'),
      marketplace: getAddress('0x37a2065C4B8F0701fb551956A7364458FD8831D4'),
      treasuryAddress: getAddress('0x7743171843F2aA9a88408634648d4dFa3416f6AC'),
      lendingContract: getAddress('0xc8018D687667bF009e2aA92ed3453bBF21Fa021C'),
      uwRegistar: getAddress('0xe8ffa9b489c26b5422128ac8fe1d4a9e6dec10ca'),
      uwVault: getAddress('0xb1fdfa22f8e400d0e2db5b45e39dc3fc66e6769a'),
      uwManager: getAddress('0x66c240a50fb236d86e16bcc541b4513c7c5a721f'),
      uwSupport: true,
    },
  ],
  production: [
    {
      ...bsc,
      scan_url: 'https://bscscan.com',
      hexId: '0x38',
      wrappedToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      marketplace: '0x4b17F5Ef7F6CcdD06105F0a260c0E39eC6376007',
      treasuryAddress: '0x9bCd5003715C936051aBD41E00B175b1756C2D90',
      lendingContract: '0x7CDEe851050EbD22Dd7dDb6dfc97c73C5c36238f',
      uwRegistar: getAddress('0x3011573a16a987011b3fbbd2af98619bdbd14d95'),
      uwVault: getAddress('0xd040c0391200b17b0808dd50bad84a1e951cf336'),
      uwManager: getAddress('0xc28e3946316b4a7725e4bb3cf31a096d972ddca7'),

      rpcUrls: {
        default: {
          http: ['https://bsc-dataseed1.binance.org/', 'https://bsc-dataseed2.binance.org/'],
        },
        public: {
          http: ['https://bsc-dataseed1.binance.org/', 'https://bsc-dataseed2.binance.org/'],
        },
      },
      uwSupport: true,
    },
    // {
    //   ...mainnet,
    //   scan_url: 'https://etherscan.io',
    //   hexId: '0x01',
    //   wrappedToken: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    //   marketplace: '0x4b17F5Ef7F6CcdD06105F0a260c0E39eC6376007',
    //   treasuryAddress: '0x9bCd5003715C936051aBD41E00B175b1756C2D90',
    //   lendingContract: '0x7CDEe851050EbD22Dd7dDb6dfc97c73C5c36238f',
    // },
    // {
    //   ...avalanche,
    //   scan_url: 'https://snowtrace.io',
    //   hexId: '0xa86a',
    //   wrappedToken: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    //   marketplace: '0x4b17F5Ef7F6CcdD06105F0a260c0E39eC6376007',
    //   treasuryAddress: '0x9bCd5003715C936051aBD41E00B175b1756C2D90',
    //   lendingContract: '0x7CDEe851050EbD22Dd7dDb6dfc97c73C5c36238f',
    // },
    {
      ...polygon,
      scan_url: 'https://polygonscan.com',
      hexId: '0x89',
      wrappedToken: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      marketplace: '0x4b17F5Ef7F6CcdD06105F0a260c0E39eC6376007',
      treasuryAddress: '0x9bCd5003715C936051aBD41E00B175b1756C2D90',
      lendingContract: '0x7CDEe851050EbD22Dd7dDb6dfc97c73C5c36238f',
      uwSupport: false,
    },
    // {
    //   ...zkSync,
    //   iconUrl: '/chains/0x144.svg',
    //   scan_url: 'https://explorer.zksync.io',
    //   hexId: '0x144',
    //   wrappedToken: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
    //   marketplace: '0xae8603ede0328b73d69e785566806F01c52f14ff',
    //   treasuryAddress: '0x7a8BaC1dF163f220e37167E607BdA97afDc52CE7',
    //   lendingContract: '0xd805a81840DA8Bb102562Bd6be576D6D53AC7352',
    // },
    // {
    //   ...polygonZkEvm,
    //   iconUrl: '/chains/0x44d.svg',
    //   scan_url: 'https://zkevm.polygonscan.com',
    //   hexId: '0x44d',
    //   wrappedToken: '0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9',
    //   marketplace: '0x4b17F5Ef7F6CcdD06105F0a260c0E39eC6376007',
    //   treasuryAddress: '0x9bCd5003715C936051aBD41E00B175b1756C2D90',
    //   lendingContract: '0x7CDEe851050EbD22Dd7dDb6dfc97c73C5c36238f',
    // },
    {
      ...blast,
      iconUrl: '/chains/0x13e31.svg',
      scan_url: 'https://blastscan.io',
      hexId: '0x13e31',
      wrappedToken: '0x4300000000000000000000000000000000000004',
      marketplace: '0xd4f09B5fd79BbB1e86fED718f31e1f94A4Bb8c29',
      treasuryAddress: '0xbfeC4848bDD4406Dc5f8786474546d9784317d07',
      lendingContract: '0x2865fCbA03174605722bC0E3a22bFF50BECcBBae',
      uwRegistar: getAddress('0x3963bc8dd117d343de2f8ef8a16238ce85e96bab'),
      uwVault: getAddress('0x3011573a16a987011b3fbbd2af98619bdbd14d95'),
      uwManager: getAddress('0x2943cf2b86b0bda9cce54af64df0cdc935610236'),
      uwSupport: true,
    },
  ],
};

const helpersObj = {
  development: {
    explorer: 'https://testnet.bscscan.com',
    noImage: '/images/no_image.png',
    transparent: '/images/gray.png',
    wbnb: '0xEf347ABbAEf4a2306e6e4a35237E0f62232b9734', // testnet
    weth: '0x641063bA1cA491de22668b38Cf26Df57699B11b7', // testnet
    marketplace: '0xE738e76661737DF0133A1c3A3593ff720a26c368', // testnet
    treasuryAddress: '0x9bCd5003715C936051aBD41E00B175b1756C2D90',
    chainId: '0x61', // testnet
    supportedChains: supportedChains.development,
    lending: {
      url: 'https://lending.test.bit5.com',
      ASSETS_TO_BORROW: [
        {
          address: '0xEf347ABbAEf4a2306e6e4a35237E0f62232b9734',
          value: 'WBNB',
          name: 'WBNB',
        },
      ],
    },
  },
  staging: {
    explorer: 'https://testnet.bscscan.com',
    noImage: '/images/no_image.png',
    transparent: '/images/gray.png',
    wbnb: '0xEf347ABbAEf4a2306e6e4a35237E0f62232b9734', // testnet
    weth: '0x641063bA1cA491de22668b38Cf26Df57699B11b7', // testnet
    marketplace: '0x08C9104485222AA2cE79e47583084f16646112d7', // testnet
    treasuryAddress: '0x9bCd5003715C936051aBD41E00B175b1756C2D90',
    chainId: '0x61', // testnet
    supportedChains: supportedChains.staging,
    lending: {
      url: 'https://lending.test.bit5.com',
      ASSETS_TO_BORROW: [
        {
          address: '0xEf347ABbAEf4a2306e6e4a35237E0f62232b9734',
          value: 'WBNB',
          name: 'WBNB',
        },
      ],
    },
  },
  production: {
    explorer: 'https://bscscan.com',
    noImage: '/images/no_image.png',
    transparent: '/images/gray.png',
    wbnb: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', // mainnet
    weth: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', // mainnet
    wavax: '0xAB8355Fb82773DBCbEf3134ce05CB33711e7B4C1', // mainnet
    marketplace: '0x4b17F5Ef7F6CcdD06105F0a260c0E39eC6376007', // mainnet
    treasuryAddress: '0x9bCd5003715C936051aBD41E00B175b1756C2D90',
    chainId: '0x38', // mainnet
    supportedChains: supportedChains.production,
    lending: {
      url: 'https://lending.bit5.com',
      ASSETS_TO_BORROW: [
        {
          address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
          value: 'WBNB',
          name: 'WBNB',
        },
      ],
    },
  },
};

export const helpers = helpersObj[process.env.NEXT_PUBLIC_ENV_VARIABLE];

export const IS_PROD = process.env.NEXT_PUBLIC_ENV_VARIABLE === 'production';
export const SWAP_WIDGET_URL = IS_PROD
  ? 'https://squadswap.com'
  : 'https://sstest.test.techapp.dev';
