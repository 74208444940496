import { getCollectionBySlugFetcher, getCollectionFetcher } from '../hooks/useGetCollection';
import { createContext } from 'react';
import { getItemActivitiesFetcher } from '../hooks/useGetItemActivities';
import { getItemAttributesFetcher } from '../hooks/useGetItemAttributes';
import { getBidsFetcher } from '../hooks/useGetBids';
import { getNftMetadataFetcher } from '../hooks/useGetNftMetadata';
import { getGlobalBidsBySlugFetcher, getGlobalBidsFetcher } from '../hooks/useGetGlobalBids';
import { getMintingFetcher } from '../hooks/useGetMinting';
import { getLendOffersFetcher } from '../hooks/useGetLendOffers';
import { getLendOrderFetcher } from '../hooks/useGetLendOrder';
import {
  getGlobalLendOffersBySlugFetcher,
  getGlobalLendOffersFetcher,
} from '../hooks/useGetGlobalLendOffers';
import { getActiveGlobalBidsFetcher } from '../hooks/useGetActiveGlobalBids';
import { getItemLookupFetcher } from '../hooks/useGetItemLookUp';
import { getTokenMintingFetcher } from '../hooks/useGetTokenMinting';

const fetchers = [
  {
    propsName: 'collection',
    fetcher: getCollectionFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection];
    },
    notFound: true,
  },
  {
    propsName: 'tokenMetadata',
    fetcher: getNftMetadataFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection, params.tokenId];
    },
  },
  {
    propsName: 'activities',
    fetcher: getItemActivitiesFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection, params.tokenId];
    },
  },
  {
    propsName: 'itemAttributes',
    fetcher: getItemAttributesFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection, params.tokenId];
    },
  },
  {
    propsName: 'offers',
    fetcher: getBidsFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection, params.tokenId];
    },
  },
  {
    propsName: 'lendOffers',
    fetcher: getLendOffersFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection, params.tokenId];
    },
  },
  {
    propsName: 'lendOrder',
    fetcher: getLendOrderFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection, params.tokenId];
    },
  },
  {
    propsName: 'globalBids',
    fetcher: getGlobalBidsFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection];
    },
  },
  {
    propsName: 'activeGlobalBids',
    fetcher: getActiveGlobalBidsFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection];
    },
  },
  {
    propsName: 'globalLendOffers',
    fetcher: getGlobalLendOffersFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection];
    },
  },
  {
    propsName: 'user',
    fetcher: (address) => {},
    getArgs: (context) => {
      const { params } = context;
      return [params.address];
    },
  },
  {
    propsName: 'minting',
    fetcher: getMintingFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection];
    },
  },
  {
    propsName: 'tokenMinting',
    fetcher: getTokenMintingFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.token];
    },
  },
  {
    propsName: 'itemLookup',
    fetcher: getItemLookupFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection];
    },
  },
  {
    propsName: 'collectionBySlug',
    fetcher: getCollectionBySlugFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection];
    },
    notFound: true,
  },
  {
    propsName: 'globalBidsBySlug',
    fetcher: getGlobalBidsBySlugFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection];
    },
  },
  {
    propsName: 'globalLendOffersBySlug',
    fetcher: getGlobalLendOffersBySlugFetcher,
    getArgs: (context) => {
      const { params } = context;
      return [params.collection];
    },
  },
];

export const ServerPropsContext = createContext();

export const filterServerSideProps = (propsList) => {
  return async (context) => {
    const filteredFetchers = fetchers.filter((f) => propsList.includes(f.propsName));
    const propsResults = await Promise.all(
      filteredFetchers.map((f) => {
        const { fetcher, getArgs, notFound } = f;
        return new Promise(async (resolve) => {
          try {
            if (
              typeof fetcher(...getArgs(context)).enabled === 'boolean' &&
              !fetcher(...getArgs(context)).enabled
            )
              return resolve({ propsName: f.propsName, result: null, notFound });
            resolve({
              propsName: f.propsName,
              result: await fetcher(...getArgs(context)).fetcher(),
            });
          } catch (e) {
            resolve({ propsName: f.propsName, result: null, notFound });
          }
        });
      }),
    );
    let notFound = false;
    const finalProps = {};
    propsList.forEach((l, i) => {
      const prop = propsResults.find((r) => r?.propsName === l);
      if (!prop?.result && prop.notFound) {
        notFound = true;
      } else
        finalProps[l.replace('BySlug', '')] = propsResults.find((r) => r?.propsName === l)?.result;
    });
    if (notFound) return { notFound: true };

    return {
      props: { serverProps: finalProps },
    };
  };
};
