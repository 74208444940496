import Link from 'next/link';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNetwork, useSignTypedData, useAccount } from 'wagmi';
import { switchNetwork } from 'wagmi/actions';
import { constants } from '../../helpers/constants';
import { closeModal } from '../../redux/counterSlice';
import { ModalContainer } from './modalContainer';
import { disconnect } from 'wagmi/actions';
import { acceptTermsMessage, dynamicDomain, types } from '../../lib/sign';
import axios from 'axios';

const MODAL_KEY = 'acceptTermsModal';
const AcceptTermsModal = () => {
  const { chain } = useNetwork();
  const { supportedChains } = constants.helpers;
  const dispatch = useDispatch();
  const { address } = useAccount();

  const { signTypedDataAsync } = useSignTypedData({
    account: address,
    domain: dynamicDomain(chain?.hexId),
    types: types,
    primaryType: 'AcceptTerms',
  });

  async function handleAcceptTerms() {
    if (chain?.unsupported) {
      await switchNetwork({ chainId: supportedChains[0].id });
    }
    const signature = await signTypedDataAsync({
      message: { M: acceptTermsMessage },
    });

    if (signature) {
      try {
        const data = (
          await axios.post(`${constants.api.url_new}/users/acceptTerms`, {
            signature,
            chainId: chain?.hexId,
            walletAddress: address,
          })
        ).data.data;
      } catch (err) {
        throw err;
      }
      dispatch(closeModal());
    }
  }
  async function handleCancelTerms() {
    await disconnect();
    dispatch(closeModal());
  }

  return (
    <ModalContainer close={false} modalKey={MODAL_KEY} title='Welcome to Bit5'>
      {/* <!-- Body --> */}
      <div className='modal-body p-1' />

      <div className='mx-5'>
        By connecting your wallet and using Bit5, you agree to our{' '}
        <span className='text-accent'>
          <Link href='https://docs.bit5.com/legal/terms-of-service' rel='noopener noreferrer'>
            <a target='_blank'>Terms of Service</a>
          </Link>
        </span>{' '}
        and{' '}
        <span className='text-accent'>
          <Link href='https://docs.bit5.com/legal/privacy-policy' rel='noopener noreferrer'>
            <a target='_blank'>Privacy Policy</a>
          </Link>
        </span>
        .
      </div>

      {/* <!-- end body --> */}

      <div className='modal-footer'>
        <div className='mx-3'>
          {chain?.unsupported ? (
            <button
              type='button'
              className='rounded-xl mb-2 bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark'
              onClick={() => switchNetwork({ chainId: supportedChains[0].id })}
            >
              Switch Network
            </button>
          ) : (
            <button
              type='button'
              className={`rounded-xl mb-2 bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark`}
              onClick={handleAcceptTerms}
            >
              Accept
            </button>
          )}
        </div>
        <div>
          <button
            type='button'
            className={`rounded-xl mb-2 bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark`}
            onClick={handleCancelTerms}
          >
            Cancel
          </button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default AcceptTermsModal;
