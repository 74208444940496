import React from 'react';
import Link from 'next/link';
import { footerMenuList, socialIcons } from '../data/footer_data';
import { TbBrandDiscord, TbBrandTelegram } from 'react-icons/tb';
import { FaXTwitter } from 'react-icons/fa6';
import { Bit5Logo } from './bit5Logo';
import { constants } from '../helpers/constants';
import { useTheme } from 'next-themes';
import { TradingViewDarkLogo, TradingViewLightLogo } from './TradingViewLogo';

const icons = [
  {
    id: 1,
    name: 'telegram',
    icon: (
      <TbBrandTelegram className='icon h-7 w-7 hover:fill-[#4267B2] dark:group-hover:fill-white' />
    ),
  },
  {
    id: 2,
    name: 'twitter',
    icon: <FaXTwitter className='icon h-7 w-7 hover:fill-[#1DA1F2] dark:group-hover:fill-white' />,
  },
  {
    id: 3,
    name: 'discord',
    icon: (
      <TbBrandDiscord className='icon h-7 w-7  hover:text-[#7289da] dark:group-hover:fill-white' />
    ),
  },
];

const Footer = () => {
  const { theme } = useTheme();
  const isDark = theme === 'dark';
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className='page-footer bg-white dark:bg-jacarta-900'>
        <div className='container'>
          <div className=' flex flex-col flex-wrap justify-between space-x-4 pt-12 pb-6 sm:flex-row sm:pt-24 sm:pb-12'>
            <div className='col-span-3 md:col-span-4'>
              {/* <!-- Logo --> */}
              <Link href='/' passHref>
                <a>
                  <Bit5Logo />
                </a>
              </Link>
              <p className='mb-12 mt-6 dark:text-jacarta-300'>
                {/*Create, sell and collect truly rare digital artworks.*/}
                Create. Collect. Connect.
              </p>

              {/* <!-- Socials --> */}
              <div className='mb-10 flex space-x-5'>
                {socialIcons.map((item, i) => {
                  const { id, href, text } = item;
                  return (
                    <Link href={href} key={i}>
                      <a target='_blank' rel='noopener noreferrer' className='group cursor-pointer'>
                        {icons.find((icon) => icon.name === text)?.icon}
                      </a>
                    </Link>
                  );
                })}
              </div>
              <div className='mb-7 ml-4 flex flex-wrap space-x-4 text-base dark:text-jacarta-400'>
                <Link href='https://t.me/Bit5Official/422'>
                  <a
                    className='hover:text-accent dark:hover:text-white'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Submit a bug
                  </a>
                </Link>
              </div>
            </div>

            {footerMenuList.map((single, i) => {
              return single.list ? (
                <div
                  className={`col-span-full mb-12 sm:col-span-3 md:col-span-2 ${single.diffClass}`}
                  key={i}
                >
                  <h3 className='mb-4 font-display text-sm text-jacarta-700 dark:text-white'>
                    {single.title}
                  </h3>
                  <ul className='flex flex-col space-y-1 dark:text-jacarta-300'>
                    {single.list.map((item, i) => {
                      const { id, href, text } = item;
                      return (
                        <li key={i}>
                          <Link href={href}>
                            <a
                              className='hover:text-accent dark:hover:text-white'
                              target={single.id === 99 ? '_blank' : ''}
                              {...(single.id === 99 && { rel: 'noopener noreferrer' })}
                            >
                              {text}
                            </a>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <></>
              );
            })}
          </div>
          <div className='-mt-14 flex flex-col items-start justify-end gap-2 sm:flex-row sm:items-center'>
            <Link href='/tradingview'>
              <a rel='noopener noreferrer' className='w-full max-w-[250px]'>
                {isDark ? <TradingViewDarkLogo /> : <TradingViewLightLogo />}
              </a>
            </Link>
            <a href='https://hacken.io/audits/bit5/' target='_blank' rel='noopener noreferrer'>
              <img
                src={`${constants.cdn.path}pi/hacken-badge.png`}
                alt=''
                className='aspect-[1032_/_400] w-full max-w-[250px]'
              />
            </a>
            <a
              href='https://www.quillaudits.com/leaderboard/bit5'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={`${constants.cdn.path}pi/quillaudits.png`}
                alt=''
                className='aspect-[962_/_400] w-full max-w-[250px]'
              />
            </a>
          </div>
          <div className='flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0'>
            <span className='text-sm dark:text-jacarta-400'>
              <span>© 2022-{new Date().getFullYear()} Bit5</span>
              {/*    <span>© {new Date().getFullYear()} Bit5 — Made by</span>
              <Link href="https://themeforest.net/user/ib-themes">
                <a className="hover:text-accent dark:hover:text-white">
                  {" "}
                    ib-themes
                </a>
              </Link>*/}
            </span>

            <ul className='flex flex-wrap space-x-4 text-sm dark:text-jacarta-400'>
              <li>
                <Link href='https://docs.bit5.com/resources/privacy-policy'>
                  <a
                    className='hover:text-accent dark:hover:text-white'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Privacy policy
                  </a>
                </Link>
              </li>
              <li>
                <Link href='https://docs.bit5.com/resources/terms-of-service'>
                  <a
                    className='hover:text-accent dark:hover:text-white'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Terms Of Service
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
