export const parseErc404TokenId = (tokenId, prefix) => {
  try {
    return (BigInt(prefix) + BigInt(tokenId)).toString();
  } catch (err) {
    return '';
  }
};

export const formatErc404TokenId = (tokenId, prefix) => {
  try {
    return (BigInt(tokenId) - BigInt(prefix)).toString();
  } catch (err) {
    return '';
  }
};
