import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { ethers } from 'ethers';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export function getGlobalLendOffersFetcher(collectionAddress, refreshDate) {
  let config;

  if (!ethers.utils.isAddress(collectionAddress)) {
    config = {};
  } else {
    config = {
      url: `${constants.api.url_new}/orders/getGlobalLendOffers`,
      method: constants.httpMethods.get,
      params: { collectionAddress, refreshDate },
    };
  }

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return { config, fetcher };
}

export default function useGetGlobalLendOffers(collectionAddress) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, fetcher } = getGlobalLendOffersFetcher(collectionAddress, refreshDate);
  return useAdaptor(axios.getUri(config), fetcher);
}

export function getGlobalLendOffersBySlugFetcher(slug, refreshDate) {
  const config = {
    url: `${constants.api.url_new}/orders/getGlobalLendOffersBySlug`,
    method: constants.httpMethods.get,
    params: { slug, refreshDate },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return { config: slug ? config : {}, fetcher };
}
