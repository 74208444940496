import { NumberInput, isValidNumber } from './numberInput';
import React from 'react';

export const PriceRange = ({ value = {}, onChange }) => {
  return (
    <div className={'mt-4 flex w-full flex-row gap-3 max-[860px]:flex-col'}>
      <NumberInput
        decimal
        maxLength={6}
        label={'Min Price'}
        value={value.min}
        onChange={(val) => {
          // if (value.max && Number(val) > value.max)
          //   return onChange({ ...value, min: value.max, max: val });
          if (
            ((!val || val == 0) && !isValidNumber({ value: val, decimal: true })) ||
            (val && !isValidNumber({ value: val, decimal: true }))
          )
            return onChange({ ...value, min: null });
          onChange({ ...value, min: val });
        }}
        className={'w-full'}
      />
      <NumberInput
        decimal
        maxLength={6}
        label={'Max Price'}
        value={value.max}
        onChange={(val) => {
          if (
            ((!val || val == 0) && !isValidNumber({ value: val, maxLength: 6, decimal: true })) ||
            (val && !isValidNumber({ value: val, decimal: true }))
          )
            return onChange({ ...value, max: null });
          onChange({ ...value, max: val });
        }}
        className={'w-full'}
      />
    </div>
  );
};

export const RarityRange = ({ value = {}, onChange, raritySetsEnabled }) => {
  const rarityLabel = raritySetsEnabled ? 'Rarity + Set' : 'Rarity';
  return (
    <div className={'mt-4 flex w-full flex-row gap-3 max-[860px]:flex-col'}>
      <NumberInput
        maxLength={6}
        label={`Min ${rarityLabel}`}
        value={value.min}
        onChange={(val) => {
          // if (value.max && Number(val) > value.max)
          //   return onChange({ ...value, min: value.max, max: val });
          if (!String(val).length) return onChange({ ...value, min: null });
          onChange({ ...value, min: val });
        }}
        className={'w-full'}
      />
      <NumberInput
        maxLength={6}
        label={`Max ${rarityLabel}`}
        value={value.max}
        onChange={(val) => {
          if (!String(val).length) return onChange({ ...value, max: null });
          onChange({ ...value, max: val });
        }}
        className={'w-full'}
      />
    </div>
  );
};
