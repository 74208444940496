import { getAddress } from 'viem';
import { getCdnUrl } from '../lib/cdnUrl';

export const getCollectionPath = (chain, address) => {
  return `/collection/${chain}/${address}`;
};

export const getItemPath = (chain, address, itemId) => {
  return `/item/${chain}/${address}/${itemId}`;
};

export const getItemImagePath = (item, imageSize = 'detail') => {
  const isMoonpetsItem =
    item?.collectionAddress === getAddress('0xE32aE22Ec60E21980247B4bDAA16E9AEa265F919');
  const itemImage = isMoonpetsItem
    ? `https://moonpets.com/api/nftThumbnailimage/${item.itemId}`
    : item?.imageFtpPath
    ? getCdnUrl(item?.imageFtpPath, imageSize, item?.updatedAt)
    : item?.metadata?.image;
  return itemImage;
};
