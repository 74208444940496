import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useWalletClient, useSignMessage } from 'wagmi';
import { getContract } from 'wagmi/actions';
import uwManagerABI from '../../helpers/uwManagerABI';
import { closeModal } from '../../redux/counterSlice';
import { ModalContainer } from './modalContainer';
import { errorToast } from '../toast';
import { useCatchTxError } from '../../hooks/useCatchTxError';
import { useGetChain } from '../../hooks/useGetChain';
import { Button, SwitchNetworkButton } from '../Button';
import { Box, Paper, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import axios from 'axios';
import { constants } from '../../helpers/constants';
import { formatEther } from 'viem';

const MODAL_KEY = 'uwCollWrapModal';
const UwCollWrapModal = () => {
  const dispatch = useDispatch();
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient();
  const { connector: activeConnector } = useAccount();
  const { fetchWithCatchTxError } = useCatchTxError();
  const { modal } = useSelector((state) => state.counter);
  const {
    chain: chainId,
    collectionAddress,
    collectionName,
    creatorAddress,
    description,
    telegramAccount,
  } = modal?.data ?? {};
  const [activeStep, setActiveStep] = useState(0);
  const { signMessageAsync } = useSignMessage();
  const { chain, isChainActive } = useGetChain(chainId);
  const [collectionFee, setCollectionFee] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const uwManagerContract = getContract({
    address: chain?.uwManager,
    abi: uwManagerABI,
    walletClient,
  });

  const steps = [
    {
      label: 'Sign a message',
      description: `We need your signature to verify information regarding to the collection.`,
    },
    {
      label: 'Wrap collection',
      description: 'Your collection is ready to be wrapped.',
    },
  ];

  const handleSignInfo = async () => {
    if (!modal?.data?.collectionAddress) {
      return errorToast('No collection address provided.');
    }
    if (!modal?.data?.collectionName) {
      return errorToast('No collection name provided.');
    }
    // if (!modal?.data?.creatorAddress) {
    //   return errorToast('No creator address provided.');
    // }
    if (!modal?.data?.description) {
      return errorToast('No description provided.');
    }
    if (!address) {
      return errorToast('Please connect your wallet ');
    }

    try {
      const messageStuff = {
        collectionAddress,
        collectionName,
        creatorAddress,
        telegram: telegramAccount,
        description,
      };
      const messageJSON = JSON.stringify(messageStuff);

      const signature = await signMessageAsync({ message: messageJSON });

      const res = (
        await axios.post(`${constants.api.url_new}/collections/wrapCollection`, {
          walletAddress: address,
          data: messageJSON,
          signature: signature,
        })
      ).data;

      if (!res) throw new Error('Something went wrong on the server side. Try again.');

      handleNext();
    } catch (err) {
      errorToast(err?.response?.data?.message ?? 'err:' + err);
    }
  };

  const handleCollectionWrap = async () => {
    if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
    // if modal data array has different collection addresses, we need to wrap them separately
    // Throw error toast if this is the case lol

    if (!modal?.data?.collectionAddress) {
      return errorToast('No collection address provided.');
    }

    try {
      const collectionFee = await uwManagerContract.read.getCollectionFee();
      await fetchWithCatchTxError({
        callTx: () =>
          uwManagerContract.write.wrapCollection([collectionAddress], {
            value: collectionFee,
          }),
        chainId,
        toastMessage: 'Wrapping Successful!',
      });
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    }
  };

  useEffect(() => {
    const getCollectionFee = async () => {
      try {
        const fee = await uwManagerContract.read.getCollectionFee();
        setCollectionFee(fee);
      } catch (err) {
        console.log('Error getting collection fee: ', err);
      }
    };

    if (uwManagerContract) {
      getCollectionFee();
    }
  }, [chain, address]);

  useEffect(() => {
    const handleConnectorUpdate = ({ account, chain }) => {
      if (account) {
        dispatch(closeModal());
      }
    };

    if (activeConnector) {
      activeConnector.on('change', handleConnectorUpdate);
    }

    return () => activeConnector?.off('change', handleConnectorUpdate);
  }, [activeConnector]);

  return (
    <ModalContainer modalKey={MODAL_KEY} title='Wrap Collection'>
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        <div className='mb-2 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            {modal?.data?.name ?? modal?.data?.metadata?.name ?? ''}
          </span>
        </div>
        {isChainActive ? (
          <Box sx={{ maxWidth: 400 }}>
            {collectionFee ? (
              <div className='alert-info mb-2 rounded-lg px-2 py-1.5 text-center text-sm'>
                <>
                  Enabling fee for wrapping a collection is {formatEther(collectionFee)}{' '}
                  {chain.nativeCurrency.symbol}.
                </>
              </div>
            ) : null}
            <Stepper activeStep={activeStep} orientation='vertical' classes={{ root: '' }}>
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === 2 ? <Typography variant='caption'>Last step</Typography> : null
                    }
                    StepIconProps={{ classes: { root: '!text-accent dark:!text-accent-dark' } }}
                    classes={{
                      label: '!text-jacarta-700 dark:!text-white',
                    }}
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    <Typography>{step.description}</Typography>
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant='contained'
                          onClick={
                            index === steps.length - 1 ? handleCollectionWrap : handleSignInfo
                          }
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === steps.length - 1 ? 'Wrap collection' : 'Sign message'}
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>All steps completed - you&apos;re finished</Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )}
          </Box>
        ) : (
          <div className='flex items-center justify-center'>
            <SwitchNetworkButton chainId={chain.id} />
          </div>
        )}
      </div>
      {/* <!-- end body --> */}
    </ModalContainer>
  );
};

export default UwCollWrapModal;
