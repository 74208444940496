import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useWalletClient, usePublicClient } from 'wagmi';
import { getContract } from '@wagmi/core';
import abi from '../../helpers/abi';
import { cancelAllOrders } from '../../helpers/blockchainOperations';
import { normalizeEther } from '../../helpers/digits';
import useGetOrder from '../../hooks/useGetOrder';
import { ChainIcon } from '../Icon';
import { getTokenName } from '../../helpers/getChainName';
import useGetTokenPrice from '../../hooks/useGetTokenPrice';
import { ModalContainer } from './modalContainer';
import { ModalItemDetails } from './modalComponents/modalItemDetails';
import { errorToast } from '../toast';
import { useCatchTxError } from '../../hooks/useCatchTxError';
import { useGetChain } from '../../hooks/useGetChain';
import { Button, SwitchNetworkButton } from '../Button';

const MODAL_KEY = 'cancelModal';
const CancelModal = () => {
  const { fetchWithCatchTxError } = useCatchTxError();
  const { modal } = useSelector((state) => state.counter);
  const defaultText = 'Cancel Offer';
  const [buttonText, setButtonText] = useState(defaultText);
  const { chain: chainId, id, isBulkBid, item: fullItem, kind } = modal?.data || {};
  const { chain, isChainActive } = useGetChain(chainId);
  // const item = modal?.data?.other;
  const isGlobal = modal?.data?.isGlobal;
  const collection = modal?.data?.collection;
  /*   const listing = useGetListing({
    collectionAddress: item?.collectionAddress,
    itemId: item?.itemId,
  }).data; */
  const isListing = String(kind) === '1';
  /*   const signature =
    !isListing || item?.collection?.contractType === 'ERC1155'
      ? isBulkBid
        ? ''
        : modal?.data?.signature
      : listing?.signature;
 */
  const signer = useWalletClient();
  const publicClient = usePublicClient();
  const order = useGetOrder(id, kind, chainId)?.data;

  const bulkBids =
    order?.bulkSignature !== undefined && order?.bulkSignature !== null ? [order] : [];

  const singleBids =
    order?.bulkSignature === undefined || order?.bulkSignature === null ? [order] : [];

  const marketplaceContract = getContract({
    address: chain?.marketplace,
    abi,
    publicClient,
    walletClient: signer.data,
  });
  const { usdPrice: tokenPrice } = useGetTokenPrice(chainId);

  const handleCancelOrder = async () => {
    if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
    try {
      setButtonText('Canceling the offer...');
      const contractFn = await cancelAllOrders(singleBids, bulkBids, chain);
      await fetchWithCatchTxError({
        callTx: contractFn,
        chainId,
        toastMessage: 'Offer cancelled!',
      });
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setButtonText(defaultText);
    }
  };

  const isButtonDisabled = buttonText !== defaultText;

  return (
    <ModalContainer modalKey={MODAL_KEY} title='Cancel Offer'>
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        <ModalItemDetails item={modal?.data?.other} collection={collection} isGlobal={isGlobal} />
        <div className='relative flex border-jacarta-100 pb-4 dark:border-jacarta-600'>
          <div className='mt-2 w-full'>
            <div className='flex w-full items-center'>
              {/* <div className='font-semibold'>Current Listing Price:</div> */}
              <div className='font-semibold'>
                {isListing ? 'Current Listing Price:' : 'Current Bid:'}
              </div>
              <div className='ml-auto flex w-fit space-x-1'>
                <span className='ml-auto flex items-center whitespace-nowrap'>
                  <ChainIcon
                    width={14}
                    name={chainId}
                    tooltip={getTokenName(chain.id)}
                    sideGap={true}
                  />
                  <span className='text-sm font-medium tracking-tight text-green'>
                    {normalizeEther(order?.price)}
                  </span>
                </span>
                <div className='text-sm leading-7 dark:text-jacarta-300'>
                  ~ ${normalizeEther(tokenPrice * normalizeEther(order?.price))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Terms --> */}
      </div>
      {/* <!-- end body --> */}

      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {!isChainActive ? (
            <SwitchNetworkButton chainId={chain.id} />
          ) : (
            <Button
              key={'cancel_button'}
              onClick={handleCancelOrder}
              disabled={isButtonDisabled}
              text={buttonText}
              data-testid='cancelOffer'
            />
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default CancelModal;
