import React from 'react';

const HorizontalFilterContainer = ({ children }) => {
  return (
    <div className='z-10 mb-2 flex flex-wrap items-start justify-between md:pb-2 md:pt-4  '>
      {' '}
      {/*md:sticky md:top-[88px]*/}
      {/*<JsonStringify object={filterObject}/>*/}
      <ul className='flex w-full flex-wrap items-center'>{children}</ul>
    </div>
  );
};

export default HorizontalFilterContainer;
