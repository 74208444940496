import React, { useRef } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'tippy.js/dist/tippy.css';
import { constants } from '../../helpers/constants';
import { getItemPath, getCollectionPath } from '../../helpers/path';
import { getCdnUrl } from '../../lib/cdnUrl';
import NFTItemCard from '../categories/nftItemCard';

export default function BidsCarousel({ data }) {
  const swiperRef = useRef();

  return (
    <>
      <Swiper
        ref={swiperRef}
        modules={[Autoplay, Navigation, Pagination, Scrollbar]}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        spaceBetween={30}
        slidesPerView='auto'
        loop={true}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          565: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
          1100: {
            slidesPerView: 4,
          },
        }}
        className=' card-slider-4-columns !py-5'
      >
        {data?.map((obj, index) => {
          // let { item, activity } = obj;
          // const { collections } = activity;
          const { activity } = obj;
          let item = activity?.item;
          const { collection } = item;
          if (!item) return;

          if (typeof item?.metadata === 'string') {
            item.metadata = JSON.parse(item?.metadata);
            item.metadata.image = item?.imageFtpPath
              ? constants.cdn.path +
                item?.imageFtpPath +
                constants.cdn.detail +
                constants.cdn.version
              : item?.metadata?.image
              ? item?.metadata?.image
              : constants.helpers.noImage;
          }
          if (collection && activity.to === collection.address) {
            item.metadata = {
              image: getCdnUrl(collection.profilePhotoPath),
              name: collection.name,
            };
          }
          const link =
            activity.to === collection?.address
              ? getCollectionPath(item?.chain, item?.collectionAddress)
              : getItemPath(item?.chain, item?.collectionAddress, item?.itemId);
          return (
            <SwiperSlide className='text-white' key={'bidsC' + item?.metadata?.name + index}>
              <NFTItemCard item={item} itemOwner={item?.itemowner?.[0]} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <!-- Slider Navigation --> */}
      <div
        onClick={() => swiperRef?.current?.swiper?.slidePrev()}
        className='bids-swiper-button-prev swiper-button-prev group absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer select-none items-center justify-center rounded-full bg-white p-3 text-xl text-jacarta-700 shadow-white-volume after:hidden sm:!-left-6'
      >
        <MdKeyboardArrowLeft />
      </div>
      <div
        onClick={() => swiperRef?.current?.swiper?.slideNext()}
        className='bids-swiper-button-next swiper-button-next group absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer select-none items-center justify-center rounded-full bg-white p-3 text-xl text-jacarta-700 shadow-white-volume after:hidden sm:!-right-6'
      >
        <MdKeyboardArrowRight />
      </div>
    </>
  );
}
