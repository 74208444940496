import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useRouter } from 'next/router';

const MINTING_LS_KEY = 'minting_notification';

const MintingWarning = () => {
  const router = useRouter();
  const [showNotificationBar, setShowNotificationBar] = useState(false);
  const [collectionAddress, setCollectionAddress] = useState(null);
  const [notificationBarText, setNotificationBarText] = useState(null);

  const onClick = (e) => {
    localStorage.setItem(
      MINTING_LS_KEY,
      JSON.stringify({
        [collectionAddress]: true,
      }),
    );
    setShowNotificationBar(false);
  };

  const getNotification = async () => {
    if (router.asPath.indexOf('/nft/minting') > -1) return setShowNotificationBar(false);
    const response = await fetch('/api/minting/getActiveMintingNotification');
    const responseJson = await response.json();
    if (responseJson?.status === 'success' && responseJson?.data) {
      const { collectionAddress, notificationBarText } = responseJson.data;
      setCollectionAddress(collectionAddress);
      setNotificationBarText(notificationBarText);
      const mintingLs = localStorage.getItem(MINTING_LS_KEY);
      if (mintingLs) {
        const mintingLsJson = JSON.parse(mintingLs);
        if (!mintingLsJson[collectionAddress]) {
          setShowNotificationBar(true);
        } else {
          setShowNotificationBar(false);
        }
      } else {
        setShowNotificationBar(true);
      }
    }
  };

  useEffect(() => {
    getNotification();
  }, [router.asPath]);

  if (!showNotificationBar) {
    return null;
  }

  return (
    <section
      className='relative flex items-center justify-center py-3 text-[#fff4f4]'
      style={{
        background: 'linear-gradient(to right, #FF1D78, #9F04D6)',
      }}
    >
      <Link href={`/nft/minting/${collectionAddress}`}>
        <a className='ml-2 font-bold underline'>{notificationBarText || 'Minting is now live!'}</a>
      </Link>
      <MdClose
        className='absolute right-5 cursor-pointer transition-all hover:rotate-90'
        size={24}
        onClick={onClick}
      />
    </section>
  );
};

export default MintingWarning;
