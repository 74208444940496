import { Tooltip } from '@mui/material';
import { useState } from 'react';

const RarityBadge = ({ item }) => {
  const [isHovered, setIsHovered] = useState(false);
  const itemCount = item?.collection?.collectionmetrics?.itemCount || 0;
  const rarityRatio =
    ((item?.collection?.raritySetsEnabled && item?.rarityPlusSetRank
      ? item?.rarityPlusSetRank
      : item?.rarityRank) /
      itemCount) *
    100;

  if (!item) return null;

  return (
    <Tooltip
      title={
        <div>
          <div className='flex w-full'>Rarity Score: {item?.rarityRank}</div>
          {item?.collection?.raritySetsEnabled && item.rarityPlusSetRank ? (
            <div className='flex'>Rarity Score + Sets: {item.rarityPlusSetRank}</div>
          ) : null}
        </div>
      }
    >
      <div
        style={{
          filter: `grayscale(${rarityRatio}%)`,
          background: isHovered
            ? 'linear-gradient(to right, #FF1D78, #9F04D6)'
            : 'linear-gradient(to right, rgba(255, 29, 120, 0.5), rgba(159, 4, 214, 0.5))',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`${
          item?.rarityRank ? '' : 'hidden'
        } via-red-500 absolute left-3 top-3 z-[1] flex min-w-[50px] cursor-pointer items-center justify-between rounded-lg border-jacarta-100  px-2 py-1 text-white transition-all group-hover/price:bg-accent dark:text-[#FFF]`}
      >
        {item?.collection?.raritySetsEnabled && item.rarityPlusSetRank ? (
          <span className='ml-[2px] font-display text-sm'>
            <span>RS + S</span> <span className='ml-2'>{item?.rarityPlusSetRank}</span>
          </span>
        ) : (
          <span className='ml-[2px] font-display text-sm'>
            <span>RS</span> <span className='ml-2'>{item?.rarityRank}</span>
          </span>
        )}
      </div>
    </Tooltip>
  );
};

export default RarityBadge;
