import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useWalletClient } from 'wagmi';
import { getContract } from 'wagmi/actions';
import abi from '../../helpers/abi';
import { cancelAllOrders } from '../../helpers/blockchainOperations';
import { normalizeEther } from '../../helpers/digits';
import { closeModal } from '../../redux/counterSlice';
import { ChainIcon } from '../Icon';
import { getTokenName } from '../../helpers/getChainName';
import useGetTokenPrice from '../../hooks/useGetTokenPrice';
import { ModalContainer } from './modalContainer';
import { ModalItemDetails } from './modalComponents/modalItemDetails';
import useGetListing from '../../hooks/useGetListing';
import { errorToast } from '../toast';
import { useCatchTxError } from '../../hooks/useCatchTxError';
import { useGetChain } from '../../hooks/useGetChain';
import { Button, SwitchNetworkButton } from '../Button';

const MODAL_KEY = 'cancelSellModal';
const CancelSellModal = () => {
  const { fetchWithCatchTxError } = useCatchTxError();
  const dispatch = useDispatch();
  const { data: walletClient } = useWalletClient();
  const { address } = useAccount();
  const { modal } = useSelector((state) => state.counter);
  const defaultButtonText = 'Cancel Sell';
  const [buttonText, setButtonText] = useState(defaultButtonText);
  const { chain: chainId, collection, other: item, signature: sig } = modal?.data ?? {};
  const { chain, isChainActive } = useGetChain(chainId);
  const listings = useGetListing({
    collectionAddress: item?.collectionAddress,
    itemId: item?.itemId,
    findMany: true,
    walletAddress: address,
  }).data;
  const listing = sig ? listings?.find((l) => l?.signature === sig) : listings?.[0];

  const bulkListings = listings?.filter(
    (listing) => listing.bulkSignature !== undefined && listing.bulkSignature !== null,
  );
  const singleListings = listings?.filter(
    (listing) => listing.bulkSignature === undefined || listing.bulkSignature === null,
  );

  const marketplaceContract = getContract({
    address: chain?.marketplace,
    abi,
    walletClient,
  });
  const { usdPrice: tokenPrice } = useGetTokenPrice(chainId);

  const handleCancelOrders = async () => {
    if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
    try {
      setButtonText('Canceling the listing...');
      if (bulkListings.length > 0 || singleListings.length > 0) {
        setButtonText('Canceling the old sell...');
        const contractFn = await cancelAllOrders(singleListings, bulkListings, chain);
        const receipt = await fetchWithCatchTxError({
          callTx: contractFn,
          chainId: chain.id,
          toastMessage: 'Listing cancelled!',
          keepModalOpen: true,
        });
        if (!receipt) return;
        /* const tx = await cancelAllOrders(
          marketplaceContract,
          setButtonText,
          singleListings,
          bulkListings,
        );
        toast.success(
          () => (
            <span>
              Offer cancelled! Here is your{' '}
              <Link href={`${chain?.scan_url}/tx/${tx}`}>
                <a target='_blank' rel='noopener noreferrer'>
                  <b>transaction.</b>
                </a>
              </Link>
            </span>
          ),
          { duration: 2500 },
        ); */
      }
      dispatch(closeModal());
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setButtonText(defaultButtonText);
    }
  };

  const isButtonDisabled = buttonText !== defaultButtonText;

  return (
    <ModalContainer modalKey={MODAL_KEY} title='Cancel Sell'>
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        <ModalItemDetails item={item} collection={collection} />
        <div className='relative flex border-jacarta-100 pb-4 dark:border-jacarta-600'>
          <div className='mt-2 w-full'>
            <div className='flex w-full items-center'>
              <div className='font-semibold'>Current Listing Price:</div>
              <div className='ml-auto flex w-fit space-x-1'>
                <span className='ml-auto flex items-center whitespace-nowrap'>
                  <ChainIcon
                    width={14}
                    name={item?.chain}
                    tooltip={getTokenName(chain.id)}
                    sideGap={true}
                  />
                  <span className='text-sm font-medium tracking-tight text-green'>
                    {normalizeEther(listings?.[0]?.price)}
                  </span>
                </span>
                <div className='text-sm leading-7 dark:text-jacarta-300'>
                  ~ ${normalizeEther(tokenPrice * normalizeEther(listings?.[0]?.price))}
                </div>
              </div>
            </div>
            {sig && listing && (
              <div className='flex w-full items-center'>
                <div className='font-semibold'>Listing Price to Be Cancelled:</div>
                <div className='ml-auto flex w-fit space-x-1'>
                  <span className='ml-auto flex items-center whitespace-nowrap'>
                    <ChainIcon
                      width={14}
                      name={item?.chain}
                      tooltip={getTokenName(chain.id)}
                      sideGap={true}
                    />
                    <span className='text-sm font-medium tracking-tight text-green'>
                      {normalizeEther(listing?.price)}
                    </span>
                  </span>
                  <div className='text-sm leading-7 dark:text-jacarta-300'>
                    ~ ${normalizeEther(tokenPrice * normalizeEther(listing?.price))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- end body --> */}

      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {!isChainActive ? (
            <SwitchNetworkButton chainId={chain.id} />
          ) : (
            <Button
              key={'cancel_sell_button'}
              onClick={handleCancelOrders}
              disabled={isButtonDisabled}
              text={buttonText}
              data-testid='cancelSellModal'
            />
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default CancelSellModal;
