import { useEffect, useState } from 'react';
import { getCdnUrl } from '../../../lib/cdnUrl';
import { useSearchDebounce } from '../../../hooks/useSearchDebounce';
import { ImageFixedAO } from '../../Image_fixed_aspect_ratio';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import useGetOwnedNftsByWallet from '../../../hooks/useGetOwnedNftsByWallet';
import { formatErc404TokenId } from '../../../helpers/erc404';

export const columns = [
  {
    id: 'image',
    label: 'NFT',
    minWidth: 60,
  },
  {
    id: 'name',
    label: null,
    minWidth: 125,
  },
  {
    id: 'checkbox',
    label: null,
    minWidth: 25,
  },
];

export const SelectNfts = ({
  collectionAddress,
  walletAddress,
  amount,
  setNfts,
  itemId,
  hideSelect = true,
  traitFilter,
  rarityFilter,
  collectionInfo,
}) => {
  if (typeof amount === 'string') amount = Number(amount);
  const [selectedNFTs, setSelectedNFTs] = useState(itemId ? [itemId] : []);
  const [search, setSearch] = useState(
    collectionInfo?.contractType === 'ERC404' && itemId
      ? formatErc404TokenId(itemId, collectionInfo?.itemIdPrefix)
      : itemId
      ? itemId.toString()
      : '',
  );
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useSearchDebounce(500, search);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const { data, isLoading } =
    useGetOwnedNftsByWallet({
      collectionAddress,
      walletAddress,
      traitFilter,
      rarityFilter,
      limit,
      page,
      search:
        collectionInfo?.contractType === 'ERC404' && searchText
          ? formatErc404TokenId(searchText, collectionInfo?.itemIdPrefix)
          : searchText,
    }) || {};

  const { results: userNfts, totalCount, totalPages } = data || {};

  const rows = userNfts?.map((item, index) => {
    if (typeof item?.metadata === 'string') item.metadata = JSON.parse(item?.metadata);

    const thumbnail =
      collectionAddress === '0xE32aE22Ec60E21980247B4bDAA16E9AEa265F919'
        ? `https://moonpets.com/api/nftThumbnailimage/${item.itemId}`
        : getCdnUrl(item?.imageFtpPath, 'thumbnail', item?.updatedAt);

    const itemId = String(item?.itemId);

    return {
      id: item?.itemId,
      image: {
        minWidth: 50,
        children: <ImageFixedAO image={thumbnail} className={'w-full'} bottomRounded />,
      },
      name: {
        minWidth: 125,
        children: item?.name || item?.metadata?.name || `#${itemId}`,
      },
      checkbox: {
        minWidth: 25,
        children: (
          <input
            type={amount === 1 ? 'radio' : 'checkbox'}
            id={`checkbox-${itemId}`}
            name={`checkbox-${itemId}`}
            className={`ml-4 h-5 w-5 cursor-pointer self-start border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600 ${
              amount === 1 ? 'rounded-full' : 'rounded'
            }`}
            checked={selectedNFTs.includes(itemId)}
            onChange={() => {
              if (amount === 1) return setSelectedNFTs([itemId]);

              if (amount === selectedNFTs.length && !selectedNFTs.includes(itemId)) return;

              if (selectedNFTs.includes(itemId)) {
                setSelectedNFTs(selectedNFTs.filter((id) => id != itemId));
              } else {
                setSelectedNFTs([...selectedNFTs, itemId]);
              }
            }}
          />
        ),
      },
    };
  });

  useEffect(() => {
    if (setNfts) setNfts(selectedNFTs);
  }, [selectedNFTs]);

  return (
    <div>
      {rarityFilter || traitFilter
        ? `Your NFTs in this collection (Filtered):`
        : 'Your NFTs in this collection:'}

      {rarityFilter && (
        <>
          {' '}
          <br />
          {`Rarity: ${rarityFilter.min} - ${rarityFilter.max}`}
        </>
      )}
      <div className='flex items-center py-2'>
        <input
          type='text'
          placeholder='Search by Token ID'
          className='h-10 w-full rounded border-jacarta-200 px-3 text-base placeholder-jacarta-400 focus:border-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600'
          value={search}
          autoFocus
          onChange={(e) => {
            if (e.target.value.length > 6) return;
            setSearch(e.target.value);
            setSearchText(e.target.value);
          }}
          data-testid='searchTokenId'
        />
      </div>
      <div className='max-h-[450px] overflow-auto'>
        <TableContainer>
          <Table stickyHeader aria-label='sticky table'>
            <TableBody>
              {!isLoading ? (
                rows &&
                rows.length > 0 &&
                rows.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={row.id}
                      data-testid='selectedNfts'
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        const { children } = value;

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            classes={{
                              root: 'dark:border-jacarta-100 border-jacarta-100 dark:text-white text-jacarta-700 dark:bg-jacarta-600 bg-jacarta-50',
                            }}
                            style={{ minWidth: value.minWidth }}
                          >
                            {children}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <div className='flex w-full items-center justify-center'>
                  <CircularProgress className='m-5' />
                </div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          classes={{
            root: 'dark:!bg-jacarta-700 !bg-light-base dark:!text-jacarta-100 !text-jacarta-700 flex flex-row-reverse',
            ...(hideSelect && {
              select: '!hidden',
              selectRoot: '!hidden',
              selectIcon: '!hidden',
              selectLabel: '!hidden',
            }),
          }}
          component='div'
          count={totalCount || 0}
          page={page - 1}
          onPageChange={handleChangePage}
          backIconButtonProps={{
            classes: {
              root: `dark:!bg-jacarta-700 !bg-light-base dark:!text-jacarta-100 !text-jacarta-700 ${
                !isLoading && (!rows?.length || page === 1) ? '!hidden' : ''
              } ${isLoading ? '!hidden' : ''}`,
            },
          }}
          nextIconButtonProps={{
            classes: {
              root: `dark:!bg-jacarta-700 !bg-light-base dark:!text-jacarta-100 !text-jacarta-700 ${
                !isLoading && (!rows?.length || rows?.length < 10 || page === totalPages)
                  ? '!hidden'
                  : ''
              } ${isLoading ? '!hidden' : ''}`,
            },
          }}
          rowsPerPage={10}
          labelRowsPerPage={'NFTs per page'}
        />
      </div>
    </div>
  );
};
