/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { constants } from '../../helpers/constants';
import { normalizeEther, priceFormatter } from '../../helpers/digits';
import { getCollectionPath } from '../../helpers/path';
import useGetTrendingCollections from '../../hooks/useGetTrendingCollections';
import { ChainIcon } from '../Icon';
import { getTokenName } from '../../helpers/getChainName';
import { TopCollectionsSkeleton } from '../Skelaton';
import { getCdnUrl } from '../../lib/cdnUrl';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';

const Top_collection = () => {
  const [data, setData] = useState();
  const trendingCollections = useGetTrendingCollections();

  useEffect(() => {
    if (trendingCollections.data !== undefined) {
      setData(trendingCollections.data);
    }
  }, [trendingCollections]);
  return (
    <div>
      <section className='relative sm:pt-4'>
        <div>
          <div className='grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-4'>
            {data?.slice(0, 16).map((item) => {
              const { collection, volume, usdVolume } = item;

              return (
                <div
                  className='rounded-2xl flex border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700'
                  key={collection?.address}
                >
                  <figure className='mr-4 flex w-12 shrink-0 items-center'>
                    <Link href={getCollectionPath(collection?.chain, collection?.pathName)}>
                      <a className='relative block w-full'>
                        <ImageFixedAO
                          image={getCdnUrl(
                            collection?.profilePhotoPath,
                            'thumbnail',
                            collection?.updatedAt,
                          )}
                          alt={collection?.name}
                          bottomRounded={true}
                          moonpetsAccessory={collection?.parentCollection}
                        />
                        {/* <div
                                                    className="dark:border-jacarta-600 bg-jacarta-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white text-xs text-white">
                                                    {"id"}
                                                </div>*/}
                        {collection?.isVerified && (
                          <div
                            className='absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600'
                            data-tippy-content='Verified Collection'
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 24 24'
                              width='24'
                              height='24'
                              className='h-[.875rem] w-[.875rem] fill-white'
                            >
                              <path fill='none' d='M0 0h24v24H0z' />
                              <path d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z' />
                            </svg>
                          </div>
                        )}
                      </a>
                    </Link>
                  </figure>
                  <div className='flex flex-col items-start justify-center'>
                    <Link href={getCollectionPath(collection?.chain, collection?.pathName)}>
                      <a className='block'>
                        <span className='font-display leading-3 text-jacarta-700 hover:text-accent dark:text-white'>
                          {collection?.name}
                        </span>
                      </a>
                    </Link>
                    {/*<span className="dark:text-jacarta-300 text-sm">{amount} ETH</span>*/}
                    <span className='flex flex-row items-center gap-1 whitespace-nowrap rounded-md border-jacarta-100 py-1 dark:border-jacarta-600 lg:flex-col lg:items-start'>
                      <div className='flex flex-row gap-0.5'>
                        <ChainIcon
                          name={collection?.chain}
                          tooltip={getTokenName(collection?.chain)}
                        />

                        <span className='text text-sm font-medium tracking-tight'>
                          {/*{volume}*/}
                          <span>{normalizeEther(volume)}</span>
                        </span>
                      </div>
                      {usdVolume ? (
                        <span className='flex flex-row text-xs font-medium tracking-tight'>
                          <span className='block lg:hidden'>/&nbsp;</span>
                          {'$' + priceFormatter(Number(usdVolume?.toFixed(2))) + ''}
                        </span>
                      ) : null}
                    </span>
                  </div>
                </div>
              );
            })}
            {!trendingCollections.data && <TopCollectionsSkeleton count={8} />}
          </div>
          <div className='mt-10 text-center'>
            <Link href='/collection/explore_collection'>
              <a className='mt-8 block text-center text-sm font-bold tracking-tight text-accent'>
                Go to Collections
              </a>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Top_collection;
