import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useContext, useEffect, useState } from 'react';
import { DataRefreshContext } from '../components/refreshContext';
import { isAddress } from 'ethers/lib/utils.js';

export default function useGetItemsByCollectionAddress(
  collectionAddress,
  filter,
  limit = 10,
  page = 1,
  fetchFavorites,
  walletAddress,
  favoritesWalletAddress,
) {
  const [enabled, setEnabled] = useState(false);
  const { refreshDate } = useContext(DataRefreshContext);

  useEffect(() => {
    if (!collectionAddress) return setEnabled(true);
    setEnabled(isAddress(collectionAddress));
  }, [collectionAddress]);

  let sortBy = 'updatedAt:desc';
  let config;
  if (filter.sort) {
    sortBy = filter.sort.id;
  }
  if (fetchFavorites) {
    delete filter.buy_now;
  }
  config = {
    url: `${constants.api.url_new}/items`, //`${constants.api.url}${fetchFavorites ? '/users/favoriteItems': '/items'}`,
    method: constants.httpMethods.get,
    params: fetchFavorites
      ? { sortBy, limit, page, ...filter, favorites: true, favoritesWalletAddress }
      : { sortBy, limit, page, ...filter },
  };
  if (collectionAddress && collectionAddress != 'undefined') {
    config.params.collectionAddress = collectionAddress;
  }

  config.params.refreshDate = refreshDate;

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data;
    });
  return useAdaptor(enabled ? axios.getUri(config) : null, fetcher);
}
