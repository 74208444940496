import { constants } from '../helpers/constants';
import axios from '../lib/axios';

export async function createFavoriteItem(data, chainId) {
  const config = {
    url: `${constants.api.url_new}/users/favoriteItems/create`,
    headers: {
      //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: constants.httpMethods.post,
    data: { ...data, chainId },
  };

  const res = (await axios(config)).data;

  return res;
}

export async function deleteFavoriteItem(data, chainId) {
  const config = {
    url: `${constants.api.url_new}/users/favoriteItems/delete`,
    headers: {
      //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: constants.httpMethods.post,
    data: { ...data, chainId },
  };

  const res = (await axios(config)).data;

  return res;
}
