import React, { useContext, useEffect, useRef, useState } from 'react';
import useGetCollections from '../../hooks/useGetCollections';
import { OnEnterScreen } from '../onEnterScreen';
import { SearchInput } from '../SearchInput/search_input';

const DropDownItemProperties = ({ selectedCollectionIds = [], onChange, chainIds }) => {
  const [filterVal, setFilterVal] = useState({
    sort: {
      id: 'order:asc',
      text: 'Default',
    },
  });
  const [page, setPage] = useState(1);

  const { data: swr } = useGetCollections({ filter: filterVal, limit: 20, page, chainIds });
  const { results: data, page: lastPage, totalPages } = swr || {};
  const [lastData, setLastData] = useState([]);
  const [shouldLoad, setShouldLoad] = useState({});

  useEffect(() => {
    if (data && lastData && lastPage !== 1) {
      return setLastData([...lastData, ...data]);
    }
    if (data && lastPage == 1) {
      setLastData(data);
    }
  }, [data]);
  useEffect(() => {
    if (lastPage < totalPages) setPage(lastPage + 1);
  }, [shouldLoad]);
  useEffect(() => {
    setPage(1);
  }, [filterVal]);

  return (
    <>
      <SearchInput
        label={'Search Collection'}
        className={'mx-1 mt-1 w-full'}
        value={filterVal.search}
        onChange={(val) => setFilterVal({ ...filterVal, search: val })}
      />
      <div className={'w-full'}>
        {lastData &&
          lastData.map((c) => {
            return (
              <div
                onClick={() => {
                  if (selectedCollectionIds.some((a) => a.address == c.address)) {
                    onChange(selectedCollectionIds.filter((a) => c.address !== a.address));
                  } else {
                    onChange([...selectedCollectionIds, { address: c.address, name: c.name }]);
                  }
                }}
                className={
                  'relative cursor-pointer py-3 pl-9 text-jacarta-500 dark:text-jacarta-200'
                }
                key={c.address + 'vf'}
              >
                {c.name} ({c.itemCount})
                <input
                  type='checkbox'
                  className='absolute left-2 top-3.5 h-5 w-5 cursor-pointer rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600'
                  checked={selectedCollectionIds.some((a) => a.address == c.address)}
                  readOnly
                />
              </div>
            );
          })}
        <OnEnterScreen
          onVisible={() => {
            setShouldLoad({});
          }}
        />
      </div>
    </>
  );
};

export default DropDownItemProperties;
