import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { closeModal } from '../../redux/counterSlice';

export const ModalContainer = ({ modalKey, children, title, close = true }) => {
  const dispatch = useDispatch();

  const { modal } = useSelector((state) => state.counter);

  return (
    <div>
      {/* <!-- Buy Now Modal --> */}
      <div
        className={modal?.key === modalKey ? 'modal fade show block' : 'modal fade'}
        onClick={() => {
          close && dispatch(closeModal());
        }}
      >
        <div className='modal-dialog w-full max-w-md'>
          <div
            className='modal-content'
            onClick={(e) => {
              e.stopPropagation();
            }}
            data-testid='modal'
          >
            <div className='modal-header flex justify-between'>
              <h5 className='modal-title' id='placeBidLabel'>
                {title}
              </h5>
              {close && (
                <button
                  type='button'
                  className='btn-close !static'
                  onClick={() => dispatch(closeModal())}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='h-6 w-6 fill-jacarta-700 dark:fill-white'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z' />
                  </svg>
                </button>
              )}
            </div>
            {modal?.key === modalKey && children}
          </div>
        </div>
      </div>
    </div>
  );
};
