import React, { useContext, useEffect, useRef, useState } from 'react';
import { JsonStringify } from '../_debug/json.stringify';
import useGetCollectionAttributes from '../../hooks/useGetCollectionAttributes';
import { CollectionContext } from '../categories/filterCategoryItem';
import { VerticalFilterCollapsable } from '../filter/collapsable';
import moment from 'moment';
import { LoadingSpinner } from '../loadingSpinner';
import { ServerPropsContext } from '../../helpers/filterServerSideProps';
import { SearchInput } from '../SearchInput/search_input';

const DropDownItemProperties = ({
  value,
  onSelect = () => {},
  className,
  collectionAddress,
  singleSelect,
  disableScroll,
}) => {
  const [activeProperty, setActiveProperty] = useState(null);
  const [search, setSearch] = useState('');
  const collection = useContext(CollectionContext) || collectionAddress;
  const serverProps = useContext(ServerPropsContext);

  const { data, isLoading } = useGetCollectionAttributes(collection);

  const handleClick = (name, attribute) => {
    const newValue = JSON.parse(JSON.stringify(value));
    if (!newValue[name]) {
      newValue[name] = [];
    }
    if (newValue[name].includes(attribute)) {
      newValue[name] = newValue[name].filter((v) => v != attribute);
      if (newValue[name].length == 0) {
        delete newValue[name];
      }
    } else {
      newValue[name].push(attribute);
    }
    if (singleSelect) {
      onSelect({ [name]: [attribute] });
    } else {
      onSelect(newValue);
    }
  };
  return (
    <>
      <div className={'w-full'}>
        <SearchInput
          className={'!w-[180px] lg:!w-full'}
          value={search}
          onChange={(val) => setSearch(val)}
        />
        {isLoading && <LoadingSpinner />}
        {data &&
          Object.keys(data)
            .sort((a, b) => {
              if (a == 'SET') return 1;
              if (b == 'SET') return -1;
              return a.localeCompare(b);
            })
            .filter((key) => {
              if (key.toLowerCase().includes(search.toLowerCase())) return true;
              return false;
            })
            .map((key) => {
              const values = data[key];
              if (key === 'SET' && !serverProps?.collection?.raritySetsEnabled) return;
              return (
                <VerticalFilterCollapsable
                  key={key}
                  name={key}
                  active={activeProperty}
                  setActive={setActiveProperty}
                >
                  <div
                    className={`relative ${
                      disableScroll ? 'overflow-y-hidden' : 'max-h-[200px] overflow-y-scroll'
                    }`}
                  >
                    {values
                      .sort((a, b) => {
                        if (a.value == 'SET') return 1;
                        if (b.value == 'SET') return -1;
                        return a.value.localeCompare(b.value);
                      })
                      .map((a, ai) => (
                        <div
                          onClick={() => handleClick(key, a.value)}
                          className={
                            'relative cursor-pointer py-3 pl-9 text-accent dark:text-jacarta-200'
                          }
                          key={a.value + ai}
                        >
                          {key == 'Birth Date'
                            ? moment(new Date(Number(a.value) * 1000)).format('DD/MM/YYYY')
                            : a.value.length > 10
                            ? a.value.substring(0, 14) + '...'
                            : a.value}{' '}
                          ({a._count.value})
                          {
                            <span className='absolute right-3'>
                              {a.rarity ? '%' + parseFloat(a.rarity).toFixed(2) : '-'}
                            </span>
                          }
                          <input
                            type='checkbox'
                            className='absolute left-2 top-3.5 h-5 w-5 cursor-pointer rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600'
                            checked={value[key] && value[key].includes(a.value) ? true : false}
                            readOnly
                          />
                        </div>
                      ))}
                  </div>
                </VerticalFilterCollapsable>
              );
            })}
        {/*data?.names?.map((d, i) => {
					if(d == 'Birth Date') return
					return <VerticalFilterCollapsable key={d} name={d}>
						<div className={'max-h-[200px] overflow-y-scroll'}>
							{data?.attributes[i].map((a, ai) => <div onClick={() => handleClick(d, a)}
																	 className={'pl-9 text-accent dark:text-jacarta-200 py-3 relative cursor-pointer'}
																	 key={a + ai}>
								{d == 'Birth Date' ? moment(new Date(Number(a) * 1000)).format('DD/MM/YYYY') : a}
								<input
									type="checkbox"
									id="terms"
									className="absolute left-2 top-3.5 cursor-pointer checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 rounded focus:ring-offset-0"
									checked={value[d] && value[d].includes(a)}
								/>
							</div>)}
						</div>
					</VerticalFilterCollapsable>
				})*/}
      </div>
    </>
  );
};

export default DropDownItemProperties;
