import React, { useEffect } from 'react';
import { BsArrowUpShort } from 'react-icons/bs';

function Gototop(props) {
  const scrollTop = React.useRef(null);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  useEffect(() => {
    if (!scrollTop?.current && window === 'undefined') return;
    window.addEventListener('scroll', () => {
      if (window.scrollY > 300) {
        scrollTop?.current?.classList.remove('hidden');
      } else {
        scrollTop?.current?.classList.add('hidden');
      }
    });
    return () => {
      window.removeEventListener('scroll', () => {
        if (window.scrollY > 300) {
          scrollTop.current?.classList.remove('hidden');
        } else {
          scrollTop.current?.classList.add('hidden');
        }
      });
    };
  }, [scrollTop]);
  return (
    <div ref={scrollTop} onClick={handleScrollTop} className='hidden select-none'>
      <button className='rounded-full bg-white p-2 shadow-2xl dark:bg-jacarta-800'>
        <BsArrowUpShort size={32} />
      </button>
    </div>
  );
}

export default Gototop;
