import { ClickAwayListener } from '@mui/material';
import React, { useState } from 'react';

const DropdownDynamic = ({
  value,
  items = [],
  onSelect = () => {},
  className,
  optionClassName,
  containerClassName,
  isQuestionModal,
}) => {
  const [dropdownShow, setDropdownShow] = useState(false);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setDropdownShow(false);
      }}
      mouseEvent='onMouseDown'
      touchEvent='onTouchStart'
    >
      <div className={className + ' dropdown relative my-2 cursor-pointer md:my-1'}>
        <button
          className={'dropdown-toggle text-black inline-flex h-[42px] items-center justify-between rounded-lg border border-jacarta-100 bg-white py-2 px-3 text-sm dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white '.concat(
            containerClassName,
          )}
          data-testid='dropdownDynamic'
          onClick={() => setDropdownShow(!dropdownShow)}
        >
          <span className='font-display'>{value?.text}</span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='24'
            height='24'
            className='h-4 w-4 fill-jacarta-500 dark:fill-white'
          >
            <path fill='none' d='M0 0h24v24H0z' />
            <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
          </svg>
        </button>

        <div
          className={
            dropdownShow
              ? optionClassName +
                ` rounded-xl show absolute top-full overflow-y-auto ${
                  isQuestionModal ? 'left-0 box-border max-h-[120px]' : 'right-0'
                } z-20 w-[13rem] whitespace-nowrap bg-white py-4 px-2 text-left shadow-xl dark:border dark:border-jacarta-600 dark:bg-jacarta-800 md:max-w-sm`
              : ' rounded-xl absolute top-full right-0 z-10 hidden w-[13rem] whitespace-nowrap bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800 md:max-w-sm'
          }
        >
          {items.map((item) => {
            const { id, text } = item;
            return (
              <button
                key={id}
                className='dropdown-item rounded-xl flex w-full items-center justify-between gap-3 px-3 py-2 text-left font-display text-sm text-jacarta-700 transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600'
                onClick={() => {
                  onSelect(item);
                  setDropdownShow(false);
                }}
                data-testid='dropdownSelect'
              >
                {text}
                {value && value.id === id && (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='h-4 w-4 fill-accent'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z' />
                  </svg>
                )}
              </button>
            );
          })}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default DropdownDynamic;
