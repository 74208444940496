import { fetchBalance } from 'wagmi/actions';
import { normalizeEther } from './digits';

export const filterBidsByFloorPrice = ({ bids, floorPrice }) => {
  return bids.filter((bid) => {
    const price = normalizeEther(bid?.price);
    if (floorPrice === 0 || !floorPrice) return true;
    return price >= floorPrice / 2;
  });
};

const sortBids = ({ bids, sortByPrice }) => {
  const sortedBids = bids.sort((a, b) => {
    const balanceComparison = compareBalance(a, b);
    if (balanceComparison !== 0) return balanceComparison;

    const approvalComparison = compareApproval(a, b);
    if (approvalComparison !== 0) return approvalComparison;

    if (sortByPrice) return comparePrice(a, b);

    return 0;
  });
  return sortedBids;
};

const compareBalance = (a, b) => {
  if (a.hasEnoughBalance && !b.hasEnoughBalance) return -1;
  if (!a.hasEnoughBalance && b.hasEnoughBalance) return 1;
  return 0;
};

const compareApproval = (a, b) => {
  if (a.isApprovedEnough && !b.isApprovedEnough) return -1;
  if (!a.isApprovedEnough && b.isApprovedEnough) return 1;
  return 0;
};

const comparePrice = (a, b) => {
  if (b?.priceBigInt < a?.priceBigInt) return -1;
  if (b?.priceBigInt > a?.priceBigInt) return 1;
  return 0;
};

export const compareBigInt = (a, b) => {
  if (b < a) return -1;
  if (b > a) return 1;
  return 0;
};

export const getBidsWithBalance = async ({ contract, bids, chain, sortByPrice }) => {
  const bidsWithBalancePromises = bids.map(async (bid) => {
    const [approvedAmount, userBalance] = await Promise.all([
      contract?.read?.allowance?.([bid?.issuer, chain?.marketplace]),
      fetchBalance({
        address: bid?.issuer,
        chainId: chain?.id,
        token: chain?.wrappedToken,
      }),
    ]);
    const balance = userBalance?.value || 0n;
    const priceBigInt = BigInt(bid?.price) || 0n;
    const hasEnoughBalance = balance >= priceBigInt;
    return {
      ...bid,
      balance,
      hasEnoughBalance,
      priceBigInt,
      isReady: true,
      isApprovedEnough: priceBigInt <= approvedAmount,
    };
  });

  const bidsWithBalance = await Promise.all(bidsWithBalancePromises);
  return sortBids({ bids: bidsWithBalance, sortByPrice });
};

export const getLendignOfferWithBalance = async ({ contract, offers, chain, sortByPrice }) => {
  const offerssWithBalancePromises = offers.map(async (offer) => {
    const [approvedAmount, userBalance] = await Promise.all([
      contract?.read?.allowance?.([offer?.issuer, chain?.lendingContract]),
      fetchBalance({
        address: offer?.issuer,
        chainId: chain?.id,
        token: chain?.wrappedToken,
      }),
    ]);
    const balance = userBalance?.value || 0n;
    const priceBigInt = BigInt(offer?.borrowAmount) || 0n;
    const hasEnoughBalance = balance >= priceBigInt;
    return {
      ...offer,
      balance,
      hasEnoughBalance,
      priceBigInt,
      isReady: true,
      isApprovedEnough: priceBigInt <= approvedAmount,
    };
  });

  const bidsWithBalance = await Promise.all(offerssWithBalancePromises);
  return sortBids({ bids: bidsWithBalance, sortByPrice });
};
