/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from 'next-themes';
import Link from 'next/link';
import MblNavbar from './mblNavbar';
import { useSelector, useDispatch } from 'react-redux';
import { openMblMenu } from '../redux/counterSlice';
import { useRouter } from 'next/router';
import { MainSearch } from './mainSearch';
import { useAccount, useNetwork } from 'wagmi';
import ChainWarning from './modal/ChainWarning';
import { ChainIcon } from './Icon';
import getChainName from '../helpers/getChainName';
import { constants } from '../helpers/constants';
import { Bit5Logo } from './bit5Logo';
import { useSwitchNetworkWrapper } from './Web3WalletProvider';
import MintingWarning from './banners/mintingWarning';
import { useCartFunctions } from './cart/useCartFunctions';
import { useChainModal } from '@rainbow-me/rainbowkit';
import { LuArrowUpDown } from 'react-icons/lu';

const useCloseOnOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
};

function useElementHeight() {
  const [height, setHeight] = useState(95);
  const elementRef = useRef(null);
  useEffect(() => {
    setInterval(() => {
      if (elementRef.current) {
        if (height !== elementRef.current.clientHeight) {
          setHeight(elementRef.current.clientHeight);
        }
      }
    }, 100);
  }, []);
  return [elementRef, height];
}

const Navbar = () => {
  const [hoverCartState, setHoverCartState] = useState(false);
  const { toggleCart, itemList } = useCartFunctions();
  const { openChainModal } = useChainModal();

  const [mounted, setMounted] = useState(false);
  const [elementRef, height] = useElementHeight();
  const { theme, setTheme } = useTheme();
  let [scroll, setScroll] = useState(false);
  const [home3, setHome3] = useState(false);
  const { isConnected } = useAccount();
  const [isChainMenuOpen, setIsChainMenuOpen] = useState(false);
  const chainMenuRef = useRef(null);

  const supportedChains = constants.helpers.supportedChains;

  useCloseOnOutsideClick(chainMenuRef, () => setIsChainMenuOpen(false));

  const [isWarningOpen, setIsWarningOpen] = useState(true);
  const warning = process.env.NEXT_PUBLIC_ENV_VARIABLE !== 'production' ? true : false;
  const [isChainSupported, setIsChainSupported] = useState(true);

  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetworkWrapper();
  const chainId = chain && !chain?.unsupported ? chain?.hexId : constants.helpers.chainId;

  useEffect(() => {
    document.documentElement.style.setProperty('--header-height', `${height}px`);
  }, [height]);

  useEffect(() => {
    if (!chain) return;
    for (let i = 0; i < chains.length; i++) {
      if (chains[i].id === chain.id) {
        setIsChainSupported(true);
        return;
      }
    }

    setIsChainSupported(false);
  }, [chain, chains]);

  const { mblMenu } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const router = useRouter();
  const pid = router.asPath;

  const handleSticky = function () {
    if (window.scrollY >= 100) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const handleTheme = () => {
    if (theme == 'dark' && pid === '/home/home_8') {
      setTheme('dark');
    } else if (theme == 'dark' && pid !== '/home/home_8') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  };

  // const router = useRouter();

  useEffect(() => {
    if (pid === '/home/home_3') {
      setHome3(true);
    } else {
      setHome3(false);
    }

    if (theme === 'light' && pid === '/home/home_8') {
      setTheme('dark');
    }
  }, [pid, setTheme, theme]);

  useEffect(() => {
    setMounted(true);
    window.addEventListener('scroll', handleSticky);
  }, []);

  if (!mounted) return null;
  if (mblMenu) {
    return (
      <div>
        <header
          ref={elementRef}
          className={
            scroll
              ? 'js-page-header js-page-header--is-sticky fixed top-0 z-20 h-full w-full backdrop-blur transition-colors'
              : 'js-page-header fixed top-0 z-20 h-full w-full backdrop-blur transition-colors'
          }
        >
          {pid === '/platform_status' ? (
            <div className='container'>
              <div className='flex items-center py-[1.5625rem] lg:py-[1.8125rem]'>
                {/* <!-- Logo --> */}
                <Link href='/' passHref>
                  <a>
                    <Bit5Logo />
                  </a>
                </Link>

                <span className='mt-1 hidden font-display text-lg font-semibold lg:inline-block'>
                  Status
                </span>

                <Link href='#'>
                  <a className='rounded-xl ml-auto inline-block bg-accent py-2.5 px-8 text-center text-sm font-semibold text-white transition-all hover:bg-accent-dark'>
                    <span className='hidden lg:block'>Subscribe to Updates</span>
                    <span className='lg:hidden'>Subscribe</span>
                  </a>
                </Link>
              </div>
            </div>
          ) : (
            <div className='flex items-center px-6 py-6 xl:px-24 '>
              {/* <!-- Logo --> */}

              <Link href='/'>
                <a className='block shrink-0 dark:hidden'>
                  <img src='/images/bit5-logo/bit5-beta.png' alt='' className='max-h-9 h-auto' />
                </a>
              </Link>
              <Link href='/'>
                <a className='hidden shrink-0 dark:block'>
                  <img
                    src='/images/bit5-logo/bit5-white-beta.png'
                    className='max-h-9 h-auto'
                    alt='Bit5 | Create. Collect. Connect.'
                  />
                </a>
              </Link>

              {/* <!-- Search --> */}
              <form action='search' className='ml-12 mr-8 hidden basis-3/12 lg:block xl:ml-[8%]'>
                <input
                  type='search'
                  className='rounded-2xl w-full border border-jacarta-100 py-[0.6875rem] px-4 pl-10 text-jacarta-700 placeholder-jacarta-500 focus:ring-accent dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white'
                  placeholder='Search'
                />
                <span className='rounded-2xl absolute left-0 top-0 flex h-full w-12 items-center justify-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='h-4 w-4 fill-jacarta-500 dark:fill-white'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z' />
                  </svg>
                </span>
              </form>

              {/* <!-- Menu / Actions --> */}
              <MblNavbar theme={theme} onThemeChange={handleTheme} />

              {/* <!-- Chain --> */}
              <div ref={chainMenuRef} className='ml-auto flex lg:hidden'>
                <div className='relative'>
                  <button
                    onClick={openChainModal}
                    className='group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
                  >
                    <ChainIcon
                      width={20}
                      name={chainId}
                      tooltip={getChainName(chainId)}
                      placement={'top'}
                      type={'chain'}
                    />
                  </button>
                  {/* <div
                    className={
                      'rounded-xl absolute !-right-0 !top-[85%] !left-auto z-10 grid translate-y-4 space-y-2 whitespace-nowrap border border-jacarta-100 bg-white px-3 py-2 shadow-2xl transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full dark:border-jacarta-600 dark:bg-jacarta-800' +
                      (isChainMenuOpen ? ' block' : ' hidden')
                    }
                  >
                    {supportedChains.map(({ id, name, hexId }) => (
                      <div
                        key={`supported-chain-${id}`}
                        className='rounded-xl flex cursor-pointer items-center px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600'
                        onClick={() => {
                          switchNetwork(id);
                          setIsChainMenuOpen(false);
                        }}
                      >
                        <div className='flex items-center space-x-2'>
                          <ChainIcon width={16} name={hexId} tooltip={null} type={'chain'} />
                          <span className='pt-1 font-display text-sm text-jacarta-700 dark:text-white'>
                            {name}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div> */}
                </div>
              </div>

              {/* <!-- Mobile Menu Actions --> */}
              <div className='ml-auto flex lg:hidden'>
                {/* <!-- Profile --> */}
                <Link href='/'>
                  <a
                    className='group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
                    aria-label='profile'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      width='24'
                      height='24'
                      className='h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
                    >
                      <path fill='none' d='M0 0h24v24H0z' />
                      <path d='M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z' />
                    </svg>
                  </a>
                </Link>

                {/* <!-- Dark Mode --> */}
                {/* <button
                  className='js-dark-mode-trigger group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
                  onClick={() => {
                    handleTheme();
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='dark-mode-light h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z' />
                  </svg>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='dark-mode-dark hidden h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z' />
                  </svg>
                </button> */}

                {/* <!-- Mobile Menu Toggle --> */}
                <button
                  className='js-mobile-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
                  aria-label='open mobile menu'
                  onClick={() => dispatch(openMblMenu())}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z' />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </header>
      </div>
    );
  } else {
    return (
      <div>
        <header
          ref={elementRef}
          id='pageHeader'
          className={
            scroll && home3
              ? 'js-page-header page-header--transparent js-page-header--is-sticky fixed top-0 z-20 w-full bg-white/[.15] backdrop-blur transition-colors'
              : home3
              ? 'js-page-header page-header--transparent fixed top-0 z-20 w-full bg-white/[.15] backdrop-blur transition-colors'
              : scroll
              ? `js-page-header js-page-header--is-sticky fixed top-0 z-20 w-full backdrop-blur`
              : `js-page-header fixed top-0 z-20 w-full backdrop-blur`
          }
        >
          <MintingWarning />
          {warning && (
            <div className='relative flex items-center justify-center bg-orange py-3'>
              <span className='ml-2 text-sm leading-4'>You are using Testnet.</span>
              <Link href='https://www.bnbchain.org/en/testnet-faucet'>
                <a target={'_blank'} className='ml-2 text-sm font-bold underline'>
                  Get TBNB.
                </a>
              </Link>
              <Link href='/launchpad'>
                <a className='ml-2 text-sm font-bold  underline'>Get test NFTs.</a>
              </Link>
              <Link href='https://docs.google.com/document/d/1IMwJSQqFeWdDcPYUxXqW7e2VGx_lD4BeGhlG5SYYmnc/edit?usp=sharing'>
                <a target={'_blank'} className='ml-3 text-sm font-bold  underline'>
                  See the test process.
                </a>
              </Link>
            </div>
          )}
          {!isChainSupported && isWarningOpen && (
            <div className={`mt-0`}>
              <ChainWarning close={setIsWarningOpen} />
            </div>
          )}
          {pid === '/platform_status' ? (
            <div className='container'>
              <div className='flex items-center py-[1.5625rem] lg:py-[1.8125rem]'>
                {/* <!-- Logo --> */}
                <Link href='/' passHref>
                  <a>
                    <Bit5Logo />
                  </a>
                </Link>

                <span className='mt-1 hidden font-display text-lg font-semibold lg:inline-block'>
                  Status
                </span>

                <Link href='#'>
                  <a className='rounded-xl ml-auto inline-block bg-accent py-2.5 px-8 text-center text-sm font-semibold text-white transition-all hover:bg-accent-dark'>
                    <span className='hidden lg:block'>Subscribe to Updates</span>
                    <span className='lg:hidden'>Subscribe</span>
                  </a>
                </Link>
              </div>
            </div>
          ) : (
            <div className='flex items-center px-4 py-6 sm:px-6 xl:px-24 '>
              {/* <!-- Logo --> */}
              {home3 ? (
                <Link href='/'>
                  <a className='shrink-0'>
                    <img
                      src='/images/bit5-logo/bit5-white.png'
                      className='max-h-9 h-auto'
                      alt='Bit5 | Create. Collect. Connect.'
                    />
                  </a>
                </Link>
              ) : (
                <Link href='/' passHref>
                  <a>
                    <Bit5Logo />
                  </a>
                </Link>
              )}

              {/* <!-- Search --> */}
              {home3 ? (
                <form
                  action='search'
                  className='relative ml-12 mr-8 hidden basis-3/12 lg:block xl:ml-[8%]'
                >
                  <input
                    type='search'
                    className=' rounded-2xl w-full border border-jacarta-100 border-transparent bg-white/[.15] py-[0.6875rem] px-4 pl-10 text-white placeholder-white focus:ring-accent'
                    placeholder='Search'
                  />
                  <span className='rounded-2xl absolute left-0 top-0 flex h-full w-12 items-center justify-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      width='24'
                      height='24'
                      className=' h-4 w-4 fill-white'
                    >
                      <path fill='none' d='M0 0h24v24H0z' />
                      <path d='M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z' />
                    </svg>
                  </span>
                </form>
              ) : (
                <MainSearch />
              )}

              <MblNavbar theme={theme} onThemeChange={handleTheme} />
              <div ref={chainMenuRef} className='ml-auto flex sm:hidden'>
                <div className='relative'>
                  <button className='group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15] '>
                    <Link href={'/#SquadSwapWidget'}>
                      <div className='flex h-6 w-6 items-center justify-center rounded-full bg-accent'>
                        <LuArrowUpDown color='white' />
                      </div>
                    </Link>
                  </button>
                </div>
              </div>

              <div className='ml-auto mr-0 flex self-end pr-0'>
                {/* <!-- Chain --> */}
                {isConnected && (
                  <>
                    <div ref={chainMenuRef} className='ml-2 flex lg:hidden'>
                      <div
                        className='js-dark-mode-trigger group relative flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
                        onMouseEnter={() => {
                          setHoverCartState(true);
                        }}
                        onMouseLeave={() => {
                          setHoverCartState(false);
                        }}
                        onClick={() => {
                          toggleCart(true);
                        }}
                      >
                        <div
                          className={`absolute right-0 top-0 flex h-[20px] w-[20px] items-center justify-center rounded-full ${
                            hoverCartState ? 'bg-white text-accent' : 'bg-accent text-white'
                          } text-center text-[10px]`}
                        >
                          {itemList?.length}
                        </div>

                        {hoverCartState ? (
                          <svg
                            width='40'
                            height='40'
                            viewBox='0 0 40 40'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className=''
                          >
                            <circle cx='20' cy='20' r='20' fill='#8358FF' />
                            <path
                              d='M23.4501 32.0002C24.0058 32.0002 24.4563 31.5497 24.4563 30.994C24.4563 30.4383 24.0058 29.9878 23.4501 29.9878C22.8943 29.9878 22.4438 30.4383 22.4438 30.994C22.4438 31.5497 22.8943 32.0002 23.4501 32.0002Z'
                              fill='white'
                              stroke='white'
                              strokeWidth='0.5'
                            />
                            <path
                              d='M12.8649 32.0002C13.4206 32.0002 13.8711 31.5497 13.8711 30.994C13.8711 30.4383 13.4206 29.9878 12.8649 29.9878C12.3091 29.9878 11.8586 30.4383 11.8586 30.994C11.8586 31.5497 12.3091 32.0002 12.8649 32.0002Z'
                              fill='white'
                              stroke='white'
                              strokeWidth='0.5'
                            />
                            <path
                              d='M26.8675 18.7724L25.6928 24.7458C25.5928 25.3206 25.193 25.7205 24.6681 25.7205L14.496 26.1954L13.2213 28.2198H24.2432C24.8181 28.2198 25.2929 28.7196 25.2929 29.2945C25.2929 29.4944 25.118 29.7194 24.918 29.7194C24.7181 29.7194 24.5431 29.5194 24.5431 29.3195C24.5431 29.1695 24.4182 28.9696 24.2432 28.9696H13.3213C12.8964 28.9696 12.5965 28.8946 12.4465 28.6197C12.2466 28.2448 12.4965 27.8949 12.5465 27.8199L13.8461 25.7705L10.847 15.7983C10.797 15.6983 10.3971 15.4234 9.29741 15.4234C9.29741 15.4234 8.89678 15.4389 9.02549 14.986C9.02549 14.986 9.05673 14.6853 9.67231 14.7236C11.2469 14.7986 11.5468 15.4484 11.5968 15.6483L11.9717 16.898L25.8428 17.7477C26.3926 17.7477 26.8675 18.1976 26.8675 18.7724ZM26.1177 18.6725C26.0927 18.5475 25.9677 18.4225 25.8178 18.4225L12.1966 17.6228L14.5459 25.4706L24.6431 24.9707C24.893 24.9707 24.943 24.6958 24.968 24.6208L26.1177 18.6725Z'
                              fill='white'
                              stroke='white'
                              strokeWidth='0.5'
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='w-full hover:hidden'
                            viewBox='0 0 40 40'
                            fill='none'
                          >
                            {/* <circle cx='20' cy='20' r='19.5' fill={'dark:#32344b ligth:white'} stroke={'light:#E7E8EC dark:none'} /> */}
                            <path
                              d='M23.4501 32.0001C24.0058 32.0001 24.4563 31.5496 24.4563 30.9939C24.4563 30.4382 24.0058 29.9877 23.4501 29.9877C22.8943 29.9877 22.4438 30.4382 22.4438 30.9939C22.4438 31.5496 22.8943 32.0001 23.4501 32.0001Z'
                              fill={'#8358FF'}
                              stroke={'#8358FF'}
                              strokeWidth='0.5'
                            />
                            <path
                              d='M12.8649 32.0001C13.4206 32.0001 13.8711 31.5496 13.8711 30.9939C13.8711 30.4382 13.4206 29.9877 12.8649 29.9877C12.3091 29.9877 11.8586 30.4382 11.8586 30.9939C11.8586 31.5496 12.3091 32.0001 12.8649 32.0001Z'
                              fill={'#8358FF'}
                              stroke={'#8358FF'}
                              strokeWidth='0.5'
                            />
                            <path
                              d='M26.8675 18.7722L25.6928 24.7455C25.5928 25.3204 25.193 25.7202 24.6681 25.7202L14.496 26.1951L13.2213 28.2195H24.2432C24.8181 28.2195 25.2929 28.7194 25.2929 29.2942C25.2929 29.4942 25.118 29.7191 24.918 29.7191C24.7181 29.7191 24.5431 29.5192 24.5431 29.3192C24.5431 29.1693 24.4182 28.9693 24.2432 28.9693H13.3213C12.8964 28.9693 12.5965 28.8944 12.4465 28.6194C12.2466 28.2445 12.4965 27.8946 12.5465 27.8197L13.8461 25.7702L10.847 15.798C10.797 15.6981 10.3971 15.4231 9.29741 15.4231C9.29741 15.4231 8.89678 15.4386 9.02549 14.9858C9.02549 14.9858 9.05673 14.6851 9.67231 14.7233C11.2469 14.7983 11.5468 15.4481 11.5968 15.6481L11.9717 16.8977L25.8428 17.7475C26.3926 17.7475 26.8675 18.1974 26.8675 18.7722ZM26.1177 18.6722C26.0927 18.5473 25.9677 18.4223 25.8178 18.4223L12.1966 17.6225L14.5459 25.4703L24.6431 24.9705C24.893 24.9705 24.943 24.6955 24.968 24.6206L26.1177 18.6722Z'
                              fill={'#8358FF'}
                              stroke={'#8358FF'}
                              strokeWidth='0.5'
                            />
                            <path
                              d='M24.3125 16.64C23.9627 16.64 23.6483 16.5734 23.3692 16.4402C23.092 16.3052 22.8706 16.1202 22.7051 15.8853C22.5395 15.6504 22.4511 15.3822 22.4399 15.0807H23.2771C23.2976 15.3249 23.4064 15.5256 23.6037 15.6828C23.8009 15.84 24.0371 15.9186 24.3125 15.9186C24.532 15.9186 24.7264 15.8687 24.8957 15.7688C25.0669 15.6671 25.2008 15.5274 25.2976 15.3498C25.3962 15.1723 25.4455 14.9697 25.4455 14.7422C25.4455 14.511 25.3953 14.3047 25.2948 14.1235C25.1943 13.9422 25.0557 13.7998 24.879 13.6962C24.7041 13.5926 24.5032 13.5399 24.2762 13.538C24.1032 13.538 23.9292 13.5676 23.7543 13.6268C23.5795 13.686 23.4381 13.7637 23.3302 13.8599L22.5404 13.7434L22.8613 10.88H26.0036V11.6153H23.5785L23.3971 13.2051H23.4306C23.5423 13.0978 23.6902 13.0081 23.8743 12.936C24.0604 12.8638 24.2595 12.8278 24.4716 12.8278C24.8195 12.8278 25.1292 12.9101 25.4008 13.0747C25.6743 13.2393 25.8892 13.4641 26.0455 13.7489C26.2036 14.0319 26.2818 14.3575 26.2799 14.7256C26.2818 15.0936 26.198 15.422 26.0287 15.7105C25.8613 15.9991 25.6288 16.2266 25.3311 16.3931C25.0353 16.5577 24.6957 16.64 24.3125 16.64Z'
                              fill='white'
                            />
                          </svg>
                        )}
                      </div>
                    </div>

                    <div ref={chainMenuRef} className='ml-auto flex lg:hidden'>
                      <div className='relative'>
                        <button
                          onClick={openChainModal}
                          className='group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
                        >
                          <ChainIcon
                            width={20}
                            name={chainId}
                            tooltip={getChainName(chainId)}
                            placement={'top'}
                            type={'chain'}
                          />
                        </button>
                        {/* <div
                          className={
                            'rounded-xl absolute !-right-0 !top-[85%] !left-auto z-10 grid translate-y-4 space-y-2 whitespace-nowrap border border-jacarta-100 bg-white px-2 py-2 shadow-2xl transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full dark:border-jacarta-600 dark:bg-jacarta-800' +
                            (isChainMenuOpen ? ' block' : ' hidden')
                          }
                        >
                          {supportedChains.map(({ id, name, hexId }) => (
                            <div
                              key={`supported-chain-${id}`}
                              className='rounded-xl flex cursor-pointer items-center px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600'
                              onClick={() => {
                                switchNetwork(id);
                                setIsChainMenuOpen(false);
                              }}
                            >
                              <div className='flex items-center space-x-2'>
                                <ChainIcon width={17} name={hexId} tooltip={null} type={'chain'} />
                                <span className='font-display text-sm text-jacarta-700 dark:text-white'>
                                  {name}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div> */}
                      </div>
                    </div>
                  </>
                )}

                {/* <!-- Mobile Menu Actions --> */}
                <div className='ml-auto flex lg:hidden'>
                  {/* <!-- Mobile Menu Toggle --> */}
                  {home3 ? (
                    <button
                      className='js-mobile-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 border-transparent bg-white bg-white/[.15] transition-colors hover:border-transparent focus:border-transparent focus:bg-accent'
                      aria-label='open mobile menu'
                      onClick={() => {
                        dispatch(openMblMenu());
                      }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width='24'
                        height='24'
                        className=' h-4 w-4 fill-white transition-colors group-hover:fill-white group-focus:fill-white'
                      >
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z' />
                      </svg>
                    </button>
                  ) : (
                    <button
                      className='js-mobile-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent'
                      aria-label='open mobile menu'
                      onClick={() => {
                        dispatch(openMblMenu());
                      }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width='24'
                        height='24'
                        className='h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
                      >
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z' />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </header>
      </div>
    );
  }
};

export default Navbar;
