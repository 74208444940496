import React, { useEffect, useState } from 'react';
import Dropdown_dynamic from '../dropdown/dropdown_dynamic';
import { JsonStringify } from '../_debug/json.stringify';
import { SearchInput } from '../SearchInput/search_input';
import Horizontal_fitler_contantainer from './horizontal_fitler_contantainer';
import { useRouter } from 'next/router';

const rarityRankF = [
  {
    id: 'rarityRank:asc',
    text: 'Rarity Rare to Common',
  },
  {
    id: 'rarityRank:desc',
    text: 'Rarity Common to Rare',
  },
];

export const HorizontalCollectionItemsFilter = ({
  filterObject = {},
  onChange,
  SortByIdDisabled,
  item,
  rarityFilterEnabled,
}) => {
  const router = useRouter();
  const pid = router.query.collection;
  const handleFitlerEvents = (name, value) => {
    onChange({
      ...filterObject,
      [name]: value,
    });
  };

  //console.log({ item });
  let rarityRankFilter = rarityRankF;
  if (item?.collection?.raritySetsEnabled) {
    rarityRankFilter = [
      {
        id: 'rarityPlusSetRank:asc',
        text: 'Rarity Rare to Common',
      },
      {
        id: 'rarityPlusSetRank:desc',
        text: 'Rarity Common to Rare',
      },
    ];
  }

  let filterItems = [
    {
      id: 'default:asc',
      text: 'Default',
    },
    {
      id: 'itemIdInt:asc',
      text: 'By Id',
    },
    {
      id: 'price:asc',
      text: 'Price Low to High',
    },
    {
      id: 'price:desc',
      text: 'Price High to Low',
    },
    {
      id: 'updatedAt:desc',
      text: 'Recent Activity',
    },
    /*{
      id: 'likes:desc',
      text: 'Most Favorites',
    },*/
    /*{
                    id: 'createdAt:desc',
                    text: 'Newest',
                },*/

    // {
    //   id: 'rarityRank:asc',
    //   text: 'Rarity Low to High',
    // },
    // {
    //   id: 'rarityRank:desc',
    //   text: 'Rarity High to Low',
    // },
    ...(rarityFilterEnabled ? rarityRankFilter : []),
    /*{
          id: "itemId:asc",
          text: "By Id",
      },*/
  ];
  if (SortByIdDisabled) {
    filterItems = filterItems.filter((f) => f.id !== 'itemId:asc');
  }
  return (
    <Horizontal_fitler_contantainer>
      <SearchInput
        className={'w-full sm:min-w-[200px]'}
        value={filterObject.search}
        onChange={(val) => handleFitlerEvents('search', val)}
      />
      <Dropdown_dynamic
        items={filterItems}
        value={filterObject.sort}
        onSelect={(val) => {
          handleFitlerEvents('sort', val);
        }}
        className={'ml-auto mt-4 w-full md:mt-1 md:w-fit'}
      />
    </Horizontal_fitler_contantainer>
  );
};
