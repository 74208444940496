import { useEffect } from 'react';
import { useNetwork, useWalletClient, usePublicClient } from 'wagmi';
import { getContract } from 'wagmi/actions';
import abi from './abi';
import { constants } from './constants';
import wbnbABI from './wbnbABI';
import { getSupportedChain } from './getSupportedChain';

export const useSetMarketPlaceContractToWindow = () => {
  const { data: walletClient, status } = useWalletClient();
  const { chain } = useNetwork();

  const marketplaceContract = getContract({
    address: chain?.marketplace,
    abi,
    walletClient,
  });
  useEffect(() => {
    if (window !== undefined && !window.marketplaceContract && status === 'success')
      window.marketplaceContract = marketplaceContract;
  }, [marketplaceContract, status]);
  return marketplaceContract;
};

export const useMarketPlaceContract = () => {
  const { data: walletClient, status } = useWalletClient();
  const { chain } = useNetwork();

  const marketplaceContract = getContract({
    address: chain?.marketplace,
    abi,
    walletClient,
  });
  if (status === 'success') return marketplaceContract;
  return null;
};

export const useLendingContract = () => {
  const { data: walletClient, status } = useWalletClient();
  const { chain } = useNetwork();

  const lendingContract = getContract({
    address: chain?.lendingContract,
    abi,
    walletClient,
  });
  if (status === 'success') return lendingContract;
  return null;
};

export const useTokenContract = (chainId) => {
  const { chain: defaultChain } = useNetwork();
  const { data: walletClient, status } = useWalletClient();
  const currentChain = getSupportedChain(chainId) || defaultChain;
  const tokenContract = getContract({
    address: currentChain?.wrappedToken,
    abi: wbnbABI,
    walletClient,
    chainId: Number(currentChain?.id),
  });

  if (status === 'success') return tokenContract;
  return null;
};
