import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export default function useGetCollections({ filter, limit, page, chainIds, ...props }) {
  const { refreshDate } = useContext(DataRefreshContext);
  let sortBy = 'updatedAt:desc';
  if (filter?.sort) {
    sortBy = filter.sort.id;
  }
  const config = {
    //url: `${constants.api.url}/collections`,
    url: `${constants.api.url_new}/collections`,
    method: constants.httpMethods.get,
    params: {
      ...filter,
      sortBy,
      limit,
      page,
      ...(chainIds ? { chainIds } : {}),
      refreshDate,
      ...props,
    },
  };
  const fetcher = () =>
    axios(config).then((response) => {
      return {
        ...response?.data,
        results: response?.data?.results?.filter((el) => !!el.isActive),
      };
    });

  return useAdaptor(axios.getUri(config), fetcher);
}
