import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useContext } from 'react';
import { isAddress } from 'viem';
import { DataRefreshContext } from '../components/refreshContext';

export default function useGetAcceptTermSignature(walletAddress) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, fetcher } = getAcceptTermSignatureFetcher(walletAddress, refreshDate);
  return useAdaptor(axios.getUri(config), fetcher);
}

export function getAcceptTermSignatureFetcher(walletAddress, refreshDate) {
  let config;

  if (!isAddress(walletAddress)) {
    config = {};
  } else {
    config = {
      url: `${constants.api.url_new}/users/acceptTerms`,
      method: constants.httpMethods.get,
      params: {
        walletAddress,
        refreshDate,
      },
    };
  }

  const fetcher = () =>
    axios(config).then((response) => {
      // return response.data;
      return response.data.data;
    });

  return { config, fetcher };
}
