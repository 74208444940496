import { ClickAwayListener } from '@mui/material';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';

export const DaysDropdown = ({ days, setDays, values, placeholder, position = 'left' }) => {
  const [dropdown, setDropdown] = useState(false);
  const [customExpiration, setCustomExpiration] = useState(false);

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  if (customExpiration) {
    return (
      <div className='relative flex items-center'>
        <input
          type='number'
          className='w-full rounded-lg border-jacarta-100 py-3 px-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300'
          placeholder='Amount'
          min={0}
          value={days}
          // onChange={(e) => setAssetAmount(e.target.value)}
          onChange={(e) => (String(e.target.value).length < 6 ? setDays(e.target.value) : () => {})}
        />
        <button
          onClick={() => {
            setCustomExpiration(false);
            setDays(values[0]);
          }}
          className='absolute right-2 flex h-6 w-6 items-center justify-center rounded-md        bg-jacarta-500 text-center font-semibold text-white shadow-jacarta-500 transition-all hover:bg-accent-dark'
        >
          <MdClose size={16} />
        </button>
      </div>
    );
  }

  return (
    <>
      <div
        className='dropdown-toggle1 show z-40 flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300'
        onClick={() => toggleDropdown()}
      >
        {days ? (
          <span className=''>
            {days} {days == 1 ? 'Day' : 'Days'}
          </span>
        ) : (
          <span className=''>{placeholder ? placeholder : 'Select Expiration'}</span>
        )}
      </div>

      <ClickAwayListener
        onClickAway={() => {
          setDropdown(false);
        }}
        mouseEvent='onMouseDown'
        touchEvent='onTouchStart'
      >
        <div
          className={
            dropdown
              ? `rounded-xl show absolute ${
                  position === 'left' ? 'left-0' : 'right-0'
                } z-50 w-full whitespace-nowrap bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800 sm:w-1/2`
              : 'rounded-xl absolute z-50 hidden w-full whitespace-nowrap bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800'
          }
        >
          <ul className='scrollbar-custom max-h-48 flex flex-col overflow-y-auto'>
            {(values && values.length > 0 ? values : [1, 3, 7, 30, 'Custom']).map(
              (expiration, i) => {
                return (
                  <li key={i + 'expiration'}>
                    <button
                      className='dropdown-item rounded-xl flex w-full items-center justify-between px-5 py-2 text-left font-display text-sm capitalize transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600'
                      onClick={() => {
                        toggleDropdown();
                        if (expiration === 'Custom') {
                          setCustomExpiration(true);
                        } else {
                          setDays(expiration);
                        }
                      }}
                    >
                      <span className='flex items-center space-x-3'>
                        <span className='text-jacarta-700 dark:text-white'>
                          {expiration}{' '}
                          {expiration !== 'Custom' && (expiration === 1 ? 'Day' : 'Days')}
                        </span>
                      </span>
                      {days && days === expiration && (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          width='24'
                          height='24'
                          className='mb-[3px] h-4 w-4 fill-accent'
                        >
                          <path fill='none' d='M0 0h24v24H0z' />
                          <path d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z' />
                        </svg>
                      )}
                    </button>
                  </li>
                );
              },
            )}
          </ul>
        </div>
      </ClickAwayListener>
    </>
  );
};
