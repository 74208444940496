import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from '../redux/counterSlice';
import { useChainId } from 'wagmi';

const BulkBid = ({ itemList, cartChainId }) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const chainId = useChainId();

  useEffect(() => {
    if (itemList.length > 0) setDisabled(false);
  }, [itemList]);

  const areAllItemsSameChainId = itemList?.every((item) => item.chain === itemList[0].chain);

  return (
    <>
      {areAllItemsSameChainId && chainId && cartChainId && (
        <div className='flex w-full items-center'>
          {itemList.length > 0 && chainId == cartChainId && (
            <div className='mt-2 w-full px-[16px]'>
              <button
                disabled={disabled}
                className={`w-full rounded-[12px] bg-accent py-[12px] text-center text-white ${
                  disabled ? 'bg-opacity-50' : ''
                }`}
                onClick={() => {
                  dispatch(
                    setModal({ key: 'bulkbidsModal', data: itemList, chainId: cartChainId }),
                  );
                }}
                data-testid='cartBid'
              >
                Place {itemList.length} {itemList.length === 1 ? 'Bid' : 'Bids'}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BulkBid;
