import { createContext, useContext, useEffect } from 'react';

export const FloorSweepContext = createContext({});

export const useFloorSweep = () => {
  const { sweepCount, setFloorSweepContextData, sweepingItems, sweepStatus } =
    useContext(FloorSweepContext);

  const setFloorSweepData = (data) => {
    setFloorSweepContextData({ sweepCount, sweepingItems, sweepStatus, ...data });
  };

  const setSweepingItems = (items, status = 'sweep') => {
    setFloorSweepData({ sweepingItems: items, sweepCount: items.length, sweepStatus: status });
  };

  const setSweepCount = (count, status = 'sweep') => {
    setFloorSweepData({
      sweepingItems: sweepingItems,
      sweepCount: count,
      sweepStatus: status,
    });
  };

  return {
    setSweepCount,
    sweepCount,
    setSweepingItems,
    sweepingItems,
    sweepStatus,
  };
};
