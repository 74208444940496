import { cdn, helpers, httpMethods } from './common';

export const isProd = process.env.NEXT_PUBLIC_ENV_VARIABLE === 'production';

const development = {
  api: {
    url: 'http://localhost:3000/api/v1',
    gaming_url: 'http://localhost:3001',
    url_new:
      process.env.API_HOST || process.env.NEXT_PUBLIC_API_HOST || 'http://localhost:3000/api',
    ipfs_url: 'https://ipfs.test.bit5.com/ipfs',
    version: '/v1',
  },
  httpMethods,
  ...cdn,
  helpers,
  ISEAddress: '0xCa1b4D1D43d40fb7C3E816FDacF8502A0D02a782',
};

const staging = {
  api: {
    url: '/api',
    gaming_url: 'https://gamingapi.prod.bit5.com',
    url_new:
      process.env.API_HOST || process.env.NEXT_PUBLIC_API_HOST || 'https://test.bit5.com/api',
    ipfs_url: 'https://ipfs.test.bit5.com/ipfs',
    version: '/v1',
  },
  httpMethods,
  ...cdn,
  helpers,
  ISEAddress: '0xCa1b4D1D43d40fb7C3E816FDacF8502A0D02a782',
};

const production = {
  api: {
    url: 'https://bit5uitest-58d4p.ondigitalocean.app/api',
    gaming_url: 'https://gamingapi.prod.bit5.com',
    url_new: process.env.API_HOST || process.env.NEXT_PUBLIC_API_HOST || 'https://bit5.com/api',
    ipfs_url: 'https://ipfs.bit5.com/ipfs',
    version: '/v1',
    destination: 'https://bit5apitest-mzb7g.ondigitalocean.app/bit5api/:path*',
  },
  httpMethods,
  ...cdn,
  helpers,
  ISEAddress: '0x47051ac7dE314f3844fb76B338f21A834B6bab74',
};

const constantArr = {
  development,
  staging,
  production,
};

export const constants = constantArr[process.env.NEXT_PUBLIC_ENV_VARIABLE];
