import { useEffect, useState } from 'react';

function parseFloatToFixed(string) {
  return parseFloat(string).toFixed(string.split('.')[1]?.length);
}

export const NumberInput = ({ value, onChange, className, label, decimal = false, maxLength }) => {
  const [input, setInput] = useState('');

  useEffect(() => {
    setInput((value || value == 0) && !isNaN(value) ? `${value}` : '');
  }, [value]);

  const handleChange = (event) => {
    const { value } = event.target;

    if (isValidNumber({ value, decimal, maxLength })) {
      setInput(value);
      onChange(decimal ? parseFloatToFixed(value) : parseFloat(value));
    }
  };

  return (
    <div className={`flex flex-col px-0.5 ${className}`}>
      {label && <p className='ml-1.5 mb-1.5 text-sm text-jacarta-700 dark:text-white'>{label}</p>}
      <input
        placeholder='...'
        type='text'
        value={input}
        onChange={handleChange}
        className={
          'mt-0 w-full appearance-none rounded-lg border border-jacarta-100 text-jacarta-700 placeholder-jacarta-500 focus:ring-accent dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-jacarta-300'
        }
        data-testid='numberInput'
      />
    </div>
  );
};

export const isValidNumber = ({ decimal, maxLength, value }) => {
  const regex = decimal ? /^[0-9.]*$/ : /^[0-9]*$/;
  return regex.test(value) && (maxLength ? value.length < maxLength + 1 : true) && !isNaN(value);
};
