import { encodeAbiParameters, parseAbiParameters } from 'viem';
const keccak256 = require('keccak256');
const buf2hex = (x) => '0x' + x.toString('hex');

export const hashOrder = (order) => {
  const PRIVILEGED_INFORMATION_TYPEHASH = buf2hex(
    keccak256('PrivilegedInformation(address privilegedCollection,uint256 privilegedTokenId)'),
  );
  const ORDER_TYPEHASH = buf2hex(
    keccak256(
      'Order(address issuer,address nftAddress,address paymentToken,uint256 price,uint256 tokenId,uint256 end,uint256 kind,uint256 tokenKind,uint256 globalBidAmount,PrivilegedInformation privileges)PrivilegedInformation(address privilegedCollection,uint256 privilegedTokenId)',
    ),
  );

  const data = encodeAbiParameters(
    parseAbiParameters(
      'bytes32, address, address, address, uint256, uint256, uint256, uint256, uint256, uint256, bytes32',
    ),
    [
      ORDER_TYPEHASH,
      order.issuer,
      order.nftAddress,
      order.paymentToken,
      order.price,
      order.tokenId,
      order.end,
      order.kind,
      order.tokenKind,
      order.globalBidAmount,
      buf2hex(
        keccak256(
          encodeAbiParameters(parseAbiParameters('bytes32, address, uint256'), [
            PRIVILEGED_INFORMATION_TYPEHASH,
            order.privileges.privilegedCollection,
            order.privileges.privilegedTokenId,
          ]),
        ),
      ),
    ],
  );

  return buf2hex(keccak256(data));
};
