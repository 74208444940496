import React from 'react';
import { constants } from '../../helpers/constants';
import { ChainIcon } from '../Icon';
import getChainName from '../../helpers/getChainName';

const DropDownItemNetworks = ({ selectedChainIds = [], onChange }) => {
  return (
    <>
      {/* <SearchInput
        label={'Search Collection'}
        className={'w-full mx-1 mt-1'}
        value={filterVal.search}
        onChange={(val) => setFilterVal({ ...filterVal, search: val })}
      /> */}
      <div className={'w-full'}>
        {constants.helpers.supportedChains.map((chain, i) => {
          const chainId = chain.hexId;

          return (
            <div
              key={i}
              className={`flex cursor-pointer items-center justify-between px-2 py-3 text-jacarta-500 dark:text-jacarta-200`}
              onClick={() => {
                if (selectedChainIds.some((n) => n === chainId)) {
                  onChange(selectedChainIds.filter((n) => n !== chainId));
                } else {
                  onChange([...selectedChainIds, chainId]);
                }
              }}
            >
              <div className={'flex items-center whitespace-normal'}>
                <input
                  type='checkbox'
                  className='mr-2 h-5 w-5 cursor-pointer rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600'
                  checked={selectedChainIds.some((n) => n === chainId)}
                  readOnly
                />
                <ChainIcon name={chainId} tooltip={getChainName(chainId)} type='chain' />
                <div className={'ml-2 text-sm'}>{chain.name}</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DropDownItemNetworks;
