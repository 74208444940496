import axios from 'axios';
import { ethers } from 'ethers';
import { constants } from './constants';
import { waitForTransaction } from 'wagmi/actions';

export const approveNFTLending = async (contract, walletaddress, contractAddress) => {
  try {
    const isApprovedForAll = await contract.read.isApprovedForAll([walletaddress, contractAddress]);

    if (isApprovedForAll) return true;

    const tx = await contract.write.setApprovalForAll([contractAddress, true]);
    await waitForTransaction({
      hash: tx,
    });
    return true;
  } catch (err) {
    throw err;
  }
};

export const approveSelectedCurrencyLending = async (
  tokenContract,
  walletAddress,
  borrowAmount,
  contractAddress,
) => {
  try {
    let currencyAllowance = await tokenContract.read.allowance([walletAddress, contractAddress]);

    if (currencyAllowance < borrowAmount) {
      const tx = await tokenContract.write.approve([contractAddress, ethers.constants.MaxUint256]);
      await waitForTransaction({
        hash: tx,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const cancelLendOrderByID = async ({ id }) => {
  try {
    const data = (
      await axios.post(`${constants.api.url_new}/orders/cancelLendOrderById`, {
        id,
      })
    ).data.data;
    return data;
  } catch (err) {
    throw err;
  }
};

export const cancelLendOfferByOfferID = async ({ id }) => {
  try {
    const data = (
      await axios.post(`${constants.api.url_new}/orders/cancelLendOfferByOfferID`, {
        id,
      })
    ).data.data;
    return data;
  } catch (err) {
    throw err;
  }
};

export const paybackLoanByOfferID = async ({ id }) => {
  try {
    const data = (
      await axios.post(`${constants.api.url_new}/orders/paybackLoanByOfferID`, {
        id,
      })
    ).data.data;
    return data;
  } catch (err) {
    throw err;
  }
};

export const liquidateLoanByOfferID = async ({ id }) => {
  try {
    const data = (
      await axios.post(`${constants.api.url_new}/orders/liquidateLoanByOfferID`, {
        id,
      })
    ).data.data;
    return data;
  } catch (err) {
    throw err;
  }
};

export const acceptGlobalLendOfferByOfferID = async ({ id, signature, chainId, order }) => {
  try {
    const data = (
      await axios.post(`${constants.api.url_new}/orders/acceptGlobalLendOfferByOfferID`, {
        id,
        signature,
        chainId,
        order,
      })
    ).data.data;
    return data;
  } catch (err) {
    throw err;
  }
};

export const acceptLendOfferByOfferID = async ({ id }) => {
  try {
    const data = (
      await axios.post(`${constants.api.url_new}/orders/acceptLendOfferByOfferID`, {
        id,
      })
    ).data.data;
    return data;
  } catch (err) {
    throw err;
  }
};
