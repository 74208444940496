import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  erc20ABI,
  useAccount,
  useWalletClient,
  useSignTypedData,
  usePublicClient,
  useBalance,
} from 'wagmi';
import { lendingABI } from '../../lib/lendingAbi';
import { normalizeEther } from '../../helpers/digits';
import { closeModal } from '../../redux/counterSlice';
import { ChainIcon } from '../Icon';
import { getTokenName } from '../../helpers/getChainName';
import { ModalContainer } from './modalContainer';
import { ModalItemDetails } from './modalComponents/modalItemDetails';
import useGetTokenPrice from '../../hooks/useGetTokenPrice';
import { formatEther } from 'viem';
import { getContract } from 'wagmi/actions';
import { CREATE_BORROW_EIP712_TYPES, CREATE_DYNAMIC_BORROW_EIP712_DOMAIN } from '../../lib/sign';
import Link from 'next/link';
import { approveNFTLending } from '../../helpers/lendingOperations';
import erc721ABI from '../../helpers/erc721ABI';
import { SelectNfts } from './acceptBidModal/selectNfts';
import moment from 'moment';
import { DataRefreshContext } from '../refreshContext';
import { approveSelectedCurrency, handleConvertWrapped } from '../../helpers/blockchainOperations';
import { errorToast } from '../toast';
import { getBlockExploreLink } from '../../helpers/utils';
import { useGetChain } from '../../hooks/useGetChain';
import { Button } from '../Button';

const getOrderInput = ({ offer, address, isGlobal, signingTime, itemId }) => ({
  issuer: address,
  nftAddresses: [offer.nftAddress],
  colleteralTokenIDs: [Number(itemId)],
  amounts: [Number(offer.amount) || 1],
  paymentToken: offer.paymentToken,
  borrowAmount: offer.borrowAmount,
  interestRate: Number(offer.interestRate),
  times: {
    signingTime,
    expiration: Number(offer.expiration),
    duration: Number(offer.duration),
  },
  orderKind: Number(offer.orderKind),
  tokenKinds: [Number(offer.tokenKind)],
  isGlobal,
});

const MODAL_KEY = 'lendingModal';
const LendingModal = () => {
  const { refreshHooks } = useContext(DataRefreshContext);
  const dispatch = useDispatch();
  const [nfts, setNfts] = useState([]);
  const [signingTime, setSigningTime] = useState(moment().unix());
  const { modal } = useSelector((state) => state.counter);
  const { address } = useAccount();
  const { item, collection, refresh, type, ...offer } = modal?.data;
  const isGlobal = offer?.isGlobal;
  const isListing = String(offer?.orderKind) === '1';
  const { chain } = useGetChain(item?.chain);

  const { signTypedDataAsync } = useSignTypedData({
    domain: CREATE_DYNAMIC_BORROW_EIP712_DOMAIN(item?.chain),
    types: CREATE_BORROW_EIP712_TYPES,
    message: getOrderInput({
      offer,
      address,
      isGlobal,
      signingTime,
      itemId: nfts[0],
    }),
    primaryType: 'Order',
  });

  const signer = useWalletClient();
  const publicClient = usePublicClient();

  const { data: nativeBalance } = useBalance({ address, watch: true });
  const { data: wrappedTokenBalance } = useBalance({
    address,
    token: offer?.paymentToken,
    watch: true,
  });
  const { data: issuerWrappedTokenBalance } = useBalance({
    address: offer?.issuer,
    token: offer?.paymentToken,
    watch: true,
  });
  const totalBalance =
    parseFloat(nativeBalance?.formatted || '0') + parseFloat(wrappedTokenBalance?.formatted || '0');
  const isTotalBalanceEnough = totalBalance >= parseFloat(formatEther(offer?.borrowAmount || '0'));

  const { usdPrice: tokenPrice } = useGetTokenPrice(item?.chain);

  const lendingContract = getContract({
    address: chain?.lendingContract,
    abi: lendingABI,
    walletClient: signer.data,
  });

  const selectedCurrencyContract = getContract({
    address: offer.paymentToken,
    abi: erc20ABI,
    walletClient: signer.data,
  });

  const offerContract = getContract({
    address: item?.collectionAddress,
    abi: erc721ABI,
    walletClient: signer.data,
  });

  const types = {
    cancel: {
      title: `Cancel the ${isGlobal ? 'global' : ''} ${isListing ? 'listing' : 'offer'}`,
      buttonText: 'Cancel',
      progressText: `Canceling the ${isGlobal ? 'global' : ''} ${
        isListing ? 'listing' : 'offer'
      }...`,
      contractFn: 'cancelOrder',
      // fn: isListing ? cancelLendOrderByID : cancelLendOfferByOfferID,
      toast: `${isGlobal ? 'Global' : ''} ${isListing ? 'Listing' : 'Offer'} canceled!`,
      approve: false,
      tokenIdParam: false,
    },
    payback: {
      title: 'Payback the loan',
      buttonText: 'Payback',
      progressText: 'Paybacking the loan...',
      contractFn: 'payback',
      // fn: paybackLoanByOfferID,
      toast: 'Loan paybacked!',
      approve: approveSelectedCurrency,
      approveParams: [
        selectedCurrencyContract,
        address,
        offer.borrowAmount,
        chain?.lendingContract,
      ],
      tokenIdParam: false,
      checkBalance: true,
    },
    liquidate: {
      title: 'Liquidate the loan',
      buttonText: 'Liquidate',
      progressText: 'Liquidating the loan...',
      contractFn: 'liquidate',
      // fn: liquidateLoanByOfferID,
      toast: 'Loan liquidated!',
      approve: approveSelectedCurrency,
      approveParams: [
        selectedCurrencyContract,
        address,
        offer.borrowAmount,
        chain?.lendingContract,
      ],
      tokenIdParam: false,
    },
    accept: {
      title: `Accept the ${isGlobal ? 'global' : ''} offer`,
      buttonText: 'Accept',
      progressText: 'Accepting the offer...',
      contractFn: isGlobal ? 'acceptGlobalOffer' : 'processOrder',
      // fn: isGlobal ? acceptGlobalLendOfferByOfferID : acceptLendOfferByOfferID,
      toast: 'Offer accepted!',
      approve: approveNFTLending,
      approveParams: [offerContract, address, chain?.lendingContract],
      tokenIdParam: isGlobal ? true : false,
      checkIssuerBalance: true,
    },
  };

  const defaultButtonText = types[type].buttonText;
  const [buttonText, setButtonText] = useState(defaultButtonText);

  const onSubmit = async () => {
    // console.log(id);

    if (signer.status !== 'success') return;

    if (types[type].checkBalance && !isTotalBalanceEnough) return errorToast('Not enough balance');
    if (types[type].checkIssuerBalance && !isIssuerBalanceEnough)
      return errorToast("Lender doesn't have enough balance");

    if (types[type].checkBalance)
      await handleConvertWrapped({
        amount: offer.borrowAmount,
        balance: wrappedTokenBalance,
        contract: selectedCurrencyContract,
        parse: false,
      });
    if (types[type].approve) {
      try {
        await types[type].approve(...types[type].approveParams);
      } catch (err) {
        return errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
      }
    }

    setButtonText(types[type].progressText);
    try {
      const inputForOrderArg = [
        offer?.issuer,
        [item?.collectionAddress],
        // isGlobal ? [0] : [Number(offer?.colleteralTokenID)],
        [Number(offer?.colleteralTokenID)],
        [Number(offer?.amount)],
        offer?.paymentToken,
        offer?.borrowAmount,
        Number(offer?.interestRate),
        [Number(offer?.signingTime), Number(offer?.expiration), Number(offer?.duration)],
        Number(offer?.orderKind),
        [Number(offer?.tokenKind)],
        isGlobal,
      ];

      // let signature, order;

      // if (type === 'accept' && isGlobal) {
      //   setSigningTime(moment().unix());
      //   signature = await signTypedDataAsync();
      //   order = getOrderInput({
      //     offer,
      //     address,
      //     isGlobal,
      //     signingTime: signingTime,
      //     itemId: nfts[0],
      //   });
      // }
      //   console.log('offer: ', offer);
      //console.log('inputForOrderArg', inputForOrderArg);

      const tx = await lendingContract.write[types[type].contractFn]([
        inputForOrderArg,
        offer?.signature,
        ...(types[type].tokenIdParam ? [nfts] : []),
      ]);

      await publicClient.waitForTransactionReceipt({
        confirmations: 2,
        hash: tx,
      });

      // if (type === 'accept' && isGlobal) {
      //   await types[type].fn({
      //     id: offer?.id,
      //     signature,
      //     order,
      //     chainId: item?.chain,
      //   });
      // } else await types[type].fn({ id: offer?.id });
      toast.success((t) => (
        <span>
          {/* Order cancelled! Here is your{" "} */}
          {types[type].toast} Here is your{' '}
          <Link href={getBlockExploreLink(tx, 'transaction', chain.id)}>
            <a target='_blank' rel='noopener noreferrer'>
              <b>transaction.</b>
            </a>
          </Link>
        </span>
      ));

      refresh && refresh();
      refreshHooks();
      dispatch(closeModal());
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      // setButtonLoading(false);
      // refreshOffers();
      setButtonText(defaultButtonText);
    }
  };

  const isIssuerBalanceEnough = !isGlobal
    ? parseFloat(issuerWrappedTokenBalance?.formatted || '0') >=
      parseFloat(formatEther(offer?.borrowAmount || '0'))
    : parseFloat(issuerWrappedTokenBalance?.formatted || '0') >=
      parseFloat(formatEther(offer?.borrowAmount || '0')) * nfts?.length;
  const isButtonDisabled = buttonText !== defaultButtonText;

  return (
    <ModalContainer
      modalKey={MODAL_KEY}
      //   title={`Cancel ${isGlobal ? "Global" : ""} Offer`}
      title={types[type].title}
    >
      {/* <Toaster position='top-right' /> */}

      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        {/* {!isGlobal && ( */}
        <ModalItemDetails item={item} collection={collection} isGlobal={isGlobal} />
        {/* )} */}
        {!isGlobal && (
          <div className='relative flex border-jacarta-100 pb-4 dark:border-jacarta-600'>
            <div className='mt-2 w-full'>
              <div className='flex w-full items-center'>
                <div className='font-semibold'>
                  {isListing ? 'Current Listing Price:' : 'Current Bid:'}
                </div>
                <div className='ml-auto flex w-fit space-x-1'>
                  <span className='ml-auto flex items-center whitespace-nowrap'>
                    <ChainIcon
                      width={14}
                      name={item?.chain}
                      tooltip={getTokenName(chain.id)}
                      sideGap={true}
                      // pt={0.5}
                    />
                    <span className='text-sm font-medium tracking-tight dark:text-jacarta-100'>
                      {normalizeEther(offer?.borrowAmount)}
                    </span>
                  </span>
                  <div className='text-sm leading-7 dark:text-jacarta-300'>
                    ~ ${normalizeEther(tokenPrice * normalizeEther(offer?.borrowAmount))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isGlobal && type === 'accept' && (
          <SelectNfts
            collectionAddress={offer?.nftAddress}
            walletAddress={address}
            amount={/* offer?.amount */ 1}
            setNfts={setNfts}
          />
        )}

        {/* <!-- Terms --> */}
      </div>
      {/* <!-- end body --> */}

      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {(types[type].tokenIdParam ? nfts?.length : true) ? (
            <Button
              key={'lending_button'}
              onClick={onSubmit}
              text={buttonText}
              disabled={isButtonDisabled}
            />
          ) : (
            'Select NFTs'
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default LendingModal;
