import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { ethers } from 'ethers';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export const getItemActivitiesFetcher = (collectionAddress, itemId, refreshDate) => {
  let config;

  if (!ethers.utils.isAddress(collectionAddress)) {
    config = {};
  } else {
    config = {
      url: `${constants.api.url_new}/items/getItemActivities`, //`${constants.api.url}/itemActivities/getItemActivities`,//
      method: constants.httpMethods.get,
      params: { collectionAddress, itemId, limit: 250, sortBy: 'updatedAt:desc', refreshDate },
    };
  }

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data;
    });
  return { config, fetcher };
};

export default function useGetItemActivities(collectionAddress, itemId) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, fetcher } = getItemActivitiesFetcher(collectionAddress, itemId, refreshDate);
  return useAdaptor(axios.getUri(config), fetcher);
}
