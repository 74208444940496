import { RiDeleteBin2Line } from 'react-icons/ri';
import { useCartFunctions } from './useCartFunctions';
import { TbTrash } from 'react-icons/tb';

export const CartHeader = () => {
  const { clearCart, toggleCart, itemList } = useCartFunctions();
  return (
    <div className='mt-[15px] mb-[15px] flex w-full items-center px-[16px]'>
      <h1 className='text-[20px] font-bold text-[#000] dark:text-white'>Your Cart</h1>
      <div className='ml-auto flex items-center'>
        {itemList.length > 0 && (
          <button
            onClick={() => {
              clearCart();
            }}
            data-testid='clearCart'
            className='mr-4 rounded-[12px] border border-solid border-[#E7E8EC] bg-[#FDFAFD] px-[16px] py-[8px] text-[16px] hover:text-accent-light dark:border-[#363A5D] dark:bg-[#363A5D] dark:text-jacarta-200 dark:hover:text-white'
          >
            <RiDeleteBin2Line size={20} />
          </button>
        )}

        <svg
          className='light:hidden cursor-pointer dark:visible'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          onClick={() => toggleCart(true)}
          data-testid='exitCart'
        >
          <path d='M7 4L17 12L7 20' stroke='white' strokeWidth='2' strokeLinecap='round' />
        </svg>

        <svg
          className='light:visible cursor-pointer dark:hidden'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          onClick={() => toggleCart(true)}
        >
          <path d='M1 1L11 9L1 17' stroke='black' strokeWidth='2' strokeLinecap='round' />
        </svg>
      </div>
    </div>
  );
};
