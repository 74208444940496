export const onlyNumber = (e, n) => {
    console.log(e.code, e.keyCode, e.target.value);
    console.log(e);

    const dotLength = ("" + e.target.value).split("").filter((f) => f === ".").length;

    const targetValue = ("" + e.target.value);

    const isMaxLen = targetValue.length < 10;
    const isMaxDec = targetValue.indexOf(".") > 0 ? targetValue.split(".")[1].length < 4 : true;

    if (isMaxLen && isMaxDec) {
        if (!isNaN(e.key)) return true;
        if (e.key === '.' && dotLength < 1) return true;
    }

    if (e.code === 'Backspace') return true;
    if (e.keyCode === 8) return true;

    if (('' + e.key).toLowerCase() === 'a' && e.metaKey) return true;
    if (('' + e.key).toLowerCase() === 'a' && e.ctrlKey) return true;

    // if (e.code === 'ArrowUp') return true;
    // if (e.code === 'ArrowDown') return true;
    if (e.code === 'ArrowLeft') return true;
    if (e.code === 'ArrowRight') return true;

    e.preventDefault();
    return false;
}