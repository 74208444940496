import { ethers } from 'ethers';
import { getAddress, parseEther } from 'ethers/lib/utils';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useBalance, useWalletClient, usePublicClient } from 'wagmi';
import { getContract } from '@wagmi/core';
import { approveERC20 } from '../../helpers/blockchainOperations';
import { constants } from '../../helpers/constants';
import { normalizeNumber } from '../../helpers/digits';
import erc20ABI from '../../helpers/erc20ABI';
import wbnbABI from '../../helpers/wbnbABI';
import axios from '../../lib/axios';
import { CREATE_BORROW_EIP712_TYPES, CREATE_DYNAMIC_BORROW_EIP712_DOMAIN } from '../../lib/sign';
import { closeModal } from '../../redux/counterSlice';
import Icon from '../Icon';
import { ModalContainer } from './modalContainer';
import { getCoinGeckoWrappedTokenId, getTokenName } from '../../helpers/getChainName';
import useGetTokenPrice from '../../hooks/useGetTokenPrice';
import { DataRefreshContext } from '../refreshContext';
import { AssetToBorrowDropdown } from '../dropdown/lend/asset_to_borrow_dropdown';
import { DaysDropdown } from '../dropdown/lend/days_dropdown';
import moment from 'moment';
import { Typography } from '@mui/material';
import calculateAPR from '../../helpers/calculateAPR';
import useGetLendOrder from '../../hooks/useGetLendOrder';
import { useGetChain } from '../../hooks/useGetChain';
import { Button, SwitchNetworkButton } from '../Button';
import { errorToast } from '../../components/toast';

const MODAL_KEY = 'lendOfferModal';
const LendOfferModal = () => {
  const dispatch = useDispatch();
  const { address } = useAccount();
  const { refreshHooks } = useContext(DataRefreshContext);
  const { modal } = useSelector((state) => state.counter);
  const { item, owner } = modal?.data;
  const isOwner = owner === address;
  const collection = modal?.data?.collection || item?.collection;
  const [assetToBorrow, setAssetToBorrow] = useState(constants.helpers.lending.ASSETS_TO_BORROW[0]);
  const [expireDays, setExpireDays] = useState(1);
  const [borrowDuration, setBorrowDuration] = useState(7);
  const [interestRate, setInterestRate] = useState(1);
  const [apr, setApr] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const isItemExists = !!item;
  const [activeTab, setActiveTab] = useState(isItemExists && !isOwner ? 0 : 1);
  const [assetAmount, setAssetAmount] = useState('1');
  const [usdAmount, setUsdAmount] = useState(0);
  const defaultText = 'Place Bid';
  const [buttonText, setButtonText] = useState(defaultText);
  const { chain, isChainActive } = useGetChain(collection?.chain);

  const wrappedToken =
    assetToBorrow?.name?.toLowerCase() || getCoinGeckoWrappedTokenId(collection?.chain);

  let { data: nativeBalance } = useBalance({ address });
  let { data: wrappedTokenBalance } = useBalance({
    address,
    token: chain?.wrappedToken,
  });

  const signer = useWalletClient();
  const publicClient = usePublicClient();

  const wbnbContract = getContract({
    address: chain?.wrappedToken,
    abi: wbnbABI,
    publicClient,
    walletClient: signer.data,
  });

  const { usdPrice: tokenPrice } = useGetTokenPrice(collection?.chain);
  const { data: lendOrder } = useGetLendOrder(collection?.address, item?.itemId);

  const handleConvert = async () => {
    try {
      const diff = balanceDifference();

      if (assetAmount > parseFloat(wrappedTokenBalance?.formatted) && 0 > diff) {
        const tx = await wbnbContract.write.deposit({
          value: parseEther(Math.abs(diff).toString()),
        });
        await publicClient.waitForTransactionReceipt({
          confirmations: 2,
          hash: tx,
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const tokenContract = getContract({
    address: assetToBorrow?.address,
    abi: erc20ABI,
    publicClient,
    walletClient: signer.data,
  });

  const handlePlaceBid = async () => {
    if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);
    if (!assetAmount || assetAmount == 0) return toast.error('Enter a valid number');
    if (!assetToBorrow) return toast.error('Select The Currency!');
    if (!expireDays || expireDays === '')
      return toast.error('Set How Many Days You Want It To Expire!');
    if (borrowDuration === '') return toast.error('Set Duration!');

    try {
      setButtonText('Converting tokens...');
      await handleConvert();

      setButtonText('Approving tokens...');
      // get approve from bidder
      const isGlobal = activeTab === 1;
      const price = ethers.utils
        .parseUnits(isGlobal ? String(quantity * Number(assetAmount)) : assetAmount, 'ether')
        .toString();
      await approveERC20(tokenContract, address, price, chain?.marketplace);

      setButtonText('Giving the bid...');
      const signingTime = moment().unix();
      const duration = moment.duration(borrowDuration, 'days').asSeconds();
      const expiration = moment.duration(expireDays, 'days').asSeconds();
      const issuer = getAddress(address);
      const orderKind = 0;
      const tokenKinds = [collection?.contractType === 'ERC721' ? 0 : 1];
      const nftAddresses = [collection?.address || item?.collectionAddress];
      const colleteralTokenIDs = [Number(isGlobal ? '1' : item?.itemId ?? '1')];
      const amounts = isGlobal ? [Number(quantity)] : [1];
      const paymentToken = assetToBorrow.address;
      const paymentTokenName = assetToBorrow.name;
      const borrowAmount = price;
      const _interestRate = Number(interestRate) * 100;

      let inputForSignature = {
        issuer,
        nftAddresses,
        colleteralTokenIDs: colleteralTokenIDs,
        amounts,
        paymentToken,
        borrowAmount,
        interestRate: _interestRate,
        times: {
          signingTime,
          expiration,
          duration,
        },
        orderKind,
        tokenKinds,
        isGlobal,
      };

      const typedData = JSON.stringify({
        domain: CREATE_DYNAMIC_BORROW_EIP712_DOMAIN(chain.id),
        types: CREATE_BORROW_EIP712_TYPES,
        message: inputForSignature,
        primaryType: 'Order',
      });

      const signature = await (window?.ethereum?.request)({
        method: 'eth_signTypedData_v4',
        params: [address, typedData],
      });

      const input = {
        issuer,
        amount: amounts[0].toString(),
        borrowAmount,
        colleteralTokenID: colleteralTokenIDs[0].toString(),
        duration: duration.toString(),
        expiration: expiration.toString(),
        signingTime: signingTime.toString(),
        interestRate: _interestRate,
        isGlobal,
        nftAddress: nftAddresses[0],
        orderKind: orderKind,
        tokenKind: tokenKinds[0],
        paymentToken,
        paymentTokenName,
        signature,
        lendOrdersId: lendOrder?.id || null,
      };

      const res = (await axios.post(`${constants.api.url_new}/orders/giveLendOffer`, input)).data
        .data;

      if (!res) throw 'Failed to give bid';
      toast.success(`Bid given!`);

      refreshHooks();
      dispatch(closeModal());
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setButtonText(defaultText);
    }
  };

  const handleEThAmount = (e) => {
    e.preventDefault();
    // handleAmountChanged(e?.target?.value);
    const newAmount = e?.target?.value.replace(',', '.');
    const zeroCounts = newAmount.split('.');

    if (zeroCounts?.length >= 1 && zeroCounts[1]?.length > 4) return;
    setAssetAmount(newAmount);

    const usd = Number(tokenPrice * e.target.value * quantity).toFixed(2);
    setUsdAmount(usd);
  };

  // const bnbPrice = useGetUSDPriceOfToken('wbnb', 1)?.data?.wbnb?.usd;

  const balanceDifference = () => {
    try {
      if (!assetAmount || !wrappedTokenBalance) return 0;

      return (parseFloat(wrappedTokenBalance?.formatted) - assetAmount).toFixed(18);
    } catch (err) {
      return 1;
    }
  };

  const totalBalance =
    parseFloat(nativeBalance?.formatted) + parseFloat(wrappedTokenBalance?.formatted);
  const isButtonDisabled = buttonText !== defaultText;

  useEffect(() => {
    assetAmount &&
      borrowDuration &&
      interestRate &&
      setApr(Number(calculateAPR(borrowDuration, interestRate)));

    return () => {};
  }, [assetAmount, borrowDuration, interestRate]);

  return (
    <ModalContainer
      modalKey={MODAL_KEY} /* title={'Give Lend Offer'} */
      title={
        isItemExists && !isOwner && activeTab === 0 ? 'Give Lend Offer' : 'Give Global Lend Offer'
      }
    >
      {isItemExists && !isOwner && (
        <div
          className={
            'shadow mx-2 mt-4 items-center text-center ' +
            'text-sm font-medium text-jacarta-700 ' +
            'flex px-2 dark:text-jacarta-300'
          }
        >
          <button
            className={`w-full rounded-l-lg px-2 py-2 ${
              activeTab === 0
                ? 'border border-accent bg-accent text-jacarta-100'
                : 'cursor-pointer border border-jacarta-200 dark:border-jacarta-400'
            }`}
            {...(activeTab === 0 ? {} : { onClick: () => setActiveTab(0) })}
          >
            Offer to Item
          </button>
          <button
            className={`w-full rounded-r-lg px-2 py-2 ${
              activeTab === 1
                ? 'border border-accent bg-accent text-jacarta-100'
                : 'cursor-pointer border border-jacarta-200 dark:border-jacarta-400'
            }`}
            {...(activeTab === 1 ? {} : { onClick: () => setActiveTab(1) })}
          >
            Offer to Collection
          </button>
        </div>
      )}

      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        <div className='mb-2 flex items-center justify-between'>
          <span className='text font-display font-semibold text-jacarta-700 dark:text-white'>
            {collection?.name ?? item?.metadata?.name?.split(' #')[0] ?? ''}
          </span>
        </div>

        {activeTab === 1 && (
          <div className='relative'>
            <label
              htmlFor='item-external-link'
              className='mb-2 block font-display text-jacarta-700 dark:text-white'
            >
              Global Offer Quantity
            </label>
            <div className='relative flex items-center'>
              <input
                type='number'
                disabled
                className='h-12 w-full rounded-lg border-jacarta-100 bg-jacarta-100 py-3 px-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-800 dark:text-white dark:placeholder:text-jacarta-300'
                placeholder='Quantity'
                min={0}
                value={quantity}
                // onChange={(e) => setAssetAmount(e.target.value)}
                // onChange={(e) =>
                //   String(e.target.value).length < 6 ? setQuantity(e.target.value) : () => {}
                // }
              />
            </div>
          </div>
        )}

        <div className='flex w-full space-x-3'>
          <div className='flex-1'>
            <div className='mb-2 mt-5 flex items-center justify-between'>
              <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
                Amount
              </span>
            </div>

            <div className='relative mb-2 flex items-center'>
              <input
                type='number'
                className='h-12 w-full rounded-lg border-jacarta-100 py-3 px-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300'
                placeholder='Amount'
                value={assetAmount}
                //onChange={(e) => handleEThAmount(e)}
                onChange={(e) =>
                  String(e.target.value).length < 9 ? handleEThAmount(e) : () => {}
                }
                onWheel={(e) => e.target.blur()}
              />

              {/* <div className='flex flex-1 justify-end self-stretch rounded-lg border border-jacarta-100 bg-jacarta-50 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white'>
                <span className='self-center px-2 text-sm'>~${usdAmount}</span>
            </div> */}
            </div>
          </div>

          <div className='relative sm:min-w-[16ch]'>
            <div className='mb-2 mt-5 flex items-center justify-between'>
              <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
                Currency
              </span>
            </div>

            {/* dropdown */}
            <div className='dropdown cursor-pointer'>
              <AssetToBorrowDropdown
                id={'assetToBorrow'}
                assetToBorrow={assetToBorrow}
                setAssetToBorrow={setAssetToBorrow}
              />
            </div>
          </div>
        </div>

        <div className='relative flex flex-row space-x-3'>
          <div className='w-full'>
            <div className='mt-5 flex items-center justify-between'>
              <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
                Expire After
              </span>
            </div>
            <div className='mt-3 mb-6'>
              <div className='dropdown cursor-pointer'>
                <DaysDropdown
                  position={'left'}
                  days={expireDays}
                  setDays={setExpireDays}
                  values={[1, 3, 7, 30, 'Custom']}
                  placeholder={'Select Expiration'}
                />
              </div>
            </div>
          </div>

          <div className='w-full'>
            <div className='mt-5 flex items-center justify-between'>
              <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
                Duration
              </span>
            </div>
            <div className='mt-3 mb-6'>
              <div className='dropdown cursor-pointer'>
                <DaysDropdown
                  position={'right'}
                  days={borrowDuration}
                  setDays={setBorrowDuration}
                  values={[7, 30, 60, 90, 'Custom']}
                  placeholder={'Select Duration'}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='relative'>
          <label
            htmlFor='item-external-link'
            className='mb-2 block font-display text-jacarta-700 dark:text-white'
          >
            Interest Rate
          </label>
          <div className='relative flex items-center'>
            <input
              type='number'
              className='w-full rounded-lg border-jacarta-100 py-3 px-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300'
              placeholder='Specify Interest Rate'
              min={1}
              value={interestRate}
              onChange={(e) =>
                String(e.target.value).length < 6 ? setInterestRate(e.target.value) : () => {}
              }
              onWheel={(e) => e.target.blur()}
            />
            {interestRate !== '' && (
              <span style={{ right: '7%' }} className='text-black absolute dark:text-jacarta-300'>
                %
              </span>
            )}
          </div>
        </div>

        <div className='relative mt-5 h-full'>
          <label
            htmlFor='item-external-link'
            className='block font-display text-jacarta-700 dark:text-white'
          >
            Payback Amount
          </label>

          <Typography className='text-black mt-2 dark:text-jacarta-200 '>
            {Number(
              Number(assetAmount) + (Number(assetAmount) / 100) * Number(interestRate),
            ).toFixed(2)}{' '}
            {assetAmount &&
              borrowDuration &&
              interestRate &&
              `(APR: ${calculateAPR(borrowDuration, interestRate)}%)`}
          </Typography>
          {Number(apr) > 500 && (
            <Typography className='text-black mt-6 dark:text-jacarta-200 '>
              <span className='!text-[#ef5353]'>Warning: </span> APR is too high, please check your
              input.
            </Typography>
          )}
        </div>

        <div className='flex items-center justify-end'>
          Balance:&nbsp; <Icon name={`icon-${wrappedToken}`} tooltip={wrappedToken.toUpperCase()} />
          <span className='text-sm dark:text-jacarta-400'>
            {normalizeNumber(wrappedTokenBalance?.formatted)}
          </span>
        </div>

        {/* <!-- Terms --> */}

        <div className='alert-info mt-2 w-full rounded-lg p-2'>
          Note: Bit5 does not escrow your money. Which means, you can bid without paying for gas
          fees. Conversion from {getTokenName(collection?.chain)} to{' '}
          {getCoinGeckoWrappedTokenId(collection?.chain).toUpperCase()} is done during the offer and
          if the {getCoinGeckoWrappedTokenId(collection?.chain).toUpperCase()} balance falls below
          the offered value, the offer cannot be accepted.
        </div>
      </div>
      {/* <!-- end body --> */}

      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4'>
          {!isChainActive ? (
            <SwitchNetworkButton chainId={chain.id} />
          ) : totalBalance > assetAmount ? (
            apr <= 500 ? (
              <Button
                key={'give_lend_offer_button'}
                onClick={handlePlaceBid}
                text={buttonText}
                disabled={isButtonDisabled}
              />
            ) : (
              'Apr is Too High (Max: 500)'
            )
          ) : (
            'Insufficient Funds'
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default LendOfferModal;
