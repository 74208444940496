import PropTypes from 'prop-types';

export const TradingViewLightLogo = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='w-full max-w-[308px]'
      viewBox='0 0 1508 257'
      fill='none'
      {...props}
    >
      <path
        d='M375.342 203.117H405.008V86.8882H443.819V58.847H336.938V86.8882H375.342V203.117Z'
        fill='#131722'
      />
      <path
        d='M443.862 203.117H471.7V154.553C471.7 137.687 480.437 127.527 493.645 127.527C498.318 127.527 501.976 128.34 506.243 129.763V101.518C502.789 100.502 499.944 100.299 496.896 100.299C485.517 100.299 475.154 107.817 471.7 117.774V101.518H443.862V203.117Z'
        fill='#131722'
      />
      <path
        d='M559.674 205.352C573.695 205.352 584.261 198.24 588.935 191.738V203.117H616.773V101.518H588.935V112.897C584.261 106.395 573.695 99.2832 559.674 99.2832C532.649 99.2832 511.923 123.667 511.923 152.317C511.923 180.968 532.649 205.352 559.674 205.352ZM565.567 179.952C550.124 179.952 539.964 168.37 539.964 152.317C539.964 136.265 550.124 124.683 565.567 124.683C581.01 124.683 591.17 136.265 591.17 152.317C591.17 168.37 581.01 179.952 565.567 179.952Z'
        fill='#131722'
      />
      <path
        d='M683.053 205.352C697.073 205.352 707.639 198.24 712.313 191.738V203.117H740.151V50.7192H712.313V112.897C707.639 106.395 697.073 99.2832 683.053 99.2832C656.028 99.2832 635.302 123.667 635.302 152.317C635.302 180.968 656.028 205.352 683.053 205.352ZM688.945 179.952C673.502 179.952 663.343 168.37 663.343 152.317C663.343 136.265 673.502 124.683 688.945 124.683C704.388 124.683 714.548 136.265 714.548 152.317C714.548 168.37 704.388 179.952 688.945 179.952Z'
        fill='#131722'
      />
      <path
        d='M779 84.8562C788.956 84.8562 796.881 76.9315 796.881 67.1781C796.881 57.4247 788.956 49.5 779 49.5C769.449 49.5 761.322 57.4247 761.322 67.1781C761.322 76.9315 769.449 84.8562 779 84.8562ZM765.182 203.117H793.02V101.518H765.182V203.117Z'
        fill='#131722'
      />
      <path
        d='M817.72 203.117H845.558V153.537C845.558 134.03 855.108 124.683 868.112 124.683C879.695 124.683 885.994 133.623 885.994 148.254V203.117H913.832V143.58C913.832 117.164 899.202 99.2832 874.615 99.2832C861.407 99.2832 851.044 104.973 845.558 113.101V101.518H817.72V203.117Z'
        fill='#131722'
      />
      <path
        d='M984.673 176.701C970.652 176.701 958.867 166.135 958.867 150.692C958.867 135.046 970.652 124.683 984.673 124.683C998.693 124.683 1010.48 135.046 1010.48 150.692C1010.48 166.135 998.693 176.701 984.673 176.701ZM981.422 247.617C1012.51 247.617 1036.08 231.767 1036.08 195.192V101.518H1008.24V112.897C1002.76 104.973 991.785 99.2832 978.78 99.2832C952.161 99.2832 930.826 122.041 930.826 150.692C930.826 179.139 952.161 201.897 978.78 201.897C991.785 201.897 1002.76 196.005 1008.24 188.486V195.395C1008.24 211.651 997.474 223.233 981.015 223.233C969.636 223.233 958.257 219.372 949.316 210.635L934.077 231.361C945.659 242.537 963.743 247.617 981.422 247.617Z'
        fill='#131722'
      />
      <path
        d='M1094.94 203.117H1121.15L1180.89 58.847H1148.38L1108.15 159.023L1067.31 58.847H1035.4L1094.94 203.117Z'
        fill='#131722'
      />
      <path
        d='M1206.43 84.8562C1216.39 84.8562 1224.32 76.9315 1224.32 67.1781C1224.32 57.4247 1216.39 49.5 1206.43 49.5C1196.88 49.5 1188.76 57.4247 1188.76 67.1781C1188.76 76.9315 1196.88 84.8562 1206.43 84.8562ZM1192.62 203.117H1220.46V101.518H1192.62V203.117Z'
        fill='#131722'
      />
      <path
        d='M1292.5 205.352C1313.84 205.352 1330.09 196.411 1339.64 182.594L1318.92 167.151C1314.24 174.263 1305.5 179.952 1292.7 179.952C1279.29 179.952 1267.3 172.434 1265.68 158.82H1341.67C1342.28 154.553 1342.08 151.708 1342.08 149.27C1342.08 116.148 1318.71 99.2832 1292.09 99.2832C1261.21 99.2832 1238.65 122.244 1238.65 152.317C1238.65 184.423 1262.22 205.352 1292.5 205.352ZM1266.69 139.922C1269.34 127.527 1280.92 122.244 1291.28 122.244C1301.64 122.244 1311.8 127.731 1314.24 139.922H1266.69Z'
        fill='#131722'
      />
      <path
        d='M1446.37 203.117H1474.01L1507.54 101.518H1477.67L1458.57 166.541L1437.84 101.518H1416.5L1395.98 166.541L1376.68 101.518H1347.01L1380.74 203.117H1408.38L1427.27 145.409L1446.37 203.117Z'
        fill='#131722'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M112.284 203.186H58.8155V112.289H0V58.8203H112.284V203.186ZM192.487 90.9015C192.487 108.619 178.124 122.983 160.406 122.983C142.688 122.983 128.325 108.619 128.325 90.9015C128.325 73.1835 142.688 58.8203 160.406 58.8203C178.124 58.8203 192.487 73.1835 192.487 90.9015ZM160.406 203.186L221.895 58.8203H286.057L224.568 203.186H160.406Z'
        fill='#131722'
      />
    </svg>
  );
};

TradingViewLightLogo.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string || PropTypes.number,
  height: PropTypes.string || PropTypes.number,
};

export const TradingViewDarkLogo = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='w-full max-w-[308px]'
      viewBox='0 0 1508 257'
      fill='none'
      {...props}
    >
      <path
        d='M375.342 203.117H405.008V86.8882H443.819V58.847H336.938V86.8882H375.342V203.117Z'
        fill='white'
      />
      <path
        d='M443.862 203.117H471.7V154.553C471.7 137.687 480.437 127.527 493.645 127.527C498.318 127.527 501.976 128.34 506.243 129.763V101.518C502.789 100.502 499.944 100.299 496.896 100.299C485.517 100.299 475.154 107.817 471.7 117.774V101.518H443.862V203.117Z'
        fill='white'
      />
      <path
        d='M559.674 205.352C573.695 205.352 584.261 198.24 588.935 191.738V203.117H616.773V101.518H588.935V112.897C584.261 106.395 573.695 99.2832 559.674 99.2832C532.649 99.2832 511.923 123.667 511.923 152.317C511.923 180.968 532.649 205.352 559.674 205.352ZM565.567 179.952C550.124 179.952 539.964 168.37 539.964 152.317C539.964 136.265 550.124 124.683 565.567 124.683C581.01 124.683 591.17 136.265 591.17 152.317C591.17 168.37 581.01 179.952 565.567 179.952Z'
        fill='white'
      />
      <path
        d='M683.053 205.352C697.073 205.352 707.639 198.24 712.313 191.738V203.117H740.151V50.7192H712.313V112.897C707.639 106.395 697.073 99.2832 683.053 99.2832C656.028 99.2832 635.302 123.667 635.302 152.317C635.302 180.968 656.028 205.352 683.053 205.352ZM688.945 179.952C673.502 179.952 663.343 168.37 663.343 152.317C663.343 136.265 673.502 124.683 688.945 124.683C704.388 124.683 714.548 136.265 714.548 152.317C714.548 168.37 704.388 179.952 688.945 179.952Z'
        fill='white'
      />
      <path
        d='M779 84.8562C788.956 84.8562 796.881 76.9315 796.881 67.1781C796.881 57.4247 788.956 49.5 779 49.5C769.449 49.5 761.322 57.4247 761.322 67.1781C761.322 76.9315 769.449 84.8562 779 84.8562ZM765.182 203.117H793.02V101.518H765.182V203.117Z'
        fill='white'
      />
      <path
        d='M817.72 203.117H845.558V153.537C845.558 134.03 855.108 124.683 868.112 124.683C879.695 124.683 885.994 133.623 885.994 148.254V203.117H913.832V143.58C913.832 117.164 899.202 99.2832 874.615 99.2832C861.407 99.2832 851.044 104.973 845.558 113.101V101.518H817.72V203.117Z'
        fill='white'
      />
      <path
        d='M984.673 176.701C970.652 176.701 958.867 166.135 958.867 150.692C958.867 135.046 970.652 124.683 984.673 124.683C998.693 124.683 1010.48 135.046 1010.48 150.692C1010.48 166.135 998.693 176.701 984.673 176.701ZM981.422 247.617C1012.51 247.617 1036.08 231.767 1036.08 195.192V101.518H1008.24V112.897C1002.76 104.973 991.785 99.2832 978.78 99.2832C952.161 99.2832 930.826 122.041 930.826 150.692C930.826 179.139 952.161 201.897 978.78 201.897C991.785 201.897 1002.76 196.005 1008.24 188.486V195.395C1008.24 211.651 997.474 223.233 981.015 223.233C969.636 223.233 958.257 219.372 949.316 210.635L934.077 231.361C945.659 242.537 963.743 247.617 981.422 247.617Z'
        fill='white'
      />
      <path
        d='M1094.94 203.117H1121.15L1180.89 58.847H1148.38L1108.15 159.023L1067.31 58.847H1035.4L1094.94 203.117Z'
        fill='white'
      />
      <path
        d='M1206.43 84.8562C1216.39 84.8562 1224.32 76.9315 1224.32 67.1781C1224.32 57.4247 1216.39 49.5 1206.43 49.5C1196.88 49.5 1188.76 57.4247 1188.76 67.1781C1188.76 76.9315 1196.88 84.8562 1206.43 84.8562ZM1192.62 203.117H1220.46V101.518H1192.62V203.117Z'
        fill='white'
      />
      <path
        d='M1292.5 205.352C1313.84 205.352 1330.09 196.411 1339.64 182.594L1318.92 167.151C1314.24 174.263 1305.5 179.952 1292.7 179.952C1279.29 179.952 1267.3 172.434 1265.68 158.82H1341.67C1342.28 154.553 1342.08 151.708 1342.08 149.27C1342.08 116.148 1318.71 99.2832 1292.09 99.2832C1261.21 99.2832 1238.65 122.244 1238.65 152.317C1238.65 184.423 1262.22 205.352 1292.5 205.352ZM1266.69 139.922C1269.34 127.527 1280.92 122.244 1291.28 122.244C1301.64 122.244 1311.8 127.731 1314.24 139.922H1266.69Z'
        fill='white'
      />
      <path
        d='M1446.37 203.117H1474.01L1507.54 101.518H1477.67L1458.57 166.541L1437.84 101.518H1416.5L1395.98 166.541L1376.68 101.518H1347.01L1380.74 203.117H1408.38L1427.27 145.409L1446.37 203.117Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M112.284 203.186H58.8155V112.289H0V58.8203H112.284V203.186ZM192.487 90.9015C192.487 108.619 178.124 122.983 160.406 122.983C142.688 122.983 128.325 108.619 128.325 90.9015C128.325 73.1835 142.688 58.8203 160.406 58.8203C178.124 58.8203 192.487 73.1835 192.487 90.9015ZM160.406 203.186L221.895 58.8203H286.057L224.568 203.186H160.406Z'
        fill='white'
      />
    </svg>
  );
};

TradingViewDarkLogo.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string || PropTypes.number,
  height: PropTypes.string || PropTypes.number,
};
