import React, { createContext, useEffect, useState } from 'react';
import CategoryItem from './categoryItem';
import { useRouter } from 'next/router';
import useGetItemsByCollectionAddress from '../../hooks/useGetItemsByCollectionAddress';
import { VerticalFilter, VerticalFilterReset } from '../filter/verticalFilter';
import { HorizontalCollectionItemsFilter } from '../filter/HorizontalCollectionItemsFilter';
import { OnEnterScreen } from '../onEnterScreen';
import { useAccount } from 'wagmi';
import { FloorSweep } from '../floor-sweep/floor-sweep';
import { useFloorSweep } from '../floor-sweep/useFloorSweep';

export const setFilterToLocalstorage = (collectionAddress, filter) => {
  const parsed = JSON.parse(localStorage.getItem('filters') || '{}');
  localStorage.setItem('filters', JSON.stringify({ ...parsed, [collectionAddress]: filter }));
};

export const CollectionContext = createContext(null);
let INITIAL_FILTERS = {
  search: '',
  buy_now: false,
  itemAttributes: {},
  collectionIds: [],
  sort: {
    // id: 'updatedAt:desc',
    // text: 'Recent Activity',
    id: 'default:asc',
    text: 'Default',
  },
  categories: { id: 1, text: 'All Categories' },
  chainIds: [],
  tags: [],
};
let firstSet = false;
const FilterCategoryItem = ({
  search,
  owner,
  size,
  collection,
  collectionInfo,
  propertiesDisabled,
  disableFilters,
  disablePagination,
  horizontal,
  horizontalFilterEnabled,
  additionalFilter,
  fetchFavorites,
  SortByIdDisabled,
  chainsDisabled,
  raritySetsEnabled = false,
  tags,
  disableScroll,
  favoritesWalletAddress,
  visibleFloorSweep,
}) => {
  let INITIAL_FILTER = INITIAL_FILTERS;
  if (collection)
    INITIAL_FILTER = {
      ...INITIAL_FILTER,
      sort: {
        id: 'price:asc',
        text: 'Price Low to High',
      },
    };
  const { sweepCount, sweepingItems, sweepStatus, setSweepingItems } = useFloorSweep();
  const [isRarityRangeOpen, setIsRarityRangeOpen] = useState(false);
  const router = useRouter();
  const filterRef = React.useRef();
  const [filterVal, setFilterVal] = useState(INITIAL_FILTER);
  const [filterEverUsed, setFilterEverUsed] = useState(false);
  const [page, setPage] = useState(1);
  const { address: walletAddress } = useAccount();
  let f = search ? { search, collectionIds: [] } : filterVal;
  f = owner ? { ...f, owner } : f;
  f = { ...f, walletAddress };
  if (additionalFilter) {
    f = { ...f, ...additionalFilter };
  }
  const pageSize = size || 50;
  const { data: swr, isLoading } = useGetItemsByCollectionAddress(
    collection,
    {
      ...f,
      collectionIds: filterVal?.collectionIds?.map
        ? filterVal?.collectionIds?.map((c) => c.address) || []
        : [],
    },
    pageSize,
    page,
    fetchFavorites,
    walletAddress,
    favoritesWalletAddress,
  );
  const { results: data, page: lastPage, isError } = swr || {};
  const [sweepCountState, setSweepCountState] = useState(0);
  const [lastData, setLastData] = useState(null);
  const [shouldLoad, setShouldLoad] = useState({});

  useEffect(() => {
    firstSet = false;
  }, []);
  useEffect(() => {
    if (data && lastData && lastPage !== 1) {
      return setLastData([...lastData, ...data]);
    }
    if (data && lastPage == 1) {
      setLastData(data);
    }
  }, [data]);
  useEffect(() => {
    if (
      lastPage &&
      (lastData?.length / pageSize === lastPage || lastData?.length / 2 / pageSize === lastPage) &&
      !disablePagination
    )
      setPage(lastPage + 1);
  }, [shouldLoad]);
  useEffect(() => {
    setPage(1);
  }, [filterVal]);
  const HandleFilterChange = (newVal) => {
    setSweepingItems([], 'sweep');
    setFilterEverUsed(true);
    setFilterVal({ ...filterVal, ...newVal });
  };
  const clearFilters = () => {
    setFilterEverUsed(false);
    setFilterVal(INITIAL_FILTER);
  };

  // update filters from localstorage
  useEffect(() => {
    if (router.pathname.indexOf('user') > -1) {
      return;
    }
    const parsed = JSON.parse(localStorage.getItem('filters') || '{}');
    if (parsed[collection]) {
      setFilterVal(parsed[collection]);
      setFilterEverUsed(true);
    }
  }, [router]);

  // update localstorage from filters
  useEffect(() => {
    if (router.pathname.indexOf('user') > -1) {
      return;
    }
    setFilterToLocalstorage(collection, filterVal);
  }, [filterVal]);

  useEffect(() => {
    setTimeout(() => {
      if (filterRef.current) {
        const HeaderHeight = Number(
          document.documentElement.style.getPropertyValue('--header-height').replace('px', ''),
        );
        window.scrollTo({
          top: filterRef.current?.getBoundingClientRect()?.top + window?.scrollY - HeaderHeight,
        });
      }
    }, 1000);
  }, [filterVal, filterRef]);

  useEffect(() => {
    if (sweepCountState != sweepCount) {
      if (lastData && sweepCount > 0 && sweepStatus == 'sweep') {
        let filtered = lastData
          ?.filter((item) => {
            const isOnSale = item?.orders?.length > 0;
            const itemOwner = item?.ownerObject?.owner;

            return isOnSale && itemOwner !== walletAddress;
          })
          .filter((_, index) => index < sweepCount);

        setSweepCountState(filtered);
        setSweepingItems(filtered, 'sweep');
      }
    }
  }, [sweepCount, lastData]);

  const loading = !data && !isError;
  return (
    <CollectionContext.Provider value={collection}>
      <div ref={filterRef}>
        {/*<CollectionRarityRangeStats
          collectionAddress={collection}
          isOpen={isRarityRangeOpen}
          onClose={() => setIsRarityRangeOpen(false)}
          setFilter={(val) => {
            setFilterVal(val);
            setFilterEverUsed(true);
          }}
        />*/}
        {/* <!-- Filter --> */}
        <div className={'md:flex'}>
          {!disableFilters && (
            <div className={'w-full md:max-w-[220px] lg:max-w-[300px]'}>
              <VerticalFilter
                openRarityRange={() => setIsRarityRangeOpen(true)}
                collectionFilterDisabled={collection}
                filterObject={filterVal}
                onChange={HandleFilterChange}
                propertiesDisabled={propertiesDisabled}
                chainsDisabled={chainsDisabled}
                raritySetsEnabled={raritySetsEnabled}
                tags={tags}
                disableScroll={disableScroll}
              />
            </div>
          )}
          <div className={horizontal ? 'flex w-full overflow-x-auto' : 'flex-grow'}>
            {/* <Collection_category_filter/> */}
            {(!disableFilters || horizontalFilterEnabled) && !fetchFavorites && (
              <HorizontalCollectionItemsFilter
                filterObject={filterVal}
                onChange={HandleFilterChange}
                SortByIdDisabled={SortByIdDisabled}
                item={lastData?.[0]}
                rarityFilterEnabled={collectionInfo?.hasTraits || !collectionInfo}
              />
            )}
            <VerticalFilterReset
              filterEverUsed={filterEverUsed}
              clearFilters={clearFilters}
              filterObject={filterVal}
              isFilterDefault={JSON.stringify(filterVal) == JSON.stringify(INITIAL_FILTER)}
              onChange={(val) => setFilterVal(val)}
            />
            {loading && !lastData && <CategoryItem loading={loading} />}

            {lastData?.length == 0 && !isLoading && <div className={'ml-4'}>No Results Found!</div>}
            {lastData?.length > 0 && (
              <CategoryItem
                foo={lastData}
                horizontal={horizontal}
                loading={loading}
                withContainerClass={disableFilters}
                selectedItems={sweepingItems}
              />
            )}
            {visibleFloorSweep && (
              <FloorSweep
                collection={collection}
                onSaleItemLength={
                  lastData?.length > 0
                    ? lastData.filter(
                        (f) => f?.ownerObject?.owner !== walletAddress && f.orders?.length > 0,
                      ).length
                    : 0
                }
              />
            )}

            {/* isLoading ? <NftSkelaton count={16}/> : lastData?.length > 0 && <CategoryItem foo={lastData} horizontal={horizontal}/> */}
            {!disablePagination && (
              <OnEnterScreen
                onVisible={() => {
                  setShouldLoad({});
                }}
              />
            )}
          </div>
        </div>
      </div>
    </CollectionContext.Provider>
  );
};
export default FilterCategoryItem;
