import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { constants } from '../../helpers/constants';
import { getItemPath } from '../../helpers/path';

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const formatNftImage = (item, collectionInfo) => {
  let image = '';
  if (collectionInfo?.isDNFT) {
    image = collectionInfo.thumbnailAPI + item?.itemId;
  } else {
    image = item?.imageFtpPath
      ? constants.cdn.path + item?.imageFtpPath + constants.cdn.thumbnail + constants.cdn.version
      : item?.metadata?.image
      ? item?.metadata?.image
      : constants.helpers.noImage;
  }
  return image;
};

const createNotifKey = (item) => {
  return item?.itemId + item?.collectionAddress;
};

const checkNotifSent = (item) => {
  const notifKey = createNotifKey(item);
  const notifSent = localStorage.getItem('notifSent');
  if (notifSent) {
    const notifSentArr = JSON.parse(notifSent);
    if (notifSentArr.includes(notifKey)) {
      return true;
    }
  }
  return false;
};

const clearOldNotif = (newNotifKeys) => {
  const notifSent = localStorage.getItem('notifSent');
  if (notifSent) {
    const notifSentArr = JSON.parse(notifSent);
    const newNotifSentArr = notifSentArr.filter((notifKey) => !newNotifKeys.includes(notifKey));
    localStorage.setItem('notifSent', JSON.stringify(newNotifSentArr));
  }
};

const setNotifSent = (item) => {
  const notifKey = createNotifKey(item);
  const notifSent = localStorage.getItem('notifSent');
  if (notifSent) {
    const notifSentArr = JSON.parse(notifSent);
    notifSentArr.push(notifKey);
    localStorage.setItem('notifSent', JSON.stringify(notifSentArr));
  } else {
    localStorage.setItem('notifSent', JSON.stringify([notifKey]));
  }
};

const useGetLastMintings = () => {
  const [mintings, setMintings] = useState([]);

  const getMintings = async () => {
    const { data } = await axios.get(`${constants.api.url_new}/minting/lastmintings`);
    const filteredList = data.data.filter((minting) => !checkNotifSent(minting));
    clearOldNotif(filteredList.map((minting) => createNotifKey(minting.item)));
    setMintings(new Set([...mintings, ...filteredList]));
  };

  useEffect(() => {
    getMintings();
    setInterval(() => {
      getMintings();
    }, 60000);
  }, []);

  const popMinting = (mintings) => {
    const newMintings = [...mintings];
    const minting = newMintings.pop();
    setNotifSent(minting);
    setMintings(newMintings);
    return minting;
  };

  return { mintings, popMinting };
};

export const useLastMintingsNotification = () => {
  const { mintings, popMinting } = useGetLastMintings();
  useEffect(() => {
    if (mintings.length === 0) return;
    const dur = randomIntFromInterval(1000, 5000);
    setTimeout(() => {
      const minting = popMinting(mintings);
      // const { item } = minting;
      const item = minting?.item;
      if (minting)
        toast.success(
          <a
            href={getItemPath(item?.chain, item?.collectionAddress, item?.itemId)}
            target='_blank'
            rel='noreferrer'
          >
            <div className='flex'>
              <img
                className='h-10 w-10'
                src={formatNftImage(minting?.item, minting?.item?.collection)}
              />
              <div className='pl-4 pt-1'>
                <div className='text-gray-900 text-sm font-medium'>
                  {minting?.item?.collection?.name} #{minting?.itemId}
                </div>
                <div className='text-gray-500 text-xs font-medium'>Just Minted on Bit5</div>
              </div>
            </div>
          </a>,
          {
            position: 'top-right',
            icon: false,
            duration: 2000,
          },
        );
    }, dur);
  }, [mintings]);
};
