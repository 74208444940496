import Link from 'next/link';
import Gravatar from 'react-gravatar';
import { shortenAddress } from '../helpers/address';
import { getCollectionPath } from '../helpers/path';
import { getCdnUrl } from '../lib/cdnUrl';
import { MD5 } from '../lib/md5';
import { ImageFixedAO } from './Image_fixed_aspect_ratio';
import { Tooltip } from '@mui/material';
import useGetUser from '../hooks/useGetUser';

export const UserImage = ({ user, showName, showAddress, responsive, hideImage, newTab }) => {
  return (
    <Link href={`/user/${user.walletAddress}`} className=' !no-underline'>
      <a {...(newTab && { target: '_blank' })}>
        <div className='flex items-center align-middle ' data-testid='profile'>
          {!hideImage && (
            <div className={`${responsive ? 'h-8 w-8 lg:h-12 lg:w-12' : 'h-12 w-12'}`}>
              {user.profilePhoto ? (
                <ImageFixedAO
                  image={getCdnUrl(user.profilePhoto, 'thumbnail', user?.updatedAt)}
                  bottomRounded
                />
              ) : (
                <Gravatar email={MD5(user?.walletAddress)} className='rounded-2lg' size={48} />
              )}
            </div>
          )}
          <div className='flex flex-col'>
            {showName && (
              <Tooltip title={user?.name || user?.walletAddress || ''} placement='top'>
                <p
                  className={`${
                    !hideImage ? 'ml-2' : ''
                  } font-semibold text-jacarta-700 dark:text-white`}
                >
                  {/* {user?.name?.slice(0, 9) || shortenAddress(user.walletAddress) || '-'} */}
                  {user?.name
                    ? user?.name?.length > 9
                      ? user?.name?.slice(0, 7) + '...'
                      : user?.name
                    : shortenAddress(user.walletAddress) || '-'}
                </p>
              </Tooltip>
            )}
            {user?.name && showAddress && (
              <Tooltip title={user?.walletAddress || ''}>
                <p
                  className={`${
                    !hideImage ? 'ml-2' : ''
                  } text-[12px] font-semibold text-jacarta-700 dark:text-white`}
                >
                  {shortenAddress(user.walletAddress) || '-'}
                </p>
              </Tooltip>
            )}
          </div>
        </div>
      </a>
    </Link>
  );
};

export const UserLink = ({ address, noColor, fontDisplay, textSm = true }) => {
  return (
    <Tooltip title={address} placement='top'>
      <div>
        <Link
          href={`/user/${address}`}
          className={` !no-underline ${
            noColor ? ' text-jacarta-700 dark:text-white' : 'text-accent-dark dark:text-accent'
          }`}
        >
          <a
            className={`${textSm ? 'text-sm' : ''} font-semibold ${
              fontDisplay ? 'font-display' : ''
            }`}
          >
            {shortenAddress(address)}
          </a>
        </Link>
      </div>
    </Tooltip>
  );
};

export const ItemLink = ({ item, noColor, fontDisplay, textSm = true }) => {
  if (!item) return '-';
  const name = item?.name
    ? item?.name?.length > 9
      ? item?.name?.slice(0, 4) + '...' + item?.name?.slice(-4)
      : item?.name
    : `#${item?.itemId || '-'}`;

  return (
    <Tooltip title={item?.name || ''} placement='top'>
      <div>
        <Link
          href={`/item/${item?.chain}/${item?.collectionAddress}/${item?.itemId}`}
          className={` !no-underline ${
            noColor ? ' text-jacarta-700 dark:text-white' : 'text-accent-dark dark:text-accent'
          }`}
        >
          <a
            className={`${textSm ? 'text-sm' : ''} font-semibold ${
              fontDisplay ? 'font-display' : ''
            }`}
          >
            {name}
          </a>
        </Link>
      </div>
    </Tooltip>
  );
};

export const ShowUser = ({
  user,
  collection,
  item,
  address,
  showName,
  hideImage,
  textSm = true,
}) => {
  const fetchedUser = useGetUser(address)?.data;

  if (fetchedUser) {
    user = fetchedUser;
  }

  if (item && item?.collectionAddress && item?.itemId) return <ItemLink item={item} />;

  return (
    <>
      {collection && (
        <CollectionImage
          responsive
          collection={collection}
          showName={showName || false}
          hideImage={hideImage || false}
        />
      )}
      {user && !collection && (
        <UserImage
          responsive
          user={user}
          showName={showName || false}
          hideImage={hideImage || false}
        />
      )}
      {!user &&
        !collection &&
        (address ? <UserLink address={address} noColor textSm={textSm} /> : '-')}
    </>
  );
};

export const CollectionImage = ({ collection, showName, responsive, hideImage }) => {
  return (
    <Tooltip title={collection?.name || ''} placement='top'>
      <div>
        <Link
          href={getCollectionPath(collection.chain, collection.pathName)}
          className=' !no-underline'
        >
          <a>
            <div className='flex items-center align-middle'>
              {!hideImage && (
                <div className={`${responsive ? 'h-8 w-8 lg:h-12 lg:w-12' : 'h-12 w-12'}`}>
                  <ImageFixedAO
                    image={getCdnUrl(
                      collection.profilePhotoPath,
                      'thumbnail',
                      collection?.updatedAt,
                    )}
                    bottomRounded
                  />
                </div>
              )}
              {showName && (
                <p className='ml-2 text-jacarta-700 dark:text-white'>{collection.name || '-'}</p>
              )}
            </div>
          </a>
        </Link>
      </div>
    </Tooltip>
  );
};
