import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useContext, useEffect, useState } from 'react';
import { DataRefreshContext } from '../components/refreshContext';
import { utils } from 'ethers';

export const getListingFetcher = (
  collectionAddress,
  itemId,
  signature,
  walletAddress,
  findMany = false,
  refreshDate,
) => {
  let enabled = false;
  if (!collectionAddress || !itemId) {
    enabled = false;
    return;
  }
  enabled = utils.isAddress(collectionAddress);

  const config = {
    url: `${constants.api.url_new}/items/getListing${walletAddress ? `ByWallet` : ''}`,
    method: constants.httpMethods.get,
    params: {
      collectionAddress,
      itemId,
      refreshDate,
      ...(signature ? { signature } : {}),
      ...(walletAddress ? { walletAddress } : {}),
      ...(findMany ? { findMany } : {}),
    },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return { config, fetcher, enabled };
};

export default function useGetListing({
  collectionAddress,
  itemId,
  signature,
  walletAddress,
  findMany = false,
}) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, enabled, fetcher } = getListingFetcher(
    collectionAddress,
    itemId,
    signature,
    walletAddress,
    findMany,
    refreshDate,
  );
  return useAdaptor(enabled ? axios.getUri(config) : null, fetcher);
}
