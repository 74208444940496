import React from 'react';
import { Tooltip } from '@mui/material';

export default function Icon({ name, tooltip, noSideGap, fillCurrent }) {
  switch (name) {
    case 'bnb':
      return (
        <Tooltip title={tooltip}>
          <svg className='icon mr-1 h-4 w-4 flex-shrink-0'>
            <use xlinkHref='/icons.svg#icon-BNB' />
          </svg>
        </Tooltip>
      );
    case 'wbnb':
      return (
        <Tooltip title={tooltip}>
          <svg className='icon mr-1 h-5 w-5 flex-shrink-0'>
            <use xlinkHref='/icons.svg#icon-WBNB' />
          </svg>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title={tooltip}>
          <svg
            className={`icon h-4 w-4 ${noSideGap ? '' : 'mr-1'} flex-shrink-0 ${
              fillCurrent ? 'fill-current' : ''
            }`}
          >
            <use xlinkHref={`/icons.svg#${name}`} />
          </svg>
        </Tooltip>
      );
  }
}

export const ChainIcon = ({
  name,
  tooltip,
  width,
  sideGap,
  className,
  placement,
  type = 'token',
  pt = 0,
}) => {
  const randomId = Math.random().toString(36).substring(7);
  if (type === 'chain' && (name === '0x5a2' || name === '0x44d')) {
    return (
      <Tooltip title={tooltip} className={className} placement={placement}>
        <svg
          className={`icon flex-shrink-0 ${sideGap ? 'mr-1' : ''} ${pt}`}
          style={{
            width: width || 16,
            height: width || 16,
          }}
          viewBox='0 0 500 500'
        >
          <defs>
            <clipPath id={`clippath-polygonzkevm${randomId}`}>
              <circle fill='none' cx='250' cy='250' r='244.91' />
            </clipPath>
            <linearGradient
              id={`linear-gradient-polygonzkevm${randomId}`}
              x1='-116.09'
              y1='25.97'
              x2='437.45'
              y2='364.71'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0' stopColor='#a229c5' />
              <stop offset='1' stopColor='#7b3fe4' />
            </linearGradient>
          </defs>
          <g clipPath={`url('#clippath-polygonzkevm${randomId}')`}>
            <rect
              fill={`url('#linear-gradient-polygonzkevm${randomId}')`}
              x='-18.1'
              y='-18.1'
              width='536.2'
              height='536.2'
            />
          </g>
          <path
            fill='#fff'
            d='m320.83,302.85l69.29-40.01c3.67-2.12,5.94-6.06,5.94-10.3v-80.01c0-4.23-2.28-8.18-5.94-10.3l-69.29-40.01c-3.67-2.12-8.22-2.11-11.89,0l-69.29,40.01c-3.67,2.12-5.94,6.07-5.94,10.3v142.99l-48.59,28.05-48.59-28.05v-56.11l48.59-28.05,32.05,18.5v-37.64l-26.11-15.07c-1.8-1.04-3.86-1.59-5.95-1.59s-4.15.55-5.94,1.59l-69.29,40.01c-3.67,2.12-5.94,6.06-5.94,10.3v80.01c0,4.23,2.28,8.18,5.94,10.3l69.29,40.01c3.66,2.11,8.22,2.11,11.89,0l69.29-40c3.67-2.12,5.94-6.07,5.94-10.3v-142.99l.88-.5,47.71-27.55,48.59,28.05v56.11l-48.59,28.05-32-18.48v37.64l26.06,15.05c3.67,2.11,8.22,2.11,11.89,0Z'
          />
        </svg>
      </Tooltip>
    );
  }
  if (name === '0x89') {
    return (
      <Tooltip title={tooltip} className={className} placement={placement}>
        {/* <svg
          className={`icon flex-shrink-0 ${sideGap ? 'mr-1' : ''} ${pt}`}
          style={{
            width: width || 16,
            height: width || 16,
          }}
          viewBox='0 0 500 500'
        >
          <defs>
            <linearGradient
              id={`linear-gradient-polygon${randomId}`}
              x1='128.76'
              y1='338.4'
              x2='379.84'
              y2='155.32'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0' stopColor='#a726c1' />
              <stop offset='.88' stopColor='#803bdf' />
              <stop offset='1' stopColor='#7b3fe4' />
            </linearGradient>
          </defs>
          <circle fill='#fff' cx='250' cy='250' r='244.91' />
          <path
            fill={`url('#linear-gradient-polygon${randomId}')`}
            d='m320.83,302.85l69.29-40.01c3.67-2.12,5.94-6.06,5.94-10.3v-80.01c0-4.23-2.28-8.18-5.94-10.3l-69.29-40.01c-3.67-2.12-8.22-2.11-11.89,0l-69.29,40.01c-3.67,2.12-5.94,6.07-5.94,10.3v142.99l-48.59,28.05-48.59-28.05v-56.11l48.59-28.05,32.05,18.5v-37.64l-26.11-15.07c-1.8-1.04-3.86-1.59-5.95-1.59s-4.15.55-5.94,1.59l-69.29,40.01c-3.67,2.12-5.94,6.06-5.94,10.3v80.01c0,4.23,2.28,8.18,5.94,10.3l69.29,40.01c3.66,2.11,8.22,2.11,11.89,0l69.29-40c3.67-2.12,5.94-6.07,5.94-10.3v-142.99l.88-.5,47.71-27.55,48.59,28.05v56.11l-48.59,28.05-32-18.48v37.64l26.06,15.05c3.67,2.11,8.22,2.11,11.89,0Z'
          />
        </svg> */}
        <svg
          className={`icon flex-shrink-0 ${sideGap ? 'mr-1' : ''} ${pt}`}
          style={{
            width: width || 16,
            height: width || 16,
          }}
          viewBox='0 0 489.8 489.8'
        >
          <defs>
            <linearGradient
              id={`linear-gradient-polygon${randomId}`}
              x1='123.7'
              y1='1831.36'
              x2='374.78'
              y2='1648.28'
              gradientTransform='translate(0 -1498)'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0' stopColor='#a726c1' />
              <stop offset='.88' stopColor='#803bdf' />
              <stop offset='1' stopColor='#7b3fe4' />
            </linearGradient>
          </defs>
          <circle fill='#f5f0fd' cx='244.9' cy='244.9' r='244.9' />
          <path
            fill={`url('#linear-gradient-polygon${randomId}')`}
            d='m315.7,297.8l69.3-40c3.7-2.1,5.9-6.1,5.9-10.3v-80c0-4.2-2.3-8.2-5.9-10.3l-69.3-40c-3.7-2.1-8.2-2.1-11.9,0l-69.3,40c-3.7,2.1-5.9,6.1-5.9,10.3v143l-48.6,28-48.6-28v-56.1l48.6-28.1,32.1,18.5v-37.6l-26.1-15.1c-1.8-1-3.9-1.6-5.9-1.6s-4.1.6-5.9,1.6l-69.3,40c-3.7,2.1-5.9,6.1-5.9,10.3v80c0,4.2,2.3,8.2,5.9,10.3l69.3,40c3.7,2.1,8.2,2.1,11.9,0l69.3-40c3.7-2.1,5.9-6.1,5.9-10.3v-143l.9-.5,47.7-27.6,48.6,28.1v56.1l-48.6,28-32-18.5v37.6l26.1,15c3.5,2.3,8.1,2.3,11.7.2h0Z'
          />
        </svg>
        {/* <svg
          className={`icon flex-shrink-0 ${sideGap ? 'mr-1' : ''} ${pt}`}
          style={{
            width: width || 16,
            height: width || 16,
          }}
          viewBox='0 0 500 500'
        >
          <defs>
            <linearGradient
              id={`linear-gradient-polygon${randomId}`}
              x1='54.83'
              y1='392.31'
              x2='459.03'
              y2='97.58'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0' stopColor='#a726c1' />
              <stop offset='.88' stopColor='#803bdf' />
              <stop offset='1' stopColor='#7b3fe4' />
            </linearGradient>
          </defs>
          <path
            fill={`url('#linear-gradient-polygon${randomId}')`}
            d='m364.03,335.08l111.55-64.4c5.9-3.41,9.57-9.76,9.57-16.58V125.28c0-6.81-3.67-13.17-9.57-16.58l-111.55-64.4c-5.9-3.41-13.24-3.4-19.14,0l-111.55,64.4c-5.9,3.41-9.57,9.76-9.57,16.58v230.19l-78.22,45.15-78.22-45.15v-90.33l78.22-45.15,51.6,29.78v-60.59l-42.03-24.26c-2.9-1.67-6.21-2.55-9.57-2.55s-6.67.88-9.57,2.55L24.42,229.33c-5.9,3.41-9.57,9.76-9.57,16.58v128.81c0,6.81,3.67,13.17,9.57,16.58l111.55,64.41c5.9,3.4,13.23,3.4,19.14,0l111.55-64.4c5.9-3.41,9.57-9.77,9.57-16.58v-230.19l1.41-.81,76.81-44.34,78.22,45.16v90.32l-78.22,45.16-51.52-29.74v60.59l41.95,24.23c5.9,3.4,13.24,3.4,19.14,0Z'
          />
        </svg> */}
      </Tooltip>
    );
  }
  return (
    <Tooltip title={tooltip} className={className} placement={placement}>
      <svg
        className={`icon flex-shrink-0 ${sideGap ? 'mr-1' : ''} ${pt}`}
        style={{
          width: width || 16,
          height: width || 16,
        }}
      >
        <use xlinkHref={`/icons.svg#${type}-${name}`} />
      </svg>
    </Tooltip>
  );
};

export const CustomIcon = ({ name, width, className }) => {
  return (
    <svg
      className={`icon flex-shrink-0 ${className}`}
      style={{
        width: width || 16,
        height: width || 16,
      }}
    >
      <use xlinkHref={`/icons.svg#${name}`} />
    </svg>
  );
};
