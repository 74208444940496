import { Tooltip } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useWalletClient, useSignTypedData } from 'wagmi';
import { getContract } from 'wagmi/actions';
import abi from '../../helpers/abi';
import {
  AddressZero,
  approveNFT,
  cancelOrder,
  getTokenBalance,
} from '../../helpers/blockchainOperations';
import { constants } from '../../helpers/constants';
import { normalizeEther } from '../../helpers/digits';
import erc721ABI from '../../helpers/erc721ABI';
import useGetCollection from '../../hooks/useGetCollection';
import useGetIsPrivileged from '../../hooks/useGetIsPrivileged';
import { dynamicDomain, types, multipleTypes } from '../../lib/sign';
import { closeModal } from '../../redux/counterSlice';
import Icon, { ChainIcon } from '../Icon';
import { getCoinGeckoWrappedTokenId, getTokenName } from '../../helpers/getChainName';
import useGetTokenPrice from '../../hooks/useGetTokenPrice';
import { DataRefreshContext } from '../refreshContext';
import { ModalContainer } from './modalContainer';
import { ModalItemDetails } from './modalComponents/modalItemDetails';
import useGetListing from '../../hooks/useGetListing';
import moment from 'moment';
import { errorToast, successToast } from '../toast';
import { getAddress, parseEther } from 'viem';
import { sendClientError } from '../../helpers/sendClientError';
import useGetOwnedPrivilegedNftsByWallet from '../../hooks/useGetOwnedPrivilegedNftsByWallet';
import { PrivilegeInfo } from '../privilegeInfo';
import { useCatchTxError } from '../../hooks/useCatchTxError';
import { Button, SwitchNetworkButton } from '../Button';
import { useGetChain } from '../../hooks/useGetChain';
import { useGetBidsWithBalance } from '../../hooks/useGetBidsWithBalance';
import { useTokenContract } from '../../helpers/setMarketPlaceAbi';
import { useGetGlobalBidsBySlug } from '../../hooks/useGetGlobalBids';
import BulkToolBulkSell from '../bulkTool/BulkToolBulkSell';

const MODAL_KEY = 'sellModal';
const SellModal = () => {
  const { fetchWithCatchTxError } = useCatchTxError();
  const { signTypedDataAsync } = useSignTypedData();
  const { data: walletClient } = useWalletClient();
  const { refreshHooks } = useContext(DataRefreshContext);
  const { address, connector } = useAccount();
  const dispatch = useDispatch();

  const { modal } = useSelector((state) => state.counter);
  const data = modal?.data || {};
  const { chain, isChainActive } = useGetChain(data?.chain);
  const nftContract = getContract({
    address: data?.collectionAddress,
    abi: erc721ABI,
    walletClient,
    chainId: chain.id,
  });
  const marketplaceContract = getContract({
    address: chain?.marketplace,
    abi,
    walletClient,
    chainId: chain.id,
  });
  const tokenContract = useTokenContract(data?.chain);

  const wrappedToken = getCoinGeckoWrappedTokenId(data?.chain);
  const [loading, setLoading] = useState(false);
  const [bnbAmount, setBnbAmount] = useState('');
  const [usdAmount, setUsdAmount] = useState(0);
  const [expiration, setExpiration] = useState(1);
  const [isPrivilegedFeeLoading, setIsPrivilegedFeeLoading] = useState(true);
  const [serviceFee, setServiceFee] = useState(2);
  const [privilegedFee, setPrivilegedFee] = useState(serviceFee);
  const [privilegedItem, setPrivilegedItem] = useState(null);
  const [wbnbBalance, setWbnbBalance] = useState(0);
  const { data: globalBids, isLoading: isGlobalBidsLoading } = useGetGlobalBidsBySlug(
    data?.collectionAddress,
  );
  const highestGlobalBid = useGetBidsWithBalance({
    chainId: data?.chain,
    contract: tokenContract,
    offers: globalBids,
    sortByPrice: true,
    floorPrice: collectionInfo?.floorPrice,
  })?.[0];
  const collectionInfo = useGetCollection(data?.collectionAddress).data;
  let { data: isPrivileged, isLoading: isPrivilegeLoading } = useGetIsPrivileged(
    address,
    data?.chain,
  );
  const { usdPrice: tokenPrice } = useGetTokenPrice(data?.chain);
  const { data: privNfts } =
    useGetOwnedPrivilegedNftsByWallet({
      walletAddress: address,
      page: 1,
      limit: 1,
      selectedNft: {
        collectionAddress: data?.collectionAddress,
        itemId: data?.itemId,
      },
    }) || {};
  const listing = useGetListing({
    collectionAddress: data?.collectionAddress,
    itemId: data?.itemId,
    walletAddress: address,
  }).data;

  const isListed = listing && listing?.status === 'not processed' && listing?.kind === 1;
  const buttonTexts = ['Sell', 'Update Listing'];
  const text = isListed ? 'Update Listing' : 'Sell';
  const [buttonText, setButtonText] = useState(text);

  const modalDataArray = Object.keys(modal?.data)
    .map((key) => modal?.data[key])
    .slice(0, -2);

  let onLastPrivilegedItem = false;
  if (
    collectionInfo?.isPrivileged &&
    isPrivileged?.privilegedItemCount - isPrivileged?.privilegedListedItemCount === 1
  ) {
    onLastPrivilegedItem = true;
    isPrivileged = false;
  }

  const handleSell = async () => {
    if (!isChainActive) return errorToast('Please switch to the correct chain');
    if (!bnbAmount || bnbAmount == 0) return errorToast('Please enter a price.');
    setLoading(true);
    setButtonText('Approving the NFT...');
    await approveNFT(nftContract, address, chain?.marketplace);

    try {
      // if there is already a listing, cancel it first
      let isOrderCanceled = false;
      const listed = isListed;
      if (isListed && BigInt(listing?.price) < BigInt(parseEther(bnbAmount))) {
        setButtonText('Canceling the old sell...');
        try {
          const contractFn = await cancelOrder({
            marketplaceContract,
            signature: listing?.signature,
            kind: 1,
            id: listing?.id,
            chain: data?.chain,
            paymentToken: chain?.wrappedToken,
          });
          const receipt = await fetchWithCatchTxError({
            callTx: contractFn,
            chainId: data?.chain,
            toastMessage: 'Listing cancelled!',
            keepModalOpen: true,
          });
          if (receipt) isOrderCanceled = true;
        } catch (err) {
          return errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
        }
      }

      setButtonText('Listing the NFT...');
      const order = {
        issuer: getAddress(address),
        nftAddress: data?.collectionAddress,
        tokenId: data?.itemId,
        paymentToken: chain?.wrappedToken,
        price: parseEther(bnbAmount).toString(),
        end: Math.floor(Date.now() / 1000) + expiration * 24 * 60 * 60,
        kind: 1,
        tokenKind:
          collectionInfo?.contractType === 'ERC1155'
            ? 1
            : collectionInfo?.contractType === 'ERC404'
            ? 2
            : 0,
        globalBidAmount: 0,
        privileges: privilegedItem
          ? {
              privilegedCollection: privilegedItem?.collectionAddress,
              privilegedTokenId: String(privilegedItem?.itemId),
            }
          : {
              privilegedCollection: AddressZero,
              privilegedTokenId: String(0),
            },
      };
      const signature = await signTypedDataAsync({
        domain: dynamicDomain(chain.id),
        message: order,
        types: types,
        primaryType: 'Order',
      });
      order.signature = signature;

      // returns boolean
      const res = (
        await axios.post(`${constants.api.url_new}/orders/listItem`, {
          order,
          chain: data?.chain,
        })
      ).data;

      if (!res) throw new Error('Something went wrong on the server side. Try again.');

      successToast(
        `${
          data?.metadata?.name
        } listed successfully for ${bnbAmount} ${wrappedToken?.toUpperCase()}s`,
      );
      refreshHooks();
      dispatch(closeModal());
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setLoading(false);
      setButtonText(text);
    }
  };

  const handleAccept = async () => {
    try {
      if (!isChainActive) return errorToast(`Please switch to ${chain?.name} network`);

      setButtonText('Approving the NFT...');
      await approveNFT(nftContract, address, chain?.marketplace);
      setButtonText(`Accepting Global Bid...`);
      setButtonText('Awaiting blockchain...');
      const order = {
        issuer: highestGlobalBid?.issuer,
        nftAddress: highestGlobalBid?.collectionAddress,
        paymentToken: highestGlobalBid?.paymentToken,
        price: highestGlobalBid?.price,
        tokenId: '0',
        end: highestGlobalBid?.end,
        kind: highestGlobalBid?.kind,
        tokenKind: highestGlobalBid?.tokenKind,
        globalBidAmount: highestGlobalBid?.globalBidAmount,
        privileges: {
          privilegedCollection: highestGlobalBid?.privilegedCollection,
          privilegedTokenId: highestGlobalBid?.privilegedTokenId,
        },
        signature: highestGlobalBid?.signature,
      };
      await fetchWithCatchTxError({
        callTx: () => marketplaceContract.write.acceptGlobalBid([order, [data?.itemId]]),
        toastMessage: 'Global bid accepted!',
        chainId: chain.id,
      });
    } catch (err) {
      errorToast(err?.shortMessage ? err.shortMessage : 'err:' + err);
    } finally {
      setButtonText(text);
    }
  };

  const handleEThAmount = (e) => {
    e.preventDefault();
    const newAmount = e?.target?.value?.replace(',', '.');
    const zeroCounts = newAmount.split('.');

    if (zeroCounts?.length >= 1 && zeroCounts[1]?.length > 4) return;

    setBnbAmount(newAmount);

    const usd = Number(tokenPrice * e.target.value).toFixed(2);
    setUsdAmount(usd);
  };

  useEffect(() => {
    if (data.useBulkPrice) {
      const newAmount = data?.price?.replace(',', '.');
      const zeroCounts = newAmount.split('.');

      if (zeroCounts?.length >= 1 && zeroCounts[1]?.length > 4) return;

      setBnbAmount(newAmount);

      const usd = Number(tokenPrice * data?.price).toFixed(2);
      setUsdAmount(usd);
    }
  }, []);

  const youWillGetApprx =
    bnbAmount -
    (bnbAmount * (isPrivileged ? privilegedFee : serviceFee)) / 100 -
    (bnbAmount * collectionInfo?.totalCreatorRoyalty) / 100;

  const showGlobalBidWarning =
    !isGlobalBidsLoading &&
    !!highestGlobalBid &&
    highestGlobalBid?.isApprovedEnough &&
    highestGlobalBid?.hasEnoughBalance &&
    highestGlobalBid?.issuer !== address &&
    !!bnbAmount &&
    bnbAmount != 0 &&
    BigInt(highestGlobalBid?.price) >= parseEther(bnbAmount);

  const isButtonDisabled = data?.useBatchTransfer
    ? false
    : !bnbAmount || bnbAmount == 0 || !buttonTexts.includes(buttonText) || isGlobalBidsLoading;

  useEffect(() => {
    const updateServiceFee = async () => {
      try {
        const _fee = await marketplaceContract.read.FEE();

        if (isPrivileged) {
          setPrivilegedFee(parseFloat((_fee * BigInt(isPrivileged?.privilege)) / 10000n) / 100);
        }

        setServiceFee(parseFloat(_fee) / 100);
        setIsPrivilegedFeeLoading(false);
      } catch (e) {
        const error = JSON.stringify(e, Object.getOwnPropertyNames(e));
        sendClientError({
          functionName: 'updateServiceFee',
          contractName: 'Marketplace',
          contractAddress: chain?.marketplace,
          walletAddress: address,
          walletId: connector?.id,
          walletName: connector?.name,
          isWalletReady: connector?.ready,
          error,
        });
      }
    };

    updateServiceFee();
  }, [isPrivileged, marketplaceContract, serviceFee]);

  useEffect(() => {
    const updateWbnbBalance = async () => {
      const b = await getTokenBalance(tokenContract, address);
      setWbnbBalance(normalizeEther(b));
    };

    if (address) updateWbnbBalance();
  }, [address, wbnbBalance, tokenContract]);

  useEffect(() => {
    if (!loading) setButtonText(text);
  }, [isListed, loading, text]);

  useEffect(() => {
    if (privNfts?.results?.length) {
      setPrivilegedItem({
        collectionAddress: privNfts.results[0].collectionAddress,
        itemId: privNfts.results[0].itemId,
      });
    }
  }, [privNfts]);

  return (
    <ModalContainer modalKey={MODAL_KEY} title='Sell Items'>
      {/* <Toaster position='top-right' /> */}
      {/* <!-- Body --> */}
      <div className='modal-body p-6'>
        {data.useBatchTransfer ? (
          modalDataArray?.map((item, index) => (
            <div className='mt-4 flex flex-col' key={index}>
              <BulkToolBulkSell item={item} />
            </div>
          ))
        ) : (
          <div>
            <ModalItemDetails item={data} collection={collectionInfo} />
            {isListed && (
              <div className='relative flex border-jacarta-100 pb-4 dark:border-jacarta-600'>
                <div className='mt-2 w-full'>
                  <div className='flex w-full items-center'>
                    <div className='font-semibold'>Current Listing Price:</div>
                    <div className='ml-auto flex w-fit space-x-1'>
                      <span className='ml-auto flex items-center whitespace-nowrap'>
                        <ChainIcon
                          width={16}
                          name={data?.chain}
                          tooltip={getTokenName(chain.id)}
                          sideGap={true}
                        />
                        <span className='text-sm font-medium tracking-tight text-green'>
                          {normalizeEther(data?.price)}
                        </span>
                      </span>
                      <div className='whitespace-nowrap text-sm leading-7 dark:text-jacarta-300'>
                        ~ ${normalizeEther(tokenPrice * normalizeEther(data?.price))}
                      </div>
                    </div>
                  </div>
                  <div className='flex w-full items-center justify-between space-x-1'>
                    <div className='font-semibold'>Expires:</div>
                    <div className='ml-auto flex w-fit space-x-1'>
                      <span className='ml-auto flex items-center whitespace-nowrap'>
                        <span className='text-sm font-medium tracking-tight text-green'>
                          {moment.unix(listing?.end).format('DD MMM YYYY')}
                        </span>
                      </span>
                      <div className='text-sm leading-7 dark:text-jacarta-300'>
                        {moment.unix(listing?.end).format('HH:mm:ss')}
                        <span className='text-jacarta-300'> UTC</span> (
                        {moment.unix(listing?.end).fromNow()})
                        <span className='text-jacarta-300'> </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='mb-2 flex items-center justify-between'>
              <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
                Price
              </span>
            </div>
            <div className='relative mb-2 flex items-center overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600'>
              <div
                className='flex items-center justify-center self-stretch border-r border-jacarta-100 bg-jacarta-50 px-5 dark:border-jacarta-600 dark:bg-jacarta-700'
                data-testid='sellModalInput'
              >
                <ChainIcon
                  name={data?.chain}
                  tooltip={getTokenName(chain.id)}
                  width={20}
                  sideGap={false}
                />
              </div>

              <input
                type='number'
                className='h-12 w-full flex-[3] border-0 focus:ring-0 dark:text-jacarta-700'
                placeholder='Price'
                value={bnbAmount}
                onChange={(e) => handleEThAmount(e)}
                onWheel={(e) => e.target.blur()}
              />

              <div className='flex flex-1 justify-end self-stretch border-l border-jacarta-100 bg-jacarta-50 dark:text-jacarta-700'>
                <span className='self-center px-2 text-sm'>~${usdAmount}</span>
              </div>
            </div>

            <div className='mb-2 flex flex-wrap items-center text-sm text-jacarta-base dark:text-jacarta-200'>
              You get approx&nbsp;
              <ChainIcon
                width={12}
                name={data?.chain}
                tooltip={getTokenName(chain.id)}
                sideGap={true}
              />
              <Tooltip title={youWillGetApprx}>
                <span>{normalizeEther(youWillGetApprx)}</span>
              </Tooltip>
              , creator royalty&nbsp;
              {collectionInfo?.totalCreatorRoyalty}%,&nbsp;
              <span
                className={`${isPrivileged && !isPrivilegedFeeLoading && 'text-red line-through'}`}
              >
                service fee {serviceFee}%
              </span>
            </div>
          </div>
        )}

        {!isPrivilegeLoading && !isPrivilegedFeeLoading && (
          <PrivilegeInfo isPrivileged={isPrivileged} privilegedFee={privilegedFee} />
        )}
        {onLastPrivilegedItem && (
          <span className={`text-red`}>
            You are listing your last privileged item, you might lose your privileges.
          </span>
        )}

        <div className='mt-5 flex items-center justify-between'>
          <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
            Expiration
          </span>
        </div>
        <div className='mt-3 mb-6'>
          <select
            className='rounded-xl h-12 w-full border border-jacarta-100 bg-jacarta-50 px-4 text-sm text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white'
            value={expiration}
            onChange={(e) => setExpiration(e.target.value)}
            data-testid='sellModalExpiration'
          >
            <option value={1}>1 day</option>
            <option value={3}>3 day</option>
            <option value={7}>7 days</option>
            <option value={30}>30 days</option>
            <option value={180}>6 months</option>
          </select>
        </div>

        <div className='flex items-center justify-end'>
          Balance:&nbsp; <Icon name={`icon-${wrappedToken}`} tooltip={wrappedToken.toUpperCase()} />
          <span className='text-sm dark:text-jacarta-400'>{wbnbBalance}</span>
        </div>

        {/* <!-- Terms --> */}

        <div className='alert-info mt-2 w-full rounded-lg p-2'>
          Note: Bit5 does not escrow your NFT. Which means, you can list your token without paying
          for gas fees.
        </div>

        {showGlobalBidWarning && (
          <div className='alert-error mt-2 w-full rounded-lg p-2'>
            Your price is below or equal to the highest global bid, do you want to accept the global
            bid?
          </div>
        )}
      </div>
      {/* <!-- end body --> */}
      <div className='modal-footer'>
        <div className='flex items-center justify-center space-x-4' data-testid='sellModalButton'>
          {!isChainActive ? (
            <SwitchNetworkButton chainId={chain.id} />
          ) : (
            <>
              {showGlobalBidWarning ? (
                <div className='flex w-full items-center justify-between gap-3 text-xs md:text-sm'>
                  <Button
                    key={'accept_bid_button'}
                    onClick={handleAccept}
                    disabled={isButtonDisabled}
                    text={'Yes, Accept Bid'}
                  />
                  <Button
                    key={'list_for_sale_button'}
                    onClick={handleSell}
                    disabled={isButtonDisabled}
                    text={'No, List for Sale'}
                  />
                </div>
              ) : (
                <Button
                  key={'sell_button'}
                  onClick={handleSell}
                  disabled={isButtonDisabled}
                  text={buttonText}
                />
              )}
            </>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default SellModal;
