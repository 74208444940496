import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'tippy.js/dist/tippy.css';
import { getCollectionPath } from '../../helpers/path';
import useGetFeaturedCollections from '../../hooks/useGetFeaturedCollections';
import { getCdnUrl } from '../../lib/cdnUrl';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';
import { ChainIcon } from '../Icon';
import getChainName from '../../helpers/getChainName';

const Feature_collections_carousel = ({ collections, twiceItems }) => {
  const swiperRef = useRef();
  const [data, setData] = useState();
  const featuredCollections = useGetFeaturedCollections();
  const itemCountMp = twiceItems ? 2 : 0;

  useEffect(() => {
    if (collections) return setData(collections);
    if (featuredCollections.data !== undefined) {
      setData(featuredCollections.data);
    }
  }, [featuredCollections, collections]);
  return (
    <>
      <Swiper
        ref={swiperRef}
        modules={[Autoplay, Navigation, Pagination, Scrollbar]}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          // when window width is >= 640px
          640: {
            slidesPerView: 2 + itemCountMp,
            spaceBetween: 20 - (10 * itemCountMp) / 2,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 2 + itemCountMp,
            spaceBetween: 20 - (10 * itemCountMp) / 2,
          },
          900: {
            slidesPerView: 3 + itemCountMp,
            spaceBetween: 20 - (10 * itemCountMp) / 2,
          },
          1100: {
            slidesPerView: 4 + itemCountMp,
            spaceBetween: 30 - (10 * itemCountMp) / 2,
          },
        }}
        loop={true}
        className='!py-5'
      >
        {data?.map((item) => {
          const {
            address,
            coverPhoto,
            profilePhotoPath,
            name,
            pathName,
            itemCount,
            _count,
            userName,
            coverPhotoPath,
            creatorAddress,
            parentCollection,
            chain,
          } = item;

          return (
            <SwiperSlide key={'featureCC' + address}>
              <article>
                <div className='rounded-2xl border border-jacarta-100 bg-white p-0 transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700'>
                  <Link href={getCollectionPath(chain, pathName)}>
                    <a className='flex space-x-[0.625rem]'>
                      <figure className='h-full w-[100%]'>
                        <ImageFixedAO
                          image={getCdnUrl(profilePhotoPath, 'thumbnail', item?.updatedAt)}
                          alt={name}
                          bottomRounded={false}
                          moonpetsAccessory={parentCollection}
                        />
                        {/*<Image
                              src={
                                  headerPhoto
                                      ? headerPhoto
                                      : constants.helpers.noImage
                              }
                              alt={name}
                              width={230}
                              height={230}
                              layout="responsive"
                              objectFit="cover"
                              className={headerPhoto ? "rounded-[0.625rem] w-full" : "animate-pulse rounded-[0.625rem] w-full"}
                              loading="lazy"
                          />*/}
                      </figure>
                    </a>
                  </Link>
                  <div className={'p-[1.1875rem]'}>
                    <div className='flex w-full items-center justify-between space-x-1'>
                      <Link href={getCollectionPath(chain, pathName)}>
                        <a className='block overflow-hidden text-ellipsis whitespace-nowrap break-keep font-display text-base text-jacarta-700 hover:text-accent dark:text-white dark:hover:text-accent'>
                          {name}
                        </a>
                      </Link>
                      <ChainIcon
                        name={chain}
                        tooltip={getChainName(chain)}
                        width={'1.25rem'}
                        type={'chain'}
                      />
                    </div>
                    <div className='mt-2 flex items-center justify-between text-sm font-medium tracking-tight'>
                      {/* <div className="flex flex-wrap items-center">
                              <Link href={`/item/item_${itemLink}`}>
                                <a className="mr-2 shrink-0">
                                  <img
                                    src={userImage}
                                    alt="owner"
                                    className="h-5 w-5 rounded-full"
                                  />
                                </a>
                              </Link>
                              <span className="dark:text-jacarta-400 mr-1">by</span>
                              <Link href={`/item/item_${itemLink}`}>
                                <a className="text-accent">
                                  <span>{userName}</span>
                                </a>
                              </Link>
                            </div>*/}
                      <span className='text-sm dark:text-jacarta-300'>
                        {_count?.items || itemCount} Items
                      </span>
                    </div>
                  </div>
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* <!-- Slider Navigation --> */}
      <div
        onClick={() => swiperRef?.current?.swiper?.slidePrev()}
        className='swiper-button-prev group absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer select-none items-center justify-center rounded-full bg-white p-3 text-xl text-jacarta-700 shadow-white-volume after:hidden sm:!-left-6'
      >
        <MdKeyboardArrowLeft />
      </div>
      <div
        onClick={() => swiperRef?.current?.swiper?.slideNext()}
        className='swiper-button-next group absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer select-none items-center justify-center rounded-full bg-white p-3 text-xl text-jacarta-700 shadow-white-volume after:hidden sm:!-right-6'
      >
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default Feature_collections_carousel;
