// import Link from 'next/link';
import { getAddress } from 'viem';
import { getTokenName } from '../../../helpers/getChainName';
import { getCdnUrl } from '../../../lib/cdnUrl';
import { ChainIcon } from '../../Icon';
import { ImageFixedAO } from '../../Image_fixed_aspect_ratio';

export const ModalItemDetails = ({ item, collection, isGlobal = false }) => {
  if (!item && !collection) return null;
  const metadata = typeof item?.metadata === 'string' ? JSON.parse(item?.metadata) : item?.metadata;

  const isMoonpetsItem =
    item?.collectionAddress === getAddress('0xE32aE22Ec60E21980247B4bDAA16E9AEa265F919');
  const itemImage = isMoonpetsItem
    ? `https://moonpets.com/api/nftThumbnailimage/${item.itemId}`
    : item?.imageFtpPath || item?.metadata?.image;

  const chain = item?.chain || collection?.chain;
  const name =
    collection?.contractType && collection?.contractType === 'ERC1155'
      ? `${item?.name} #${item?.itemId}`
      : item?.name || metadata?.name || collection?.name + ' #' + item?.itemId;

  return (
    <div className='mb-2 flex'>
      <div className='mt-2 mb-2 inline-flex w-[35%] items-center justify-center'>
        {(itemImage || collection?.profilePhotoPath) && (
          <ImageFixedAO
            image={
              isMoonpetsItem && !isGlobal
                ? itemImage
                : getCdnUrl(
                    (!isGlobal ? itemImage : collection?.profilePhotoPath) || '',
                    100,
                    item?.updatedAt || collection?.updatedAt,
                  )
            }
            bottomRounded
          />
        )}
      </div>
      <div className='w-full pt-4 pl-4 pb-4'>
        {/* <h1 className='font-bold'>{!isGlobal ? name : collection?.name}</h1> */}
        {/* <Link
          href={`${
            !isGlobal
              ? `/item/${item?.chain}/${item?.collectionAddress}/${item?.itemId}`
              : `/collection/${collection?.chain}/${collection?.address}`
          }`}
        > */}
        {/* <a target='_blank' className='text-base font-bold text-accent-dark dark:text-accent'> */}
        <span className='text-base font-bold text-jacarta-900 dark:text-jacarta-100'>
          {!isGlobal ? name : collection?.name}
        </span>
        {/* </a> */}
        {/* </Link> */}
        {item?.collection?.collectionmetrics?.floorPrice ||
        collection?.collectionmetrics?.floorPrice ? (
          <p className='flex text-sm font-medium text-jacarta-500 dark:text-jacarta-300'>
            Floor Price:
            <span className='ml-auto flex'>
              {item?.collection?.collectionmetrics?.floorPrice ??
                collection?.collectionmetrics?.floorPrice}
              <div className='mt-0.5 ml-1'>
                <ChainIcon width={13} name={chain} tooltip={getTokenName(chain)} />
              </div>
            </span>
          </p>
        ) : null}
        {item?.collection?.collectionmetrics?.topGlobalBid ||
        collection?.collectionmetrics?.topGlobalBid ? (
          <p className='flex text-sm font-medium text-jacarta-500 dark:text-jacarta-300'>
            Top Global Bid:
            <span className='ml-auto flex'>
              {item?.collection?.collectionmetrics?.topGlobalBid ??
                collection?.collectionmetrics?.topGlobalBid}
              <div className='mt-0.5 ml-1'>
                <ChainIcon width={13} name={chain} tooltip={getTokenName(chain)} />
              </div>
            </span>
          </p>
        ) : null}
      </div>
    </div>
  );
};
