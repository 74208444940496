import Link from 'next/link';
import React from 'react';
import { LuArrowUpDown } from 'react-icons/lu';

function Gotoswapwidget({ swapRef, isVisible }) {
  return (
    <div ref={swapRef}>
      {!isVisible && (
        <div className='rounded-2.5xl'>
          <button className='hidden sm:block'>
            <Link href={'/#SquadSwapWidget'}>
              <div className='relative z-auto mb-1.5'>
                <div className='relative flex h-[42px] w-[111px] flex-col rounded-[8px] bg-[#9172FF] shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)]'>
                  <span className='absolute top-1.5 left-2 font-display text-sm text-jacarta-800 '>
                    Need a Token?
                  </span>
                  <span className='absolute bottom-0.5 left-2 text-[14px] font-bold text-white '>
                    Swap Now!
                  </span>
                </div>
                <div className='absolute -right-3 -bottom-2 z-10 h-8 w-8 rounded-full bg-[#9172FF] drop-shadow-md'>
                  <div>
                    <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform'>
                      <div className='flex h-6 w-6 items-center justify-center rounded-full bg-white'>
                        <LuArrowUpDown color='black' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </button>
        </div>
      )}
    </div>
  );
}

export default Gotoswapwidget;
