import React, { useRef, useState } from 'react';
import { MainSearchResults } from './mainSearchResults';

export const MainSearch = () => {
  const [search, setSearch] = useState('');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef();

  return (
    <>
      <div
        className={`${
          mobileOpen
            ? 'fixed top-0 left-0 mt-[17px] ml-2 h-[60px] w-[96%] rounded-lg bg-white dark:bg-jacarta-800'
            : 'relative ml-4 hidden md:block'
        } z-[100] mr-2 w-full md:z-50 md:ml-12 md:mr-8`}
      >
        {search && isOpen && (
          <div
            onClick={() => setIsOpen(false)}
            className={`fixed top-0 left-0 mt-[145px] h-[100vh] w-full bg-jacarta-700 ${
              !mobileOpen && 'opacity-30'
            } md:mt-[0px]`}
          />
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            //router.push("/search?key=" + search)
            //setIsOpen(false)
          }}
          className={`relative z-10 ${mobileOpen && 'h-full'} w-[100%] basis-3/12 lg:block`}
          data-testid='mainSearch'
        >
          <input
            ref={inputRef}
            onFocus={() => setIsOpen(true)}
            // onBlur={() => setMobileOpen(false)}
            type='search'
            value={isOpen ? search : ''}
            onChange={(e) => setSearch(e.target.value)}
            className={`${(!isOpen || !search) && 'opacity-70'} ${
              mobileOpen && 'h-full'
            } w-full rounded-lg border border-jacarta-100 py-[0.4475rem] px-4 pl-10 text-jacarta-700 placeholder-jacarta-500 focus:ring-accent dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white`}
            placeholder='Search'
          />
          {(mobileOpen || search) && (
            <svg
              onClick={() => {
                setSearch('');
                setIsOpen(false);
                if (mobileOpen && !search) setMobileOpen(false);
              }}
              className={`absolute top-3 ${
                mobileOpen && 'top-[22px]'
              } right-4 w-[15px] cursor-pointer text-jacarta-600 dark:text-white`}
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <defs />
              <title>close</title>
              <line
                x1='0.75'
                y1='23.249'
                x2='23.25'
                y2='0.749'
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5px'
              />
              <line
                x1='23.25'
                y1='23.249'
                x2='0.75'
                y2='0.749'
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5px'
              />
            </svg>
          )}
          <span className='rounded-2xl pointer-events-none absolute left-0 top-0 flex h-full w-12 items-center justify-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              width='24'
              height='24'
              className='h-4 w-4 fill-jacarta-500 dark:fill-white'
            >
              <path fill='none' d='M0 0h24v24H0z' />
              <path d='M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z' />
            </svg>
          </span>
        </form>
        {search && isOpen && (
          <MainSearchResults
            search={search}
            inputRef={inputRef}
            mobileOpen={mobileOpen}
            setIsOpen={setIsOpen}
            key={`mainsearch-${search}`}
          />
        )}
      </div>
      <div className={mobileOpen ? 'hidden' : 'w-full md:hidden'}>
        <button
          onClick={() => setMobileOpen(!mobileOpen)}
          className={`${
            !mobileOpen && 'hover:bg-accent'
          } js-dark-mode-trigger group ml-auto flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:text-white focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent`}
        >
          {!mobileOpen && (
            <svg className={'w-[16px]'} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
              <path
                d='M23.41,20.59,18.77,16a10.25,10.25,0,1,0-8.52,4.56,10.14,10.14,0,0,0,5.69-1.74l4.65,4.65a2,2,0,0,0,2.82,0A2,2,0,0,0,23.41,20.59ZM10.25,3A7.25,7.25,0,1,1,3,10.26,7.26,7.26,0,0,1,10.25,3Z'
                fill='currentColor'
              />
            </svg>
          )}
        </button>
      </div>
    </>
  );
};
