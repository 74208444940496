import { useState } from 'react';
import { useCartFunctions } from '../../cart/useCartFunctions';

export const AddToCartButton = ({ item }) => {
  const { addItemToCart } = useCartFunctions();

  return (
    <div
      className={
        'relative z-[1] flex h-[40px] w-[40px] items-center justify-center gap-[10px] rounded-[6px] bg-jacarta-700 bg-opacity-75 align-middle transition-colors duration-300 hover:bg-white hover:bg-opacity-100 md:h-[32px] md:w-[32px]'
      }
      data-testid='addToCart'
      style={{ cursor: 'pointer' }}
      onClick={() => {
        addItemToCart([item]);
      }}
    >
      <svg
        width='25'
        height='26'
        viewBox='0 0 25 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={
          'visible relative bottom-[1px] h-full w-full fill-white stroke-white p-1 transition-colors duration-300 hover:fill-[#8358FF] hover:stroke-[#8358FF]'
        }
      >
        <path
          d='M24.1604 5.91094C24.1604 3.22683 21.9619 1 19.278 1C16.5942 1 14.3671 3.22683 14.3671 5.91094C14.3671 6.79601 14.6083 7.64559 15.0081 8.35926L4.40243 7.7094L3.9732 6.27864C3.91597 6.04972 3.57259 5.30573 1.76983 5.21988C1.06504 5.1761 1.02927 5.52034 1.02927 5.52034C0.881617 6.03885 1.3406 6.02111 1.3406 6.02111C2.59967 6.02111 3.05752 6.33588 3.11475 6.45034L6.54857 17.8678L5.06058 20.2142C5.00335 20.3001 4.7172 20.7007 4.94612 21.1299C5.11781 21.4447 5.46119 21.5305 5.94765 21.5305H18.4525C18.6528 21.5305 18.7959 21.7595 18.7959 21.9312C18.7959 22.1601 18.9962 22.389 19.2251 22.389C19.454 22.389 19.6543 22.1315 19.6543 21.9025C19.6543 21.2444 19.1106 20.6721 18.4525 20.6721H5.83319L7.29257 18.3543L18.9389 17.8106C19.5399 17.8106 19.9977 17.3527 20.1122 16.6946L21.3629 10.3346C24.2482 8.90008 24.1604 5.91094 24.1604 5.91094ZM19.2823 16.5515C19.2537 16.6373 19.1965 16.9521 18.9103 16.9521L7.3498 17.5244L4.65997 8.53925L15.6167 9.18251C16.4474 10.1998 17.8218 10.8219 19.2494 10.8219C19.2494 10.8219 19.5854 10.8436 20.4198 10.6671L19.2823 16.5515ZM22.4472 6.28236C22.4472 6.45377 22.2188 6.53933 22.076 6.53933H20.1345C19.9631 6.53933 19.8775 6.71073 19.8775 6.85352V8.90924C19.8775 9.05203 19.7061 9.08064 19.5633 9.08064H18.9638C18.7927 9.08064 18.7355 9.08064 18.7355 8.90924V6.85352C18.7355 6.71073 18.5644 6.53933 18.393 6.53933H16.4514C16.28 6.53933 16.1659 6.42515 16.1659 6.28236V5.73982C16.1659 5.5687 16.28 5.3973 16.4514 5.3973H18.393C18.5358 5.3973 18.7355 5.31174 18.7355 5.16895V3.11323C18.7355 2.97044 18.8211 2.82765 18.9638 2.82765H19.5633C19.7061 2.82765 19.8775 2.97044 19.8775 3.11323V5.16895C19.8775 5.31174 19.9917 5.3973 20.1345 5.3973H22.076C22.2188 5.3973 22.4472 5.59703 22.4472 5.73982V6.28236Z'
          strokeWidth='0.5'
        />
        <path d='M17.5444 24.9994C18.1806 24.9994 18.6964 24.4836 18.6964 23.8474C18.6964 23.2111 18.1806 22.6953 17.5444 22.6953C16.9081 22.6953 16.3923 23.2111 16.3923 23.8474C16.3923 24.4836 16.9081 24.9994 17.5444 24.9994Z' />
        <path d='M5.42573 24.9994C6.06199 24.9994 6.57778 24.4836 6.57778 23.8474C6.57778 23.2111 6.06199 22.6953 5.42573 22.6953C4.78947 22.6953 4.27368 23.2111 4.27368 23.8474C4.27368 24.4836 4.78947 24.9994 5.42573 24.9994Z' />
      </svg>
    </div>
  );
};

export const RemoveFromCartButton = ({ item }) => {
  const { removeItemFromCart } = useCartFunctions();
  const [isHoverBid, setIsHoverBid] = useState(false);
  const toggleHoverBid = () => {
    setIsHoverBid(!isHoverBid);
  };

  return (
    <div
      className={
        'relative z-[1] h-[40px] w-[40px] gap-[10px] rounded-[6px] bg-[#8358FF] align-middle transition-colors duration-300 hover:bg-red md:h-[32px] md:w-[32px]'
      }
      data-testid='removeFromCart'
      style={{ cursor: 'pointer' }}
      onClick={() => {
        removeItemFromCart(item);
      }}
      onMouseEnter={toggleHoverBid}
      onMouseLeave={toggleHoverBid}
    >
      <svg
        width='24'
        height='25'
        viewBox='0 0 24 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='h-full w-full p-1'
      >
        <path
          {...(!isHoverBid
            ? {
                d: 'M23.1603 4.91095C23.1603 2.22684 20.9618 0 18.278 0C15.5942 0 13.367 2.22684 13.367 4.91095C13.367 5.79602 13.6083 6.64561 14.008 7.35928L3.40235 6.70942L2.97312 5.27866C2.91589 5.04974 2.57251 4.30574 0.769746 4.21989C0.0649522 4.17611 0.0291831 4.52035 0.0291831 4.52035C-0.118186 5.03915 0.340517 5.02112 0.340517 5.02112C1.59959 5.02112 2.05743 5.33589 2.11466 5.45035L5.5485 16.8678L4.0605 19.2143C4.00327 19.3001 3.71712 19.7008 3.94604 20.13C4.11773 20.4448 4.46112 20.5306 4.94758 20.5306H17.4525C17.6528 20.5306 17.7958 20.7595 17.7958 20.9312C17.7958 21.1601 17.9961 21.3891 18.2251 21.3891C18.454 21.3891 18.6543 21.1315 18.6543 20.9026C18.6543 20.2444 18.1106 19.6721 17.4525 19.6721H4.83312L6.29249 17.3543L17.9389 16.8106C18.5398 16.8106 18.9977 16.3528 19.1121 15.6946L20.3629 9.33459C23.2482 7.9001 23.1603 4.91095 23.1603 4.91095ZM18.2823 15.5515C18.2537 15.6374 18.1964 15.9522 17.9103 15.9522L6.34972 16.5245L3.65989 7.53927L14.6167 8.18254C15.4474 9.19981 16.8218 9.82191 18.2494 9.82191C18.2494 9.82191 18.5853 9.84365 19.4198 9.6671L18.2823 15.5515ZM21.5613 3.48362L17.4213 7.62368C17.3071 7.73786 17.1357 7.73786 17.0215 7.62368L14.6233 5.22543C14.5091 5.11126 14.5091 4.93985 14.6233 4.82568L14.8802 4.56871C14.9944 4.45454 15.1658 4.45454 15.28 4.56871L17.0215 6.31024C17.1357 6.42441 17.3071 6.42441 17.4213 6.31024L20.9046 2.8269C21.0188 2.71273 21.1902 2.71273 21.3044 2.8269L21.5613 3.08387C21.6755 3.16943 21.6755 3.36916 21.5613 3.48362Z',
              }
            : {
                d: 'M23.2652 4.91103C23.2652 2.22687 21.0666 0 18.3828 0C15.6989 0 13.4717 2.22687 13.4717 4.91103C13.4717 5.79611 13.713 6.64571 14.1127 7.35938L3.50689 6.70952L3.07766 5.27874C3.02043 5.04981 2.67704 4.3058 0.87425 4.21996C0.169445 4.17617 0.133676 4.52042 0.133676 4.52042C-0.0136951 5.03922 0.445015 5.0212 0.445015 5.0212C1.70411 5.0212 2.16196 5.33597 2.21919 5.45043L5.65307 16.8681L4.16505 19.2146C4.10782 19.3004 3.82167 19.701 4.05059 20.1303C4.22229 20.4451 4.56567 20.5309 5.05214 20.5309H17.5572C17.7575 20.5309 17.9006 20.7598 17.9006 20.9315C17.9006 21.1604 18.1009 21.3894 18.3298 21.3894C18.5587 21.3894 18.7591 21.1318 18.7591 20.9029C18.7591 20.2447 18.2154 19.6724 17.5572 19.6724H4.93768L6.39708 17.3546L18.0437 16.8109C18.6446 16.8109 19.1024 16.353 19.2169 15.6948L20.4677 9.33473C23.353 7.90022 23.2652 4.91103 23.2652 4.91103ZM18.3871 15.5518C18.3584 15.6376 18.3012 15.9524 18.015 15.9524L6.45431 16.5247L3.76443 7.53938L14.7214 8.18266C15.5521 9.19995 16.9265 9.82205 18.3541 9.82205C18.3541 9.82205 18.6901 9.8438 19.5245 9.66724L18.3871 15.5518ZM21.6947 5.31079C21.6947 5.48219 21.5806 5.59637 21.4092 5.59637H15.6986C15.5272 5.59637 15.413 5.48219 15.413 5.31079V4.73962C15.413 4.56821 15.5272 4.45403 15.6986 4.45403H21.4092C21.5806 4.45403 21.6947 4.56821 21.6947 4.73962V5.31079Z',
              })}
          fill='white'
        />
        <path
          d='M16.6491 23.9999C17.2854 23.9999 17.8012 23.4841 17.8012 22.8479C17.8012 22.2116 17.2854 21.6958 16.6491 21.6958C16.0129 21.6958 15.4971 22.2116 15.4971 22.8479C15.4971 23.4841 16.0129 23.9999 16.6491 23.9999Z'
          fill='white'
          stroke='white'
          strokeWidth='0.5'
        />
        <path
          d='M4.53 23.9999C5.16627 23.9999 5.68207 23.4841 5.68207 22.8479C5.68207 22.2116 5.16627 21.6958 4.53 21.6958C3.89373 21.6958 3.37793 22.2116 3.37793 22.8479C3.37793 23.4841 3.89373 23.9999 4.53 23.9999Z'
          fill='white'
          stroke='white'
          strokeWidth='0.5'
        />
      </svg>
    </div>
  );
};
