import { useNetwork, useSwitchNetwork } from 'wagmi';
import { useCartFunctions } from './useCartFunctions';
import { useState, useEffect } from 'react';
import { useGetChain } from '../../hooks/useGetChain';
import BulkBid from '../bulkBid';

export const CartSubmit = () => {
  const { chain } = useNetwork();
  const [buttonText, setButtonText] = useState('Buy Now');
  const { switchNetwork } = useSwitchNetwork();
  const { itemList, cartChainId, buyEnabled, buyStatusText, buyBatch, nativeBalance } =
    useCartFunctions();
  const [isEnoughBalance, setIsEnoughBalance] = useState(true);

  const isIncludeBid = itemList?.some((item) => item?.orders?.length == 0);

  const areAllItemsSameChainId = itemList.every((item) => item.chain === itemList[0].chain);

  const itemsChainId = useGetChain(itemList[0]?.chain)?.chain?.id;

  useEffect(() => {
    let totalPrice = 0;

    itemList.map((item) => {
      totalPrice += item.price;
    });

    if (isIncludeBid) {
      setIsEnoughBalance(true);
    } else {
      if (parseFloat(nativeBalance?.formatted || '0') < totalPrice) {
        setIsEnoughBalance(false);
        setButtonText('Insufficient Funds');
      } else {
        setIsEnoughBalance(true);
        setButtonText('Buy ' + itemList.length + ' Now');
      }
    }
  }, [itemList]);

  useEffect(() => {
    if (buyEnabled) {
      setButtonText('Buy ' + itemList.length + ' Now');
    } else {
      setButtonText(buyStatusText);
    }
  }, [buyEnabled]);

  return (
    <>
      {areAllItemsSameChainId && itemList.length !== 0 && cartChainId && cartChainId > 0 ? (
        chain?.id != itemsChainId ? (
          <div className='mt-10 w-full px-[16px]'>
            <button
              className={`w-full rounded-[12px] bg-orange py-[12px] text-center text-white`}
              onClick={(e) => {
                switchNetwork(cartChainId);
              }}
            >
              Switch Chain
            </button>
          </div>
        ) : (
          <div>
            <div className='mt-10 w-full px-[16px]'>
              {itemList.length > 0 && (
                <button
                  onClick={() => {
                    buyBatch(chain?.id, itemList);
                  }}
                  disabled={!buyEnabled || isIncludeBid || !isEnoughBalance}
                  className={`w-full rounded-[12px] bg-accent py-[12px] text-center text-white ${
                    !buyEnabled || isIncludeBid ? 'bg-opacity-50' : ''
                  }`}
                  data-testid='cartBuy'
                >
                  {buttonText}
                </button>
              )}
            </div>
            <BulkBid itemList={itemList} cartChainId={itemsChainId} />
          </div>
        )
      ) : null}
    </>
  );
};
