import { useEffect, useState } from 'react';
import { CartHeader } from './cart-header';
import { CartList } from './cart-list';
import { CartSummary } from './cart-summary';
import { CartSubmit } from './cart-submit';
import { useCartFunctions } from './useCartFunctions';
import BulkBid from '../bulkBid';

export const CartModal = () => {
  const {
    itemList,
    isCartOpen,
    toggleCart,
    refreshItemList,
    totalEtherPrice,
    totalUsdPrice,
    cartChainId,
  } = useCartFunctions();

  useEffect(() => {
    globalThis.document.body.classList.add('!overflow-hidden');
    if (!isCartOpen) {
      refreshItemList();
      globalThis.document.body.classList.remove('!overflow-hidden');
    }
  }, [isCartOpen]);

  return (
    <div
      className={`fixed left-0 top-0 z-[300] h-full w-[100%] md:h-[100vh] ${
        isCartOpen ? '' : 'pointer-events-none'
      }`}
    >
      <div
        className={`absolute left-0 top-0 h-full w-[100vw] ${
          isCartOpen ? 'opacity-1' : 'opacity-0'
        } duration-400 transition-all`}
        onClick={() => toggleCart(true)}
        style={{
          background: 'linear-gradient(180deg, #31355E77 0%, #13174077 100%)',
          filter: 'blur(12px)',
        }}
      />

      <div
        className={`absolute right-0 top-0 h-full w-[300px] bg-white dark:bg-jacarta-800 ${
          isCartOpen ? 'translate-x-0' : 'translate-x-[442px] lg:translate-x-[302px]'
        } transition-all duration-300`}
      >
        <div className='flex h-full flex-col justify-between py-3'>
          <CartHeader />

          <div className='flex h-full overflow-y-auto'>
            <CartList />
          </div>
          <div className='block'>
            <CartSummary
              itemList={itemList}
              totalEtherPrice={totalEtherPrice}
              totalUsdPrice={totalUsdPrice}
              cartChainId={cartChainId}
            />
            <CartSubmit />
          </div>
        </div>
      </div>
    </div>
  );
};
