import React, { useEffect, useState } from 'react';

export const VerticalFilterCollapsable = ({
  name,
  active,
  setActive,
  bold,
  disabledWhenClosed = false,
  isInitialyOpen = false,
  children,
  alwaysOn,
  absoluteMode,
}) => {
  const [isOpen, setIsOpen] = useState(isInitialyOpen);

  const ch = absoluteMode ? (
    isOpen || alwaysOn ? (
      <div className='b-[-5px] collapsable-absolute rounded-xl show absolute right-0 z-10 w-[13rem] whitespace-nowrap bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800 md:max-w-sm'>
        {children}
      </div>
    ) : null
  ) : (
    <div
      className={`overflow-hidden p-1 transition-all ${
        isOpen || alwaysOn ? 'h-fit' : '!m-0 h-0 !py-0'
      }`}
    >
      {(!disabledWhenClosed || isOpen) && (active ? active === name : true) && children}
    </div>
  );

  return (
    <div className={'w-full pb-2'}>
      <div
        onClick={() => {
          setIsOpen(active ? (active === name ? !isOpen : true) : !isOpen);
          setActive && setActive(name);
        }}
        className={'flex w-full cursor-pointer select-none py-3 pr-3'}
      >
        <p className={'capitalize ' + (bold ? 'font-bold' : '')}>{String(name).toLowerCase()}</p>
        {!alwaysOn && (
          <button
            className={
              'ml-auto ' + (isOpen && (active ? active === name : true) ? 'rotate-180' : '')
            }
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              width='24'
              height='24'
              className={`h-4 w-4 fill-jacarta-500 dark:fill-white ${
                bold ? 'scale-150' : 'scale-125'
              }`}
            >
              <path fill='none' d='M0 0h24v24H0z' />
              <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
            </svg>
          </button>
        )}
      </div>
      {ch}
    </div>
  );
};
