import React, { useEffect, useState } from 'react';
import { HeadLine } from '../component';
import { VerticalFilterContainer } from './verticalFilterContainer';
import { PriceRange, RarityRange } from '../price-range';
import { VerticalFilterCollapsable } from './collapsable';
import { infinite } from 'swr/infinite';
import DropDownItepProperties from '../dropdown/DropDownItemProperties';
import { CheckInput } from '../checkInput';
import DropDownItemCollections from '../dropdown/DropDownItemCollections';
import { LoadingSpinner } from '../loadingSpinner';
import DropDownItemNetworks from '../dropdown/DropDownItemNetworks';
import DropDownItemTags from '../dropdown/DropDownItemTags';
infinite();

export const VerticalFilterReset = ({
  filterObject,
  onChange,
  clearFilters,
  filterEverUsed,
  isFilterDefault,
}) => {
  return (
    <div>
      <div className={'flex flex-wrap'}>
        {filterEverUsed && !isFilterDefault && (
          <div
            onClick={() => clearFilters()}
            className={
              'relative mr-2 mb-2 w-fit cursor-pointer rounded-[300px] border border-jacarta-400 py-1 pl-2 pr-6 dark:border-jacarta-600'
            }
          >
            Clear Filters
            <svg
              className={'absolute top-3 right-2 w-[10px]'}
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <defs />
              <title>close</title>
              <line
                x1='0.75'
                y1='23.249'
                x2='23.25'
                y2='0.749'
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5px'
              />
              <line
                x1='23.25'
                y1='23.249'
                x2='0.75'
                y2='0.749'
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5px'
              />
            </svg>
          </div>
        )}
        {filterObject.collectionIds?.length > 0 && (
          <>
            {filterObject.collectionIds.map((ci, i) => {
              if (ci?.name) {
                return (
                  <div
                    onClick={() =>
                      onChange({
                        ...filterObject,
                        collectionIds: filterObject.collectionIds.filter(
                          (c) => c.address !== ci.address,
                        ),
                      })
                    }
                    key={'vfr' + ci.address + '-' + i}
                    className={
                      'relative mr-2 mb-2 w-fit cursor-pointer rounded-[300px] border border-jacarta-400 py-1 pl-2 pr-6 dark:border-jacarta-600'
                    }
                  >
                    {ci.name}
                    <svg
                      className={'absolute top-3 right-2 w-[10px]'}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                    >
                      <defs />
                      <title>close</title>
                      <line
                        x1='0.75'
                        y1='23.249'
                        x2='23.25'
                        y2='0.749'
                        fill='none'
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='1.5px'
                      />
                      <line
                        x1='23.25'
                        y1='23.249'
                        x2='0.75'
                        y2='0.749'
                        fill='none'
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='1.5px'
                      />
                    </svg>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </>
        )}
        {Object.keys(filterObject.itemAttributes)?.length > 0 && (
          <>
            {Object.keys(filterObject.itemAttributes).map((ia, i1) => {
              return filterObject.itemAttributes[ia].map((attr, i2) => {
                return (
                  <div
                    onClick={() =>
                      onChange({
                        ...filterObject,
                        itemAttributes: {
                          ...filterObject.itemAttributes,
                          [ia]: filterObject.itemAttributes[ia].filter((a) => a !== attr),
                        },
                      })
                    }
                    key={`vfr2-${ia}-${attr}-${i1}-${i2}`}
                    className={
                      'relative mr-2 mb-2 w-fit cursor-pointer rounded-[300px] border border-jacarta-400 py-1 pl-2 pr-6 dark:border-jacarta-600'
                    }
                  >
                    {ia}: {attr}
                    <svg
                      className={'absolute top-3 right-2 w-[10px]'}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                    >
                      <defs />
                      <title>close</title>
                      <line
                        x1='0.75'
                        y1='23.249'
                        x2='23.25'
                        y2='0.749'
                        fill='none'
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='1.5px'
                      />
                      <line
                        x1='23.25'
                        y1='23.249'
                        x2='0.75'
                        y2='0.749'
                        fill='none'
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='1.5px'
                      />
                    </svg>
                  </div>
                );
              });
            })}
          </>
        )}
      </div>
    </div>
  );
};
export const VerticalFilter = ({
  loading,
  filterObject,
  onChange,
  propertiesDisabled,
  collectionFilterDisabled,
  chainsDisabled,
  raritySetsEnabled,
  tags,
  disableScroll,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const rarityKey = raritySetsEnabled ? 'rarityPlusSet' : 'rarity';
  const handleFitlerEvents = (name, value) => {
    onChange({
      ...filterObject,
      [name]: value,
    });
  };
  return (
    <VerticalFilterContainer>
      <div className={'w-full pt-8 pb-2 pr-3 text-accent'}>
        <svg
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          className={'ml-auto w-[20px]'}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          strokeWidth='1.5px'
        >
          <defs />
          <title>filter</title>
          <line
            x1='0.75'
            y1='4.293'
            x2='23.25'
            y2='4.293'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <line
            x1='3.146'
            y1='9.431'
            x2='20.854'
            y2='9.431'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <line
            x1='8.72'
            y1='19.707'
            x2='15.28'
            y2='19.707'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <line
            x1='5.543'
            y1='14.569'
            x2='18.457'
            y2='14.569'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
      {loading && (
        <div className={'absolute top-[64px] right-0'}>
          <LoadingSpinner />
        </div>
      )}

      <div
        className={`fixed top-[var(--header-height)] left-0 z-50 h-full max-h-[-webkit-fill-available] w-full overflow-auto bg-white px-3 pt-1.5 pb-20 dark:bg-jacarta-700 md:relative md:top-0 md:block md:h-full md:max-h-navbar md:overflow-visible md:bg-transparent md:px-0 md:pb-0 md:dark:bg-transparent ${
          isFilterOpen ? 'block' : 'hidden'
        }`}
      >
        <div className={'block w-full cursor-pointer pt-8 pb-2 pr-3 text-accent md:hidden'}>
          <svg
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            className={'ml-auto w-[20px]'}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            strokeWidth='1.5px'
          >
            <defs />
            <title>filter</title>
            <line
              x1='0.75'
              y1='4.293'
              x2='23.25'
              y2='4.293'
              fill='none'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <line
              x1='3.146'
              y1='9.431'
              x2='20.854'
              y2='9.431'
              fill='none'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <line
              x1='8.72'
              y1='19.707'
              x2='15.28'
              y2='19.707'
              fill='none'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <line
              x1='5.543'
              y1='14.569'
              x2='18.457'
              y2='14.569'
              fill='none'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
        <CheckInput
          label={'Listed'}
          onChange={(val) => handleFitlerEvents('buy_now', val)}
          checked={filterObject.buy_now}
        />
        {/* <VerticalFilterCollapsable name={'Price'} bold alwaysOn> */}
        <div>
          <PriceRange
            value={filterObject.price}
            onChange={(val) => handleFitlerEvents('price', val)}
          />
          {/* </VerticalFilterCollapsable> */}
          {/* <VerticalFilterCollapsable name={'Rarity'} bold alwaysOn> */}
          <RarityRange
            value={filterObject[rarityKey]}
            onChange={(val) => handleFitlerEvents(rarityKey, val)}
            raritySetsEnabled={raritySetsEnabled}
          />
        </div>
        {/* </VerticalFilterCollapsable> */}
        <div className='pl-2'>
          {!propertiesDisabled && (
            <VerticalFilterCollapsable name={'Traits'} bold disabledWhenClosed={true}>
              <DropDownItepProperties
                value={filterObject.itemAttributes}
                onSelect={(val) => {
                  handleFitlerEvents('itemAttributes', val);
                }}
                disableScroll={disableScroll}
              />
            </VerticalFilterCollapsable>
          )}
          {!collectionFilterDisabled && (
            <VerticalFilterCollapsable isInitialyOpen name={'Collection'} bold disabledWhenClosed>
              <DropDownItemCollections
                selectedCollectionIds={filterObject.collectionIds}
                chainIds={filterObject.chainIds}
                onChange={(val) => handleFitlerEvents('collectionIds', val)}
              />
            </VerticalFilterCollapsable>
          )}
          {!chainsDisabled && (
            <VerticalFilterCollapsable name={'Chain'} bold disabledWhenClosed>
              <DropDownItemNetworks
                selectedChainIds={filterObject.chainIds}
                onChange={(val) => handleFitlerEvents('chainIds', val)}
              />
            </VerticalFilterCollapsable>
          )}
          {!!tags && tags.length > 0 && (
            <VerticalFilterCollapsable name={'Tags'} bold disabledWhenClosed>
              <DropDownItemTags
                selectedTags={filterObject.tags}
                onChange={(val) => handleFitlerEvents('tags', val)}
                tags={tags}
              />
            </VerticalFilterCollapsable>
          )}
        </div>
      </div>
    </VerticalFilterContainer>
  );
};
