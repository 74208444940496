import axios from 'axios';
import { ethers } from 'ethers';
import chains from './chains';
import { constants } from './constants';
import { prepareWriteContract, waitForTransaction, writeContract } from 'wagmi/actions';
import abi from './abi';
import { parseEther } from 'viem';

export const AddressZero = '0x0000000000000000000000000000000000000000';

export const approveERC20 = async (tokenContract, walletAddress, amount, contractAddress) => {
  try {
    const approvedAmount = await tokenContract.read.allowance([walletAddress, contractAddress]);

    if (approvedAmount < amount) {
      const tx = await tokenContract.write.approve([
        contractAddress,
        ethers?.constants?.MaxUint256,
      ]);
      await waitForTransaction({
        hash: tx,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const approveNFT = async (nftContract, walletaddress, contractAddress) => {
  try {
    const isApprovedForAll = await nftContract.read.isApprovedForAll([
      walletaddress,
      contractAddress,
    ]);

    if (isApprovedForAll) return true;

    const tx = await nftContract.write.setApprovalForAll([contractAddress, true]);

    await waitForTransaction({
      hash: tx,
    });

    return true;
  } catch (err) {
    throw err;
  }
};

export const getTokenBalance = async (tokenContract, walletAddress) => {
  try {
    return await tokenContract.read.balanceOf([walletAddress]);
  } catch (err) {
    return 0;
  }
};

export const approveSelectedCurrency = async (tokenContract, walletAddress, borrowAmount) => {
  try {
    let currencyAllowance = await tokenContract.read.allowance([
      walletAddress,
      LENDING_CONTRACT_ADDRESS,
    ]);

    if (currencyAllowance < BigInt(borrowAmount)) {
      const tx = await tokenContract.write.approve([
        LENDING_CONTRACT_ADDRESS,
        ethers.constants.MaxUint256,
      ]);
      await waitForTransaction({
        hash: tx,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const handleConvertWrapped = async ({ contract, balance, amount, parse = true }) => {
  try {
    const diff = balanceDifference({ balance, amount, parse });

    if (diff && 0 > Number(diff)) {
      const hash = await contract?.write?.deposit?.({
        value: Math.abs(diff).toString(),
      });
      await waitForTransaction({ hash });
    }
  } catch (err) {
    throw err;
  }
};

const balanceDifference = ({ balance, amount, parse }) => {
  try {
    if (!amount || !balance) return 0;
    return Number(balance?.value - (parse ? parseEther(amount) : BigInt(amount)));
    // return Number(
    //   parseUnits(balance?.formatted, 18).sub(parseUnits(amount, 18))
    // );
  } catch (err) {
    console.log('err', err);
    return 1;
  }
};

export const checkChain = async (chainHex) => {
  const chain = chains[chainHex];
  try {
    // if chain added, switch
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainHex }],
    });
  } catch (switchError) {
    // if chain is not added, request to add chain
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: chainHex,
              chainName: chain.name,
              rpcUrls: chain.rpcUrls,
              blockExplorerUrl: chain.blockExplorerUrl,
            },
          ],
        });
      } catch (addError) {
        throw 'Adding ' + chain.name + ' failed';
      }
    }
  }
};

export const formatSignature = (signature) => {
  let newSignature = signature;
  if (signature?.endsWith('00')) newSignature = signature?.slice(0, -2) + '1B';
  if (signature?.endsWith('01')) newSignature = signature?.slice(0, -2) + '1C';
  return newSignature;
};

// export const cancelOrder = async (
//   marketplaceContract,
//   signature,
//   kind,
//   chain,
//   setText,
//   paymentToken,
// ) => {
//   try {
//     if (!signature || typeof kind === 'undefined' || !chain) throw new Error('Invalid order');

//     const order = (
//       await axios.get(`${constants.api.url_new}/orders/getOrder`, {
//         params: {
//           signature,
//           kind,
//           chain,
//         },
//       })
//     ).data.data;

//     let newSignature = signature;

//     if (signature?.endsWith('00')) newSignature = signature?.slice(0, -2) + '1B';
//     if (signature?.endsWith('01')) newSignature = signature?.slice(0, -2) + '1C';

//     //console.log('newSignature', newSignature);

//     const tx = await marketplaceContract.write.cancelOrder([
//       {
//         issuer: order?.issuer,
//         nftAddress: order?.collectionAddress,
//         paymentToken,
//         price: order?.price,
//         tokenId: order?.globalBidAmount > 0 ? '0' : order?.itemId,
//         end: order?.end,
//         kind: order?.kind,
//         tokenKind: order?.tokenKind,
//         globalBidAmount: order?.globalBidAmount,
//         privileges: {
//           privilegedCollection: order?.privilegedCollection,
//           privilegedTokenId: order?.privilegedTokenId,
//         },
//       },
//       newSignature,
//     ]);

//     setText('Awaiting blockchain...');

//     await waitForTransaction({
//       hash: tx,
//     });

//     return tx;
//   } catch (err) {
//     throw err;
//   }
// };

export const cancelOrder = async ({
  marketplaceContract,
  signature,
  kind,
  chain,
  paymentToken,
  id,
}) => {
  try {
    if (!id || typeof kind === 'undefined' || !chain) throw new Error('Invalid order');

    const order = (
      await axios.get(`${constants.api.url_new}/orders/getOrder`, {
        params: {
          id,
          kind,
          chain,
        },
      })
    ).data.data;

    const newSignature = formatSignature(order?.signature);

    return () =>
      marketplaceContract?.write?.cancelOrder?.([
        {
          issuer: order?.issuer,
          nftAddress: order?.collectionAddress,
          paymentToken,
          price: order?.price,
          tokenId: order?.globalBidAmount > 0 ? '0' : order?.itemId,
          end: order?.end,
          kind: order?.kind,
          tokenKind: order?.tokenKind,
          globalBidAmount: order?.globalBidAmount,
          privileges: {
            privilegedCollection: order?.privilegedCollection,
            privilegedTokenId: order?.privilegedTokenId,
          },
          signature: newSignature,
          proof: order?.proof ? JSON.parse(order?.proof) : [],
        },
      ]);
  } catch (err) {
    throw err;
  }
};

export const cancelOrders = async (marketplaceContract, setText, chain, orderList) => {
  try {
    let orders = [];
    orderList.map((order) => {
      const newSignature = formatSignature(order?.signature);
      orders.push({ ...order, signature: newSignature });
    });

    const _orders = orders.map((order) => {
      return {
        issuer: order.issuer,
        nftAddress: order.collectionAddress,
        paymentToken: order.paymentToken,
        price: order.price,
        tokenId: order.globalBidAmount > 0 ? '0' : order.itemId,
        end: order.end,
        kind: order.kind,
        tokenKind: order.tokenKind,
        globalBidAmount: order.globalBidAmount,
        privileges: {
          privilegedCollection: order.privilegedCollection,
          privilegedTokenId: order.privilegedTokenId,
        },
        signature: order.signature,
        proof: order.proof ? JSON.parse(order.proof) : [],
      };
    });

    const tx = await marketplaceContract.write.cancelOrders([_orders]);

    setText('Awaiting blockchain...');

    await waitForTransaction({
      hash: tx,
    });

    return tx;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

/* 
export const cancelBulkOrder = async (marketplaceContract, setText, chain, nftList) => {
  try {
    let orders = [];
    nftList.map((nft) => {
      nft.orders.map((order) => {
        const newSignature = formatSignature(order?.signature);
        orders.push({ ...order, signature: newSignature });
      });
    });

    return orders.map((order) => {
      console.log('orderCancel', order);
      return {
        issuer: order.issuer,
        nftAddress: order.collectionAddress,
        paymentToken: order.paymentToken,
        price: order.price,
        tokenId: order.globalBidAmount > 0 ? '0' : order.itemId,
        end: order.end,
        kind: order.kind,
        tokenKind: order.tokenKind,
        globalBidAmount: order.globalBidAmount,
        privileges: {
          privilegedCollection: order.privilegedCollection,
          privilegedTokenId: order.privilegedTokenId,
        },
        signature: order.signature,
        proof: order.proof ? JSON.parse(order.proof) : [],
      };
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
}; */

export const cancelBulkOrder = async (marketplaceContract, setText, chain, orderList) => {
  try {
    let orders = [];
    orderList.map((order) => {
      const newSignature = formatSignature(order?.bulkSignature);
      orders.push({ ...order, bulkSignature: newSignature });
    });

    const _orders = orders.map((order) => {
      return {
        issuer: order.issuer,
        nftAddress: order.collectionAddress,
        paymentToken: order.paymentToken,
        price: order.price,
        tokenId: order.globalBidAmount > 0 ? '0' : order.itemId,
        end: order.end,
        kind: order.kind,
        tokenKind: order.tokenKind,
        globalBidAmount: order.globalBidAmount,
        privileges: {
          privilegedCollection: order.privilegedCollection,
          privilegedTokenId: order.privilegedTokenId,
        },
        signature: order.signature,
        proof: order.proof ? JSON.parse(order.proof) : [],
      };
    });

    const tx = await marketplaceContract.write.cancelOrderBatch([_orders], {
      gas: BigInt(2100000),
      gasPrice: 8000000000,
    });

    setText('Awaiting blockchain...');

    await waitForTransaction({
      hash: tx,
    });

    return tx;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const cancelAllOrders = async (singleOrderList, bulkOrderList, chain) => {
  try {
    let bulkOrders = [];
    bulkOrderList.map((order) => {
      const newSignature = formatSignature(order?.bulkSignature);
      bulkOrders.push({ ...order, bulkSignature: newSignature });
    });

    let signleOrders = [];
    singleOrderList.map((order) => {
      const newSignature = formatSignature(order?.signature);
      signleOrders.push({ ...order, signature: newSignature });
    });

    const _bulkOrders = bulkOrders.map((order) => {
      return {
        issuer: order.issuer,
        nftAddress: order.collectionAddress,
        paymentToken: order.paymentToken,
        price: order.price,
        tokenId: order.globalBidAmount > 0 ? '0' : order.itemId,
        end: order.end,
        kind: order.kind,
        tokenKind: order.tokenKind,
        globalBidAmount: order.globalBidAmount,
        privileges: {
          privilegedCollection: order.privilegedCollection,
          privilegedTokenId: order.privilegedTokenId,
        },
        signature: order.signature,
        proof: order.proof ? JSON.parse(order.proof) : [],
      };
    });

    const _signleOrders = signleOrders.map((order) => {
      return {
        issuer: order.issuer,
        nftAddress: order.collectionAddress,
        paymentToken: order.paymentToken,
        price: order.price,
        tokenId: order.globalBidAmount > 0 ? '0' : order.itemId,
        end: order.end,
        kind: order.kind,
        tokenKind: order.tokenKind,
        globalBidAmount: order.globalBidAmount,
        privileges: {
          privilegedCollection: order.privilegedCollection,
          privilegedTokenId: order.privilegedTokenId,
        },
        signature: order.signature,
        proof: order.proof ? JSON.parse(order.proof) : [],
      };
    });

    const { request } = await prepareWriteContract({
      address: chain.marketplace,
      abi,
      functionName: 'cancelAllOrders',
      args: [_signleOrders, _bulkOrders],
    });

    return () => writeContract(request);

    /*     return () =>
      marketplaceContract.write.cancelAllOrders([_signleOrders, _bulkOrders], {
        gas: BigInt(2100000),
        gasPrice: 8000000000,
      }); */
    /* 
    setText('Awaiting blockchain...');

    await waitForTransaction({
      hash: tx,
    }); */

    return tx;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const bulkWrapNFTs = async ({
  uwManagerContract,
  collectionAddress,
  tokenIds,
  //chain,
  //feePrice, // tokenIds.length * feePrice I guess
}) => {
  try {
    return () =>
      uwManagerContract.write.batchWrapNFT([collectionAddress, tokenIds], {
        value: BigInt(tokenIds.length) * parseEther('0.001'),
      });
  } catch (error) {
    console.error(error);
  }
};
