import Link from 'next/link';
import React from 'react';

const LoadingImage = () => {
  return (
    <div className='block aspect-square w-full'>
      <div className={`skeleton h-full w-full rounded-b-2lg rounded-t-2lg`} />
    </div>
  );
};

const LinkOrDiv = ({ href, children, ...props }) => {
  if (!href) return <div {...props}>{children}</div>;
  return (
    <Link href={href}>
      <a {...props}>{children}</a>
    </Link>
  );
};

export default function CardImageAO({ image, href, skeleton, className, children }) {
  return skeleton ? (
    <LoadingImage />
  ) : (
    <div className={'aspect-square w-full bg-accent-dark/20 '.concat(className)}>
      <LinkOrDiv
        style={{
          backgroundImage: image ? `url(${image})` : ``,
          backgroundColor: image ? `` : `#f3f3f3`,
          backgroundSize: 'cover',
          transition: 'opacity 0.3s ease-in-out',
        }}
        className='h-full w-full'
        href={href}
      />
      {children}
    </div>
  );
}
