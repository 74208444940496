import { constants } from '../helpers/constants';

const footerMenuList = [
  {
    id: 1,
    title: 'Marketplace',
    // title: "",
    diffClass: 'md:col-start-7',
    list: [
      {
        id: 1,
        href: '/collection/explore_nfts',
        text: 'Explore NFTs',
      },
      {
        id: 2,
        href: '/collection/explore_collection',
        text: 'Collections',
      },
      {
        id: 3,
        href: '/activity',
        text: 'Activity',
      },
      {
        id: 4,
        href: '/rankings',
        text: 'Rankings',
      },
    ],
  },
  // {
  //   id: 2,
  //   title: 'Stats',
  //   diffClass: '',
  //   list: [
  //     {
  //       id: 1,
  //       href: '/activities',
  //       text: 'Activities',
  //     },
  //     {
  //       id: 2,
  //       href: '/rankings',
  //       text: 'Rankings',
  //     },
  //     /* {
  //       id: 3,
  //       href: "/earnings",
  //       text: "Earnings",
  //     },*/
  //   ],
  // },
  {
    id: 4,
    title: 'Finance',
    diffClass: '',
    list: [
      {
        id: 1,
        href: constants?.helpers?.lending?.url ?? 'https://lending.bit5.com/',
        text: 'Lending',
      },
    ],
  },
  {
    id: 5,
    title: 'Gaming',
    diffClass: '',
    list: [
      {
        id: 1,
        href: '/gaming',
        text: 'Gaming',
      },
    ],
  },
  {
    id: 3,
    title: 'Launchpad',
    diffClass: '',
    list: [
      {
        id: 1,
        href: '/nft/minting',
        text: 'NFT Minting',
      },
    ],
  },
  {
    id: 99,
    title: 'Services',
    diffClass: '',
    list: [
      {
        id: 1,
        href: 'https://docs.bit5.com/services/convertion-of-static-nft-to-dynamic-nft-dnft-.',
        text: 'Converting to dNFT',
      },
      {
        id: 2,
        href: 'https://docs.bit5.com/services/nft-minting',
        text: 'NFT Minting',
      },
      {
        id: 3,
        href: 'https://docs.bit5.com/services/token-minting',
        text: 'Token Minting',
      },
      {
        id: 4,
        href: 'https://docs.bit5.com/',
        text: 'Docs',
      },
      {
        id: 5,
        href: 'https://docs.bit5.com/resources/faqs',
        text: 'FAQs',
      },
      {
        id: 6,
        href: 'https://docs.bit5.com/resources/links',
        text: 'Links',
      },
    ],
  },
  // {
  //   id: 99,
  //   title: 'Resources',
  //   diffClass: '',
  //   list: [
  //     {
  //       id: 1,
  //       href: 'https://docs.bit5.com/',
  //       text: 'Docs',
  //     },
  //     {
  //       id: 2,
  //       href: 'https://docs.bit5.com/resources/faqs',
  //       text: 'FAQs',
  //     },
  //     {
  //       id: 3,
  //       href: 'https://docs.bit5.com/resources/links',
  //       text: 'Links',
  //     },
  //   ],
  // },
];

const socialIcons = [
  {
    id: 1,
    href: 'https://t.me/Bit5Official',
    text: 'telegram',
  },
  {
    id: 2,
    href: 'https://twitter.com/Bit5Official',
    text: 'twitter',
  },
  {
    id: 3,
    href: 'https://discord.gg/5KexwvcTJD',
    text: 'discord',
  },
];

export { footerMenuList, socialIcons };
