import React, { useEffect, useState } from 'react';
import { getCdnUrl } from '../../lib/cdnUrl';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';
import { ChainIcon } from '../Icon';
import getChainName from '../../helpers/getChainName';
import { normalizeEther } from '../../helpers/digits';

const BulkToolBulkSell = ({ item, tokenPrice }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    setImage(getCdnUrl(item?.imageFtpPath, 'thumbnail', item?.updatedAt));
  }, [item.imageFtpPath]);

  return (
    <div className='flex justify-between'>
      <div className='flex'>
        <div className='mr-3 h-12 w-12 rounded '>
          <ImageFixedAO image={image} alt={item?.collectionAddress} bottomRounded />
        </div>
        <div className='flex flex-col'>
          <div className='text-base font-bold '>{item?.collection?.name}</div>
          <div className='text-sm font-normal'>{item?.name}</div>
        </div>
      </div>
      <div className='flex items-center'>
        <ChainIcon name={item?.collection?.chain} tooltip={getChainName(item?.collection?.chain)} />
        <div className='ml-1'>{item?.price}</div>
        <div className='whitespace-nowrap text-sm leading-7 dark:text-jacarta-300'>
          ~ ${normalizeEther(tokenPrice * item?.price)}
        </div>
      </div>
    </div>
  );
};

export default BulkToolBulkSell;
