export default {
  '0x38': {
    name: 'Binance Smart Chain',
    rpcUrls: [
      'https://bsc-dataseed1.binance.org/',
      'https://bsc-dataseed2.binance.org/',
      'https://bsc-dataseed3.binance.org/',
      'https://bsc-dataseed4.binance.org/',
    ],
    blockExplorerUrl: 'https://bscscan.com',
  },
  '0x61': {
    name: 'Binance Smart Chain Testnet',
    rpcUrls: [
      'https://data-seed-prebsc-1-s1.binance.org:8545/',
      'https://data-seed-prebsc-2-s1.binance.org:8545/',
    ],
    blockExplorerUrl: 'https://testnet.bscscan.com',
  },
};
