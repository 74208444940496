import { useEffect, useRef } from 'react';

export const OnEnterScreen = ({ onVisible }) => {
  const ref = useRef(null);

  useEffect(() => {
    let lastPos = window.innerHeight * 2;
    const e = () => {
      if (!ref.current) return;
      const currentPos = ref.current.getBoundingClientRect().top;
      if (lastPos > window.innerHeight && currentPos < window.innerHeight && onVisible) {
        onVisible();
      }
      lastPos = currentPos;
    };
    window.addEventListener('scroll', e);
    return () => {
      window.removeEventListener('scroll', e);
    };
  }, []);

  return <div ref={ref} className={'w-full'} />;
};
